import {
  ActionTree, GetterTree, Module, MutationTree,
} from 'vuex';
import { RootState } from '@/shared/store/types';
import {
  CampaignExampleAdsRequest,
  Creative,
  CustomizeCreativeRequest,
  MemberImage,
  UserImage,
  RecommendedAdsRequest,
  MemberImageRequest,
  ImageConfigRequest,
  ImageConfigResponse, ImageConfigResponse_Image as ImageConfigResponseImage,
  IColorPaletteJSON, CustomizeCreativeResponse,
} from '@/shared/gen/messages.pisa';
import defaultToast from '@/shared/lib/defaultToast';
import { BuilderService } from '@/shared/lib/api';

export interface MemberImageLibrary {
  images: MemberImage[];
  totalImages: number;
}

export interface Ads {
  local: Creative[];
  playable: Creative[];
  feedback: Creative[];
  click: Creative[];
  facebook: Creative[];
  instagram: Creative[];
}

export interface Themes {
  geo: string[];
  genre: string[];
  fan: string[];
  wallpaper: string[];
}

export interface FilterMapValidation {
  key: string;
  validation: any;
}

export interface ImageLibrarySelected {
  selectedImages: UserImage[];
  selectedMemberImages: MemberImage[];
}

export interface RecommendedAdsState {
  ads: Ads;
  imagesByThemeForAds: Themes,
  memberImageLibrary: MemberImageLibrary,
  processing: boolean;
  errors: string[];
  userEditedCopyIds: string[];
  userEditedMemberImages: boolean;
}

// This uses snake case because they are passed to the creative.
/* eslint-disable camelcase */
export interface ImageConfigJSONImage {
  color_palette: IColorPaletteJSON,
  urls: {
    [key:string]: string,
  }
}

export interface ImageConfigJSON {
  featured_image?: ImageConfigJSONImage,
  background_image?: ImageConfigJSONImage,
}
/* eslint-enable camelcase */

export const initialState: RecommendedAdsState = {
  ads: {
    local: [],
    playable: [],
    feedback: [],
    click: [],
    facebook: [],
    instagram: [],
  },
  imagesByThemeForAds: {
    geo: [],
    genre: [],
    fan: [],
    wallpaper: [],
  },
  memberImageLibrary: {
    images: [],
    totalImages: 0,
  },
  processing: false,
  errors: [],
  userEditedCopyIds: [],
  userEditedMemberImages: false,
};

function processImageConfigResponseImage(imageConfigResponseImage: ImageConfigResponseImage): ImageConfigJSONImage | undefined {
  if (imageConfigResponseImage && imageConfigResponseImage.urls && imageConfigResponseImage.urls.length > 0) {
    const urls: { [key: string]: string } = {};
    imageConfigResponseImage.urls.forEach((i) => {
      urls[i.key] = i.url;
    });
    return {
      color_palette: imageConfigResponseImage.colorPalette.toJSON(),
      urls,
    };
  }
  return undefined;
}

function processImageConfig(r: ImageConfigResponse): ImageConfigJSON {
  const imageConfig: ImageConfigJSON = {};
  if (r.featuredImage) {
    imageConfig.featured_image = processImageConfigResponseImage(r.featuredImage);
  }
  if (r.backgroundImage) {
    imageConfig.background_image = processImageConfigResponseImage(r.backgroundImage);
  }
  return imageConfig;
}

const actions: ActionTree<RecommendedAdsState, RootState> = {
  $_regenerateOrGet({ commit }, payload: { fn: 'regenerateRecommendedAds' | 'getRecommendedAds', campaignId: string }): Promise<void> {
    commit('setProcessing', true);
    return new Promise((resolve, reject) => {
      BuilderService(this.dispatch('profile/loggedIn'), (svc) => {
        const req = new RecommendedAdsRequest({
          campaignId: payload.campaignId,
        });
        svc[payload.fn](req, {}).then((response) => {
          // Extract ad strategies...
          const strategies: { [key: string]: Creative[] } = {};
          response.strategies.forEach((strategy) => {
            if (strategy.name === 'geo') {
              strategies.local = strategy.creatives;
            } else {
              strategies[strategy.name] = strategy.creatives;
            }
          });
          commit('setAds', strategies);
          // Extract themes...
          const themes: { [key: string]: string[] } = {};
          response.themes.forEach((theme) => {
            themes[theme.name] = theme.imageUrls;
          });
          commit('setImagesByThemeForAds', themes);
          resolve();
        }).catch((error: any) => {
          reject(error);
          defaultToast();
          commit('setErrors', [error.message]);
        }).finally(() => {
          commit('setProcessing', false);
          // Refresh member images...
          svc.listMemberImages(new MemberImageRequest({ campaignId: payload.campaignId }), {})
            .then((response) => {
              commit('setMemberImages', response.images);
            }).catch(() => {
              defaultToast();
            });
        });
      });
    });
  },
  listMemberImages({ commit }, payload: string): Promise<void> {
    return new Promise((resolve, reject) => {
      BuilderService(this.dispatch('profile/loggedIn'), (svc) => {
        svc.listMemberImages(new MemberImageRequest({ campaignId: payload }), {})
          .then((response) => {
            commit('setMemberImages', response.images);
            resolve();
          }).catch(() => {
            defaultToast();
            reject();
          });
      });
    });
  },
  regenerate({ dispatch }, payload: { campaignId: string }): Promise<void> {
    return dispatch('$_regenerateOrGet', { ...payload, fn: 'regenerateRecommendedAds' });
  },
  get({ dispatch }, payload: { campaignId: string }): Promise<null> {
    return dispatch('$_regenerateOrGet', { ...payload, fn: 'getRecommendedAds' });
  },
  // Compatibility with legacy calls...
  load({ dispatch }, payload: { draft: { id: string }}): Promise<null> {
    return dispatch('get', { campaignId: payload.draft.id });
  },
  getExamples({ commit }, payload: { campaignId: string }): Promise<string[]> {
    return new Promise((resolve, reject) => {
      BuilderService(this.dispatch('profile/loggedIn'), (svc) => {
        const req = new CampaignExampleAdsRequest({
          campaignId: payload.campaignId,
        });
        svc.getCampaignExampleAds(req, {}).then((resp) => {
          resolve(resp.creativeIds);
        }).catch((error: any) => {
          reject(error);
          defaultToast();
          commit('setErrors', [error.message]);
        });
      });
    });
  },
  saveCreative({ commit }, payload: { creative: Creative, campaignId: string }): Promise<void> {
    return new Promise((resolve, reject) => {
      BuilderService(this.dispatch('profile/loggedIn'), (svc) => {
        const req = new CustomizeCreativeRequest({
          campaignId: payload.campaignId,
          creativeId: payload.creative.id,
          customization: payload.creative.customization,
        });
        svc.customizeCreative(req, {}).then((res: CustomizeCreativeResponse) => {
          commit('updateCreative', res.creative);
          resolve();
        }).catch((error: any) => {
          reject(error);
          defaultToast();
          commit('setErrors', [error.message]);
        });
      });
    });
  },
  getImageConfig({ commit }, payload: { campaignId: string, creativeId: string, userImageId: string, memberImageId: string }): Promise<ImageConfigJSON> {
    return new Promise((resolve, reject) => {
      BuilderService(this.dispatch('profile/loggedIn'), (svc) => {
        const req = new ImageConfigRequest({
          campaignId: payload.campaignId,
          creativeId: payload.creativeId,
          userImageId: payload.userImageId,
          memberImageId: payload.memberImageId,
        });
        svc.imageConfig(req, {}).then((res: ImageConfigResponse) => {
          const imageJson = processImageConfig(res);
          resolve(imageJson);
        }).catch((error: any) => {
          reject(error);
          defaultToast();
          commit('setErrors', [error.message]);
        });
      });
    });
  },
  sendDisabledAdsToTheEnd({ commit }): void {
    commit('sendDisabledAdsToTheEnd');
  },
  userEditedMemberImages({ commit }): void {
    commit('setUserEditedMemberImages', true);
  },
  userEditedCopy({ commit }, payload: string): void {
    commit('addToUserEditedCopy', payload);
  },
};

const getters: GetterTree<RecommendedAdsState, RootState> = {
  getAds(state): Ads {
    return { ...state.ads };
  },
  getImagesByThemeForAds(state): Themes {
    return { ...state.imagesByThemeForAds };
  },
  getMemberImages(state): MemberImage[] {
    const emptyImagesArray: MemberImage[] = [];
    return emptyImagesArray.concat(state.memberImageLibrary.images);
  },
  getMemberImageById(state): (_: string) => MemberImage|undefined {
    return (id: string): MemberImage|undefined => (state.memberImageLibrary.images.find((i) => i.id === id));
  },
  getMemberImageCount(state): number {
    return state.memberImageLibrary.totalImages;
  },
  getErrors(state): Array<string> {
    return state.errors.slice(0);
  },
  isProcessing(state): boolean {
    return state.processing;
  },
  getCreativeControlMixpanelData(state): any {
    let userEditedAnyAd = false;
    const creativeControlMixpanelData = {
      numberUserCopyEditsApplied: 0,
      numberUserDisabledAds: 0,
      numberAdsEdited: 0,
      numberEnabledLocalAds: 0,
      numberEnabledPlayableAds: 0,
      numberEnabledFeedbackAds: 0,
      numberEnabledStreamingAds: 0,
      numberEnabledFBAds: 0,
      numberEnabledIGAds: 0,
      creativeControlEditsMade: false,
    };
    let distinctCopyEditedIds: string[] = [];
    if (state.userEditedCopyIds) {
      distinctCopyEditedIds = [...new Set(state.userEditedCopyIds)];
    }
    for (const [type, ads] of Object.entries({ ...state.ads })) {
      for (const ad of ads) {
        if (distinctCopyEditedIds && distinctCopyEditedIds.some((id) => id === ad.id)) {
          creativeControlMixpanelData.numberUserCopyEditsApplied += 1;
        }
        if (ad.properties.userEdited) {
          creativeControlMixpanelData.numberAdsEdited += 1;
          userEditedAnyAd = true;
        }
        if (!ad.customization.enabled) {
          creativeControlMixpanelData.numberUserDisabledAds += 1;
          userEditedAnyAd = true;
        } else {
          switch (type) {
            case 'local':
              creativeControlMixpanelData.numberEnabledLocalAds += 1;
              break;
            case 'instagram':
              creativeControlMixpanelData.numberEnabledIGAds += 1;
              break;
            case 'playable':
              creativeControlMixpanelData.numberEnabledPlayableAds += 1;
              break;
            case 'feedback':
              creativeControlMixpanelData.numberEnabledFeedbackAds += 1;
              break;
            case 'facebook':
              creativeControlMixpanelData.numberEnabledFBAds += 1;
              break;
            case 'click':
              creativeControlMixpanelData.numberEnabledStreamingAds += 1;
              break;
            default:
              // do nothing
              break;
          }
        }
      }
    }
    creativeControlMixpanelData.creativeControlEditsMade = userEditedAnyAd || state.userEditedMemberImages;
    return creativeControlMixpanelData;
  },
};

const mutations: MutationTree<RecommendedAdsState> = {
  sendDisabledAdsToTheEnd(state: RecommendedAdsState) {
    (Object.keys(initialState.ads) as Array<keyof Ads>).forEach((key) => {
      const disabledIndices: number[] = [];
      state.ads[key].forEach((creative, index) => {
        if (!creative.customization.enabled) {
          disabledIndices.push(index);
        }
      });
      disabledIndices.forEach((newAdsIndex, disabledIndicesIndex) => {
        state.ads[key].push(state.ads[key].splice(newAdsIndex - disabledIndicesIndex, 1)[0]);
      });
    });
  },
  setAds(state: RecommendedAdsState, payload: Ads) {
    (Object.keys(initialState.ads) as Array<keyof Ads>).forEach((key) => {
      const newAds = payload[key] || [];
      const newIndices = newAds.reduce<Partial<{[k: string]: number}>>((m, i, index) => {
        // eslint-disable-next-line no-param-reassign
        m[i.id] = index;
        return m;
      }, {});
      state.ads[key].forEach((creative, originalIndex) => {
        const newIndex = newIndices[creative.id];
        if (typeof newIndex === 'number' && newIndex !== originalIndex && originalIndex < newAds.length) {
          [newAds[originalIndex], newAds[newIndex]] = [newAds[newIndex], newAds[originalIndex]];
          newIndices[newAds[newIndex].id] = newIndex;
          newIndices[newAds[originalIndex].id] = originalIndex;
        }
      });
      state.ads[key] = newAds;
    });
  },
  setImagesByThemeForAds(state: RecommendedAdsState, payload: Themes) {
    state.imagesByThemeForAds = { ...initialState.imagesByThemeForAds, ...payload };
  },
  setMemberImages(state: RecommendedAdsState, payload: MemberImage[]) {
    payload.forEach((newImage) => {
      const existingIndex = state.memberImageLibrary.images.findIndex((i) => i.id === newImage.id);
      if (existingIndex === -1) {
        state.memberImageLibrary.images.push(newImage);
      }
    });
    state.memberImageLibrary.totalImages = state.memberImageLibrary.images.length;
  },
  setErrors(state: RecommendedAdsState, payload: Array<string>) {
    state.errors = payload.slice(0);
  },
  setProcessing(state: RecommendedAdsState, payload: boolean) {
    state.processing = payload;
  },
  updateCreative(state: RecommendedAdsState, modifiedCreative: Creative) {
    let theme: keyof typeof state.ads;
    // eslint-disable-next-line no-restricted-syntax
    for (theme in state.ads) {
      if (Object.prototype.hasOwnProperty.call(state.ads, theme)) {
        const index = state.ads[theme].findIndex((creative: Creative) => creative.id === modifiedCreative.id);
        if (index > -1) {
          state.ads[theme].splice(index, 1, modifiedCreative);
          break;
        }
      }
    }
  },
  setUserEditedMemberImages(state: RecommendedAdsState, payload: boolean) {
    state.userEditedMemberImages = payload;
  },
  addToUserEditedCopy(state: RecommendedAdsState, payload: string) {
    state.userEditedCopyIds.push(payload);
  },
};

export const recommendedAds: Module<RecommendedAdsState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
