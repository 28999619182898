















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ModalButton extends Vue {
  @Prop({ default: false, type: Boolean }) disabled: boolean;

  @Prop({ default: 'button' }) tag: string;

  @Prop({ default: 'button' }) type: string;

  @Prop({ default: 'primary' }) variant: string;

  @Prop({ default: () => {} }) buttonClasses: object;

  @Prop({ default: false, type: Boolean }) noFullWidth: boolean;

  @Prop({ default: 'Submit' }) text: string;

  get extractedClasses() {
    const classVal = {
      'full-width': !this.noFullWidth,
    };
    return { ...classVal, ...this.buttonClasses };
  }

  handleClick(event: Event) {
    this.$emit('click', event);
  }
}
