


















import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import format from 'date-fns/format';
import { Campaign } from '@/shared/store/campaign';
import { campaignAudio } from '@/shared/lib/campaign';

@Component
export default class AbortedCampaignCard extends Vue {
  @Prop() private campaign!: Campaign;

  @Prop({ default: false }) private isDraft: boolean;

  get createDate() {
    return this.campaign.endedAt ? format(this.campaign.endedAt, 'MMM d, yyyy') : '';
  }

  get releaseName() {
    return this.campaign.ugc!.fields.releaseName;
  }

  get campaignAudio() {
    return campaignAudio(this.campaign);
  }

  get songTitle() {
    if (this.campaignAudio) {
      return this.campaignAudio.source.name;
    }
    return '';
  }
}
