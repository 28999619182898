import Vue from 'vue';
import {
  ActionTree, GetterTree, Module, MutationTree,
} from 'vuex';
import { RootState } from '@/shared/store/types';
import {
  SearchRetailerRequest,
  SearchRetailerResponse, SearchRetailerResponse_Result as SearchRetailerResponseResult,
} from '@/shared/gen/messages.pisa';
import { BuilderService, BL_ROOT } from '@/shared/lib/api';

export interface LandingPage {
  retailer: string
  url: string
}

export interface SpotifyArtist {
  id: string
  name: string
}

export interface AlbumResult {
  name: string
  artistName: string
  artworkURL: string
  albumType: string
  genres: string[]
  landingPages: LandingPage[]
  lookupCodes: string[]
  spotifyArtists: SpotifyArtist[]
}

export interface ProviderLinks {
  processing: boolean
  searchResults: AlbumResult[]
  selectedSearchResult?: AlbumResult
}

export const initialState: ProviderLinks = {
  processing: false,
  searchResults: [],
};

function resultToAlbumResult(result: SearchRetailerResponseResult): AlbumResult {
  return {
    name: result.name,
    artistName: result.artistName,
    artworkURL: result.artworkUrl,
    albumType: result.albumType,
    genres: result.genres,
    landingPages: result.landingPages.map((lp) => ({ retailer: lp.retailer, url: lp.url })),
    lookupCodes: result.lookupCodes,
    spotifyArtists: result.spotifyArtists,
  };
}

const actions: ActionTree<ProviderLinks, RootState> = {
  $_performSearch(_, payload: SearchRetailerRequest): Promise<SearchRetailerResponse> {
    return new Promise((resolve, reject) => {
      BuilderService(this.dispatch('profile/loggedIn'), (svc) => {
        svc.searchRetailer(payload, {}).then((searchResults) => {
          resolve(searchResults);
        }).catch((error: any) => {
          reject(error);
        });
      });
    });
  },
  searchUpc({ commit, dispatch, getters }, upc: string): Promise<null> {
    return dispatch('$_performSearch', new SearchRetailerRequest({ upc }))
      .then((searchResults: SearchRetailerResponse) => {
        commit('searchResults', searchResults);
        return getters.getSearchResults;
      });
  },
  searchBL({ commit, dispatch, getters }, payload: string): Promise<null> {
    return dispatch('$_performSearch', new SearchRetailerRequest({ url: `${BL_ROOT}/revision/${payload}` }))
      .then((searchResults: SearchRetailerResponse) => {
        commit('searchResults', searchResults);
        return getters.getSearchResults;
      });
  },
  search({ commit, dispatch, getters }, payload: string): Promise<null> {
    commit('setProcessing', true);
    return dispatch('$_performSearch', new SearchRetailerRequest({ terms: payload })).then((searchResults: SearchRetailerResponse) => {
      commit('searchResults', searchResults);
      return getters.getSearchResults;
    }).finally(() => {
      commit('setProcessing', false);
    });
  },
  setSelectedIndex({ commit, getters }, payload: number): Promise<void> {
    return new Promise((resolve, reject) => {
      if (payload >= 0 && payload < getters.getSearchResults.length) {
        commit('setSelectedIndex', payload);
        resolve();
      } else {
        reject();
      }
    });
  },
  findAllRetailersForSelected({ dispatch, getters }): Promise<AlbumResult> {
    return new Promise((resolve, reject) => {
      const selectedAlbumResult: AlbumResult | undefined = getters.getSelectedResult;
      if (!selectedAlbumResult) {
        reject();
        return;
      }
      let lookupURL: string | undefined;
      const spotifyLandingPage = selectedAlbumResult.landingPages.find((i) => i.retailer === 'spotify');
      if (spotifyLandingPage) {
        lookupURL = spotifyLandingPage.url;
      } else {
        const notAppleLandingPage = selectedAlbumResult.landingPages.find((i) => i.retailer !== 'apple');
        if (notAppleLandingPage) {
          lookupURL = notAppleLandingPage.url;
        }
      }
      if (!lookupURL) {
        resolve(selectedAlbumResult);
        return;
      }
      dispatch('$_performSearch', new SearchRetailerRequest({ url: lookupURL })).then((searchResults: SearchRetailerResponse) => {
        const landingPages: LandingPage[] = [];
        const genres: string[] = selectedAlbumResult.genres.slice(0);
        selectedAlbumResult.landingPages.forEach((lp) => {
          landingPages.push({ ...lp });
        });
        if (searchResults && searchResults.results) {
          searchResults.results.forEach((i) => {
            const albumResult = resultToAlbumResult(i);
            albumResult.landingPages.forEach((lp) => {
              if (landingPages.findIndex((j) => j.retailer === lp.retailer) === -1) {
                landingPages.push({ ...lp });
              }
            });
            albumResult.genres.forEach((j) => genres.push(j));
          });
        }
        resolve({ ...selectedAlbumResult, genres, landingPages });
      }).catch(() => {
        resolve(selectedAlbumResult);
      });
    });
  },
  clearSearch({ commit }) {
    commit('clearSearch');
  },
};

const getters: GetterTree<ProviderLinks, RootState> = {
  isProcessing(state): boolean {
    return state.processing;
  },
  getSearchResults(state): AlbumResult[] {
    return state.searchResults.slice(0);
  },
  getSelectedResult(state): AlbumResult | undefined {
    if (state.selectedSearchResult) {
      return { ...state.selectedSearchResult };
    }
    return undefined;
  },
  getSelectedReleaseType(state): string | undefined {
    if (state.selectedSearchResult) {
      return state.selectedSearchResult.albumType;
    }
    return undefined;
  },
  getSelectedArtwork(state): string | undefined {
    if (state.selectedSearchResult) {
      return state.selectedSearchResult.artworkURL;
    }
    return undefined;
  },
};

const mutations: MutationTree<ProviderLinks> = {
  searchResults(state: ProviderLinks, searchResults: SearchRetailerResponse) {
    if (searchResults && searchResults.results) {
      state.searchResults = searchResults.results.map((i) => resultToAlbumResult(i));
    } else {
      state.searchResults = [];
    }
  },
  setSelectedIndex(state: ProviderLinks, payload: number) {
    if (payload < 0 || payload >= state.searchResults.length) {
      Vue.delete(state, 'selectedSearchResult');
    } else if (state.searchResults[payload]) {
      Vue.set(state, 'selectedSearchResult', state.searchResults[payload]);
    }
  },
  setProcessing(state: ProviderLinks, payload: boolean) {
    state.processing = payload;
  },
  clearSearch(state: ProviderLinks) {
    state.searchResults = [];
    Vue.delete(state, 'selectedSearchResult');
  },
};

export const retailerSearch: Module<ProviderLinks, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
