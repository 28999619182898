










import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import Pixel from '@/shared/components/pixels/Pixel.vue';

@Component({
  components: {
    Pixel,
  },
})
export default class Pixels extends Vue {
  @Getter('pixels/urls') urls: string[];

  get pixels() {
    return this.urls.map((url, index) => ({ key: `${index}|${url}`, src: url }));
  }
}
