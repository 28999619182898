























































































































































































import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import { Campaign } from '@/shared/store/campaign';
import { optionForDuration, adNetworksCopy } from '@/shared/lib/budgetOptions';
import { isInReview, Status } from '@/shared/lib/campaign_status';
import {
  campaignAudio, campaignImage, daysLeft, daysLeftText, stateText,
} from '@/shared/lib/campaign';
import LoadingIndicator from '@/shared/components/campaign/LoadingIndicator.vue';
import AbbreviatedValue from '@/shared/components/common/AbbreviatedValue.vue';

@Component({
  components: {
    AbbreviatedValue,
    LoadingIndicator,
  },
})
export default class CampaignCard extends Vue {
  @Prop({ required: true }) private campaign!: Campaign;

  @Prop({ default: 0 })
  private impressions: number;

  @Prop({ default: 0 })
  private interactions: number;

  loading: boolean = false;

  get state() {
    return this.campaign.status!;
  }

  get isInActiveState() {
    return [Status.Reviewing, Status.Running].includes(this.state);
  }

  get isInReview() {
    return isInReview(this.state);
  }

  get isRejected() {
    return this.state === Status.Rejected;
  }

  get isRunning() {
    return this.state === Status.Running;
  }

  get campaignAudio() {
    return campaignAudio(this.campaign);
  }

  get songTitle() {
    if (this.campaignAudio) {
      return this.campaignAudio.source.name;
    }
    return '';
  }

  get progressPercent() {
    if (this.isRunning) {
      const left = daysLeft(this.campaign);
      const duration = this.campaign.durationDays!;
      return (100 * (duration - left)) / duration;
    }
    return 0;
  }

  get daysLeftText() {
    return daysLeftText(this.campaign);
  }

  get stateClass() {
    return `state__${this.state.toLowerCase()}`;
  }

  get stateText() {
    return stateText(this.state);
  }

  get imageUrl() {
    return campaignImage(this.campaign);
  }

  get durationText() {
    return `${optionForDuration(this.campaign.durationDays!)!.durationSingular}`;
  }

  get adNetworksText() {
    return adNetworksCopy(this.campaign.adNetworks);
  }

  get runningImpressions() {
    if (this.isRunning) {
      return this.impressions;
    }
    return 0;
  }

  get runningInteractions() {
    if (this.isRunning) {
      return this.interactions;
    }
    return 0;
  }

  rejectedCampaign() {
    this.loading = true;
    this.$router.push({
      name: 'rejected-campaign',
      params: { id: this.campaign.id! },
    }).then(() => {
      this.loading = false;
    });
  }

  handleClick() {
    if (this.isRejected) {
      this.loading = true;
      this.$router.push({
        name: 'rejected-campaign',
        params: { id: this.campaign.id! },
      }).then(() => {
        this.loading = false;
      });
    } else {
      this.loading = true;
      this.$router.push({
        name: 'campaign-results',
        params: { id: this.campaign.id! },
      }).then(() => {
        this.loading = false;
      });
    }
  }
}
