import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import Env from '@/shared/lib/env';

const firebaseApp = firebase.initializeApp(Env.firebase);

export default firebaseApp;

export const Firebase = firebase;
export const FirebaseAuth = firebase.auth();
export const FirebaseStorage = firebase.storage();

FirebaseStorage.setMaxUploadRetryTime(2500);

export function isCanceled(error: any) {
  return error && error.code && error.code === 'storage/canceled';
}

export function isRetryLimitExceeded(error: any) {
  return error && error.code && error.code === 'storage/retry-limit-exceeded';
}
