/* eslint:disable */

// This file has been generated by https://github.com/reverbnation/protoc-gen-twirp_ts.
// Do not edit.
import * as google_protobuf from './google/protobuf/google.protobuf'


import {
  createTwirpRequest,
  Fetch,
  throwTwirpError
} from './twirp'


export interface IPaginationRequest {
  page?: number
  perPage?: number
  
  toJSON?(): object
}

export interface IPaginationRequestJSON {
  page?: number
  per_page?: number
  toJSON?(): object
}

export class PaginationRequest implements IPaginationRequest {
  private readonly _json: IPaginationRequestJSON;

  constructor(m?: IPaginationRequest) {
    this._json = {};
    if (m) {
      this._json['page'] = m.page;
      this._json['per_page'] = m.perPage;
    }
  }
  
  // page (page)
  public get page(): number {
    return this._json.page!
  }
  public set page(value: number) {
    this._json.page = value
  }
  
  // perPage (per_page)
  public get perPage(): number {
    return this._json.per_page!
  }
  public set perPage(value: number) {
    this._json.per_page = value
  }
  
  static fromJSON(m: IPaginationRequestJSON = {}): PaginationRequest {
    return new PaginationRequest({
      page: m['page']!,
      perPage: m['per_page']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IPaginationResponse {
  page?: number
  perPage?: number
  totalPages?: number
  totalEntries?: number
  
  toJSON?(): object
}

export interface IPaginationResponseJSON {
  page?: number
  per_page?: number
  total_pages?: number
  total_entries?: number
  toJSON?(): object
}

export class PaginationResponse implements IPaginationResponse {
  private readonly _json: IPaginationResponseJSON;

  constructor(m?: IPaginationResponse) {
    this._json = {};
    if (m) {
      this._json['page'] = m.page;
      this._json['per_page'] = m.perPage;
      this._json['total_pages'] = m.totalPages;
      this._json['total_entries'] = m.totalEntries;
    }
  }
  
  // page (page)
  public get page(): number {
    return this._json.page!
  }
  public set page(value: number) {
    this._json.page = value
  }
  
  // perPage (per_page)
  public get perPage(): number {
    return this._json.per_page!
  }
  public set perPage(value: number) {
    this._json.per_page = value
  }
  
  // totalPages (total_pages)
  public get totalPages(): number {
    return this._json.total_pages!
  }
  public set totalPages(value: number) {
    this._json.total_pages = value
  }
  
  // totalEntries (total_entries)
  public get totalEntries(): number {
    return this._json.total_entries!
  }
  public set totalEntries(value: number) {
    this._json.total_entries = value
  }
  
  static fromJSON(m: IPaginationResponseJSON = {}): PaginationResponse {
    return new PaginationResponse({
      page: m['page']!,
      perPage: m['per_page']!,
      totalPages: m['total_pages']!,
      totalEntries: m['total_entries']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ISearchRetailerRequest {
  upc?: string
  url?: string
  isrc?: string
  terms?: string
  
  toJSON?(): object
}

export interface ISearchRetailerRequestJSON {
  upc?: string
  url?: string
  isrc?: string
  terms?: string
  toJSON?(): object
}

export class SearchRetailerRequest implements ISearchRetailerRequest {
  private readonly _json: ISearchRetailerRequestJSON;

  constructor(m?: ISearchRetailerRequest) {
    this._json = {};
    if (m) {
      this._json['upc'] = m.upc;
      this._json['url'] = m.url;
      this._json['isrc'] = m.isrc;
      this._json['terms'] = m.terms;
    }
  }
  
  // upc (upc)
  public get upc(): string {
    return this._json.upc!
  }
  public set upc(value: string) {
    this._json.upc = value
  }
  
  // url (url)
  public get url(): string {
    return this._json.url!
  }
  public set url(value: string) {
    this._json.url = value
  }
  
  // isrc (isrc)
  public get isrc(): string {
    return this._json.isrc!
  }
  public set isrc(value: string) {
    this._json.isrc = value
  }
  
  // terms (terms)
  public get terms(): string {
    return this._json.terms!
  }
  public set terms(value: string) {
    this._json.terms = value
  }
  
  static fromJSON(m: ISearchRetailerRequestJSON = {}): SearchRetailerRequest {
    return new SearchRetailerRequest({
      upc: m['upc']!,
      url: m['url']!,
      isrc: m['isrc']!,
      terms: m['terms']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ISearchRetailerResponse_Result_LandingPage {
  retailer?: string
  url?: string
  
  toJSON?(): object
}

export interface ISearchRetailerResponse_Result_LandingPageJSON {
  retailer?: string
  url?: string
  toJSON?(): object
}

export class SearchRetailerResponse_Result_LandingPage implements ISearchRetailerResponse_Result_LandingPage {
  private readonly _json: ISearchRetailerResponse_Result_LandingPageJSON;

  constructor(m?: ISearchRetailerResponse_Result_LandingPage) {
    this._json = {};
    if (m) {
      this._json['retailer'] = m.retailer;
      this._json['url'] = m.url;
    }
  }
  
  // retailer (retailer)
  public get retailer(): string {
    return this._json.retailer!
  }
  public set retailer(value: string) {
    this._json.retailer = value
  }
  
  // url (url)
  public get url(): string {
    return this._json.url!
  }
  public set url(value: string) {
    this._json.url = value
  }
  
  static fromJSON(m: ISearchRetailerResponse_Result_LandingPageJSON = {}): SearchRetailerResponse_Result_LandingPage {
    return new SearchRetailerResponse_Result_LandingPage({
      retailer: m['retailer']!,
      url: m['url']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ISearchRetailerResponse_Result_SpotifyArtist {
  id?: string
  name?: string
  
  toJSON?(): object
}

export interface ISearchRetailerResponse_Result_SpotifyArtistJSON {
  id?: string
  name?: string
  toJSON?(): object
}

export class SearchRetailerResponse_Result_SpotifyArtist implements ISearchRetailerResponse_Result_SpotifyArtist {
  private readonly _json: ISearchRetailerResponse_Result_SpotifyArtistJSON;

  constructor(m?: ISearchRetailerResponse_Result_SpotifyArtist) {
    this._json = {};
    if (m) {
      this._json['id'] = m.id;
      this._json['name'] = m.name;
    }
  }
  
  // id (id)
  public get id(): string {
    return this._json.id!
  }
  public set id(value: string) {
    this._json.id = value
  }
  
  // name (name)
  public get name(): string {
    return this._json.name!
  }
  public set name(value: string) {
    this._json.name = value
  }
  
  static fromJSON(m: ISearchRetailerResponse_Result_SpotifyArtistJSON = {}): SearchRetailerResponse_Result_SpotifyArtist {
    return new SearchRetailerResponse_Result_SpotifyArtist({
      id: m['id']!,
      name: m['name']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ISearchRetailerResponse_Result {
  name?: string
  artistName?: string
  artworkUrl?: string
  albumType?: string
  genres?: string[]
  landingPages?: SearchRetailerResponse_Result_LandingPage[]
  lookupCodes?: string[]
  spotifyArtists?: SearchRetailerResponse_Result_SpotifyArtist[]
  
  toJSON?(): object
}

export interface ISearchRetailerResponse_ResultJSON {
  name?: string
  artist_name?: string
  artwork_url?: string
  album_type?: string
  genres?: string[]
  landing_pages?: SearchRetailerResponse_Result_LandingPage[]
  lookup_codes?: string[]
  spotify_artists?: SearchRetailerResponse_Result_SpotifyArtist[]
  toJSON?(): object
}

export class SearchRetailerResponse_Result implements ISearchRetailerResponse_Result {
  private readonly _json: ISearchRetailerResponse_ResultJSON;

  constructor(m?: ISearchRetailerResponse_Result) {
    this._json = {};
    if (m) {
      this._json['name'] = m.name;
      this._json['artist_name'] = m.artistName;
      this._json['artwork_url'] = m.artworkUrl;
      this._json['album_type'] = m.albumType;
      this._json['genres'] = m.genres;
      this._json['landing_pages'] = m.landingPages;
      this._json['lookup_codes'] = m.lookupCodes;
      this._json['spotify_artists'] = m.spotifyArtists;
    }
  }
  
  // name (name)
  public get name(): string {
    return this._json.name!
  }
  public set name(value: string) {
    this._json.name = value
  }
  
  // artistName (artist_name)
  public get artistName(): string {
    return this._json.artist_name!
  }
  public set artistName(value: string) {
    this._json.artist_name = value
  }
  
  // artworkUrl (artwork_url)
  public get artworkUrl(): string {
    return this._json.artwork_url!
  }
  public set artworkUrl(value: string) {
    this._json.artwork_url = value
  }
  
  // albumType (album_type)
  public get albumType(): string {
    return this._json.album_type!
  }
  public set albumType(value: string) {
    this._json.album_type = value
  }
  
  // genres (genres)
  public get genres(): string[] {
    return this._json.genres || []
  }
  public set genres(value: string[]) {
    this._json.genres = value
  }
  
  // landingPages (landing_pages)
  public get landingPages(): SearchRetailerResponse_Result_LandingPage[] {
    return this._json.landing_pages || []
  }
  public set landingPages(value: SearchRetailerResponse_Result_LandingPage[]) {
    this._json.landing_pages = value
  }
  
  // lookupCodes (lookup_codes)
  public get lookupCodes(): string[] {
    return this._json.lookup_codes || []
  }
  public set lookupCodes(value: string[]) {
    this._json.lookup_codes = value
  }
  
  // spotifyArtists (spotify_artists)
  public get spotifyArtists(): SearchRetailerResponse_Result_SpotifyArtist[] {
    return this._json.spotify_artists || []
  }
  public set spotifyArtists(value: SearchRetailerResponse_Result_SpotifyArtist[]) {
    this._json.spotify_artists = value
  }
  
  static fromJSON(m: ISearchRetailerResponse_ResultJSON = {}): SearchRetailerResponse_Result {
    return new SearchRetailerResponse_Result({
      name: m['name']!,
      artistName: m['artist_name']!,
      artworkUrl: m['artwork_url']!,
      albumType: m['album_type']!,
      genres: (m['genres']! || []).map((v) => { return String(v) }),
      landingPages: (m['landing_pages']! || []).map((v) => { return SearchRetailerResponse_Result_LandingPage.fromJSON(v) }),
      lookupCodes: (m['lookup_codes']! || []).map((v) => { return String(v) }),
      spotifyArtists: (m['spotify_artists']! || []).map((v) => { return SearchRetailerResponse_Result_SpotifyArtist.fromJSON(v) })
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ISearchRetailerResponse {
  results?: SearchRetailerResponse_Result[]
  
  toJSON?(): object
}

export interface ISearchRetailerResponseJSON {
  results?: SearchRetailerResponse_Result[]
  toJSON?(): object
}

export class SearchRetailerResponse implements ISearchRetailerResponse {
  private readonly _json: ISearchRetailerResponseJSON;

  constructor(m?: ISearchRetailerResponse) {
    this._json = {};
    if (m) {
      this._json['results'] = m.results;
    }
  }
  
  // results (results)
  public get results(): SearchRetailerResponse_Result[] {
    return this._json.results || []
  }
  public set results(value: SearchRetailerResponse_Result[]) {
    this._json.results = value
  }
  
  static fromJSON(m: ISearchRetailerResponseJSON = {}): SearchRetailerResponse {
    return new SearchRetailerResponse({
      results: (m['results']! || []).map((v) => { return SearchRetailerResponse_Result.fromJSON(v) })
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IUpload {
  file?: string
  industryId?: string
  
  toJSON?(): object
}

export interface IUploadJSON {
  file?: string
  industry_id?: string
  toJSON?(): object
}

export class Upload implements IUpload {
  private readonly _json: IUploadJSON;

  constructor(m?: IUpload) {
    this._json = {};
    if (m) {
      this._json['file'] = m.file;
      this._json['industry_id'] = m.industryId;
    }
  }
  
  // file (file)
  public get file(): string {
    return this._json.file!
  }
  public set file(value: string) {
    this._json.file = value
  }
  
  // industryId (industry_id)
  public get industryId(): string {
    return this._json.industry_id!
  }
  public set industryId(value: string) {
    this._json.industry_id = value
  }
  
  static fromJSON(m: IUploadJSON = {}): Upload {
    return new Upload({
      file: m['file']!,
      industryId: m['industry_id']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IUGC {
  fields?: object
  images?: UserImage[]
  audio?: UserAudio[]
  memberImages?: MemberImage[]
  
  toJSON?(): object
}

export interface IUGCJSON {
  fields?: object
  images?: UserImage[]
  audio?: UserAudio[]
  member_images?: MemberImage[]
  toJSON?(): object
}

export class UGC implements IUGC {
  private readonly _json: IUGCJSON;

  constructor(m?: IUGC) {
    this._json = {};
    if (m) {
      this._json['fields'] = m.fields;
      this._json['images'] = m.images;
      this._json['audio'] = m.audio;
      this._json['member_images'] = m.memberImages;
    }
  }
  
  // fields (fields)
  public get fields(): object {
    return this._json.fields!
  }
  public set fields(value: object) {
    this._json.fields = value
  }
  
  // images (images)
  public get images(): UserImage[] {
    return this._json.images || []
  }
  public set images(value: UserImage[]) {
    this._json.images = value
  }
  
  // audio (audio)
  public get audio(): UserAudio[] {
    return this._json.audio || []
  }
  public set audio(value: UserAudio[]) {
    this._json.audio = value
  }
  
  // memberImages (member_images)
  public get memberImages(): MemberImage[] {
    return this._json.member_images || []
  }
  public set memberImages(value: MemberImage[]) {
    this._json.member_images = value
  }
  
  static fromJSON(m: IUGCJSON = {}): UGC {
    return new UGC({
      fields: m['fields'],
      images: (m['images']! || []).map((v) => { return UserImage.fromJSON(v) }),
      audio: (m['audio']! || []).map((v) => { return UserAudio.fromJSON(v) }),
      memberImages: (m['member_images']! || []).map((v) => { return MemberImage.fromJSON(v) })
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IDraft {
  id?: string
  ugc?: UGC
  name?: string
  url?: string
  budgetCents?: number
  currency?: string
  durationDays?: number
  createdAt?: string
  updatedAt?: string
  landingPages?: LandingPage[]
  isDraft?: boolean
  builderSource?: string
  imageUrl?: string
  adNetworks?: string[]
  facebookPage?: FacebookPage
  source?: string
  
  toJSON?(): object
}

export interface IDraftJSON {
  id?: string
  ugc?: UGC
  name?: string
  url?: string
  budget_cents?: number
  currency?: string
  duration_days?: number
  created_at?: string
  updated_at?: string
  landing_pages?: LandingPage[]
  is_draft?: boolean
  builder_source?: string
  image_url?: string
  ad_networks?: string[]
  facebook_page?: FacebookPage
  source?: string
  toJSON?(): object
}

export class Draft implements IDraft {
  private readonly _json: IDraftJSON;

  constructor(m?: IDraft) {
    this._json = {};
    if (m) {
      this._json['id'] = m.id;
      this._json['ugc'] = m.ugc;
      this._json['name'] = m.name;
      this._json['url'] = m.url;
      this._json['budget_cents'] = m.budgetCents;
      this._json['currency'] = m.currency;
      this._json['duration_days'] = m.durationDays;
      this._json['created_at'] = m.createdAt;
      this._json['updated_at'] = m.updatedAt;
      this._json['landing_pages'] = m.landingPages;
      this._json['is_draft'] = m.isDraft;
      this._json['builder_source'] = m.builderSource;
      this._json['image_url'] = m.imageUrl;
      this._json['ad_networks'] = m.adNetworks;
      this._json['facebook_page'] = m.facebookPage;
      this._json['source'] = m.source;
    }
  }
  
  // id (id)
  public get id(): string {
    return this._json.id!
  }
  public set id(value: string) {
    this._json.id = value
  }
  
  // ugc (ugc)
  public get ugc(): UGC {
    return this._json.ugc!
  }
  public set ugc(value: UGC) {
    this._json.ugc = value
  }
  
  // name (name)
  public get name(): string {
    return this._json.name!
  }
  public set name(value: string) {
    this._json.name = value
  }
  
  // url (url)
  public get url(): string {
    return this._json.url!
  }
  public set url(value: string) {
    this._json.url = value
  }
  
  // budgetCents (budget_cents)
  public get budgetCents(): number {
    return this._json.budget_cents!
  }
  public set budgetCents(value: number) {
    this._json.budget_cents = value
  }
  
  // currency (currency)
  public get currency(): string {
    return this._json.currency!
  }
  public set currency(value: string) {
    this._json.currency = value
  }
  
  // durationDays (duration_days)
  public get durationDays(): number {
    return this._json.duration_days!
  }
  public set durationDays(value: number) {
    this._json.duration_days = value
  }
  
  // createdAt (created_at)
  public get createdAt(): string {
    return this._json.created_at!
  }
  public set createdAt(value: string) {
    this._json.created_at = value
  }
  
  // updatedAt (updated_at)
  public get updatedAt(): string {
    return this._json.updated_at!
  }
  public set updatedAt(value: string) {
    this._json.updated_at = value
  }
  
  // landingPages (landing_pages)
  public get landingPages(): LandingPage[] {
    return this._json.landing_pages || []
  }
  public set landingPages(value: LandingPage[]) {
    this._json.landing_pages = value
  }
  
  // isDraft (is_draft)
  public get isDraft(): boolean {
    return this._json.is_draft!
  }
  public set isDraft(value: boolean) {
    this._json.is_draft = value
  }
  
  // builderSource (builder_source)
  public get builderSource(): string {
    return this._json.builder_source!
  }
  public set builderSource(value: string) {
    this._json.builder_source = value
  }
  
  // imageUrl (image_url)
  public get imageUrl(): string {
    return this._json.image_url!
  }
  public set imageUrl(value: string) {
    this._json.image_url = value
  }
  
  // adNetworks (ad_networks)
  public get adNetworks(): string[] {
    return this._json.ad_networks || []
  }
  public set adNetworks(value: string[]) {
    this._json.ad_networks = value
  }
  
  // facebookPage (facebook_page)
  public get facebookPage(): FacebookPage {
    return this._json.facebook_page!
  }
  public set facebookPage(value: FacebookPage) {
    this._json.facebook_page = value
  }
  
  // source (source)
  public get source(): string {
    return this._json.source!
  }
  public set source(value: string) {
    this._json.source = value
  }
  
  static fromJSON(m: IDraftJSON = {}): Draft {
    return new Draft({
      id: m['id']!,
      ugc: UGC.fromJSON(m['ugc']!),
      name: m['name']!,
      url: m['url']!,
      budgetCents: m['budget_cents']!,
      currency: m['currency']!,
      durationDays: m['duration_days']!,
      createdAt: m['created_at']!,
      updatedAt: m['updated_at']!,
      landingPages: (m['landing_pages']! || []).map((v) => { return LandingPage.fromJSON(v) }),
      isDraft: m['is_draft']!,
      builderSource: m['builder_source']!,
      imageUrl: m['image_url']!,
      adNetworks: (m['ad_networks']! || []).map((v) => { return String(v) }),
      facebookPage: FacebookPage.fromJSON(m['facebook_page']!),
      source: m['source']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IDraftList {
  drafts?: Draft[]
  
  toJSON?(): object
}

export interface IDraftListJSON {
  drafts?: Draft[]
  toJSON?(): object
}

export class DraftList implements IDraftList {
  private readonly _json: IDraftListJSON;

  constructor(m?: IDraftList) {
    this._json = {};
    if (m) {
      this._json['drafts'] = m.drafts;
    }
  }
  
  // drafts (drafts)
  public get drafts(): Draft[] {
    return this._json.drafts || []
  }
  public set drafts(value: Draft[]) {
    this._json.drafts = value
  }
  
  static fromJSON(m: IDraftListJSON = {}): DraftList {
    return new DraftList({
      drafts: (m['drafts']! || []).map((v) => { return Draft.fromJSON(v) })
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IMemberImage {
  id?: string
  url?: string
  colorPalette?: ColorPalette
  theme?: string
  
  toJSON?(): object
}

export interface IMemberImageJSON {
  id?: string
  url?: string
  color_palette?: ColorPalette
  theme?: string
  toJSON?(): object
}

export class MemberImage implements IMemberImage {
  private readonly _json: IMemberImageJSON;

  constructor(m?: IMemberImage) {
    this._json = {};
    if (m) {
      this._json['id'] = m.id;
      this._json['url'] = m.url;
      this._json['color_palette'] = m.colorPalette;
      this._json['theme'] = m.theme;
    }
  }
  
  // id (id)
  public get id(): string {
    return this._json.id!
  }
  public set id(value: string) {
    this._json.id = value
  }
  
  // url (url)
  public get url(): string {
    return this._json.url!
  }
  public set url(value: string) {
    this._json.url = value
  }
  
  // colorPalette (color_palette)
  public get colorPalette(): ColorPalette {
    return this._json.color_palette!
  }
  public set colorPalette(value: ColorPalette) {
    this._json.color_palette = value
  }
  
  // theme (theme)
  public get theme(): string {
    return this._json.theme!
  }
  public set theme(value: string) {
    this._json.theme = value
  }
  
  static fromJSON(m: IMemberImageJSON = {}): MemberImage {
    return new MemberImage({
      id: m['id']!,
      url: m['url']!,
      colorPalette: ColorPalette.fromJSON(m['color_palette']!),
      theme: m['theme']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ICopy {
  header?: string
  subheader?: string
  showSubheader?: boolean
  
  toJSON?(): object
}

export interface ICopyJSON {
  header?: string
  subheader?: string
  show_subheader?: boolean
  toJSON?(): object
}

export class Copy implements ICopy {
  private readonly _json: ICopyJSON;

  constructor(m?: ICopy) {
    this._json = {};
    if (m) {
      this._json['header'] = m.header;
      this._json['subheader'] = m.subheader;
      this._json['show_subheader'] = m.showSubheader;
    }
  }
  
  // header (header)
  public get header(): string {
    return this._json.header!
  }
  public set header(value: string) {
    this._json.header = value
  }
  
  // subheader (subheader)
  public get subheader(): string {
    return this._json.subheader!
  }
  public set subheader(value: string) {
    this._json.subheader = value
  }
  
  // showSubheader (show_subheader)
  public get showSubheader(): boolean {
    return this._json.show_subheader!
  }
  public set showSubheader(value: boolean) {
    this._json.show_subheader = value
  }
  
  static fromJSON(m: ICopyJSON = {}): Copy {
    return new Copy({
      header: m['header']!,
      subheader: m['subheader']!,
      showSubheader: m['show_subheader']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ICreativeCustomization {
  copy?: Copy
  userImageId?: string
  memberImageId?: string
  enabled?: boolean
  
  toJSON?(): object
}

export interface ICreativeCustomizationJSON {
  copy?: Copy
  user_image_id?: string
  member_image_id?: string
  enabled?: boolean
  toJSON?(): object
}

export class CreativeCustomization implements ICreativeCustomization {
  private readonly _json: ICreativeCustomizationJSON;

  constructor(m?: ICreativeCustomization) {
    this._json = {};
    if (m) {
      this._json['copy'] = m.copy;
      this._json['user_image_id'] = m.userImageId;
      this._json['member_image_id'] = m.memberImageId;
      this._json['enabled'] = m.enabled;
    }
  }
  
  // copy (copy)
  public get copy(): Copy {
    return this._json.copy!
  }
  public set copy(value: Copy) {
    this._json.copy = value
  }
  
  // userImageId (user_image_id)
  public get userImageId(): string {
    return this._json.user_image_id!
  }
  public set userImageId(value: string) {
    this._json.user_image_id = value
  }
  
  // memberImageId (member_image_id)
  public get memberImageId(): string {
    return this._json.member_image_id!
  }
  public set memberImageId(value: string) {
    this._json.member_image_id = value
  }
  
  // enabled (enabled)
  public get enabled(): boolean {
    return this._json.enabled!
  }
  public set enabled(value: boolean) {
    this._json.enabled = value
  }
  
  static fromJSON(m: ICreativeCustomizationJSON = {}): CreativeCustomization {
    return new CreativeCustomization({
      copy: Copy.fromJSON(m['copy']!),
      userImageId: m['user_image_id']!,
      memberImageId: m['member_image_id']!,
      enabled: m['enabled']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ICustomizeCreativeRequest {
  campaignId?: string
  creativeId?: string
  customization?: CreativeCustomization
  
  toJSON?(): object
}

export interface ICustomizeCreativeRequestJSON {
  campaign_id?: string
  creative_id?: string
  customization?: CreativeCustomization
  toJSON?(): object
}

export class CustomizeCreativeRequest implements ICustomizeCreativeRequest {
  private readonly _json: ICustomizeCreativeRequestJSON;

  constructor(m?: ICustomizeCreativeRequest) {
    this._json = {};
    if (m) {
      this._json['campaign_id'] = m.campaignId;
      this._json['creative_id'] = m.creativeId;
      this._json['customization'] = m.customization;
    }
  }
  
  // campaignId (campaign_id)
  public get campaignId(): string {
    return this._json.campaign_id!
  }
  public set campaignId(value: string) {
    this._json.campaign_id = value
  }
  
  // creativeId (creative_id)
  public get creativeId(): string {
    return this._json.creative_id!
  }
  public set creativeId(value: string) {
    this._json.creative_id = value
  }
  
  // customization (customization)
  public get customization(): CreativeCustomization {
    return this._json.customization!
  }
  public set customization(value: CreativeCustomization) {
    this._json.customization = value
  }
  
  static fromJSON(m: ICustomizeCreativeRequestJSON = {}): CustomizeCreativeRequest {
    return new CustomizeCreativeRequest({
      campaignId: m['campaign_id']!,
      creativeId: m['creative_id']!,
      customization: CreativeCustomization.fromJSON(m['customization']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ICustomizeCreativeResponse {
  creative?: Creative
  
  toJSON?(): object
}

export interface ICustomizeCreativeResponseJSON {
  creative?: Creative
  toJSON?(): object
}

export class CustomizeCreativeResponse implements ICustomizeCreativeResponse {
  private readonly _json: ICustomizeCreativeResponseJSON;

  constructor(m?: ICustomizeCreativeResponse) {
    this._json = {};
    if (m) {
      this._json['creative'] = m.creative;
    }
  }
  
  // creative (creative)
  public get creative(): Creative {
    return this._json.creative!
  }
  public set creative(value: Creative) {
    this._json.creative = value
  }
  
  static fromJSON(m: ICustomizeCreativeResponseJSON = {}): CustomizeCreativeResponse {
    return new CustomizeCreativeResponse({
      creative: Creative.fromJSON(m['creative']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IImageConfigRequest {
  campaignId?: string
  creativeId?: string
  userImageId?: string
  memberImageId?: string
  
  toJSON?(): object
}

export interface IImageConfigRequestJSON {
  campaign_id?: string
  creative_id?: string
  user_image_id?: string
  member_image_id?: string
  toJSON?(): object
}

export class ImageConfigRequest implements IImageConfigRequest {
  private readonly _json: IImageConfigRequestJSON;

  constructor(m?: IImageConfigRequest) {
    this._json = {};
    if (m) {
      this._json['campaign_id'] = m.campaignId;
      this._json['creative_id'] = m.creativeId;
      this._json['user_image_id'] = m.userImageId;
      this._json['member_image_id'] = m.memberImageId;
    }
  }
  
  // campaignId (campaign_id)
  public get campaignId(): string {
    return this._json.campaign_id!
  }
  public set campaignId(value: string) {
    this._json.campaign_id = value
  }
  
  // creativeId (creative_id)
  public get creativeId(): string {
    return this._json.creative_id!
  }
  public set creativeId(value: string) {
    this._json.creative_id = value
  }
  
  // userImageId (user_image_id)
  public get userImageId(): string {
    return this._json.user_image_id!
  }
  public set userImageId(value: string) {
    this._json.user_image_id = value
  }
  
  // memberImageId (member_image_id)
  public get memberImageId(): string {
    return this._json.member_image_id!
  }
  public set memberImageId(value: string) {
    this._json.member_image_id = value
  }
  
  static fromJSON(m: IImageConfigRequestJSON = {}): ImageConfigRequest {
    return new ImageConfigRequest({
      campaignId: m['campaign_id']!,
      creativeId: m['creative_id']!,
      userImageId: m['user_image_id']!,
      memberImageId: m['member_image_id']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IImageConfigResponse_Image_URL {
  key?: string
  url?: string
  
  toJSON?(): object
}

export interface IImageConfigResponse_Image_URLJSON {
  key?: string
  url?: string
  toJSON?(): object
}

export class ImageConfigResponse_Image_URL implements IImageConfigResponse_Image_URL {
  private readonly _json: IImageConfigResponse_Image_URLJSON;

  constructor(m?: IImageConfigResponse_Image_URL) {
    this._json = {};
    if (m) {
      this._json['key'] = m.key;
      this._json['url'] = m.url;
    }
  }
  
  // key (key)
  public get key(): string {
    return this._json.key!
  }
  public set key(value: string) {
    this._json.key = value
  }
  
  // url (url)
  public get url(): string {
    return this._json.url!
  }
  public set url(value: string) {
    this._json.url = value
  }
  
  static fromJSON(m: IImageConfigResponse_Image_URLJSON = {}): ImageConfigResponse_Image_URL {
    return new ImageConfigResponse_Image_URL({
      key: m['key']!,
      url: m['url']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IImageConfigResponse_Image {
  colorPalette?: ColorPalette
  urls?: ImageConfigResponse_Image_URL[]
  
  toJSON?(): object
}

export interface IImageConfigResponse_ImageJSON {
  color_palette?: ColorPalette
  urls?: ImageConfigResponse_Image_URL[]
  toJSON?(): object
}

export class ImageConfigResponse_Image implements IImageConfigResponse_Image {
  private readonly _json: IImageConfigResponse_ImageJSON;

  constructor(m?: IImageConfigResponse_Image) {
    this._json = {};
    if (m) {
      this._json['color_palette'] = m.colorPalette;
      this._json['urls'] = m.urls;
    }
  }
  
  // colorPalette (color_palette)
  public get colorPalette(): ColorPalette {
    return this._json.color_palette!
  }
  public set colorPalette(value: ColorPalette) {
    this._json.color_palette = value
  }
  
  // urls (urls)
  public get urls(): ImageConfigResponse_Image_URL[] {
    return this._json.urls || []
  }
  public set urls(value: ImageConfigResponse_Image_URL[]) {
    this._json.urls = value
  }
  
  static fromJSON(m: IImageConfigResponse_ImageJSON = {}): ImageConfigResponse_Image {
    return new ImageConfigResponse_Image({
      colorPalette: ColorPalette.fromJSON(m['color_palette']!),
      urls: (m['urls']! || []).map((v) => { return ImageConfigResponse_Image_URL.fromJSON(v) })
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IImageConfigResponse {
  creativeId?: string
  featuredImage?: ImageConfigResponse_Image
  backgroundImage?: ImageConfigResponse_Image
  
  toJSON?(): object
}

export interface IImageConfigResponseJSON {
  creative_id?: string
  featured_image?: ImageConfigResponse_Image
  background_image?: ImageConfigResponse_Image
  toJSON?(): object
}

export class ImageConfigResponse implements IImageConfigResponse {
  private readonly _json: IImageConfigResponseJSON;

  constructor(m?: IImageConfigResponse) {
    this._json = {};
    if (m) {
      this._json['creative_id'] = m.creativeId;
      this._json['featured_image'] = m.featuredImage;
      this._json['background_image'] = m.backgroundImage;
    }
  }
  
  // creativeId (creative_id)
  public get creativeId(): string {
    return this._json.creative_id!
  }
  public set creativeId(value: string) {
    this._json.creative_id = value
  }
  
  // featuredImage (featured_image)
  public get featuredImage(): ImageConfigResponse_Image {
    return this._json.featured_image!
  }
  public set featuredImage(value: ImageConfigResponse_Image) {
    this._json.featured_image = value
  }
  
  // backgroundImage (background_image)
  public get backgroundImage(): ImageConfigResponse_Image {
    return this._json.background_image!
  }
  public set backgroundImage(value: ImageConfigResponse_Image) {
    this._json.background_image = value
  }
  
  static fromJSON(m: IImageConfigResponseJSON = {}): ImageConfigResponse {
    return new ImageConfigResponse({
      creativeId: m['creative_id']!,
      featuredImage: ImageConfigResponse_Image.fromJSON(m['featured_image']!),
      backgroundImage: ImageConfigResponse_Image.fromJSON(m['background_image']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ICreative_Properties {
  objective?: string
  geo?: string
  retailers?: string[]
  userEdited?: boolean
  
  toJSON?(): object
}

export interface ICreative_PropertiesJSON {
  objective?: string
  geo?: string
  retailers?: string[]
  user_edited?: boolean
  toJSON?(): object
}

export class Creative_Properties implements ICreative_Properties {
  private readonly _json: ICreative_PropertiesJSON;

  constructor(m?: ICreative_Properties) {
    this._json = {};
    if (m) {
      this._json['objective'] = m.objective;
      this._json['geo'] = m.geo;
      this._json['retailers'] = m.retailers;
      this._json['user_edited'] = m.userEdited;
    }
  }
  
  // objective (objective)
  public get objective(): string {
    return this._json.objective!
  }
  public set objective(value: string) {
    this._json.objective = value
  }
  
  // geo (geo)
  public get geo(): string {
    return this._json.geo!
  }
  public set geo(value: string) {
    this._json.geo = value
  }
  
  // retailers (retailers)
  public get retailers(): string[] {
    return this._json.retailers || []
  }
  public set retailers(value: string[]) {
    this._json.retailers = value
  }
  
  // userEdited (user_edited)
  public get userEdited(): boolean {
    return this._json.user_edited!
  }
  public set userEdited(value: boolean) {
    this._json.user_edited = value
  }
  
  static fromJSON(m: ICreative_PropertiesJSON = {}): Creative_Properties {
    return new Creative_Properties({
      objective: m['objective']!,
      geo: m['geo']!,
      retailers: (m['retailers']! || []).map((v) => { return String(v) }),
      userEdited: m['user_edited']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ICreative_Display {
  screenshotUrl?: string
  header?: string
  subheader?: string
  
  toJSON?(): object
}

export interface ICreative_DisplayJSON {
  screenshot_url?: string
  header?: string
  subheader?: string
  toJSON?(): object
}

export class Creative_Display implements ICreative_Display {
  private readonly _json: ICreative_DisplayJSON;

  constructor(m?: ICreative_Display) {
    this._json = {};
    if (m) {
      this._json['screenshot_url'] = m.screenshotUrl;
      this._json['header'] = m.header;
      this._json['subheader'] = m.subheader;
    }
  }
  
  // screenshotUrl (screenshot_url)
  public get screenshotUrl(): string {
    return this._json.screenshot_url!
  }
  public set screenshotUrl(value: string) {
    this._json.screenshot_url = value
  }
  
  // header (header)
  public get header(): string {
    return this._json.header!
  }
  public set header(value: string) {
    this._json.header = value
  }
  
  // subheader (subheader)
  public get subheader(): string {
    return this._json.subheader!
  }
  public set subheader(value: string) {
    this._json.subheader = value
  }
  
  static fromJSON(m: ICreative_DisplayJSON = {}): Creative_Display {
    return new Creative_Display({
      screenshotUrl: m['screenshot_url']!,
      header: m['header']!,
      subheader: m['subheader']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ICreative_Facebook {
  placement?: Creative_Facebook_Placement
  imageUrl?: string
  colorPalette?: ColorPalette
  primaryText?: string
  headline?: string
  description?: string
  callToAction?: string
  caption?: string
  
  toJSON?(): object
}

export enum Creative_Facebook_Placement {
  UNUSED_BUT_REQUIRED_BY_PROTO3 = 'UNUSED_BUT_REQUIRED_BY_PROTO3',
  NEWS_FEED = 'NEWS_FEED'
}

export interface ICreative_FacebookJSON {
  placement?: Creative_Facebook_Placement
  image_url?: string
  color_palette?: ColorPalette
  primary_text?: string
  headline?: string
  description?: string
  call_to_action?: string
  caption?: string
  toJSON?(): object
}

export class Creative_Facebook implements ICreative_Facebook {
  private readonly _json: ICreative_FacebookJSON;

  constructor(m?: ICreative_Facebook) {
    this._json = {};
    if (m) {
      this._json['placement'] = m.placement;
      this._json['image_url'] = m.imageUrl;
      this._json['color_palette'] = m.colorPalette;
      this._json['primary_text'] = m.primaryText;
      this._json['headline'] = m.headline;
      this._json['description'] = m.description;
      this._json['call_to_action'] = m.callToAction;
      this._json['caption'] = m.caption;
    }
  }
  
  // placement (placement)
  public get placement(): Creative_Facebook_Placement {
    return (<any>Creative_Facebook_Placement)[this._json.placement!]
  }
  public set placement(value: Creative_Facebook_Placement) {
    this._json.placement = value
  }
  
  // imageUrl (image_url)
  public get imageUrl(): string {
    return this._json.image_url!
  }
  public set imageUrl(value: string) {
    this._json.image_url = value
  }
  
  // colorPalette (color_palette)
  public get colorPalette(): ColorPalette {
    return this._json.color_palette!
  }
  public set colorPalette(value: ColorPalette) {
    this._json.color_palette = value
  }
  
  // primaryText (primary_text)
  public get primaryText(): string {
    return this._json.primary_text!
  }
  public set primaryText(value: string) {
    this._json.primary_text = value
  }
  
  // headline (headline)
  public get headline(): string {
    return this._json.headline!
  }
  public set headline(value: string) {
    this._json.headline = value
  }
  
  // description (description)
  public get description(): string {
    return this._json.description!
  }
  public set description(value: string) {
    this._json.description = value
  }
  
  // callToAction (call_to_action)
  public get callToAction(): string {
    return this._json.call_to_action!
  }
  public set callToAction(value: string) {
    this._json.call_to_action = value
  }
  
  // caption (caption)
  public get caption(): string {
    return this._json.caption!
  }
  public set caption(value: string) {
    this._json.caption = value
  }
  
  static fromJSON(m: ICreative_FacebookJSON = {}): Creative_Facebook {
    return new Creative_Facebook({
      placement: (<any>Creative_Facebook_Placement)[m['placement']!]!,
      imageUrl: m['image_url']!,
      colorPalette: ColorPalette.fromJSON(m['color_palette']!),
      primaryText: m['primary_text']!,
      headline: m['headline']!,
      description: m['description']!,
      callToAction: m['call_to_action']!,
      caption: m['caption']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ICreative_Instagram {
  placement?: Creative_Instagram_Placement
  imageUrl?: string
  colorPalette?: ColorPalette
  primaryText?: string
  callToAction?: string
  
  toJSON?(): object
}

export enum Creative_Instagram_Placement {
  UNUSED_BUT_REQUIRED_BY_PROTO3 = 'UNUSED_BUT_REQUIRED_BY_PROTO3',
  FEED = 'FEED',
  STORY = 'STORY'
}

export interface ICreative_InstagramJSON {
  placement?: Creative_Instagram_Placement
  image_url?: string
  color_palette?: ColorPalette
  primary_text?: string
  call_to_action?: string
  toJSON?(): object
}

export class Creative_Instagram implements ICreative_Instagram {
  private readonly _json: ICreative_InstagramJSON;

  constructor(m?: ICreative_Instagram) {
    this._json = {};
    if (m) {
      this._json['placement'] = m.placement;
      this._json['image_url'] = m.imageUrl;
      this._json['color_palette'] = m.colorPalette;
      this._json['primary_text'] = m.primaryText;
      this._json['call_to_action'] = m.callToAction;
    }
  }
  
  // placement (placement)
  public get placement(): Creative_Instagram_Placement {
    return (<any>Creative_Instagram_Placement)[this._json.placement!]
  }
  public set placement(value: Creative_Instagram_Placement) {
    this._json.placement = value
  }
  
  // imageUrl (image_url)
  public get imageUrl(): string {
    return this._json.image_url!
  }
  public set imageUrl(value: string) {
    this._json.image_url = value
  }
  
  // colorPalette (color_palette)
  public get colorPalette(): ColorPalette {
    return this._json.color_palette!
  }
  public set colorPalette(value: ColorPalette) {
    this._json.color_palette = value
  }
  
  // primaryText (primary_text)
  public get primaryText(): string {
    return this._json.primary_text!
  }
  public set primaryText(value: string) {
    this._json.primary_text = value
  }
  
  // callToAction (call_to_action)
  public get callToAction(): string {
    return this._json.call_to_action!
  }
  public set callToAction(value: string) {
    this._json.call_to_action = value
  }
  
  static fromJSON(m: ICreative_InstagramJSON = {}): Creative_Instagram {
    return new Creative_Instagram({
      placement: (<any>Creative_Instagram_Placement)[m['placement']!]!,
      imageUrl: m['image_url']!,
      colorPalette: ColorPalette.fromJSON(m['color_palette']!),
      primaryText: m['primary_text']!,
      callToAction: m['call_to_action']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ICreative {
  id?: string
  properties?: Creative_Properties
  network?: Creative_Network
  display?: Creative_Display
  facebook?: Creative_Facebook
  instagram?: Creative_Instagram
  customization?: CreativeCustomization
  
  toJSON?(): object
}

export enum Creative_Network {
  UNUSED_BUT_REQUIRED_BY_PROTO3 = 'UNUSED_BUT_REQUIRED_BY_PROTO3',
  DISPLAY = 'DISPLAY',
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  LANDING_PAGE = 'LANDING_PAGE'
}

export interface ICreativeJSON {
  id?: string
  properties?: Creative_Properties
  network?: Creative_Network
  display?: Creative_Display
  facebook?: Creative_Facebook
  instagram?: Creative_Instagram
  customization?: CreativeCustomization
  toJSON?(): object
}

export class Creative implements ICreative {
  private readonly _json: ICreativeJSON;

  constructor(m?: ICreative) {
    this._json = {};
    if (m) {
      this._json['id'] = m.id;
      this._json['properties'] = m.properties;
      this._json['network'] = m.network;
      this._json['display'] = m.display;
      this._json['facebook'] = m.facebook;
      this._json['instagram'] = m.instagram;
      this._json['customization'] = m.customization;
    }
  }
  
  // id (id)
  public get id(): string {
    return this._json.id!
  }
  public set id(value: string) {
    this._json.id = value
  }
  
  // properties (properties)
  public get properties(): Creative_Properties {
    return this._json.properties!
  }
  public set properties(value: Creative_Properties) {
    this._json.properties = value
  }
  
  // network (network)
  public get network(): Creative_Network {
    return (<any>Creative_Network)[this._json.network!]
  }
  public set network(value: Creative_Network) {
    this._json.network = value
  }
  
  // display (display)
  public get display(): Creative_Display {
    return this._json.display!
  }
  public set display(value: Creative_Display) {
    this._json.display = value
  }
  
  // facebook (facebook)
  public get facebook(): Creative_Facebook {
    return this._json.facebook!
  }
  public set facebook(value: Creative_Facebook) {
    this._json.facebook = value
  }
  
  // instagram (instagram)
  public get instagram(): Creative_Instagram {
    return this._json.instagram!
  }
  public set instagram(value: Creative_Instagram) {
    this._json.instagram = value
  }
  
  // customization (customization)
  public get customization(): CreativeCustomization {
    return this._json.customization!
  }
  public set customization(value: CreativeCustomization) {
    this._json.customization = value
  }
  
  static fromJSON(m: ICreativeJSON = {}): Creative {
    return new Creative({
      id: m['id']!,
      properties: Creative_Properties.fromJSON(m['properties']!),
      network: (<any>Creative_Network)[m['network']!]!,
      display: Creative_Display.fromJSON(m['display']!),
      facebook: Creative_Facebook.fromJSON(m['facebook']!),
      instagram: Creative_Instagram.fromJSON(m['instagram']!),
      customization: CreativeCustomization.fromJSON(m['customization']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IRecommendedAds_Strategy {
  name?: string
  creatives?: Creative[]
  
  toJSON?(): object
}

export interface IRecommendedAds_StrategyJSON {
  name?: string
  creatives?: Creative[]
  toJSON?(): object
}

export class RecommendedAds_Strategy implements IRecommendedAds_Strategy {
  private readonly _json: IRecommendedAds_StrategyJSON;

  constructor(m?: IRecommendedAds_Strategy) {
    this._json = {};
    if (m) {
      this._json['name'] = m.name;
      this._json['creatives'] = m.creatives;
    }
  }
  
  // name (name)
  public get name(): string {
    return this._json.name!
  }
  public set name(value: string) {
    this._json.name = value
  }
  
  // creatives (creatives)
  public get creatives(): Creative[] {
    return this._json.creatives || []
  }
  public set creatives(value: Creative[]) {
    this._json.creatives = value
  }
  
  static fromJSON(m: IRecommendedAds_StrategyJSON = {}): RecommendedAds_Strategy {
    return new RecommendedAds_Strategy({
      name: m['name']!,
      creatives: (m['creatives']! || []).map((v) => { return Creative.fromJSON(v) })
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IRecommendedAds_Theme {
  name?: string
  imageUrls?: string[]
  
  toJSON?(): object
}

export interface IRecommendedAds_ThemeJSON {
  name?: string
  image_urls?: string[]
  toJSON?(): object
}

export class RecommendedAds_Theme implements IRecommendedAds_Theme {
  private readonly _json: IRecommendedAds_ThemeJSON;

  constructor(m?: IRecommendedAds_Theme) {
    this._json = {};
    if (m) {
      this._json['name'] = m.name;
      this._json['image_urls'] = m.imageUrls;
    }
  }
  
  // name (name)
  public get name(): string {
    return this._json.name!
  }
  public set name(value: string) {
    this._json.name = value
  }
  
  // imageUrls (image_urls)
  public get imageUrls(): string[] {
    return this._json.image_urls || []
  }
  public set imageUrls(value: string[]) {
    this._json.image_urls = value
  }
  
  static fromJSON(m: IRecommendedAds_ThemeJSON = {}): RecommendedAds_Theme {
    return new RecommendedAds_Theme({
      name: m['name']!,
      imageUrls: (m['image_urls']! || []).map((v) => { return String(v) })
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IRecommendedAds {
  strategies?: RecommendedAds_Strategy[]
  themes?: RecommendedAds_Theme[]
  
  toJSON?(): object
}

export interface IRecommendedAdsJSON {
  strategies?: RecommendedAds_Strategy[]
  themes?: RecommendedAds_Theme[]
  toJSON?(): object
}

export class RecommendedAds implements IRecommendedAds {
  private readonly _json: IRecommendedAdsJSON;

  constructor(m?: IRecommendedAds) {
    this._json = {};
    if (m) {
      this._json['strategies'] = m.strategies;
      this._json['themes'] = m.themes;
    }
  }
  
  // strategies (strategies)
  public get strategies(): RecommendedAds_Strategy[] {
    return this._json.strategies || []
  }
  public set strategies(value: RecommendedAds_Strategy[]) {
    this._json.strategies = value
  }
  
  // themes (themes)
  public get themes(): RecommendedAds_Theme[] {
    return this._json.themes || []
  }
  public set themes(value: RecommendedAds_Theme[]) {
    this._json.themes = value
  }
  
  static fromJSON(m: IRecommendedAdsJSON = {}): RecommendedAds {
    return new RecommendedAds({
      strategies: (m['strategies']! || []).map((v) => { return RecommendedAds_Strategy.fromJSON(v) }),
      themes: (m['themes']! || []).map((v) => { return RecommendedAds_Theme.fromJSON(v) })
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ICampaignExampleAds {
  creativeIds?: string[]
  
  toJSON?(): object
}

export interface ICampaignExampleAdsJSON {
  creative_ids?: string[]
  toJSON?(): object
}

export class CampaignExampleAds implements ICampaignExampleAds {
  private readonly _json: ICampaignExampleAdsJSON;

  constructor(m?: ICampaignExampleAds) {
    this._json = {};
    if (m) {
      this._json['creative_ids'] = m.creativeIds;
    }
  }
  
  // creativeIds (creative_ids)
  public get creativeIds(): string[] {
    return this._json.creative_ids || []
  }
  public set creativeIds(value: string[]) {
    this._json.creative_ids = value
  }
  
  static fromJSON(m: ICampaignExampleAdsJSON = {}): CampaignExampleAds {
    return new CampaignExampleAds({
      creativeIds: (m['creative_ids']! || []).map((v) => { return String(v) })
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IColorPalette {
  averageLuminance?: number
  color1?: string
  color2?: string
  color3?: string
  color4?: string
  color5?: string
  color6?: string
  vibrant?: string
  vibrantDark?: string
  vibrantLight?: string
  muted?: string
  mutedDark?: string
  mutedLight?: string
  
  toJSON?(): object
}

export interface IColorPaletteJSON {
  average_luminance?: number
  color_1?: string
  color_2?: string
  color_3?: string
  color_4?: string
  color_5?: string
  color_6?: string
  vibrant?: string
  vibrant_dark?: string
  vibrant_light?: string
  muted?: string
  muted_dark?: string
  muted_light?: string
  toJSON?(): object
}

export class ColorPalette implements IColorPalette {
  private readonly _json: IColorPaletteJSON;

  constructor(m?: IColorPalette) {
    this._json = {};
    if (m) {
      this._json['average_luminance'] = m.averageLuminance;
      this._json['color_1'] = m.color1;
      this._json['color_2'] = m.color2;
      this._json['color_3'] = m.color3;
      this._json['color_4'] = m.color4;
      this._json['color_5'] = m.color5;
      this._json['color_6'] = m.color6;
      this._json['vibrant'] = m.vibrant;
      this._json['vibrant_dark'] = m.vibrantDark;
      this._json['vibrant_light'] = m.vibrantLight;
      this._json['muted'] = m.muted;
      this._json['muted_dark'] = m.mutedDark;
      this._json['muted_light'] = m.mutedLight;
    }
  }
  
  // averageLuminance (average_luminance)
  public get averageLuminance(): number {
    return this._json.average_luminance!
  }
  public set averageLuminance(value: number) {
    this._json.average_luminance = value
  }
  
  // color1 (color_1)
  public get color1(): string {
    return this._json.color_1!
  }
  public set color1(value: string) {
    this._json.color_1 = value
  }
  
  // color2 (color_2)
  public get color2(): string {
    return this._json.color_2!
  }
  public set color2(value: string) {
    this._json.color_2 = value
  }
  
  // color3 (color_3)
  public get color3(): string {
    return this._json.color_3!
  }
  public set color3(value: string) {
    this._json.color_3 = value
  }
  
  // color4 (color_4)
  public get color4(): string {
    return this._json.color_4!
  }
  public set color4(value: string) {
    this._json.color_4 = value
  }
  
  // color5 (color_5)
  public get color5(): string {
    return this._json.color_5!
  }
  public set color5(value: string) {
    this._json.color_5 = value
  }
  
  // color6 (color_6)
  public get color6(): string {
    return this._json.color_6!
  }
  public set color6(value: string) {
    this._json.color_6 = value
  }
  
  // vibrant (vibrant)
  public get vibrant(): string {
    return this._json.vibrant!
  }
  public set vibrant(value: string) {
    this._json.vibrant = value
  }
  
  // vibrantDark (vibrant_dark)
  public get vibrantDark(): string {
    return this._json.vibrant_dark!
  }
  public set vibrantDark(value: string) {
    this._json.vibrant_dark = value
  }
  
  // vibrantLight (vibrant_light)
  public get vibrantLight(): string {
    return this._json.vibrant_light!
  }
  public set vibrantLight(value: string) {
    this._json.vibrant_light = value
  }
  
  // muted (muted)
  public get muted(): string {
    return this._json.muted!
  }
  public set muted(value: string) {
    this._json.muted = value
  }
  
  // mutedDark (muted_dark)
  public get mutedDark(): string {
    return this._json.muted_dark!
  }
  public set mutedDark(value: string) {
    this._json.muted_dark = value
  }
  
  // mutedLight (muted_light)
  public get mutedLight(): string {
    return this._json.muted_light!
  }
  public set mutedLight(value: string) {
    this._json.muted_light = value
  }
  
  static fromJSON(m: IColorPaletteJSON = {}): ColorPalette {
    return new ColorPalette({
      averageLuminance: m['average_luminance']!,
      color1: m['color_1']!,
      color2: m['color_2']!,
      color3: m['color_3']!,
      color4: m['color_4']!,
      color5: m['color_5']!,
      color6: m['color_6']!,
      vibrant: m['vibrant']!,
      vibrantDark: m['vibrant_dark']!,
      vibrantLight: m['vibrant_light']!,
      muted: m['muted']!,
      mutedDark: m['muted_dark']!,
      mutedLight: m['muted_light']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IUserImage {
  id?: string
  name?: string
  type?: string
  url?: string
  width?: number
  height?: number
  colorPalette?: ColorPalette
  
  toJSON?(): object
}

export interface IUserImageJSON {
  id?: string
  name?: string
  type?: string
  url?: string
  width?: number
  height?: number
  color_palette?: ColorPalette
  toJSON?(): object
}

export class UserImage implements IUserImage {
  private readonly _json: IUserImageJSON;

  constructor(m?: IUserImage) {
    this._json = {};
    if (m) {
      this._json['id'] = m.id;
      this._json['name'] = m.name;
      this._json['type'] = m.type;
      this._json['url'] = m.url;
      this._json['width'] = m.width;
      this._json['height'] = m.height;
      this._json['color_palette'] = m.colorPalette;
    }
  }
  
  // id (id)
  public get id(): string {
    return this._json.id!
  }
  public set id(value: string) {
    this._json.id = value
  }
  
  // name (name)
  public get name(): string {
    return this._json.name!
  }
  public set name(value: string) {
    this._json.name = value
  }
  
  // type (type)
  public get type(): string {
    return this._json.type!
  }
  public set type(value: string) {
    this._json.type = value
  }
  
  // url (url)
  public get url(): string {
    return this._json.url!
  }
  public set url(value: string) {
    this._json.url = value
  }
  
  // width (width)
  public get width(): number {
    return this._json.width!
  }
  public set width(value: number) {
    this._json.width = value
  }
  
  // height (height)
  public get height(): number {
    return this._json.height!
  }
  public set height(value: number) {
    this._json.height = value
  }
  
  // colorPalette (color_palette)
  public get colorPalette(): ColorPalette {
    return this._json.color_palette!
  }
  public set colorPalette(value: ColorPalette) {
    this._json.color_palette = value
  }
  
  static fromJSON(m: IUserImageJSON = {}): UserImage {
    return new UserImage({
      id: m['id']!,
      name: m['name']!,
      type: m['type']!,
      url: m['url']!,
      width: m['width']!,
      height: m['height']!,
      colorPalette: ColorPalette.fromJSON(m['color_palette']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IUserImageRequest {
  pagination?: PaginationRequest
  
  toJSON?(): object
}

export interface IUserImageRequestJSON {
  pagination?: PaginationRequest
  toJSON?(): object
}

export class UserImageRequest implements IUserImageRequest {
  private readonly _json: IUserImageRequestJSON;

  constructor(m?: IUserImageRequest) {
    this._json = {};
    if (m) {
      this._json['pagination'] = m.pagination;
    }
  }
  
  // pagination (pagination)
  public get pagination(): PaginationRequest {
    return this._json.pagination!
  }
  public set pagination(value: PaginationRequest) {
    this._json.pagination = value
  }
  
  static fromJSON(m: IUserImageRequestJSON = {}): UserImageRequest {
    return new UserImageRequest({
      pagination: PaginationRequest.fromJSON(m['pagination']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IUserImageResponse {
  images?: UserImage[]
  pagination?: PaginationResponse
  
  toJSON?(): object
}

export interface IUserImageResponseJSON {
  images?: UserImage[]
  pagination?: PaginationResponse
  toJSON?(): object
}

export class UserImageResponse implements IUserImageResponse {
  private readonly _json: IUserImageResponseJSON;

  constructor(m?: IUserImageResponse) {
    this._json = {};
    if (m) {
      this._json['images'] = m.images;
      this._json['pagination'] = m.pagination;
    }
  }
  
  // images (images)
  public get images(): UserImage[] {
    return this._json.images || []
  }
  public set images(value: UserImage[]) {
    this._json.images = value
  }
  
  // pagination (pagination)
  public get pagination(): PaginationResponse {
    return this._json.pagination!
  }
  public set pagination(value: PaginationResponse) {
    this._json.pagination = value
  }
  
  static fromJSON(m: IUserImageResponseJSON = {}): UserImageResponse {
    return new UserImageResponse({
      images: (m['images']! || []).map((v) => { return UserImage.fromJSON(v) }),
      pagination: PaginationResponse.fromJSON(m['pagination']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IUserAudioSource {
  id?: string
  name?: string
  durationMs?: number
  url?: string
  contentType?: string
  
  toJSON?(): object
}

export interface IUserAudioSourceJSON {
  id?: string
  name?: string
  duration_ms?: number
  url?: string
  content_type?: string
  toJSON?(): object
}

export class UserAudioSource implements IUserAudioSource {
  private readonly _json: IUserAudioSourceJSON;

  constructor(m?: IUserAudioSource) {
    this._json = {};
    if (m) {
      this._json['id'] = m.id;
      this._json['name'] = m.name;
      this._json['duration_ms'] = m.durationMs;
      this._json['url'] = m.url;
      this._json['content_type'] = m.contentType;
    }
  }
  
  // id (id)
  public get id(): string {
    return this._json.id!
  }
  public set id(value: string) {
    this._json.id = value
  }
  
  // name (name)
  public get name(): string {
    return this._json.name!
  }
  public set name(value: string) {
    this._json.name = value
  }
  
  // durationMs (duration_ms)
  public get durationMs(): number {
    return this._json.duration_ms!
  }
  public set durationMs(value: number) {
    this._json.duration_ms = value
  }
  
  // url (url)
  public get url(): string {
    return this._json.url!
  }
  public set url(value: string) {
    this._json.url = value
  }
  
  // contentType (content_type)
  public get contentType(): string {
    return this._json.content_type!
  }
  public set contentType(value: string) {
    this._json.content_type = value
  }
  
  static fromJSON(m: IUserAudioSourceJSON = {}): UserAudioSource {
    return new UserAudioSource({
      id: m['id']!,
      name: m['name']!,
      durationMs: m['duration_ms']!,
      url: m['url']!,
      contentType: m['content_type']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IUserAudio {
  id?: string
  source?: UserAudioSource
  startMs?: number
  durationMs?: number
  url?: string
  contentType?: string
  
  toJSON?(): object
}

export interface IUserAudioJSON {
  id?: string
  source?: UserAudioSource
  start_ms?: number
  duration_ms?: number
  url?: string
  content_type?: string
  toJSON?(): object
}

export class UserAudio implements IUserAudio {
  private readonly _json: IUserAudioJSON;

  constructor(m?: IUserAudio) {
    this._json = {};
    if (m) {
      this._json['id'] = m.id;
      this._json['source'] = m.source;
      this._json['start_ms'] = m.startMs;
      this._json['duration_ms'] = m.durationMs;
      this._json['url'] = m.url;
      this._json['content_type'] = m.contentType;
    }
  }
  
  // id (id)
  public get id(): string {
    return this._json.id!
  }
  public set id(value: string) {
    this._json.id = value
  }
  
  // source (source)
  public get source(): UserAudioSource {
    return this._json.source!
  }
  public set source(value: UserAudioSource) {
    this._json.source = value
  }
  
  // startMs (start_ms)
  public get startMs(): number {
    return this._json.start_ms!
  }
  public set startMs(value: number) {
    this._json.start_ms = value
  }
  
  // durationMs (duration_ms)
  public get durationMs(): number {
    return this._json.duration_ms!
  }
  public set durationMs(value: number) {
    this._json.duration_ms = value
  }
  
  // url (url)
  public get url(): string {
    return this._json.url!
  }
  public set url(value: string) {
    this._json.url = value
  }
  
  // contentType (content_type)
  public get contentType(): string {
    return this._json.content_type!
  }
  public set contentType(value: string) {
    this._json.content_type = value
  }
  
  static fromJSON(m: IUserAudioJSON = {}): UserAudio {
    return new UserAudio({
      id: m['id']!,
      source: UserAudioSource.fromJSON(m['source']!),
      startMs: m['start_ms']!,
      durationMs: m['duration_ms']!,
      url: m['url']!,
      contentType: m['content_type']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IMemberImageRequest {
  campaignId?: string
  
  toJSON?(): object
}

export interface IMemberImageRequestJSON {
  campaign_id?: string
  toJSON?(): object
}

export class MemberImageRequest implements IMemberImageRequest {
  private readonly _json: IMemberImageRequestJSON;

  constructor(m?: IMemberImageRequest) {
    this._json = {};
    if (m) {
      this._json['campaign_id'] = m.campaignId;
    }
  }
  
  // campaignId (campaign_id)
  public get campaignId(): string {
    return this._json.campaign_id!
  }
  public set campaignId(value: string) {
    this._json.campaign_id = value
  }
  
  static fromJSON(m: IMemberImageRequestJSON = {}): MemberImageRequest {
    return new MemberImageRequest({
      campaignId: m['campaign_id']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IMemberImageResponse {
  images?: MemberImage[]
  
  toJSON?(): object
}

export interface IMemberImageResponseJSON {
  images?: MemberImage[]
  toJSON?(): object
}

export class MemberImageResponse implements IMemberImageResponse {
  private readonly _json: IMemberImageResponseJSON;

  constructor(m?: IMemberImageResponse) {
    this._json = {};
    if (m) {
      this._json['images'] = m.images;
    }
  }
  
  // images (images)
  public get images(): MemberImage[] {
    return this._json.images || []
  }
  public set images(value: MemberImage[]) {
    this._json.images = value
  }
  
  static fromJSON(m: IMemberImageResponseJSON = {}): MemberImageResponse {
    return new MemberImageResponse({
      images: (m['images']! || []).map((v) => { return MemberImage.fromJSON(v) })
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IRecommendedAdsRequest {
  campaignId?: string
  
  toJSON?(): object
}

export interface IRecommendedAdsRequestJSON {
  campaign_id?: string
  toJSON?(): object
}

export class RecommendedAdsRequest implements IRecommendedAdsRequest {
  private readonly _json: IRecommendedAdsRequestJSON;

  constructor(m?: IRecommendedAdsRequest) {
    this._json = {};
    if (m) {
      this._json['campaign_id'] = m.campaignId;
    }
  }
  
  // campaignId (campaign_id)
  public get campaignId(): string {
    return this._json.campaign_id!
  }
  public set campaignId(value: string) {
    this._json.campaign_id = value
  }
  
  static fromJSON(m: IRecommendedAdsRequestJSON = {}): RecommendedAdsRequest {
    return new RecommendedAdsRequest({
      campaignId: m['campaign_id']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ICampaignExampleAdsRequest {
  campaignId?: string
  
  toJSON?(): object
}

export interface ICampaignExampleAdsRequestJSON {
  campaign_id?: string
  toJSON?(): object
}

export class CampaignExampleAdsRequest implements ICampaignExampleAdsRequest {
  private readonly _json: ICampaignExampleAdsRequestJSON;

  constructor(m?: ICampaignExampleAdsRequest) {
    this._json = {};
    if (m) {
      this._json['campaign_id'] = m.campaignId;
    }
  }
  
  // campaignId (campaign_id)
  public get campaignId(): string {
    return this._json.campaign_id!
  }
  public set campaignId(value: string) {
    this._json.campaign_id = value
  }
  
  static fromJSON(m: ICampaignExampleAdsRequestJSON = {}): CampaignExampleAdsRequest {
    return new CampaignExampleAdsRequest({
      campaignId: m['campaign_id']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IIndustryConfigRequest {
  industryId?: string
  
  toJSON?(): object
}

export interface IIndustryConfigRequestJSON {
  industry_id?: string
  toJSON?(): object
}

export class IndustryConfigRequest implements IIndustryConfigRequest {
  private readonly _json: IIndustryConfigRequestJSON;

  constructor(m?: IIndustryConfigRequest) {
    this._json = {};
    if (m) {
      this._json['industry_id'] = m.industryId;
    }
  }
  
  // industryId (industry_id)
  public get industryId(): string {
    return this._json.industry_id!
  }
  public set industryId(value: string) {
    this._json.industry_id = value
  }
  
  static fromJSON(m: IIndustryConfigRequestJSON = {}): IndustryConfigRequest {
    return new IndustryConfigRequest({
      industryId: m['industry_id']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface INetworkOption_BudgetRestriction {
  minimum?: CurrencyAmount
  durationDays?: number
  
  toJSON?(): object
}

export interface INetworkOption_BudgetRestrictionJSON {
  minimum?: CurrencyAmount
  duration_days?: number
  toJSON?(): object
}

export class NetworkOption_BudgetRestriction implements INetworkOption_BudgetRestriction {
  private readonly _json: INetworkOption_BudgetRestrictionJSON;

  constructor(m?: INetworkOption_BudgetRestriction) {
    this._json = {};
    if (m) {
      this._json['minimum'] = m.minimum;
      this._json['duration_days'] = m.durationDays;
    }
  }
  
  // minimum (minimum)
  public get minimum(): CurrencyAmount {
    return this._json.minimum!
  }
  public set minimum(value: CurrencyAmount) {
    this._json.minimum = value
  }
  
  // durationDays (duration_days)
  public get durationDays(): number {
    return this._json.duration_days!
  }
  public set durationDays(value: number) {
    this._json.duration_days = value
  }
  
  static fromJSON(m: INetworkOption_BudgetRestrictionJSON = {}): NetworkOption_BudgetRestriction {
    return new NetworkOption_BudgetRestriction({
      minimum: CurrencyAmount.fromJSON(m['minimum']!),
      durationDays: m['duration_days']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface INetworkOption {
  key?: string
  required?: boolean
  BudgetRestrictions?: NetworkOption_BudgetRestriction[]
  
  toJSON?(): object
}

export interface INetworkOptionJSON {
  key?: string
  required?: boolean
  BudgetRestrictions?: NetworkOption_BudgetRestriction[]
  toJSON?(): object
}

export class NetworkOption implements INetworkOption {
  private readonly _json: INetworkOptionJSON;

  constructor(m?: INetworkOption) {
    this._json = {};
    if (m) {
      this._json['key'] = m.key;
      this._json['required'] = m.required;
      this._json['BudgetRestrictions'] = m.BudgetRestrictions;
    }
  }
  
  // key (key)
  public get key(): string {
    return this._json.key!
  }
  public set key(value: string) {
    this._json.key = value
  }
  
  // required (required)
  public get required(): boolean {
    return this._json.required!
  }
  public set required(value: boolean) {
    this._json.required = value
  }
  
  // BudgetRestrictions (BudgetRestrictions)
  public get BudgetRestrictions(): NetworkOption_BudgetRestriction[] {
    return this._json.BudgetRestrictions || []
  }
  public set BudgetRestrictions(value: NetworkOption_BudgetRestriction[]) {
    this._json.BudgetRestrictions = value
  }
  
  static fromJSON(m: INetworkOptionJSON = {}): NetworkOption {
    return new NetworkOption({
      key: m['key']!,
      required: m['required']!,
      BudgetRestrictions: (m['BudgetRestrictions']! || []).map((v) => { return NetworkOption_BudgetRestriction.fromJSON(v) })
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IIndustryConfig_Genre {
  name?: string
  
  toJSON?(): object
}

export interface IIndustryConfig_GenreJSON {
  name?: string
  toJSON?(): object
}

export class IndustryConfig_Genre implements IIndustryConfig_Genre {
  private readonly _json: IIndustryConfig_GenreJSON;

  constructor(m?: IIndustryConfig_Genre) {
    this._json = {};
    if (m) {
      this._json['name'] = m.name;
    }
  }
  
  // name (name)
  public get name(): string {
    return this._json.name!
  }
  public set name(value: string) {
    this._json.name = value
  }
  
  static fromJSON(m: IIndustryConfig_GenreJSON = {}): IndustryConfig_Genre {
    return new IndustryConfig_Genre({
      name: m['name']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IIndustryConfig {
  genres?: IndustryConfig_Genre[]
  networkOptions?: NetworkOption[]
  
  toJSON?(): object
}

export interface IIndustryConfigJSON {
  genres?: IndustryConfig_Genre[]
  network_options?: NetworkOption[]
  toJSON?(): object
}

export class IndustryConfig implements IIndustryConfig {
  private readonly _json: IIndustryConfigJSON;

  constructor(m?: IIndustryConfig) {
    this._json = {};
    if (m) {
      this._json['genres'] = m.genres;
      this._json['network_options'] = m.networkOptions;
    }
  }
  
  // genres (genres)
  public get genres(): IndustryConfig_Genre[] {
    return this._json.genres || []
  }
  public set genres(value: IndustryConfig_Genre[]) {
    this._json.genres = value
  }
  
  // networkOptions (network_options)
  public get networkOptions(): NetworkOption[] {
    return this._json.network_options || []
  }
  public set networkOptions(value: NetworkOption[]) {
    this._json.network_options = value
  }
  
  static fromJSON(m: IIndustryConfigJSON = {}): IndustryConfig {
    return new IndustryConfig({
      genres: (m['genres']! || []).map((v) => { return IndustryConfig_Genre.fromJSON(v) }),
      networkOptions: (m['network_options']! || []).map((v) => { return NetworkOption.fromJSON(v) })
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IPaymentMethodResponse {
  brand?: string
  last4?: string
  expMonth?: number
  expYear?: number
  valid?: boolean
  
  toJSON?(): object
}

export interface IPaymentMethodResponseJSON {
  brand?: string
  last4?: string
  exp_month?: number
  exp_year?: number
  valid?: boolean
  toJSON?(): object
}

export class PaymentMethodResponse implements IPaymentMethodResponse {
  private readonly _json: IPaymentMethodResponseJSON;

  constructor(m?: IPaymentMethodResponse) {
    this._json = {};
    if (m) {
      this._json['brand'] = m.brand;
      this._json['last4'] = m.last4;
      this._json['exp_month'] = m.expMonth;
      this._json['exp_year'] = m.expYear;
      this._json['valid'] = m.valid;
    }
  }
  
  // brand (brand)
  public get brand(): string {
    return this._json.brand!
  }
  public set brand(value: string) {
    this._json.brand = value
  }
  
  // last4 (last4)
  public get last4(): string {
    return this._json.last4!
  }
  public set last4(value: string) {
    this._json.last4 = value
  }
  
  // expMonth (exp_month)
  public get expMonth(): number {
    return this._json.exp_month!
  }
  public set expMonth(value: number) {
    this._json.exp_month = value
  }
  
  // expYear (exp_year)
  public get expYear(): number {
    return this._json.exp_year!
  }
  public set expYear(value: number) {
    this._json.exp_year = value
  }
  
  // valid (valid)
  public get valid(): boolean {
    return this._json.valid!
  }
  public set valid(value: boolean) {
    this._json.valid = value
  }
  
  static fromJSON(m: IPaymentMethodResponseJSON = {}): PaymentMethodResponse {
    return new PaymentMethodResponse({
      brand: m['brand']!,
      last4: m['last4']!,
      expMonth: m['exp_month']!,
      expYear: m['exp_year']!,
      valid: m['valid']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IUpdatePaymentMethodRequest {
  token?: object
  
  toJSON?(): object
}

export interface IUpdatePaymentMethodRequestJSON {
  token?: object
  toJSON?(): object
}

export class UpdatePaymentMethodRequest implements IUpdatePaymentMethodRequest {
  private readonly _json: IUpdatePaymentMethodRequestJSON;

  constructor(m?: IUpdatePaymentMethodRequest) {
    this._json = {};
    if (m) {
      this._json['token'] = m.token;
    }
  }
  
  // token (token)
  public get token(): object {
    return this._json.token!
  }
  public set token(value: object) {
    this._json.token = value
  }
  
  static fromJSON(m: IUpdatePaymentMethodRequestJSON = {}): UpdatePaymentMethodRequest {
    return new UpdatePaymentMethodRequest({
      token: m['token']
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IApplyCouponRequest {
  campaignId?: string
  code?: string
  
  toJSON?(): object
}

export interface IApplyCouponRequestJSON {
  campaign_id?: string
  code?: string
  toJSON?(): object
}

export class ApplyCouponRequest implements IApplyCouponRequest {
  private readonly _json: IApplyCouponRequestJSON;

  constructor(m?: IApplyCouponRequest) {
    this._json = {};
    if (m) {
      this._json['campaign_id'] = m.campaignId;
      this._json['code'] = m.code;
    }
  }
  
  // campaignId (campaign_id)
  public get campaignId(): string {
    return this._json.campaign_id!
  }
  public set campaignId(value: string) {
    this._json.campaign_id = value
  }
  
  // code (code)
  public get code(): string {
    return this._json.code!
  }
  public set code(value: string) {
    this._json.code = value
  }
  
  static fromJSON(m: IApplyCouponRequestJSON = {}): ApplyCouponRequest {
    return new ApplyCouponRequest({
      campaignId: m['campaign_id']!,
      code: m['code']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IApplyCouponResponse {
  code?: string
  name?: string
  currency?: string
  amountOff?: number
  percentOff?: number
  
  toJSON?(): object
}

export interface IApplyCouponResponseJSON {
  code?: string
  name?: string
  currency?: string
  amount_off?: number
  percent_off?: number
  toJSON?(): object
}

export class ApplyCouponResponse implements IApplyCouponResponse {
  private readonly _json: IApplyCouponResponseJSON;

  constructor(m?: IApplyCouponResponse) {
    this._json = {};
    if (m) {
      this._json['code'] = m.code;
      this._json['name'] = m.name;
      this._json['currency'] = m.currency;
      this._json['amount_off'] = m.amountOff;
      this._json['percent_off'] = m.percentOff;
    }
  }
  
  // code (code)
  public get code(): string {
    return this._json.code!
  }
  public set code(value: string) {
    this._json.code = value
  }
  
  // name (name)
  public get name(): string {
    return this._json.name!
  }
  public set name(value: string) {
    this._json.name = value
  }
  
  // currency (currency)
  public get currency(): string {
    return this._json.currency!
  }
  public set currency(value: string) {
    this._json.currency = value
  }
  
  // amountOff (amount_off)
  public get amountOff(): number {
    return this._json.amount_off!
  }
  public set amountOff(value: number) {
    this._json.amount_off = value
  }
  
  // percentOff (percent_off)
  public get percentOff(): number {
    return this._json.percent_off!
  }
  public set percentOff(value: number) {
    this._json.percent_off = value
  }
  
  static fromJSON(m: IApplyCouponResponseJSON = {}): ApplyCouponResponse {
    return new ApplyCouponResponse({
      code: m['code']!,
      name: m['name']!,
      currency: m['currency']!,
      amountOff: m['amount_off']!,
      percentOff: m['percent_off']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ISubmitCampaignRequest {
  campaignId?: string
  budgetCents?: number
  currency?: string
  durationDays?: number
  couponCode?: string
  adNetworks?: string[]
  source?: string
  
  toJSON?(): object
}

export interface ISubmitCampaignRequestJSON {
  campaign_id?: string
  budget_cents?: number
  currency?: string
  duration_days?: number
  coupon_code?: string
  ad_networks?: string[]
  source?: string
  toJSON?(): object
}

export class SubmitCampaignRequest implements ISubmitCampaignRequest {
  private readonly _json: ISubmitCampaignRequestJSON;

  constructor(m?: ISubmitCampaignRequest) {
    this._json = {};
    if (m) {
      this._json['campaign_id'] = m.campaignId;
      this._json['budget_cents'] = m.budgetCents;
      this._json['currency'] = m.currency;
      this._json['duration_days'] = m.durationDays;
      this._json['coupon_code'] = m.couponCode;
      this._json['ad_networks'] = m.adNetworks;
      this._json['source'] = m.source;
    }
  }
  
  // campaignId (campaign_id)
  public get campaignId(): string {
    return this._json.campaign_id!
  }
  public set campaignId(value: string) {
    this._json.campaign_id = value
  }
  
  // budgetCents (budget_cents)
  public get budgetCents(): number {
    return this._json.budget_cents!
  }
  public set budgetCents(value: number) {
    this._json.budget_cents = value
  }
  
  // currency (currency)
  public get currency(): string {
    return this._json.currency!
  }
  public set currency(value: string) {
    this._json.currency = value
  }
  
  // durationDays (duration_days)
  public get durationDays(): number {
    return this._json.duration_days!
  }
  public set durationDays(value: number) {
    this._json.duration_days = value
  }
  
  // couponCode (coupon_code)
  public get couponCode(): string {
    return this._json.coupon_code!
  }
  public set couponCode(value: string) {
    this._json.coupon_code = value
  }
  
  // adNetworks (ad_networks)
  public get adNetworks(): string[] {
    return this._json.ad_networks || []
  }
  public set adNetworks(value: string[]) {
    this._json.ad_networks = value
  }
  
  // source (source)
  public get source(): string {
    return this._json.source!
  }
  public set source(value: string) {
    this._json.source = value
  }
  
  static fromJSON(m: ISubmitCampaignRequestJSON = {}): SubmitCampaignRequest {
    return new SubmitCampaignRequest({
      campaignId: m['campaign_id']!,
      budgetCents: m['budget_cents']!,
      currency: m['currency']!,
      durationDays: m['duration_days']!,
      couponCode: m['coupon_code']!,
      adNetworks: (m['ad_networks']! || []).map((v) => { return String(v) }),
      source: m['source']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ISubmitCampaignResponse {
  campaign?: Campaign
  charge?: Charge
  
  toJSON?(): object
}

export interface ISubmitCampaignResponseJSON {
  campaign?: Campaign
  charge?: Charge
  toJSON?(): object
}

export class SubmitCampaignResponse implements ISubmitCampaignResponse {
  private readonly _json: ISubmitCampaignResponseJSON;

  constructor(m?: ISubmitCampaignResponse) {
    this._json = {};
    if (m) {
      this._json['campaign'] = m.campaign;
      this._json['charge'] = m.charge;
    }
  }
  
  // campaign (campaign)
  public get campaign(): Campaign {
    return this._json.campaign!
  }
  public set campaign(value: Campaign) {
    this._json.campaign = value
  }
  
  // charge (charge)
  public get charge(): Charge {
    return this._json.charge!
  }
  public set charge(value: Charge) {
    this._json.charge = value
  }
  
  static fromJSON(m: ISubmitCampaignResponseJSON = {}): SubmitCampaignResponse {
    return new SubmitCampaignResponse({
      campaign: Campaign.fromJSON(m['campaign']!),
      charge: Charge.fromJSON(m['charge']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ILandingPage {
  retailer?: string
  url?: string
  ogImageUrl?: string
  
  toJSON?(): object
}

export interface ILandingPageJSON {
  retailer?: string
  url?: string
  og_image_url?: string
  toJSON?(): object
}

export class LandingPage implements ILandingPage {
  private readonly _json: ILandingPageJSON;

  constructor(m?: ILandingPage) {
    this._json = {};
    if (m) {
      this._json['retailer'] = m.retailer;
      this._json['url'] = m.url;
      this._json['og_image_url'] = m.ogImageUrl;
    }
  }
  
  // retailer (retailer)
  public get retailer(): string {
    return this._json.retailer!
  }
  public set retailer(value: string) {
    this._json.retailer = value
  }
  
  // url (url)
  public get url(): string {
    return this._json.url!
  }
  public set url(value: string) {
    this._json.url = value
  }
  
  // ogImageUrl (og_image_url)
  public get ogImageUrl(): string {
    return this._json.og_image_url!
  }
  public set ogImageUrl(value: string) {
    this._json.og_image_url = value
  }
  
  static fromJSON(m: ILandingPageJSON = {}): LandingPage {
    return new LandingPage({
      retailer: m['retailer']!,
      url: m['url']!,
      ogImageUrl: m['og_image_url']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ICampaign {
  id?: string
  createdAt?: string
  status?: Campaign_Status
  ugc?: UGC
  landingPages?: LandingPage[]
  budget?: CurrencyAmount
  durationDays?: number
  submittedAt?: string
  activatedAt?: string
  endedAt?: string
  deprecatedAuditResult?: AuditResult
  adNetworks?: string[]
  facebookPage?: FacebookPage
  
  toJSON?(): object
}

export enum Campaign_Status {
  UNUSED_BUT_REQUIRED_BY_PROTO3_Status = 'UNUSED_BUT_REQUIRED_BY_PROTO3_Status',
  REVIEWING = 'REVIEWING',
  REJECTED = 'REJECTED',
  RUNNING = 'RUNNING',
  COMPLETED = 'COMPLETED',
  ABORTED = 'ABORTED'
}

export interface ICampaignJSON {
  id?: string
  created_at?: string
  status?: Campaign_Status
  ugc?: UGC
  landing_pages?: LandingPage[]
  budget?: CurrencyAmount
  duration_days?: number
  submitted_at?: string
  activated_at?: string
  ended_at?: string
  deprecated_audit_result?: AuditResult
  ad_networks?: string[]
  facebook_page?: FacebookPage
  toJSON?(): object
}

export class Campaign implements ICampaign {
  private readonly _json: ICampaignJSON;

  constructor(m?: ICampaign) {
    this._json = {};
    if (m) {
      this._json['id'] = m.id;
      this._json['created_at'] = m.createdAt;
      this._json['status'] = m.status;
      this._json['ugc'] = m.ugc;
      this._json['landing_pages'] = m.landingPages;
      this._json['budget'] = m.budget;
      this._json['duration_days'] = m.durationDays;
      this._json['submitted_at'] = m.submittedAt;
      this._json['activated_at'] = m.activatedAt;
      this._json['ended_at'] = m.endedAt;
      this._json['deprecated_audit_result'] = m.deprecatedAuditResult;
      this._json['ad_networks'] = m.adNetworks;
      this._json['facebook_page'] = m.facebookPage;
    }
  }
  
  // id (id)
  public get id(): string {
    return this._json.id!
  }
  public set id(value: string) {
    this._json.id = value
  }
  
  // createdAt (created_at)
  public get createdAt(): string {
    return this._json.created_at!
  }
  public set createdAt(value: string) {
    this._json.created_at = value
  }
  
  // status (status)
  public get status(): Campaign_Status {
    return (<any>Campaign_Status)[this._json.status!]
  }
  public set status(value: Campaign_Status) {
    this._json.status = value
  }
  
  // ugc (ugc)
  public get ugc(): UGC {
    return this._json.ugc!
  }
  public set ugc(value: UGC) {
    this._json.ugc = value
  }
  
  // landingPages (landing_pages)
  public get landingPages(): LandingPage[] {
    return this._json.landing_pages || []
  }
  public set landingPages(value: LandingPage[]) {
    this._json.landing_pages = value
  }
  
  // budget (budget)
  public get budget(): CurrencyAmount {
    return this._json.budget!
  }
  public set budget(value: CurrencyAmount) {
    this._json.budget = value
  }
  
  // durationDays (duration_days)
  public get durationDays(): number {
    return this._json.duration_days!
  }
  public set durationDays(value: number) {
    this._json.duration_days = value
  }
  
  // submittedAt (submitted_at)
  public get submittedAt(): string {
    return this._json.submitted_at!
  }
  public set submittedAt(value: string) {
    this._json.submitted_at = value
  }
  
  // activatedAt (activated_at)
  public get activatedAt(): string {
    return this._json.activated_at!
  }
  public set activatedAt(value: string) {
    this._json.activated_at = value
  }
  
  // endedAt (ended_at)
  public get endedAt(): string {
    return this._json.ended_at!
  }
  public set endedAt(value: string) {
    this._json.ended_at = value
  }
  
  // deprecatedAuditResult (deprecated_audit_result)
  public get deprecatedAuditResult(): AuditResult {
    return this._json.deprecated_audit_result!
  }
  public set deprecatedAuditResult(value: AuditResult) {
    this._json.deprecated_audit_result = value
  }
  
  // adNetworks (ad_networks)
  public get adNetworks(): string[] {
    return this._json.ad_networks || []
  }
  public set adNetworks(value: string[]) {
    this._json.ad_networks = value
  }
  
  // facebookPage (facebook_page)
  public get facebookPage(): FacebookPage {
    return this._json.facebook_page!
  }
  public set facebookPage(value: FacebookPage) {
    this._json.facebook_page = value
  }
  
  static fromJSON(m: ICampaignJSON = {}): Campaign {
    return new Campaign({
      id: m['id']!,
      createdAt: m['created_at']!,
      status: (<any>Campaign_Status)[m['status']!]!,
      ugc: UGC.fromJSON(m['ugc']!),
      landingPages: (m['landing_pages']! || []).map((v) => { return LandingPage.fromJSON(v) }),
      budget: CurrencyAmount.fromJSON(m['budget']!),
      durationDays: m['duration_days']!,
      submittedAt: m['submitted_at']!,
      activatedAt: m['activated_at']!,
      endedAt: m['ended_at']!,
      deprecatedAuditResult: AuditResult.fromJSON(m['deprecated_audit_result']!),
      adNetworks: (m['ad_networks']! || []).map((v) => { return String(v) }),
      facebookPage: FacebookPage.fromJSON(m['facebook_page']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ICampaignListRequest {
  toJSON?(): object
}

export interface ICampaignListRequestJSON {
  toJSON?(): object
}

export class CampaignListRequest implements ICampaignListRequest {
  private readonly _json: ICampaignListRequestJSON;

  constructor(m?: ICampaignListRequest) {
    this._json = {};
    if (m) {
    }
  }
  
  static fromJSON(m: ICampaignListRequestJSON = {}): CampaignListRequest {
    return new CampaignListRequest({
    
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ICampaignList_Item {
  campaign?: Campaign
  impressions?: number
  interactions?: number
  
  toJSON?(): object
}

export interface ICampaignList_ItemJSON {
  campaign?: Campaign
  impressions?: number
  interactions?: number
  toJSON?(): object
}

export class CampaignList_Item implements ICampaignList_Item {
  private readonly _json: ICampaignList_ItemJSON;

  constructor(m?: ICampaignList_Item) {
    this._json = {};
    if (m) {
      this._json['campaign'] = m.campaign;
      this._json['impressions'] = m.impressions;
      this._json['interactions'] = m.interactions;
    }
  }
  
  // campaign (campaign)
  public get campaign(): Campaign {
    return this._json.campaign!
  }
  public set campaign(value: Campaign) {
    this._json.campaign = value
  }
  
  // impressions (impressions)
  public get impressions(): number {
    return this._json.impressions!
  }
  public set impressions(value: number) {
    this._json.impressions = value
  }
  
  // interactions (interactions)
  public get interactions(): number {
    return this._json.interactions!
  }
  public set interactions(value: number) {
    this._json.interactions = value
  }
  
  static fromJSON(m: ICampaignList_ItemJSON = {}): CampaignList_Item {
    return new CampaignList_Item({
      campaign: Campaign.fromJSON(m['campaign']!),
      impressions: m['impressions']!,
      interactions: m['interactions']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ICampaignList {
  campaigns?: CampaignList_Item[]
  
  toJSON?(): object
}

export interface ICampaignListJSON {
  campaigns?: CampaignList_Item[]
  toJSON?(): object
}

export class CampaignList implements ICampaignList {
  private readonly _json: ICampaignListJSON;

  constructor(m?: ICampaignList) {
    this._json = {};
    if (m) {
      this._json['campaigns'] = m.campaigns;
    }
  }
  
  // campaigns (campaigns)
  public get campaigns(): CampaignList_Item[] {
    return this._json.campaigns || []
  }
  public set campaigns(value: CampaignList_Item[]) {
    this._json.campaigns = value
  }
  
  static fromJSON(m: ICampaignListJSON = {}): CampaignList {
    return new CampaignList({
      campaigns: (m['campaigns']! || []).map((v) => { return CampaignList_Item.fromJSON(v) })
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IAuditResult {
  releaseDataApproved?: boolean
  releaseDataRejectedFields?: string[]
  releaseDataComments?: string
  landingPagesApproved?: boolean
  landingPagesRejectedRetailers?: string[]
  landingPagesComments?: string
  audioApproved?: boolean
  audioRejectedIds?: string[]
  audioComments?: string
  imagesApproved?: boolean
  imagesRejectedIds?: string[]
  imagesComments?: string
  auditorName?: string
  facebookPageConnectionApproved?: boolean
  facebookPageConnectionComments?: string
  copyApproved?: boolean
  copyHeaderRejectedIds?: string[]
  copySubheaderRejectedIds?: string[]
  copyComments?: string
  
  toJSON?(): object
}

export interface IAuditResultJSON {
  release_data_approved?: boolean
  release_data_rejected_fields?: string[]
  release_data_comments?: string
  landing_pages_approved?: boolean
  landing_pages_rejected_retailers?: string[]
  landing_pages_comments?: string
  audio_approved?: boolean
  audio_rejected_ids?: string[]
  audio_comments?: string
  images_approved?: boolean
  images_rejected_ids?: string[]
  images_comments?: string
  auditor_name?: string
  facebook_page_connection_approved?: boolean
  facebook_page_connection_comments?: string
  copy_approved?: boolean
  copy_header_rejected_ids?: string[]
  copy_subheader_rejected_ids?: string[]
  copy_comments?: string
  toJSON?(): object
}

export class AuditResult implements IAuditResult {
  private readonly _json: IAuditResultJSON;

  constructor(m?: IAuditResult) {
    this._json = {};
    if (m) {
      this._json['release_data_approved'] = m.releaseDataApproved;
      this._json['release_data_rejected_fields'] = m.releaseDataRejectedFields;
      this._json['release_data_comments'] = m.releaseDataComments;
      this._json['landing_pages_approved'] = m.landingPagesApproved;
      this._json['landing_pages_rejected_retailers'] = m.landingPagesRejectedRetailers;
      this._json['landing_pages_comments'] = m.landingPagesComments;
      this._json['audio_approved'] = m.audioApproved;
      this._json['audio_rejected_ids'] = m.audioRejectedIds;
      this._json['audio_comments'] = m.audioComments;
      this._json['images_approved'] = m.imagesApproved;
      this._json['images_rejected_ids'] = m.imagesRejectedIds;
      this._json['images_comments'] = m.imagesComments;
      this._json['auditor_name'] = m.auditorName;
      this._json['facebook_page_connection_approved'] = m.facebookPageConnectionApproved;
      this._json['facebook_page_connection_comments'] = m.facebookPageConnectionComments;
      this._json['copy_approved'] = m.copyApproved;
      this._json['copy_header_rejected_ids'] = m.copyHeaderRejectedIds;
      this._json['copy_subheader_rejected_ids'] = m.copySubheaderRejectedIds;
      this._json['copy_comments'] = m.copyComments;
    }
  }
  
  // releaseDataApproved (release_data_approved)
  public get releaseDataApproved(): boolean {
    return this._json.release_data_approved!
  }
  public set releaseDataApproved(value: boolean) {
    this._json.release_data_approved = value
  }
  
  // releaseDataRejectedFields (release_data_rejected_fields)
  public get releaseDataRejectedFields(): string[] {
    return this._json.release_data_rejected_fields || []
  }
  public set releaseDataRejectedFields(value: string[]) {
    this._json.release_data_rejected_fields = value
  }
  
  // releaseDataComments (release_data_comments)
  public get releaseDataComments(): string {
    return this._json.release_data_comments!
  }
  public set releaseDataComments(value: string) {
    this._json.release_data_comments = value
  }
  
  // landingPagesApproved (landing_pages_approved)
  public get landingPagesApproved(): boolean {
    return this._json.landing_pages_approved!
  }
  public set landingPagesApproved(value: boolean) {
    this._json.landing_pages_approved = value
  }
  
  // landingPagesRejectedRetailers (landing_pages_rejected_retailers)
  public get landingPagesRejectedRetailers(): string[] {
    return this._json.landing_pages_rejected_retailers || []
  }
  public set landingPagesRejectedRetailers(value: string[]) {
    this._json.landing_pages_rejected_retailers = value
  }
  
  // landingPagesComments (landing_pages_comments)
  public get landingPagesComments(): string {
    return this._json.landing_pages_comments!
  }
  public set landingPagesComments(value: string) {
    this._json.landing_pages_comments = value
  }
  
  // audioApproved (audio_approved)
  public get audioApproved(): boolean {
    return this._json.audio_approved!
  }
  public set audioApproved(value: boolean) {
    this._json.audio_approved = value
  }
  
  // audioRejectedIds (audio_rejected_ids)
  public get audioRejectedIds(): string[] {
    return this._json.audio_rejected_ids || []
  }
  public set audioRejectedIds(value: string[]) {
    this._json.audio_rejected_ids = value
  }
  
  // audioComments (audio_comments)
  public get audioComments(): string {
    return this._json.audio_comments!
  }
  public set audioComments(value: string) {
    this._json.audio_comments = value
  }
  
  // imagesApproved (images_approved)
  public get imagesApproved(): boolean {
    return this._json.images_approved!
  }
  public set imagesApproved(value: boolean) {
    this._json.images_approved = value
  }
  
  // imagesRejectedIds (images_rejected_ids)
  public get imagesRejectedIds(): string[] {
    return this._json.images_rejected_ids || []
  }
  public set imagesRejectedIds(value: string[]) {
    this._json.images_rejected_ids = value
  }
  
  // imagesComments (images_comments)
  public get imagesComments(): string {
    return this._json.images_comments!
  }
  public set imagesComments(value: string) {
    this._json.images_comments = value
  }
  
  // auditorName (auditor_name)
  public get auditorName(): string {
    return this._json.auditor_name!
  }
  public set auditorName(value: string) {
    this._json.auditor_name = value
  }
  
  // facebookPageConnectionApproved (facebook_page_connection_approved)
  public get facebookPageConnectionApproved(): boolean {
    return this._json.facebook_page_connection_approved!
  }
  public set facebookPageConnectionApproved(value: boolean) {
    this._json.facebook_page_connection_approved = value
  }
  
  // facebookPageConnectionComments (facebook_page_connection_comments)
  public get facebookPageConnectionComments(): string {
    return this._json.facebook_page_connection_comments!
  }
  public set facebookPageConnectionComments(value: string) {
    this._json.facebook_page_connection_comments = value
  }
  
  // copyApproved (copy_approved)
  public get copyApproved(): boolean {
    return this._json.copy_approved!
  }
  public set copyApproved(value: boolean) {
    this._json.copy_approved = value
  }
  
  // copyHeaderRejectedIds (copy_header_rejected_ids)
  public get copyHeaderRejectedIds(): string[] {
    return this._json.copy_header_rejected_ids || []
  }
  public set copyHeaderRejectedIds(value: string[]) {
    this._json.copy_header_rejected_ids = value
  }
  
  // copySubheaderRejectedIds (copy_subheader_rejected_ids)
  public get copySubheaderRejectedIds(): string[] {
    return this._json.copy_subheader_rejected_ids || []
  }
  public set copySubheaderRejectedIds(value: string[]) {
    this._json.copy_subheader_rejected_ids = value
  }
  
  // copyComments (copy_comments)
  public get copyComments(): string {
    return this._json.copy_comments!
  }
  public set copyComments(value: string) {
    this._json.copy_comments = value
  }
  
  static fromJSON(m: IAuditResultJSON = {}): AuditResult {
    return new AuditResult({
      releaseDataApproved: m['release_data_approved']!,
      releaseDataRejectedFields: (m['release_data_rejected_fields']! || []).map((v) => { return String(v) }),
      releaseDataComments: m['release_data_comments']!,
      landingPagesApproved: m['landing_pages_approved']!,
      landingPagesRejectedRetailers: (m['landing_pages_rejected_retailers']! || []).map((v) => { return String(v) }),
      landingPagesComments: m['landing_pages_comments']!,
      audioApproved: m['audio_approved']!,
      audioRejectedIds: (m['audio_rejected_ids']! || []).map((v) => { return String(v) }),
      audioComments: m['audio_comments']!,
      imagesApproved: m['images_approved']!,
      imagesRejectedIds: (m['images_rejected_ids']! || []).map((v) => { return String(v) }),
      imagesComments: m['images_comments']!,
      auditorName: m['auditor_name']!,
      facebookPageConnectionApproved: m['facebook_page_connection_approved']!,
      facebookPageConnectionComments: m['facebook_page_connection_comments']!,
      copyApproved: m['copy_approved']!,
      copyHeaderRejectedIds: (m['copy_header_rejected_ids']! || []).map((v) => { return String(v) }),
      copySubheaderRejectedIds: (m['copy_subheader_rejected_ids']! || []).map((v) => { return String(v) }),
      copyComments: m['copy_comments']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IRejectedCampaign_Flags {
  canRemoveAllUserImages?: boolean
  canRemoveAllDisplayCopy?: boolean
  canRemoveAllFacebookCopy?: boolean
  canRemoveAllInstagramCopy?: boolean
  
  toJSON?(): object
}

export interface IRejectedCampaign_FlagsJSON {
  can_remove_all_user_images?: boolean
  can_remove_all_display_copy?: boolean
  can_remove_all_facebook_copy?: boolean
  can_remove_all_instagram_copy?: boolean
  toJSON?(): object
}

export class RejectedCampaign_Flags implements IRejectedCampaign_Flags {
  private readonly _json: IRejectedCampaign_FlagsJSON;

  constructor(m?: IRejectedCampaign_Flags) {
    this._json = {};
    if (m) {
      this._json['can_remove_all_user_images'] = m.canRemoveAllUserImages;
      this._json['can_remove_all_display_copy'] = m.canRemoveAllDisplayCopy;
      this._json['can_remove_all_facebook_copy'] = m.canRemoveAllFacebookCopy;
      this._json['can_remove_all_instagram_copy'] = m.canRemoveAllInstagramCopy;
    }
  }
  
  // canRemoveAllUserImages (can_remove_all_user_images)
  public get canRemoveAllUserImages(): boolean {
    return this._json.can_remove_all_user_images!
  }
  public set canRemoveAllUserImages(value: boolean) {
    this._json.can_remove_all_user_images = value
  }
  
  // canRemoveAllDisplayCopy (can_remove_all_display_copy)
  public get canRemoveAllDisplayCopy(): boolean {
    return this._json.can_remove_all_display_copy!
  }
  public set canRemoveAllDisplayCopy(value: boolean) {
    this._json.can_remove_all_display_copy = value
  }
  
  // canRemoveAllFacebookCopy (can_remove_all_facebook_copy)
  public get canRemoveAllFacebookCopy(): boolean {
    return this._json.can_remove_all_facebook_copy!
  }
  public set canRemoveAllFacebookCopy(value: boolean) {
    this._json.can_remove_all_facebook_copy = value
  }
  
  // canRemoveAllInstagramCopy (can_remove_all_instagram_copy)
  public get canRemoveAllInstagramCopy(): boolean {
    return this._json.can_remove_all_instagram_copy!
  }
  public set canRemoveAllInstagramCopy(value: boolean) {
    this._json.can_remove_all_instagram_copy = value
  }
  
  static fromJSON(m: IRejectedCampaign_FlagsJSON = {}): RejectedCampaign_Flags {
    return new RejectedCampaign_Flags({
      canRemoveAllUserImages: m['can_remove_all_user_images']!,
      canRemoveAllDisplayCopy: m['can_remove_all_display_copy']!,
      canRemoveAllFacebookCopy: m['can_remove_all_facebook_copy']!,
      canRemoveAllInstagramCopy: m['can_remove_all_instagram_copy']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IRejectedCampaign_Field {
  key?: string
  value?: string
  rejected?: boolean
  
  toJSON?(): object
}

export interface IRejectedCampaign_FieldJSON {
  key?: string
  value?: string
  rejected?: boolean
  toJSON?(): object
}

export class RejectedCampaign_Field implements IRejectedCampaign_Field {
  private readonly _json: IRejectedCampaign_FieldJSON;

  constructor(m?: IRejectedCampaign_Field) {
    this._json = {};
    if (m) {
      this._json['key'] = m.key;
      this._json['value'] = m.value;
      this._json['rejected'] = m.rejected;
    }
  }
  
  // key (key)
  public get key(): string {
    return this._json.key!
  }
  public set key(value: string) {
    this._json.key = value
  }
  
  // value (value)
  public get value(): string {
    return this._json.value!
  }
  public set value(value: string) {
    this._json.value = value
  }
  
  // rejected (rejected)
  public get rejected(): boolean {
    return this._json.rejected!
  }
  public set rejected(value: boolean) {
    this._json.rejected = value
  }
  
  static fromJSON(m: IRejectedCampaign_FieldJSON = {}): RejectedCampaign_Field {
    return new RejectedCampaign_Field({
      key: m['key']!,
      value: m['value']!,
      rejected: m['rejected']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IRejectedCampaign_LandingPage {
  rejected?: boolean
  landingPage?: LandingPage
  
  toJSON?(): object
}

export interface IRejectedCampaign_LandingPageJSON {
  rejected?: boolean
  landing_page?: LandingPage
  toJSON?(): object
}

export class RejectedCampaign_LandingPage implements IRejectedCampaign_LandingPage {
  private readonly _json: IRejectedCampaign_LandingPageJSON;

  constructor(m?: IRejectedCampaign_LandingPage) {
    this._json = {};
    if (m) {
      this._json['rejected'] = m.rejected;
      this._json['landing_page'] = m.landingPage;
    }
  }
  
  // rejected (rejected)
  public get rejected(): boolean {
    return this._json.rejected!
  }
  public set rejected(value: boolean) {
    this._json.rejected = value
  }
  
  // landingPage (landing_page)
  public get landingPage(): LandingPage {
    return this._json.landing_page!
  }
  public set landingPage(value: LandingPage) {
    this._json.landing_page = value
  }
  
  static fromJSON(m: IRejectedCampaign_LandingPageJSON = {}): RejectedCampaign_LandingPage {
    return new RejectedCampaign_LandingPage({
      rejected: m['rejected']!,
      landingPage: LandingPage.fromJSON(m['landing_page']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IRejectedCampaign_FacebookPage {
  rejected?: boolean
  facebookPage?: FacebookPage
  
  toJSON?(): object
}

export interface IRejectedCampaign_FacebookPageJSON {
  rejected?: boolean
  facebook_page?: FacebookPage
  toJSON?(): object
}

export class RejectedCampaign_FacebookPage implements IRejectedCampaign_FacebookPage {
  private readonly _json: IRejectedCampaign_FacebookPageJSON;

  constructor(m?: IRejectedCampaign_FacebookPage) {
    this._json = {};
    if (m) {
      this._json['rejected'] = m.rejected;
      this._json['facebook_page'] = m.facebookPage;
    }
  }
  
  // rejected (rejected)
  public get rejected(): boolean {
    return this._json.rejected!
  }
  public set rejected(value: boolean) {
    this._json.rejected = value
  }
  
  // facebookPage (facebook_page)
  public get facebookPage(): FacebookPage {
    return this._json.facebook_page!
  }
  public set facebookPage(value: FacebookPage) {
    this._json.facebook_page = value
  }
  
  static fromJSON(m: IRejectedCampaign_FacebookPageJSON = {}): RejectedCampaign_FacebookPage {
    return new RejectedCampaign_FacebookPage({
      rejected: m['rejected']!,
      facebookPage: FacebookPage.fromJSON(m['facebook_page']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IRejectedCampaign_UserCopy {
  creative?: Creative
  headerRejected?: boolean
  subheaderRejected?: boolean
  
  toJSON?(): object
}

export interface IRejectedCampaign_UserCopyJSON {
  creative?: Creative
  header_rejected?: boolean
  subheader_rejected?: boolean
  toJSON?(): object
}

export class RejectedCampaign_UserCopy implements IRejectedCampaign_UserCopy {
  private readonly _json: IRejectedCampaign_UserCopyJSON;

  constructor(m?: IRejectedCampaign_UserCopy) {
    this._json = {};
    if (m) {
      this._json['creative'] = m.creative;
      this._json['header_rejected'] = m.headerRejected;
      this._json['subheader_rejected'] = m.subheaderRejected;
    }
  }
  
  // creative (creative)
  public get creative(): Creative {
    return this._json.creative!
  }
  public set creative(value: Creative) {
    this._json.creative = value
  }
  
  // headerRejected (header_rejected)
  public get headerRejected(): boolean {
    return this._json.header_rejected!
  }
  public set headerRejected(value: boolean) {
    this._json.header_rejected = value
  }
  
  // subheaderRejected (subheader_rejected)
  public get subheaderRejected(): boolean {
    return this._json.subheader_rejected!
  }
  public set subheaderRejected(value: boolean) {
    this._json.subheader_rejected = value
  }
  
  static fromJSON(m: IRejectedCampaign_UserCopyJSON = {}): RejectedCampaign_UserCopy {
    return new RejectedCampaign_UserCopy({
      creative: Creative.fromJSON(m['creative']!),
      headerRejected: m['header_rejected']!,
      subheaderRejected: m['subheader_rejected']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IRejectedCampaign {
  id?: string
  flags?: RejectedCampaign_Flags
  releaseDataComments?: string
  releaseData?: RejectedCampaign_Field[]
  landingPagesComments?: string
  landingPages?: RejectedCampaign_LandingPage[]
  facebookPageComments?: string
  facebookPage?: RejectedCampaign_FacebookPage
  userAudioComments?: string
  rejectedUserAudio?: UserAudio[]
  userImagesComments?: string
  rejectedUserImages?: UserImage[]
  userImageIds?: string[]
  userCopyComments?: string
  rejectedUserCopy?: RejectedCampaign_UserCopy[]
  
  toJSON?(): object
}

export interface IRejectedCampaignJSON {
  id?: string
  flags?: RejectedCampaign_Flags
  release_data_comments?: string
  release_data?: RejectedCampaign_Field[]
  landing_pages_comments?: string
  landing_pages?: RejectedCampaign_LandingPage[]
  facebook_page_comments?: string
  facebook_page?: RejectedCampaign_FacebookPage
  user_audio_comments?: string
  rejected_user_audio?: UserAudio[]
  user_images_comments?: string
  rejected_user_images?: UserImage[]
  user_image_ids?: string[]
  user_copy_comments?: string
  rejected_user_copy?: RejectedCampaign_UserCopy[]
  toJSON?(): object
}

export class RejectedCampaign implements IRejectedCampaign {
  private readonly _json: IRejectedCampaignJSON;

  constructor(m?: IRejectedCampaign) {
    this._json = {};
    if (m) {
      this._json['id'] = m.id;
      this._json['flags'] = m.flags;
      this._json['release_data_comments'] = m.releaseDataComments;
      this._json['release_data'] = m.releaseData;
      this._json['landing_pages_comments'] = m.landingPagesComments;
      this._json['landing_pages'] = m.landingPages;
      this._json['facebook_page_comments'] = m.facebookPageComments;
      this._json['facebook_page'] = m.facebookPage;
      this._json['user_audio_comments'] = m.userAudioComments;
      this._json['rejected_user_audio'] = m.rejectedUserAudio;
      this._json['user_images_comments'] = m.userImagesComments;
      this._json['rejected_user_images'] = m.rejectedUserImages;
      this._json['user_image_ids'] = m.userImageIds;
      this._json['user_copy_comments'] = m.userCopyComments;
      this._json['rejected_user_copy'] = m.rejectedUserCopy;
    }
  }
  
  // id (id)
  public get id(): string {
    return this._json.id!
  }
  public set id(value: string) {
    this._json.id = value
  }
  
  // flags (flags)
  public get flags(): RejectedCampaign_Flags {
    return this._json.flags!
  }
  public set flags(value: RejectedCampaign_Flags) {
    this._json.flags = value
  }
  
  // releaseDataComments (release_data_comments)
  public get releaseDataComments(): string {
    return this._json.release_data_comments!
  }
  public set releaseDataComments(value: string) {
    this._json.release_data_comments = value
  }
  
  // releaseData (release_data)
  public get releaseData(): RejectedCampaign_Field[] {
    return this._json.release_data || []
  }
  public set releaseData(value: RejectedCampaign_Field[]) {
    this._json.release_data = value
  }
  
  // landingPagesComments (landing_pages_comments)
  public get landingPagesComments(): string {
    return this._json.landing_pages_comments!
  }
  public set landingPagesComments(value: string) {
    this._json.landing_pages_comments = value
  }
  
  // landingPages (landing_pages)
  public get landingPages(): RejectedCampaign_LandingPage[] {
    return this._json.landing_pages || []
  }
  public set landingPages(value: RejectedCampaign_LandingPage[]) {
    this._json.landing_pages = value
  }
  
  // facebookPageComments (facebook_page_comments)
  public get facebookPageComments(): string {
    return this._json.facebook_page_comments!
  }
  public set facebookPageComments(value: string) {
    this._json.facebook_page_comments = value
  }
  
  // facebookPage (facebook_page)
  public get facebookPage(): RejectedCampaign_FacebookPage {
    return this._json.facebook_page!
  }
  public set facebookPage(value: RejectedCampaign_FacebookPage) {
    this._json.facebook_page = value
  }
  
  // userAudioComments (user_audio_comments)
  public get userAudioComments(): string {
    return this._json.user_audio_comments!
  }
  public set userAudioComments(value: string) {
    this._json.user_audio_comments = value
  }
  
  // rejectedUserAudio (rejected_user_audio)
  public get rejectedUserAudio(): UserAudio[] {
    return this._json.rejected_user_audio || []
  }
  public set rejectedUserAudio(value: UserAudio[]) {
    this._json.rejected_user_audio = value
  }
  
  // userImagesComments (user_images_comments)
  public get userImagesComments(): string {
    return this._json.user_images_comments!
  }
  public set userImagesComments(value: string) {
    this._json.user_images_comments = value
  }
  
  // rejectedUserImages (rejected_user_images)
  public get rejectedUserImages(): UserImage[] {
    return this._json.rejected_user_images || []
  }
  public set rejectedUserImages(value: UserImage[]) {
    this._json.rejected_user_images = value
  }
  
  // userImageIds (user_image_ids)
  public get userImageIds(): string[] {
    return this._json.user_image_ids || []
  }
  public set userImageIds(value: string[]) {
    this._json.user_image_ids = value
  }
  
  // userCopyComments (user_copy_comments)
  public get userCopyComments(): string {
    return this._json.user_copy_comments!
  }
  public set userCopyComments(value: string) {
    this._json.user_copy_comments = value
  }
  
  // rejectedUserCopy (rejected_user_copy)
  public get rejectedUserCopy(): RejectedCampaign_UserCopy[] {
    return this._json.rejected_user_copy || []
  }
  public set rejectedUserCopy(value: RejectedCampaign_UserCopy[]) {
    this._json.rejected_user_copy = value
  }
  
  static fromJSON(m: IRejectedCampaignJSON = {}): RejectedCampaign {
    return new RejectedCampaign({
      id: m['id']!,
      flags: RejectedCampaign_Flags.fromJSON(m['flags']!),
      releaseDataComments: m['release_data_comments']!,
      releaseData: (m['release_data']! || []).map((v) => { return RejectedCampaign_Field.fromJSON(v) }),
      landingPagesComments: m['landing_pages_comments']!,
      landingPages: (m['landing_pages']! || []).map((v) => { return RejectedCampaign_LandingPage.fromJSON(v) }),
      facebookPageComments: m['facebook_page_comments']!,
      facebookPage: RejectedCampaign_FacebookPage.fromJSON(m['facebook_page']!),
      userAudioComments: m['user_audio_comments']!,
      rejectedUserAudio: (m['rejected_user_audio']! || []).map((v) => { return UserAudio.fromJSON(v) }),
      userImagesComments: m['user_images_comments']!,
      rejectedUserImages: (m['rejected_user_images']! || []).map((v) => { return UserImage.fromJSON(v) }),
      userImageIds: (m['user_image_ids']! || []).map((v) => { return String(v) }),
      userCopyComments: m['user_copy_comments']!,
      rejectedUserCopy: (m['rejected_user_copy']! || []).map((v) => { return RejectedCampaign_UserCopy.fromJSON(v) })
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IRejectedCampaignCorrectionRequest_UserAudio {
  id?: string
  replacement?: UserAudio
  
  toJSON?(): object
}

export interface IRejectedCampaignCorrectionRequest_UserAudioJSON {
  id?: string
  replacement?: UserAudio
  toJSON?(): object
}

export class RejectedCampaignCorrectionRequest_UserAudio implements IRejectedCampaignCorrectionRequest_UserAudio {
  private readonly _json: IRejectedCampaignCorrectionRequest_UserAudioJSON;

  constructor(m?: IRejectedCampaignCorrectionRequest_UserAudio) {
    this._json = {};
    if (m) {
      this._json['id'] = m.id;
      this._json['replacement'] = m.replacement;
    }
  }
  
  // id (id)
  public get id(): string {
    return this._json.id!
  }
  public set id(value: string) {
    this._json.id = value
  }
  
  // replacement (replacement)
  public get replacement(): UserAudio {
    return this._json.replacement!
  }
  public set replacement(value: UserAudio) {
    this._json.replacement = value
  }
  
  static fromJSON(m: IRejectedCampaignCorrectionRequest_UserAudioJSON = {}): RejectedCampaignCorrectionRequest_UserAudio {
    return new RejectedCampaignCorrectionRequest_UserAudio({
      id: m['id']!,
      replacement: UserAudio.fromJSON(m['replacement']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IRejectedCampaignCorrectionRequest_UserImage {
  id?: string
  replacement?: UserImage
  
  toJSON?(): object
}

export interface IRejectedCampaignCorrectionRequest_UserImageJSON {
  id?: string
  replacement?: UserImage
  toJSON?(): object
}

export class RejectedCampaignCorrectionRequest_UserImage implements IRejectedCampaignCorrectionRequest_UserImage {
  private readonly _json: IRejectedCampaignCorrectionRequest_UserImageJSON;

  constructor(m?: IRejectedCampaignCorrectionRequest_UserImage) {
    this._json = {};
    if (m) {
      this._json['id'] = m.id;
      this._json['replacement'] = m.replacement;
    }
  }
  
  // id (id)
  public get id(): string {
    return this._json.id!
  }
  public set id(value: string) {
    this._json.id = value
  }
  
  // replacement (replacement)
  public get replacement(): UserImage {
    return this._json.replacement!
  }
  public set replacement(value: UserImage) {
    this._json.replacement = value
  }
  
  static fromJSON(m: IRejectedCampaignCorrectionRequest_UserImageJSON = {}): RejectedCampaignCorrectionRequest_UserImage {
    return new RejectedCampaignCorrectionRequest_UserImage({
      id: m['id']!,
      replacement: UserImage.fromJSON(m['replacement']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IRejectedCampaignCorrectionRequest_UserCopy {
  creativeId?: string
  customization?: CreativeCustomization
  
  toJSON?(): object
}

export interface IRejectedCampaignCorrectionRequest_UserCopyJSON {
  creative_id?: string
  customization?: CreativeCustomization
  toJSON?(): object
}

export class RejectedCampaignCorrectionRequest_UserCopy implements IRejectedCampaignCorrectionRequest_UserCopy {
  private readonly _json: IRejectedCampaignCorrectionRequest_UserCopyJSON;

  constructor(m?: IRejectedCampaignCorrectionRequest_UserCopy) {
    this._json = {};
    if (m) {
      this._json['creative_id'] = m.creativeId;
      this._json['customization'] = m.customization;
    }
  }
  
  // creativeId (creative_id)
  public get creativeId(): string {
    return this._json.creative_id!
  }
  public set creativeId(value: string) {
    this._json.creative_id = value
  }
  
  // customization (customization)
  public get customization(): CreativeCustomization {
    return this._json.customization!
  }
  public set customization(value: CreativeCustomization) {
    this._json.customization = value
  }
  
  static fromJSON(m: IRejectedCampaignCorrectionRequest_UserCopyJSON = {}): RejectedCampaignCorrectionRequest_UserCopy {
    return new RejectedCampaignCorrectionRequest_UserCopy({
      creativeId: m['creative_id']!,
      customization: CreativeCustomization.fromJSON(m['customization']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IRejectedCampaignCorrectionRequest {
  id?: string
  releaseData?: object
  landingPages?: LandingPage[]
  facebookPage?: FacebookPage
  userAudio?: RejectedCampaignCorrectionRequest_UserAudio[]
  userImages?: RejectedCampaignCorrectionRequest_UserImage[]
  userCopy?: RejectedCampaignCorrectionRequest_UserCopy[]
  
  toJSON?(): object
}

export interface IRejectedCampaignCorrectionRequestJSON {
  id?: string
  release_data?: object
  landing_pages?: LandingPage[]
  facebook_page?: FacebookPage
  user_audio?: RejectedCampaignCorrectionRequest_UserAudio[]
  user_images?: RejectedCampaignCorrectionRequest_UserImage[]
  user_copy?: RejectedCampaignCorrectionRequest_UserCopy[]
  toJSON?(): object
}

export class RejectedCampaignCorrectionRequest implements IRejectedCampaignCorrectionRequest {
  private readonly _json: IRejectedCampaignCorrectionRequestJSON;

  constructor(m?: IRejectedCampaignCorrectionRequest) {
    this._json = {};
    if (m) {
      this._json['id'] = m.id;
      this._json['release_data'] = m.releaseData;
      this._json['landing_pages'] = m.landingPages;
      this._json['facebook_page'] = m.facebookPage;
      this._json['user_audio'] = m.userAudio;
      this._json['user_images'] = m.userImages;
      this._json['user_copy'] = m.userCopy;
    }
  }
  
  // id (id)
  public get id(): string {
    return this._json.id!
  }
  public set id(value: string) {
    this._json.id = value
  }
  
  // releaseData (release_data)
  public get releaseData(): object {
    return this._json.release_data!
  }
  public set releaseData(value: object) {
    this._json.release_data = value
  }
  
  // landingPages (landing_pages)
  public get landingPages(): LandingPage[] {
    return this._json.landing_pages || []
  }
  public set landingPages(value: LandingPage[]) {
    this._json.landing_pages = value
  }
  
  // facebookPage (facebook_page)
  public get facebookPage(): FacebookPage {
    return this._json.facebook_page!
  }
  public set facebookPage(value: FacebookPage) {
    this._json.facebook_page = value
  }
  
  // userAudio (user_audio)
  public get userAudio(): RejectedCampaignCorrectionRequest_UserAudio[] {
    return this._json.user_audio || []
  }
  public set userAudio(value: RejectedCampaignCorrectionRequest_UserAudio[]) {
    this._json.user_audio = value
  }
  
  // userImages (user_images)
  public get userImages(): RejectedCampaignCorrectionRequest_UserImage[] {
    return this._json.user_images || []
  }
  public set userImages(value: RejectedCampaignCorrectionRequest_UserImage[]) {
    this._json.user_images = value
  }
  
  // userCopy (user_copy)
  public get userCopy(): RejectedCampaignCorrectionRequest_UserCopy[] {
    return this._json.user_copy || []
  }
  public set userCopy(value: RejectedCampaignCorrectionRequest_UserCopy[]) {
    this._json.user_copy = value
  }
  
  static fromJSON(m: IRejectedCampaignCorrectionRequestJSON = {}): RejectedCampaignCorrectionRequest {
    return new RejectedCampaignCorrectionRequest({
      id: m['id']!,
      releaseData: m['release_data'],
      landingPages: (m['landing_pages']! || []).map((v) => { return LandingPage.fromJSON(v) }),
      facebookPage: FacebookPage.fromJSON(m['facebook_page']!),
      userAudio: (m['user_audio']! || []).map((v) => { return RejectedCampaignCorrectionRequest_UserAudio.fromJSON(v) }),
      userImages: (m['user_images']! || []).map((v) => { return RejectedCampaignCorrectionRequest_UserImage.fromJSON(v) }),
      userCopy: (m['user_copy']! || []).map((v) => { return RejectedCampaignCorrectionRequest_UserCopy.fromJSON(v) })
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IBeginCampaignCancellationRequest {
  campaignId?: string
  
  toJSON?(): object
}

export interface IBeginCampaignCancellationRequestJSON {
  campaign_id?: string
  toJSON?(): object
}

export class BeginCampaignCancellationRequest implements IBeginCampaignCancellationRequest {
  private readonly _json: IBeginCampaignCancellationRequestJSON;

  constructor(m?: IBeginCampaignCancellationRequest) {
    this._json = {};
    if (m) {
      this._json['campaign_id'] = m.campaignId;
    }
  }
  
  // campaignId (campaign_id)
  public get campaignId(): string {
    return this._json.campaign_id!
  }
  public set campaignId(value: string) {
    this._json.campaign_id = value
  }
  
  static fromJSON(m: IBeginCampaignCancellationRequestJSON = {}): BeginCampaignCancellationRequest {
    return new BeginCampaignCancellationRequest({
      campaignId: m['campaign_id']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ICurrencyAmount {
  currency?: string
  amount?: number
  
  toJSON?(): object
}

export interface ICurrencyAmountJSON {
  currency?: string
  amount?: number
  toJSON?(): object
}

export class CurrencyAmount implements ICurrencyAmount {
  private readonly _json: ICurrencyAmountJSON;

  constructor(m?: ICurrencyAmount) {
    this._json = {};
    if (m) {
      this._json['currency'] = m.currency;
      this._json['amount'] = m.amount;
    }
  }
  
  // currency (currency)
  public get currency(): string {
    return this._json.currency!
  }
  public set currency(value: string) {
    this._json.currency = value
  }
  
  // amount (amount)
  public get amount(): number {
    return this._json.amount!
  }
  public set amount(value: number) {
    this._json.amount = value
  }
  
  static fromJSON(m: ICurrencyAmountJSON = {}): CurrencyAmount {
    return new CurrencyAmount({
      currency: m['currency']!,
      amount: m['amount']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IBeginCampaignCancellationResponse {
  fee?: CurrencyAmount
  
  toJSON?(): object
}

export interface IBeginCampaignCancellationResponseJSON {
  fee?: CurrencyAmount
  toJSON?(): object
}

export class BeginCampaignCancellationResponse implements IBeginCampaignCancellationResponse {
  private readonly _json: IBeginCampaignCancellationResponseJSON;

  constructor(m?: IBeginCampaignCancellationResponse) {
    this._json = {};
    if (m) {
      this._json['fee'] = m.fee;
    }
  }
  
  // fee (fee)
  public get fee(): CurrencyAmount {
    return this._json.fee!
  }
  public set fee(value: CurrencyAmount) {
    this._json.fee = value
  }
  
  static fromJSON(m: IBeginCampaignCancellationResponseJSON = {}): BeginCampaignCancellationResponse {
    return new BeginCampaignCancellationResponse({
      fee: CurrencyAmount.fromJSON(m['fee']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ICompleteCampaignCancellationRequest {
  campaignId?: string
  
  toJSON?(): object
}

export interface ICompleteCampaignCancellationRequestJSON {
  campaign_id?: string
  toJSON?(): object
}

export class CompleteCampaignCancellationRequest implements ICompleteCampaignCancellationRequest {
  private readonly _json: ICompleteCampaignCancellationRequestJSON;

  constructor(m?: ICompleteCampaignCancellationRequest) {
    this._json = {};
    if (m) {
      this._json['campaign_id'] = m.campaignId;
    }
  }
  
  // campaignId (campaign_id)
  public get campaignId(): string {
    return this._json.campaign_id!
  }
  public set campaignId(value: string) {
    this._json.campaign_id = value
  }
  
  static fromJSON(m: ICompleteCampaignCancellationRequestJSON = {}): CompleteCampaignCancellationRequest {
    return new CompleteCampaignCancellationRequest({
      campaignId: m['campaign_id']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ICreateCancellationFeedbackRequest {
  campaignId?: string
  reason?: string
  customText?: string
  
  toJSON?(): object
}

export interface ICreateCancellationFeedbackRequestJSON {
  campaign_id?: string
  reason?: string
  custom_text?: string
  toJSON?(): object
}

export class CreateCancellationFeedbackRequest implements ICreateCancellationFeedbackRequest {
  private readonly _json: ICreateCancellationFeedbackRequestJSON;

  constructor(m?: ICreateCancellationFeedbackRequest) {
    this._json = {};
    if (m) {
      this._json['campaign_id'] = m.campaignId;
      this._json['reason'] = m.reason;
      this._json['custom_text'] = m.customText;
    }
  }
  
  // campaignId (campaign_id)
  public get campaignId(): string {
    return this._json.campaign_id!
  }
  public set campaignId(value: string) {
    this._json.campaign_id = value
  }
  
  // reason (reason)
  public get reason(): string {
    return this._json.reason!
  }
  public set reason(value: string) {
    this._json.reason = value
  }
  
  // customText (custom_text)
  public get customText(): string {
    return this._json.custom_text!
  }
  public set customText(value: string) {
    this._json.custom_text = value
  }
  
  static fromJSON(m: ICreateCancellationFeedbackRequestJSON = {}): CreateCancellationFeedbackRequest {
    return new CreateCancellationFeedbackRequest({
      campaignId: m['campaign_id']!,
      reason: m['reason']!,
      customText: m['custom_text']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IUser {
  id?: string
  uid?: string
  email?: string
  emailVerified?: boolean
  consents?: ConsentStatus[]
  bandlabAccount?: string
  bandlabId?: string
  
  toJSON?(): object
}

export interface IUserJSON {
  id?: string
  uid?: string
  email?: string
  email_verified?: boolean
  consents?: ConsentStatus[]
  bandlab_account?: string
  bandlab_id?: string
  toJSON?(): object
}

export class User implements IUser {
  private readonly _json: IUserJSON;

  constructor(m?: IUser) {
    this._json = {};
    if (m) {
      this._json['id'] = m.id;
      this._json['uid'] = m.uid;
      this._json['email'] = m.email;
      this._json['email_verified'] = m.emailVerified;
      this._json['consents'] = m.consents;
      this._json['bandlab_account'] = m.bandlabAccount;
      this._json['bandlab_id'] = m.bandlabId;
    }
  }
  
  // id (id)
  public get id(): string {
    return this._json.id!
  }
  public set id(value: string) {
    this._json.id = value
  }
  
  // uid (uid)
  public get uid(): string {
    return this._json.uid!
  }
  public set uid(value: string) {
    this._json.uid = value
  }
  
  // email (email)
  public get email(): string {
    return this._json.email!
  }
  public set email(value: string) {
    this._json.email = value
  }
  
  // emailVerified (email_verified)
  public get emailVerified(): boolean {
    return this._json.email_verified!
  }
  public set emailVerified(value: boolean) {
    this._json.email_verified = value
  }
  
  // consents (consents)
  public get consents(): ConsentStatus[] {
    return this._json.consents || []
  }
  public set consents(value: ConsentStatus[]) {
    this._json.consents = value
  }
  
  // bandlabAccount (bandlab_account)
  public get bandlabAccount(): string {
    return this._json.bandlab_account!
  }
  public set bandlabAccount(value: string) {
    this._json.bandlab_account = value
  }
  
  // bandlabId (bandlab_id)
  public get bandlabId(): string {
    return this._json.bandlab_id!
  }
  public set bandlabId(value: string) {
    this._json.bandlab_id = value
  }
  
  static fromJSON(m: IUserJSON = {}): User {
    return new User({
      id: m['id']!,
      uid: m['uid']!,
      email: m['email']!,
      emailVerified: m['email_verified']!,
      consents: (m['consents']! || []).map((v) => { return ConsentStatus.fromJSON(v) }),
      bandlabAccount: m['bandlab_account']!,
      bandlabId: m['bandlab_id']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IOperation {
  id?: string
  status?: Operation_Status
  detail?: string
  
  toJSON?(): object
}

export enum Operation_Status {
  UNUSED_BUT_REQUIRED_BY_PROTO3_Status = 'UNUSED_BUT_REQUIRED_BY_PROTO3_Status',
  ERROR = 'ERROR',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED'
}

export interface IOperationJSON {
  id?: string
  status?: Operation_Status
  detail?: string
  toJSON?(): object
}

export class Operation implements IOperation {
  private readonly _json: IOperationJSON;

  constructor(m?: IOperation) {
    this._json = {};
    if (m) {
      this._json['id'] = m.id;
      this._json['status'] = m.status;
      this._json['detail'] = m.detail;
    }
  }
  
  // id (id)
  public get id(): string {
    return this._json.id!
  }
  public set id(value: string) {
    this._json.id = value
  }
  
  // status (status)
  public get status(): Operation_Status {
    return (<any>Operation_Status)[this._json.status!]
  }
  public set status(value: Operation_Status) {
    this._json.status = value
  }
  
  // detail (detail)
  public get detail(): string {
    return this._json.detail!
  }
  public set detail(value: string) {
    this._json.detail = value
  }
  
  static fromJSON(m: IOperationJSON = {}): Operation {
    return new Operation({
      id: m['id']!,
      status: (<any>Operation_Status)[m['status']!]!,
      detail: m['detail']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IUserAudioSourceOperation {
  operation?: Operation
  userAudioSource?: UserAudioSource
  
  toJSON?(): object
}

export interface IUserAudioSourceOperationJSON {
  operation?: Operation
  user_audio_source?: UserAudioSource
  toJSON?(): object
}

export class UserAudioSourceOperation implements IUserAudioSourceOperation {
  private readonly _json: IUserAudioSourceOperationJSON;

  constructor(m?: IUserAudioSourceOperation) {
    this._json = {};
    if (m) {
      this._json['operation'] = m.operation;
      this._json['user_audio_source'] = m.userAudioSource;
    }
  }
  
  // operation (operation)
  public get operation(): Operation {
    return this._json.operation!
  }
  public set operation(value: Operation) {
    this._json.operation = value
  }
  
  // userAudioSource (user_audio_source)
  public get userAudioSource(): UserAudioSource {
    return this._json.user_audio_source!
  }
  public set userAudioSource(value: UserAudioSource) {
    this._json.user_audio_source = value
  }
  
  static fromJSON(m: IUserAudioSourceOperationJSON = {}): UserAudioSourceOperation {
    return new UserAudioSourceOperation({
      operation: Operation.fromJSON(m['operation']!),
      userAudioSource: UserAudioSource.fromJSON(m['user_audio_source']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IUserAudioSourceRequest {
  pagination?: PaginationRequest
  
  toJSON?(): object
}

export interface IUserAudioSourceRequestJSON {
  pagination?: PaginationRequest
  toJSON?(): object
}

export class UserAudioSourceRequest implements IUserAudioSourceRequest {
  private readonly _json: IUserAudioSourceRequestJSON;

  constructor(m?: IUserAudioSourceRequest) {
    this._json = {};
    if (m) {
      this._json['pagination'] = m.pagination;
    }
  }
  
  // pagination (pagination)
  public get pagination(): PaginationRequest {
    return this._json.pagination!
  }
  public set pagination(value: PaginationRequest) {
    this._json.pagination = value
  }
  
  static fromJSON(m: IUserAudioSourceRequestJSON = {}): UserAudioSourceRequest {
    return new UserAudioSourceRequest({
      pagination: PaginationRequest.fromJSON(m['pagination']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IUserAudioSourceResponse {
  userAudioSources?: UserAudioSource[]
  pagination?: PaginationResponse
  
  toJSON?(): object
}

export interface IUserAudioSourceResponseJSON {
  user_audio_sources?: UserAudioSource[]
  pagination?: PaginationResponse
  toJSON?(): object
}

export class UserAudioSourceResponse implements IUserAudioSourceResponse {
  private readonly _json: IUserAudioSourceResponseJSON;

  constructor(m?: IUserAudioSourceResponse) {
    this._json = {};
    if (m) {
      this._json['user_audio_sources'] = m.userAudioSources;
      this._json['pagination'] = m.pagination;
    }
  }
  
  // userAudioSources (user_audio_sources)
  public get userAudioSources(): UserAudioSource[] {
    return this._json.user_audio_sources || []
  }
  public set userAudioSources(value: UserAudioSource[]) {
    this._json.user_audio_sources = value
  }
  
  // pagination (pagination)
  public get pagination(): PaginationResponse {
    return this._json.pagination!
  }
  public set pagination(value: PaginationResponse) {
    this._json.pagination = value
  }
  
  static fromJSON(m: IUserAudioSourceResponseJSON = {}): UserAudioSourceResponse {
    return new UserAudioSourceResponse({
      userAudioSources: (m['user_audio_sources']! || []).map((v) => { return UserAudioSource.fromJSON(v) }),
      pagination: PaginationResponse.fromJSON(m['pagination']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IUserAudioOperation {
  operation?: Operation
  userAudio?: UserAudio
  
  toJSON?(): object
}

export interface IUserAudioOperationJSON {
  operation?: Operation
  user_audio?: UserAudio
  toJSON?(): object
}

export class UserAudioOperation implements IUserAudioOperation {
  private readonly _json: IUserAudioOperationJSON;

  constructor(m?: IUserAudioOperation) {
    this._json = {};
    if (m) {
      this._json['operation'] = m.operation;
      this._json['user_audio'] = m.userAudio;
    }
  }
  
  // operation (operation)
  public get operation(): Operation {
    return this._json.operation!
  }
  public set operation(value: Operation) {
    this._json.operation = value
  }
  
  // userAudio (user_audio)
  public get userAudio(): UserAudio {
    return this._json.user_audio!
  }
  public set userAudio(value: UserAudio) {
    this._json.user_audio = value
  }
  
  static fromJSON(m: IUserAudioOperationJSON = {}): UserAudioOperation {
    return new UserAudioOperation({
      operation: Operation.fromJSON(m['operation']!),
      userAudio: UserAudio.fromJSON(m['user_audio']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IUserAudioRequest {
  pagination?: PaginationRequest
  
  toJSON?(): object
}

export interface IUserAudioRequestJSON {
  pagination?: PaginationRequest
  toJSON?(): object
}

export class UserAudioRequest implements IUserAudioRequest {
  private readonly _json: IUserAudioRequestJSON;

  constructor(m?: IUserAudioRequest) {
    this._json = {};
    if (m) {
      this._json['pagination'] = m.pagination;
    }
  }
  
  // pagination (pagination)
  public get pagination(): PaginationRequest {
    return this._json.pagination!
  }
  public set pagination(value: PaginationRequest) {
    this._json.pagination = value
  }
  
  static fromJSON(m: IUserAudioRequestJSON = {}): UserAudioRequest {
    return new UserAudioRequest({
      pagination: PaginationRequest.fromJSON(m['pagination']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IUserAudioResponse {
  userAudio?: UserAudio[]
  pagination?: PaginationResponse
  
  toJSON?(): object
}

export interface IUserAudioResponseJSON {
  user_audio?: UserAudio[]
  pagination?: PaginationResponse
  toJSON?(): object
}

export class UserAudioResponse implements IUserAudioResponse {
  private readonly _json: IUserAudioResponseJSON;

  constructor(m?: IUserAudioResponse) {
    this._json = {};
    if (m) {
      this._json['user_audio'] = m.userAudio;
      this._json['pagination'] = m.pagination;
    }
  }
  
  // userAudio (user_audio)
  public get userAudio(): UserAudio[] {
    return this._json.user_audio || []
  }
  public set userAudio(value: UserAudio[]) {
    this._json.user_audio = value
  }
  
  // pagination (pagination)
  public get pagination(): PaginationResponse {
    return this._json.pagination!
  }
  public set pagination(value: PaginationResponse) {
    this._json.pagination = value
  }
  
  static fromJSON(m: IUserAudioResponseJSON = {}): UserAudioResponse {
    return new UserAudioResponse({
      userAudio: (m['user_audio']! || []).map((v) => { return UserAudio.fromJSON(v) }),
      pagination: PaginationResponse.fromJSON(m['pagination']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IValidateURLRequest {
  url?: string
  
  toJSON?(): object
}

export interface IValidateURLRequestJSON {
  url?: string
  toJSON?(): object
}

export class ValidateURLRequest implements IValidateURLRequest {
  private readonly _json: IValidateURLRequestJSON;

  constructor(m?: IValidateURLRequest) {
    this._json = {};
    if (m) {
      this._json['url'] = m.url;
    }
  }
  
  // url (url)
  public get url(): string {
    return this._json.url!
  }
  public set url(value: string) {
    this._json.url = value
  }
  
  static fromJSON(m: IValidateURLRequestJSON = {}): ValidateURLRequest {
    return new ValidateURLRequest({
      url: m['url']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IValidateURLResponse {
  responseCode?: number
  valid?: boolean
  
  toJSON?(): object
}

export interface IValidateURLResponseJSON {
  response_code?: number
  valid?: boolean
  toJSON?(): object
}

export class ValidateURLResponse implements IValidateURLResponse {
  private readonly _json: IValidateURLResponseJSON;

  constructor(m?: IValidateURLResponse) {
    this._json = {};
    if (m) {
      this._json['response_code'] = m.responseCode;
      this._json['valid'] = m.valid;
    }
  }
  
  // responseCode (response_code)
  public get responseCode(): number {
    return this._json.response_code!
  }
  public set responseCode(value: number) {
    this._json.response_code = value
  }
  
  // valid (valid)
  public get valid(): boolean {
    return this._json.valid!
  }
  public set valid(value: boolean) {
    this._json.valid = value
  }
  
  static fromJSON(m: IValidateURLResponseJSON = {}): ValidateURLResponse {
    return new ValidateURLResponse({
      responseCode: m['response_code']!,
      valid: m['valid']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IMusicmetaQueryResponse_ProviderID {
  retailer?: MusicmetaQueryResponse_Retailer
  resultType?: MusicmetaQueryResponse_ResultType
  id?: string
  
  toJSON?(): object
}

export interface IMusicmetaQueryResponse_ProviderIDJSON {
  retailer?: MusicmetaQueryResponse_Retailer
  result_type?: MusicmetaQueryResponse_ResultType
  id?: string
  toJSON?(): object
}

export class MusicmetaQueryResponse_ProviderID implements IMusicmetaQueryResponse_ProviderID {
  private readonly _json: IMusicmetaQueryResponse_ProviderIDJSON;

  constructor(m?: IMusicmetaQueryResponse_ProviderID) {
    this._json = {};
    if (m) {
      this._json['retailer'] = m.retailer;
      this._json['result_type'] = m.resultType;
      this._json['id'] = m.id;
    }
  }
  
  // retailer (retailer)
  public get retailer(): MusicmetaQueryResponse_Retailer {
    return (<any>MusicmetaQueryResponse_Retailer)[this._json.retailer!]
  }
  public set retailer(value: MusicmetaQueryResponse_Retailer) {
    this._json.retailer = value
  }
  
  // resultType (result_type)
  public get resultType(): MusicmetaQueryResponse_ResultType {
    return (<any>MusicmetaQueryResponse_ResultType)[this._json.result_type!]
  }
  public set resultType(value: MusicmetaQueryResponse_ResultType) {
    this._json.result_type = value
  }
  
  // id (id)
  public get id(): string {
    return this._json.id!
  }
  public set id(value: string) {
    this._json.id = value
  }
  
  static fromJSON(m: IMusicmetaQueryResponse_ProviderIDJSON = {}): MusicmetaQueryResponse_ProviderID {
    return new MusicmetaQueryResponse_ProviderID({
      retailer: (<any>MusicmetaQueryResponse_Retailer)[m['retailer']!]!,
      resultType: (<any>MusicmetaQueryResponse_ResultType)[m['result_type']!]!,
      id: m['id']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IMusicmetaQueryResponse_RetailerResult {
  url?: string
  name?: string
  artistName?: string
  lookupCode?: string
  error?: string
  artworkUrl?: string
  genres?: string[]
  providerId?: MusicmetaQueryResponse_ProviderID
  rawResponse?: string
  albumType?: MusicmetaQueryResponse_AlbumType
  score?: number
  scorePlan?: string
  originalRank?: string
  
  toJSON?(): object
}

export interface IMusicmetaQueryResponse_RetailerResultJSON {
  url?: string
  name?: string
  artist_name?: string
  lookup_code?: string
  error?: string
  artwork_url?: string
  genres?: string[]
  provider_id?: MusicmetaQueryResponse_ProviderID
  raw_response?: string
  album_type?: MusicmetaQueryResponse_AlbumType
  score?: number
  score_plan?: string
  original_rank?: string
  toJSON?(): object
}

export class MusicmetaQueryResponse_RetailerResult implements IMusicmetaQueryResponse_RetailerResult {
  private readonly _json: IMusicmetaQueryResponse_RetailerResultJSON;

  constructor(m?: IMusicmetaQueryResponse_RetailerResult) {
    this._json = {};
    if (m) {
      this._json['url'] = m.url;
      this._json['name'] = m.name;
      this._json['artist_name'] = m.artistName;
      this._json['lookup_code'] = m.lookupCode;
      this._json['error'] = m.error;
      this._json['artwork_url'] = m.artworkUrl;
      this._json['genres'] = m.genres;
      this._json['provider_id'] = m.providerId;
      this._json['raw_response'] = m.rawResponse;
      this._json['album_type'] = m.albumType;
      this._json['score'] = m.score;
      this._json['score_plan'] = m.scorePlan;
      this._json['original_rank'] = m.originalRank;
    }
  }
  
  // url (url)
  public get url(): string {
    return this._json.url!
  }
  public set url(value: string) {
    this._json.url = value
  }
  
  // name (name)
  public get name(): string {
    return this._json.name!
  }
  public set name(value: string) {
    this._json.name = value
  }
  
  // artistName (artist_name)
  public get artistName(): string {
    return this._json.artist_name!
  }
  public set artistName(value: string) {
    this._json.artist_name = value
  }
  
  // lookupCode (lookup_code)
  public get lookupCode(): string {
    return this._json.lookup_code!
  }
  public set lookupCode(value: string) {
    this._json.lookup_code = value
  }
  
  // error (error)
  public get error(): string {
    return this._json.error!
  }
  public set error(value: string) {
    this._json.error = value
  }
  
  // artworkUrl (artwork_url)
  public get artworkUrl(): string {
    return this._json.artwork_url!
  }
  public set artworkUrl(value: string) {
    this._json.artwork_url = value
  }
  
  // genres (genres)
  public get genres(): string[] {
    return this._json.genres || []
  }
  public set genres(value: string[]) {
    this._json.genres = value
  }
  
  // providerId (provider_id)
  public get providerId(): MusicmetaQueryResponse_ProviderID {
    return this._json.provider_id!
  }
  public set providerId(value: MusicmetaQueryResponse_ProviderID) {
    this._json.provider_id = value
  }
  
  // rawResponse (raw_response)
  public get rawResponse(): string {
    return this._json.raw_response!
  }
  public set rawResponse(value: string) {
    this._json.raw_response = value
  }
  
  // albumType (album_type)
  public get albumType(): MusicmetaQueryResponse_AlbumType {
    return (<any>MusicmetaQueryResponse_AlbumType)[this._json.album_type!]
  }
  public set albumType(value: MusicmetaQueryResponse_AlbumType) {
    this._json.album_type = value
  }
  
  // score (score)
  public get score(): number {
    return this._json.score!
  }
  public set score(value: number) {
    this._json.score = value
  }
  
  // scorePlan (score_plan)
  public get scorePlan(): string {
    return this._json.score_plan!
  }
  public set scorePlan(value: string) {
    this._json.score_plan = value
  }
  
  // originalRank (original_rank)
  public get originalRank(): string {
    return this._json.original_rank!
  }
  public set originalRank(value: string) {
    this._json.original_rank = value
  }
  
  static fromJSON(m: IMusicmetaQueryResponse_RetailerResultJSON = {}): MusicmetaQueryResponse_RetailerResult {
    return new MusicmetaQueryResponse_RetailerResult({
      url: m['url']!,
      name: m['name']!,
      artistName: m['artist_name']!,
      lookupCode: m['lookup_code']!,
      error: m['error']!,
      artworkUrl: m['artwork_url']!,
      genres: (m['genres']! || []).map((v) => { return String(v) }),
      providerId: MusicmetaQueryResponse_ProviderID.fromJSON(m['provider_id']!),
      rawResponse: m['raw_response']!,
      albumType: (<any>MusicmetaQueryResponse_AlbumType)[m['album_type']!]!,
      score: m['score']!,
      scorePlan: m['score_plan']!,
      originalRank: m['original_rank']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IMusicmetaQueryResponse {
  results?: MusicmetaQueryResponse_RetailerResult[]
  
  toJSON?(): object
}

export enum MusicmetaQueryResponse_Retailer {
  UNUSED_BUT_REQUIRED_BY_PROTO3_Provider = 'UNUSED_BUT_REQUIRED_BY_PROTO3_Provider',
  SPOTIFY = 'SPOTIFY',
  DEEZER = 'DEEZER',
  APPLE = 'APPLE',
  BANDLAB = 'BANDLAB'
}

export enum MusicmetaQueryResponse_ResultType {
  UNUSED_BUT_REQUIRED_BY_PROTO3_ResultType = 'UNUSED_BUT_REQUIRED_BY_PROTO3_ResultType',
  ALBUM_RESULT = 'ALBUM_RESULT',
  TRACK_RESULT = 'TRACK_RESULT'
}

export enum MusicmetaQueryResponse_AlbumType {
  UNUSED_BUT_REQUIRED_BY_PROTO3_AlbumType = 'UNUSED_BUT_REQUIRED_BY_PROTO3_AlbumType',
  ALBUM = 'ALBUM',
  SINGLE = 'SINGLE',
  EP = 'EP',
  COMPILATION = 'COMPILATION'
}

export interface IMusicmetaQueryResponseJSON {
  results?: MusicmetaQueryResponse_RetailerResult[]
  toJSON?(): object
}

export class MusicmetaQueryResponse implements IMusicmetaQueryResponse {
  private readonly _json: IMusicmetaQueryResponseJSON;

  constructor(m?: IMusicmetaQueryResponse) {
    this._json = {};
    if (m) {
      this._json['results'] = m.results;
    }
  }
  
  // results (results)
  public get results(): MusicmetaQueryResponse_RetailerResult[] {
    return this._json.results || []
  }
  public set results(value: MusicmetaQueryResponse_RetailerResult[]) {
    this._json.results = value
  }
  
  static fromJSON(m: IMusicmetaQueryResponseJSON = {}): MusicmetaQueryResponse {
    return new MusicmetaQueryResponse({
      results: (m['results']! || []).map((v) => { return MusicmetaQueryResponse_RetailerResult.fromJSON(v) })
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ICharge {
  id?: string
  created?: string
  description?: string
  amount?: CurrencyAmount
  cardType?: string
  last4?: string
  isRefund?: boolean
  couponCode?: string
  
  toJSON?(): object
}

export interface IChargeJSON {
  id?: string
  created?: string
  description?: string
  amount?: CurrencyAmount
  card_type?: string
  last4?: string
  is_refund?: boolean
  coupon_code?: string
  toJSON?(): object
}

export class Charge implements ICharge {
  private readonly _json: IChargeJSON;

  constructor(m?: ICharge) {
    this._json = {};
    if (m) {
      this._json['id'] = m.id;
      this._json['created'] = m.created;
      this._json['description'] = m.description;
      this._json['amount'] = m.amount;
      this._json['card_type'] = m.cardType;
      this._json['last4'] = m.last4;
      this._json['is_refund'] = m.isRefund;
      this._json['coupon_code'] = m.couponCode;
    }
  }
  
  // id (id)
  public get id(): string {
    return this._json.id!
  }
  public set id(value: string) {
    this._json.id = value
  }
  
  // created (created)
  public get created(): string {
    return this._json.created!
  }
  public set created(value: string) {
    this._json.created = value
  }
  
  // description (description)
  public get description(): string {
    return this._json.description!
  }
  public set description(value: string) {
    this._json.description = value
  }
  
  // amount (amount)
  public get amount(): CurrencyAmount {
    return this._json.amount!
  }
  public set amount(value: CurrencyAmount) {
    this._json.amount = value
  }
  
  // cardType (card_type)
  public get cardType(): string {
    return this._json.card_type!
  }
  public set cardType(value: string) {
    this._json.card_type = value
  }
  
  // last4 (last4)
  public get last4(): string {
    return this._json.last4!
  }
  public set last4(value: string) {
    this._json.last4 = value
  }
  
  // isRefund (is_refund)
  public get isRefund(): boolean {
    return this._json.is_refund!
  }
  public set isRefund(value: boolean) {
    this._json.is_refund = value
  }
  
  // couponCode (coupon_code)
  public get couponCode(): string {
    return this._json.coupon_code!
  }
  public set couponCode(value: string) {
    this._json.coupon_code = value
  }
  
  static fromJSON(m: IChargeJSON = {}): Charge {
    return new Charge({
      id: m['id']!,
      created: m['created']!,
      description: m['description']!,
      amount: CurrencyAmount.fromJSON(m['amount']!),
      cardType: m['card_type']!,
      last4: m['last4']!,
      isRefund: m['is_refund']!,
      couponCode: m['coupon_code']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IChargeList {
  charges?: Charge[]
  
  toJSON?(): object
}

export interface IChargeListJSON {
  charges?: Charge[]
  toJSON?(): object
}

export class ChargeList implements IChargeList {
  private readonly _json: IChargeListJSON;

  constructor(m?: IChargeList) {
    this._json = {};
    if (m) {
      this._json['charges'] = m.charges;
    }
  }
  
  // charges (charges)
  public get charges(): Charge[] {
    return this._json.charges || []
  }
  public set charges(value: Charge[]) {
    this._json.charges = value
  }
  
  static fromJSON(m: IChargeListJSON = {}): ChargeList {
    return new ChargeList({
      charges: (m['charges']! || []).map((v) => { return Charge.fromJSON(v) })
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IReportRequest_RegionBreakdownOptions {
  countryCodes?: string[]
  
  toJSON?(): object
}

export interface IReportRequest_RegionBreakdownOptionsJSON {
  countryCodes?: string[]
  toJSON?(): object
}

export class ReportRequest_RegionBreakdownOptions implements IReportRequest_RegionBreakdownOptions {
  private readonly _json: IReportRequest_RegionBreakdownOptionsJSON;

  constructor(m?: IReportRequest_RegionBreakdownOptions) {
    this._json = {};
    if (m) {
      this._json['countryCodes'] = m.countryCodes;
    }
  }
  
  // countryCodes (countryCodes)
  public get countryCodes(): string[] {
    return this._json.countryCodes || []
  }
  public set countryCodes(value: string[]) {
    this._json.countryCodes = value
  }
  
  static fromJSON(m: IReportRequest_RegionBreakdownOptionsJSON = {}): ReportRequest_RegionBreakdownOptions {
    return new ReportRequest_RegionBreakdownOptions({
      countryCodes: (m['countryCodes']! || []).map((v) => { return String(v) })
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IReportRequest {
  campaignId?: string
  breakdowns?: ReportRequest_Breakdown[]
  regionBreakdownOptions?: ReportRequest_RegionBreakdownOptions
  
  toJSON?(): object
}

export enum ReportRequest_Breakdown {
  BREAKDOWN_NONE = 'BREAKDOWN_NONE',
  BREAKDOWN_DATE = 'BREAKDOWN_DATE',
  BREAKDOWN_CREATIVE = 'BREAKDOWN_CREATIVE',
  BREAKDOWN_DOMAIN = 'BREAKDOWN_DOMAIN',
  BREAKDOWN_COUNTRY = 'BREAKDOWN_COUNTRY',
  BREAKDOWN_REGION = 'BREAKDOWN_REGION',
  BREAKDOWN_IMAGE = 'BREAKDOWN_IMAGE',
  BREAKDOWN_COPY = 'BREAKDOWN_COPY',
  BREAKDOWN_DEMO = 'BREAKDOWN_DEMO'
}

export interface IReportRequestJSON {
  campaign_id?: string
  breakdowns?: ReportRequest_Breakdown[]
  region_breakdown_options?: ReportRequest_RegionBreakdownOptions
  toJSON?(): object
}

export class ReportRequest implements IReportRequest {
  private readonly _json: IReportRequestJSON;

  constructor(m?: IReportRequest) {
    this._json = {};
    if (m) {
      this._json['campaign_id'] = m.campaignId;
      this._json['breakdowns'] = m.breakdowns;
      this._json['region_breakdown_options'] = m.regionBreakdownOptions;
    }
  }
  
  // campaignId (campaign_id)
  public get campaignId(): string {
    return this._json.campaign_id!
  }
  public set campaignId(value: string) {
    this._json.campaign_id = value
  }
  
  // breakdowns (breakdowns)
  public get breakdowns(): ReportRequest_Breakdown[] {
    return this._json.breakdowns || []
  }
  public set breakdowns(value: ReportRequest_Breakdown[]) {
    this._json.breakdowns = value
  }
  
  // regionBreakdownOptions (region_breakdown_options)
  public get regionBreakdownOptions(): ReportRequest_RegionBreakdownOptions {
    return this._json.region_breakdown_options!
  }
  public set regionBreakdownOptions(value: ReportRequest_RegionBreakdownOptions) {
    this._json.region_breakdown_options = value
  }
  
  static fromJSON(m: IReportRequestJSON = {}): ReportRequest {
    return new ReportRequest({
      campaignId: m['campaign_id']!,
      breakdowns: (m['breakdowns']! || []).map((v) => { return (<any>ReportRequest_Breakdown)[v] }),
      regionBreakdownOptions: ReportRequest_RegionBreakdownOptions.fromJSON(m['region_breakdown_options']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IReportResponse_Report_Retailer {
  retailer?: string
  clicks?: number
  
  toJSON?(): object
}

export interface IReportResponse_Report_RetailerJSON {
  retailer?: string
  clicks?: number
  toJSON?(): object
}

export class ReportResponse_Report_Retailer implements IReportResponse_Report_Retailer {
  private readonly _json: IReportResponse_Report_RetailerJSON;

  constructor(m?: IReportResponse_Report_Retailer) {
    this._json = {};
    if (m) {
      this._json['retailer'] = m.retailer;
      this._json['clicks'] = m.clicks;
    }
  }
  
  // retailer (retailer)
  public get retailer(): string {
    return this._json.retailer!
  }
  public set retailer(value: string) {
    this._json.retailer = value
  }
  
  // clicks (clicks)
  public get clicks(): number {
    return this._json.clicks!
  }
  public set clicks(value: number) {
    this._json.clicks = value
  }
  
  static fromJSON(m: IReportResponse_Report_RetailerJSON = {}): ReportResponse_Report_Retailer {
    return new ReportResponse_Report_Retailer({
      retailer: m['retailer']!,
      clicks: m['clicks']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IReportResponse_Report_Audio_Progress {
  percentage?: number
  durationMs?: number
  count?: number
  
  toJSON?(): object
}

export interface IReportResponse_Report_Audio_ProgressJSON {
  percentage?: number
  duration_ms?: number
  count?: number
  toJSON?(): object
}

export class ReportResponse_Report_Audio_Progress implements IReportResponse_Report_Audio_Progress {
  private readonly _json: IReportResponse_Report_Audio_ProgressJSON;

  constructor(m?: IReportResponse_Report_Audio_Progress) {
    this._json = {};
    if (m) {
      this._json['percentage'] = m.percentage;
      this._json['duration_ms'] = m.durationMs;
      this._json['count'] = m.count;
    }
  }
  
  // percentage (percentage)
  public get percentage(): number {
    return this._json.percentage!
  }
  public set percentage(value: number) {
    this._json.percentage = value
  }
  
  // durationMs (duration_ms)
  public get durationMs(): number {
    return this._json.duration_ms!
  }
  public set durationMs(value: number) {
    this._json.duration_ms = value
  }
  
  // count (count)
  public get count(): number {
    return this._json.count!
  }
  public set count(value: number) {
    this._json.count = value
  }
  
  static fromJSON(m: IReportResponse_Report_Audio_ProgressJSON = {}): ReportResponse_Report_Audio_Progress {
    return new ReportResponse_Report_Audio_Progress({
      percentage: m['percentage']!,
      durationMs: m['duration_ms']!,
      count: m['count']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IReportResponse_Report_Audio_Feedback {
  rating?: number
  percentage?: number
  
  toJSON?(): object
}

export interface IReportResponse_Report_Audio_FeedbackJSON {
  rating?: number
  percentage?: number
  toJSON?(): object
}

export class ReportResponse_Report_Audio_Feedback implements IReportResponse_Report_Audio_Feedback {
  private readonly _json: IReportResponse_Report_Audio_FeedbackJSON;

  constructor(m?: IReportResponse_Report_Audio_Feedback) {
    this._json = {};
    if (m) {
      this._json['rating'] = m.rating;
      this._json['percentage'] = m.percentage;
    }
  }
  
  // rating (rating)
  public get rating(): number {
    return this._json.rating!
  }
  public set rating(value: number) {
    this._json.rating = value
  }
  
  // percentage (percentage)
  public get percentage(): number {
    return this._json.percentage!
  }
  public set percentage(value: number) {
    this._json.percentage = value
  }
  
  static fromJSON(m: IReportResponse_Report_Audio_FeedbackJSON = {}): ReportResponse_Report_Audio_Feedback {
    return new ReportResponse_Report_Audio_Feedback({
      rating: m['rating']!,
      percentage: m['percentage']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IReportResponse_Report_Audio {
  id?: string
  durationMs?: number
  plays?: number
  progress?: ReportResponse_Report_Audio_Progress[]
  feedback?: ReportResponse_Report_Audio_Feedback[]
  averageFeedback?: number
  feedbackEnabled?: boolean
  
  toJSON?(): object
}

export interface IReportResponse_Report_AudioJSON {
  id?: string
  duration_ms?: number
  plays?: number
  progress?: ReportResponse_Report_Audio_Progress[]
  feedback?: ReportResponse_Report_Audio_Feedback[]
  average_feedback?: number
  feedback_enabled?: boolean
  toJSON?(): object
}

export class ReportResponse_Report_Audio implements IReportResponse_Report_Audio {
  private readonly _json: IReportResponse_Report_AudioJSON;

  constructor(m?: IReportResponse_Report_Audio) {
    this._json = {};
    if (m) {
      this._json['id'] = m.id;
      this._json['duration_ms'] = m.durationMs;
      this._json['plays'] = m.plays;
      this._json['progress'] = m.progress;
      this._json['feedback'] = m.feedback;
      this._json['average_feedback'] = m.averageFeedback;
      this._json['feedback_enabled'] = m.feedbackEnabled;
    }
  }
  
  // id (id)
  public get id(): string {
    return this._json.id!
  }
  public set id(value: string) {
    this._json.id = value
  }
  
  // durationMs (duration_ms)
  public get durationMs(): number {
    return this._json.duration_ms!
  }
  public set durationMs(value: number) {
    this._json.duration_ms = value
  }
  
  // plays (plays)
  public get plays(): number {
    return this._json.plays!
  }
  public set plays(value: number) {
    this._json.plays = value
  }
  
  // progress (progress)
  public get progress(): ReportResponse_Report_Audio_Progress[] {
    return this._json.progress || []
  }
  public set progress(value: ReportResponse_Report_Audio_Progress[]) {
    this._json.progress = value
  }
  
  // feedback (feedback)
  public get feedback(): ReportResponse_Report_Audio_Feedback[] {
    return this._json.feedback || []
  }
  public set feedback(value: ReportResponse_Report_Audio_Feedback[]) {
    this._json.feedback = value
  }
  
  // averageFeedback (average_feedback)
  public get averageFeedback(): number {
    return this._json.average_feedback!
  }
  public set averageFeedback(value: number) {
    this._json.average_feedback = value
  }
  
  // feedbackEnabled (feedback_enabled)
  public get feedbackEnabled(): boolean {
    return this._json.feedback_enabled!
  }
  public set feedbackEnabled(value: boolean) {
    this._json.feedback_enabled = value
  }
  
  static fromJSON(m: IReportResponse_Report_AudioJSON = {}): ReportResponse_Report_Audio {
    return new ReportResponse_Report_Audio({
      id: m['id']!,
      durationMs: m['duration_ms']!,
      plays: m['plays']!,
      progress: (m['progress']! || []).map((v) => { return ReportResponse_Report_Audio_Progress.fromJSON(v) }),
      feedback: (m['feedback']! || []).map((v) => { return ReportResponse_Report_Audio_Feedback.fromJSON(v) }),
      averageFeedback: m['average_feedback']!,
      feedbackEnabled: m['feedback_enabled']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IReportResponse_Report_SocialActivity {
  reactions?: number
  shares?: number
  deprecatedSaves?: number
  pageLikes?: number
  linkClicks?: number
  
  toJSON?(): object
}

export interface IReportResponse_Report_SocialActivityJSON {
  reactions?: number
  shares?: number
  deprecated_saves?: number
  page_likes?: number
  link_clicks?: number
  toJSON?(): object
}

export class ReportResponse_Report_SocialActivity implements IReportResponse_Report_SocialActivity {
  private readonly _json: IReportResponse_Report_SocialActivityJSON;

  constructor(m?: IReportResponse_Report_SocialActivity) {
    this._json = {};
    if (m) {
      this._json['reactions'] = m.reactions;
      this._json['shares'] = m.shares;
      this._json['deprecated_saves'] = m.deprecatedSaves;
      this._json['page_likes'] = m.pageLikes;
      this._json['link_clicks'] = m.linkClicks;
    }
  }
  
  // reactions (reactions)
  public get reactions(): number {
    return this._json.reactions!
  }
  public set reactions(value: number) {
    this._json.reactions = value
  }
  
  // shares (shares)
  public get shares(): number {
    return this._json.shares!
  }
  public set shares(value: number) {
    this._json.shares = value
  }
  
  // deprecatedSaves (deprecated_saves)
  public get deprecatedSaves(): number {
    return this._json.deprecated_saves!
  }
  public set deprecatedSaves(value: number) {
    this._json.deprecated_saves = value
  }
  
  // pageLikes (page_likes)
  public get pageLikes(): number {
    return this._json.page_likes!
  }
  public set pageLikes(value: number) {
    this._json.page_likes = value
  }
  
  // linkClicks (link_clicks)
  public get linkClicks(): number {
    return this._json.link_clicks!
  }
  public set linkClicks(value: number) {
    this._json.link_clicks = value
  }
  
  static fromJSON(m: IReportResponse_Report_SocialActivityJSON = {}): ReportResponse_Report_SocialActivity {
    return new ReportResponse_Report_SocialActivity({
      reactions: m['reactions']!,
      shares: m['shares']!,
      deprecatedSaves: m['deprecated_saves']!,
      pageLikes: m['page_likes']!,
      linkClicks: m['link_clicks']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IReportResponse_Report_PromoPage {
  url?: string
  impressions?: number
  interactions?: number
  clicks?: number
  retailers?: ReportResponse_Report_Retailer[]
  audio?: ReportResponse_Report_Audio[]
  
  toJSON?(): object
}

export interface IReportResponse_Report_PromoPageJSON {
  url?: string
  impressions?: number
  interactions?: number
  clicks?: number
  retailers?: ReportResponse_Report_Retailer[]
  audio?: ReportResponse_Report_Audio[]
  toJSON?(): object
}

export class ReportResponse_Report_PromoPage implements IReportResponse_Report_PromoPage {
  private readonly _json: IReportResponse_Report_PromoPageJSON;

  constructor(m?: IReportResponse_Report_PromoPage) {
    this._json = {};
    if (m) {
      this._json['url'] = m.url;
      this._json['impressions'] = m.impressions;
      this._json['interactions'] = m.interactions;
      this._json['clicks'] = m.clicks;
      this._json['retailers'] = m.retailers;
      this._json['audio'] = m.audio;
    }
  }
  
  // url (url)
  public get url(): string {
    return this._json.url!
  }
  public set url(value: string) {
    this._json.url = value
  }
  
  // impressions (impressions)
  public get impressions(): number {
    return this._json.impressions!
  }
  public set impressions(value: number) {
    this._json.impressions = value
  }
  
  // interactions (interactions)
  public get interactions(): number {
    return this._json.interactions!
  }
  public set interactions(value: number) {
    this._json.interactions = value
  }
  
  // clicks (clicks)
  public get clicks(): number {
    return this._json.clicks!
  }
  public set clicks(value: number) {
    this._json.clicks = value
  }
  
  // retailers (retailers)
  public get retailers(): ReportResponse_Report_Retailer[] {
    return this._json.retailers || []
  }
  public set retailers(value: ReportResponse_Report_Retailer[]) {
    this._json.retailers = value
  }
  
  // audio (audio)
  public get audio(): ReportResponse_Report_Audio[] {
    return this._json.audio || []
  }
  public set audio(value: ReportResponse_Report_Audio[]) {
    this._json.audio = value
  }
  
  static fromJSON(m: IReportResponse_Report_PromoPageJSON = {}): ReportResponse_Report_PromoPage {
    return new ReportResponse_Report_PromoPage({
      url: m['url']!,
      impressions: m['impressions']!,
      interactions: m['interactions']!,
      clicks: m['clicks']!,
      retailers: (m['retailers']! || []).map((v) => { return ReportResponse_Report_Retailer.fromJSON(v) }),
      audio: (m['audio']! || []).map((v) => { return ReportResponse_Report_Audio.fromJSON(v) })
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IReportResponse_Report {
  impressions?: number
  interactions?: number
  clicks?: number
  retailers?: ReportResponse_Report_Retailer[]
  audio?: ReportResponse_Report_Audio[]
  socialActivity?: ReportResponse_Report_SocialActivity
  promoPage?: ReportResponse_Report_PromoPage
  
  toJSON?(): object
}

export interface IReportResponse_ReportJSON {
  impressions?: number
  interactions?: number
  clicks?: number
  retailers?: ReportResponse_Report_Retailer[]
  audio?: ReportResponse_Report_Audio[]
  social_activity?: ReportResponse_Report_SocialActivity
  promo_page?: ReportResponse_Report_PromoPage
  toJSON?(): object
}

export class ReportResponse_Report implements IReportResponse_Report {
  private readonly _json: IReportResponse_ReportJSON;

  constructor(m?: IReportResponse_Report) {
    this._json = {};
    if (m) {
      this._json['impressions'] = m.impressions;
      this._json['interactions'] = m.interactions;
      this._json['clicks'] = m.clicks;
      this._json['retailers'] = m.retailers;
      this._json['audio'] = m.audio;
      this._json['social_activity'] = m.socialActivity;
      this._json['promo_page'] = m.promoPage;
    }
  }
  
  // impressions (impressions)
  public get impressions(): number {
    return this._json.impressions!
  }
  public set impressions(value: number) {
    this._json.impressions = value
  }
  
  // interactions (interactions)
  public get interactions(): number {
    return this._json.interactions!
  }
  public set interactions(value: number) {
    this._json.interactions = value
  }
  
  // clicks (clicks)
  public get clicks(): number {
    return this._json.clicks!
  }
  public set clicks(value: number) {
    this._json.clicks = value
  }
  
  // retailers (retailers)
  public get retailers(): ReportResponse_Report_Retailer[] {
    return this._json.retailers || []
  }
  public set retailers(value: ReportResponse_Report_Retailer[]) {
    this._json.retailers = value
  }
  
  // audio (audio)
  public get audio(): ReportResponse_Report_Audio[] {
    return this._json.audio || []
  }
  public set audio(value: ReportResponse_Report_Audio[]) {
    this._json.audio = value
  }
  
  // socialActivity (social_activity)
  public get socialActivity(): ReportResponse_Report_SocialActivity {
    return this._json.social_activity!
  }
  public set socialActivity(value: ReportResponse_Report_SocialActivity) {
    this._json.social_activity = value
  }
  
  // promoPage (promo_page)
  public get promoPage(): ReportResponse_Report_PromoPage {
    return this._json.promo_page!
  }
  public set promoPage(value: ReportResponse_Report_PromoPage) {
    this._json.promo_page = value
  }
  
  static fromJSON(m: IReportResponse_ReportJSON = {}): ReportResponse_Report {
    return new ReportResponse_Report({
      impressions: m['impressions']!,
      interactions: m['interactions']!,
      clicks: m['clicks']!,
      retailers: (m['retailers']! || []).map((v) => { return ReportResponse_Report_Retailer.fromJSON(v) }),
      audio: (m['audio']! || []).map((v) => { return ReportResponse_Report_Audio.fromJSON(v) }),
      socialActivity: ReportResponse_Report_SocialActivity.fromJSON(m['social_activity']!),
      promoPage: ReportResponse_Report_PromoPage.fromJSON(m['promo_page']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IReportResponse_CreativeReport {
  creative?: Creative
  report?: ReportResponse_Report
  
  toJSON?(): object
}

export interface IReportResponse_CreativeReportJSON {
  creative?: Creative
  report?: ReportResponse_Report
  toJSON?(): object
}

export class ReportResponse_CreativeReport implements IReportResponse_CreativeReport {
  private readonly _json: IReportResponse_CreativeReportJSON;

  constructor(m?: IReportResponse_CreativeReport) {
    this._json = {};
    if (m) {
      this._json['creative'] = m.creative;
      this._json['report'] = m.report;
    }
  }
  
  // creative (creative)
  public get creative(): Creative {
    return this._json.creative!
  }
  public set creative(value: Creative) {
    this._json.creative = value
  }
  
  // report (report)
  public get report(): ReportResponse_Report {
    return this._json.report!
  }
  public set report(value: ReportResponse_Report) {
    this._json.report = value
  }
  
  static fromJSON(m: IReportResponse_CreativeReportJSON = {}): ReportResponse_CreativeReport {
    return new ReportResponse_CreativeReport({
      creative: Creative.fromJSON(m['creative']!),
      report: ReportResponse_Report.fromJSON(m['report']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IReportResponse_DateReport {
  date?: string
  report?: ReportResponse_Report
  
  toJSON?(): object
}

export interface IReportResponse_DateReportJSON {
  date?: string
  report?: ReportResponse_Report
  toJSON?(): object
}

export class ReportResponse_DateReport implements IReportResponse_DateReport {
  private readonly _json: IReportResponse_DateReportJSON;

  constructor(m?: IReportResponse_DateReport) {
    this._json = {};
    if (m) {
      this._json['date'] = m.date;
      this._json['report'] = m.report;
    }
  }
  
  // date (date)
  public get date(): string {
    return this._json.date!
  }
  public set date(value: string) {
    this._json.date = value
  }
  
  // report (report)
  public get report(): ReportResponse_Report {
    return this._json.report!
  }
  public set report(value: ReportResponse_Report) {
    this._json.report = value
  }
  
  static fromJSON(m: IReportResponse_DateReportJSON = {}): ReportResponse_DateReport {
    return new ReportResponse_DateReport({
      date: m['date']!,
      report: ReportResponse_Report.fromJSON(m['report']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IReportResponse_DomainReport {
  domain?: string
  retargeted?: boolean
  report?: ReportResponse_Report
  
  toJSON?(): object
}

export interface IReportResponse_DomainReportJSON {
  domain?: string
  retargeted?: boolean
  report?: ReportResponse_Report
  toJSON?(): object
}

export class ReportResponse_DomainReport implements IReportResponse_DomainReport {
  private readonly _json: IReportResponse_DomainReportJSON;

  constructor(m?: IReportResponse_DomainReport) {
    this._json = {};
    if (m) {
      this._json['domain'] = m.domain;
      this._json['retargeted'] = m.retargeted;
      this._json['report'] = m.report;
    }
  }
  
  // domain (domain)
  public get domain(): string {
    return this._json.domain!
  }
  public set domain(value: string) {
    this._json.domain = value
  }
  
  // retargeted (retargeted)
  public get retargeted(): boolean {
    return this._json.retargeted!
  }
  public set retargeted(value: boolean) {
    this._json.retargeted = value
  }
  
  // report (report)
  public get report(): ReportResponse_Report {
    return this._json.report!
  }
  public set report(value: ReportResponse_Report) {
    this._json.report = value
  }
  
  static fromJSON(m: IReportResponse_DomainReportJSON = {}): ReportResponse_DomainReport {
    return new ReportResponse_DomainReport({
      domain: m['domain']!,
      retargeted: m['retargeted']!,
      report: ReportResponse_Report.fromJSON(m['report']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IReportResponse_CountryReport {
  countryCode?: string
  country?: string
  hasRegions?: boolean
  report?: ReportResponse_Report
  
  toJSON?(): object
}

export interface IReportResponse_CountryReportJSON {
  country_code?: string
  country?: string
  has_regions?: boolean
  report?: ReportResponse_Report
  toJSON?(): object
}

export class ReportResponse_CountryReport implements IReportResponse_CountryReport {
  private readonly _json: IReportResponse_CountryReportJSON;

  constructor(m?: IReportResponse_CountryReport) {
    this._json = {};
    if (m) {
      this._json['country_code'] = m.countryCode;
      this._json['country'] = m.country;
      this._json['has_regions'] = m.hasRegions;
      this._json['report'] = m.report;
    }
  }
  
  // countryCode (country_code)
  public get countryCode(): string {
    return this._json.country_code!
  }
  public set countryCode(value: string) {
    this._json.country_code = value
  }
  
  // country (country)
  public get country(): string {
    return this._json.country!
  }
  public set country(value: string) {
    this._json.country = value
  }
  
  // hasRegions (has_regions)
  public get hasRegions(): boolean {
    return this._json.has_regions!
  }
  public set hasRegions(value: boolean) {
    this._json.has_regions = value
  }
  
  // report (report)
  public get report(): ReportResponse_Report {
    return this._json.report!
  }
  public set report(value: ReportResponse_Report) {
    this._json.report = value
  }
  
  static fromJSON(m: IReportResponse_CountryReportJSON = {}): ReportResponse_CountryReport {
    return new ReportResponse_CountryReport({
      countryCode: m['country_code']!,
      country: m['country']!,
      hasRegions: m['has_regions']!,
      report: ReportResponse_Report.fromJSON(m['report']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IReportResponse_RegionReport_Data {
  region?: string
  report?: ReportResponse_Report
  
  toJSON?(): object
}

export interface IReportResponse_RegionReport_DataJSON {
  region?: string
  report?: ReportResponse_Report
  toJSON?(): object
}

export class ReportResponse_RegionReport_Data implements IReportResponse_RegionReport_Data {
  private readonly _json: IReportResponse_RegionReport_DataJSON;

  constructor(m?: IReportResponse_RegionReport_Data) {
    this._json = {};
    if (m) {
      this._json['region'] = m.region;
      this._json['report'] = m.report;
    }
  }
  
  // region (region)
  public get region(): string {
    return this._json.region!
  }
  public set region(value: string) {
    this._json.region = value
  }
  
  // report (report)
  public get report(): ReportResponse_Report {
    return this._json.report!
  }
  public set report(value: ReportResponse_Report) {
    this._json.report = value
  }
  
  static fromJSON(m: IReportResponse_RegionReport_DataJSON = {}): ReportResponse_RegionReport_Data {
    return new ReportResponse_RegionReport_Data({
      region: m['region']!,
      report: ReportResponse_Report.fromJSON(m['report']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IReportResponse_RegionReport {
  countryCode?: string
  country?: string
  regions?: ReportResponse_RegionReport_Data[]
  
  toJSON?(): object
}

export interface IReportResponse_RegionReportJSON {
  country_code?: string
  country?: string
  regions?: ReportResponse_RegionReport_Data[]
  toJSON?(): object
}

export class ReportResponse_RegionReport implements IReportResponse_RegionReport {
  private readonly _json: IReportResponse_RegionReportJSON;

  constructor(m?: IReportResponse_RegionReport) {
    this._json = {};
    if (m) {
      this._json['country_code'] = m.countryCode;
      this._json['country'] = m.country;
      this._json['regions'] = m.regions;
    }
  }
  
  // countryCode (country_code)
  public get countryCode(): string {
    return this._json.country_code!
  }
  public set countryCode(value: string) {
    this._json.country_code = value
  }
  
  // country (country)
  public get country(): string {
    return this._json.country!
  }
  public set country(value: string) {
    this._json.country = value
  }
  
  // regions (regions)
  public get regions(): ReportResponse_RegionReport_Data[] {
    return this._json.regions || []
  }
  public set regions(value: ReportResponse_RegionReport_Data[]) {
    this._json.regions = value
  }
  
  static fromJSON(m: IReportResponse_RegionReportJSON = {}): ReportResponse_RegionReport {
    return new ReportResponse_RegionReport({
      countryCode: m['country_code']!,
      country: m['country']!,
      regions: (m['regions']! || []).map((v) => { return ReportResponse_RegionReport_Data.fromJSON(v) })
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IReportResponse_ImageReport {
  type?: string
  id?: string
  url?: string
  theme?: string
  report?: ReportResponse_Report
  
  toJSON?(): object
}

export interface IReportResponse_ImageReportJSON {
  type?: string
  id?: string
  url?: string
  theme?: string
  report?: ReportResponse_Report
  toJSON?(): object
}

export class ReportResponse_ImageReport implements IReportResponse_ImageReport {
  private readonly _json: IReportResponse_ImageReportJSON;

  constructor(m?: IReportResponse_ImageReport) {
    this._json = {};
    if (m) {
      this._json['type'] = m.type;
      this._json['id'] = m.id;
      this._json['url'] = m.url;
      this._json['theme'] = m.theme;
      this._json['report'] = m.report;
    }
  }
  
  // type (type)
  public get type(): string {
    return this._json.type!
  }
  public set type(value: string) {
    this._json.type = value
  }
  
  // id (id)
  public get id(): string {
    return this._json.id!
  }
  public set id(value: string) {
    this._json.id = value
  }
  
  // url (url)
  public get url(): string {
    return this._json.url!
  }
  public set url(value: string) {
    this._json.url = value
  }
  
  // theme (theme)
  public get theme(): string {
    return this._json.theme!
  }
  public set theme(value: string) {
    this._json.theme = value
  }
  
  // report (report)
  public get report(): ReportResponse_Report {
    return this._json.report!
  }
  public set report(value: ReportResponse_Report) {
    this._json.report = value
  }
  
  static fromJSON(m: IReportResponse_ImageReportJSON = {}): ReportResponse_ImageReport {
    return new ReportResponse_ImageReport({
      type: m['type']!,
      id: m['id']!,
      url: m['url']!,
      theme: m['theme']!,
      report: ReportResponse_Report.fromJSON(m['report']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IReportResponse_CopyReport {
  header?: string
  subheader?: string
  report?: ReportResponse_Report
  
  toJSON?(): object
}

export interface IReportResponse_CopyReportJSON {
  header?: string
  subheader?: string
  report?: ReportResponse_Report
  toJSON?(): object
}

export class ReportResponse_CopyReport implements IReportResponse_CopyReport {
  private readonly _json: IReportResponse_CopyReportJSON;

  constructor(m?: IReportResponse_CopyReport) {
    this._json = {};
    if (m) {
      this._json['header'] = m.header;
      this._json['subheader'] = m.subheader;
      this._json['report'] = m.report;
    }
  }
  
  // header (header)
  public get header(): string {
    return this._json.header!
  }
  public set header(value: string) {
    this._json.header = value
  }
  
  // subheader (subheader)
  public get subheader(): string {
    return this._json.subheader!
  }
  public set subheader(value: string) {
    this._json.subheader = value
  }
  
  // report (report)
  public get report(): ReportResponse_Report {
    return this._json.report!
  }
  public set report(value: ReportResponse_Report) {
    this._json.report = value
  }
  
  static fromJSON(m: IReportResponse_CopyReportJSON = {}): ReportResponse_CopyReport {
    return new ReportResponse_CopyReport({
      header: m['header']!,
      subheader: m['subheader']!,
      report: ReportResponse_Report.fromJSON(m['report']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IReportResponse_DemoReport {
  gender?: string
  ageRange?: string
  report?: ReportResponse_Report
  
  toJSON?(): object
}

export interface IReportResponse_DemoReportJSON {
  gender?: string
  age_range?: string
  report?: ReportResponse_Report
  toJSON?(): object
}

export class ReportResponse_DemoReport implements IReportResponse_DemoReport {
  private readonly _json: IReportResponse_DemoReportJSON;

  constructor(m?: IReportResponse_DemoReport) {
    this._json = {};
    if (m) {
      this._json['gender'] = m.gender;
      this._json['age_range'] = m.ageRange;
      this._json['report'] = m.report;
    }
  }
  
  // gender (gender)
  public get gender(): string {
    return this._json.gender!
  }
  public set gender(value: string) {
    this._json.gender = value
  }
  
  // ageRange (age_range)
  public get ageRange(): string {
    return this._json.age_range!
  }
  public set ageRange(value: string) {
    this._json.age_range = value
  }
  
  // report (report)
  public get report(): ReportResponse_Report {
    return this._json.report!
  }
  public set report(value: ReportResponse_Report) {
    this._json.report = value
  }
  
  static fromJSON(m: IReportResponse_DemoReportJSON = {}): ReportResponse_DemoReport {
    return new ReportResponse_DemoReport({
      gender: m['gender']!,
      ageRange: m['age_range']!,
      report: ReportResponse_Report.fromJSON(m['report']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IReportResponse {
  campaignId?: string
  unlocked?: boolean
  unlocksAt?: string
  report?: ReportResponse_Report
  creativeReports?: ReportResponse_CreativeReport[]
  dateReports?: ReportResponse_DateReport[]
  domainReports?: ReportResponse_DomainReport[]
  countryReports?: ReportResponse_CountryReport[]
  regionReports?: ReportResponse_RegionReport[]
  imageReports?: ReportResponse_ImageReport[]
  copyReports?: ReportResponse_CopyReport[]
  demoReports?: ReportResponse_DemoReport[]
  
  toJSON?(): object
}

export interface IReportResponseJSON {
  campaign_id?: string
  unlocked?: boolean
  unlocks_at?: string
  report?: ReportResponse_Report
  creative_reports?: ReportResponse_CreativeReport[]
  date_reports?: ReportResponse_DateReport[]
  domain_reports?: ReportResponse_DomainReport[]
  country_reports?: ReportResponse_CountryReport[]
  region_reports?: ReportResponse_RegionReport[]
  image_reports?: ReportResponse_ImageReport[]
  copy_reports?: ReportResponse_CopyReport[]
  demo_reports?: ReportResponse_DemoReport[]
  toJSON?(): object
}

export class ReportResponse implements IReportResponse {
  private readonly _json: IReportResponseJSON;

  constructor(m?: IReportResponse) {
    this._json = {};
    if (m) {
      this._json['campaign_id'] = m.campaignId;
      this._json['unlocked'] = m.unlocked;
      this._json['unlocks_at'] = m.unlocksAt;
      this._json['report'] = m.report;
      this._json['creative_reports'] = m.creativeReports;
      this._json['date_reports'] = m.dateReports;
      this._json['domain_reports'] = m.domainReports;
      this._json['country_reports'] = m.countryReports;
      this._json['region_reports'] = m.regionReports;
      this._json['image_reports'] = m.imageReports;
      this._json['copy_reports'] = m.copyReports;
      this._json['demo_reports'] = m.demoReports;
    }
  }
  
  // campaignId (campaign_id)
  public get campaignId(): string {
    return this._json.campaign_id!
  }
  public set campaignId(value: string) {
    this._json.campaign_id = value
  }
  
  // unlocked (unlocked)
  public get unlocked(): boolean {
    return this._json.unlocked!
  }
  public set unlocked(value: boolean) {
    this._json.unlocked = value
  }
  
  // unlocksAt (unlocks_at)
  public get unlocksAt(): string {
    return this._json.unlocks_at!
  }
  public set unlocksAt(value: string) {
    this._json.unlocks_at = value
  }
  
  // report (report)
  public get report(): ReportResponse_Report {
    return this._json.report!
  }
  public set report(value: ReportResponse_Report) {
    this._json.report = value
  }
  
  // creativeReports (creative_reports)
  public get creativeReports(): ReportResponse_CreativeReport[] {
    return this._json.creative_reports || []
  }
  public set creativeReports(value: ReportResponse_CreativeReport[]) {
    this._json.creative_reports = value
  }
  
  // dateReports (date_reports)
  public get dateReports(): ReportResponse_DateReport[] {
    return this._json.date_reports || []
  }
  public set dateReports(value: ReportResponse_DateReport[]) {
    this._json.date_reports = value
  }
  
  // domainReports (domain_reports)
  public get domainReports(): ReportResponse_DomainReport[] {
    return this._json.domain_reports || []
  }
  public set domainReports(value: ReportResponse_DomainReport[]) {
    this._json.domain_reports = value
  }
  
  // countryReports (country_reports)
  public get countryReports(): ReportResponse_CountryReport[] {
    return this._json.country_reports || []
  }
  public set countryReports(value: ReportResponse_CountryReport[]) {
    this._json.country_reports = value
  }
  
  // regionReports (region_reports)
  public get regionReports(): ReportResponse_RegionReport[] {
    return this._json.region_reports || []
  }
  public set regionReports(value: ReportResponse_RegionReport[]) {
    this._json.region_reports = value
  }
  
  // imageReports (image_reports)
  public get imageReports(): ReportResponse_ImageReport[] {
    return this._json.image_reports || []
  }
  public set imageReports(value: ReportResponse_ImageReport[]) {
    this._json.image_reports = value
  }
  
  // copyReports (copy_reports)
  public get copyReports(): ReportResponse_CopyReport[] {
    return this._json.copy_reports || []
  }
  public set copyReports(value: ReportResponse_CopyReport[]) {
    this._json.copy_reports = value
  }
  
  // demoReports (demo_reports)
  public get demoReports(): ReportResponse_DemoReport[] {
    return this._json.demo_reports || []
  }
  public set demoReports(value: ReportResponse_DemoReport[]) {
    this._json.demo_reports = value
  }
  
  static fromJSON(m: IReportResponseJSON = {}): ReportResponse {
    return new ReportResponse({
      campaignId: m['campaign_id']!,
      unlocked: m['unlocked']!,
      unlocksAt: m['unlocks_at']!,
      report: ReportResponse_Report.fromJSON(m['report']!),
      creativeReports: (m['creative_reports']! || []).map((v) => { return ReportResponse_CreativeReport.fromJSON(v) }),
      dateReports: (m['date_reports']! || []).map((v) => { return ReportResponse_DateReport.fromJSON(v) }),
      domainReports: (m['domain_reports']! || []).map((v) => { return ReportResponse_DomainReport.fromJSON(v) }),
      countryReports: (m['country_reports']! || []).map((v) => { return ReportResponse_CountryReport.fromJSON(v) }),
      regionReports: (m['region_reports']! || []).map((v) => { return ReportResponse_RegionReport.fromJSON(v) }),
      imageReports: (m['image_reports']! || []).map((v) => { return ReportResponse_ImageReport.fromJSON(v) }),
      copyReports: (m['copy_reports']! || []).map((v) => { return ReportResponse_CopyReport.fromJSON(v) }),
      demoReports: (m['demo_reports']! || []).map((v) => { return ReportResponse_DemoReport.fromJSON(v) })
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IUpdateEmailRequest {
  email?: string
  
  toJSON?(): object
}

export interface IUpdateEmailRequestJSON {
  email?: string
  toJSON?(): object
}

export class UpdateEmailRequest implements IUpdateEmailRequest {
  private readonly _json: IUpdateEmailRequestJSON;

  constructor(m?: IUpdateEmailRequest) {
    this._json = {};
    if (m) {
      this._json['email'] = m.email;
    }
  }
  
  // email (email)
  public get email(): string {
    return this._json.email!
  }
  public set email(value: string) {
    this._json.email = value
  }
  
  static fromJSON(m: IUpdateEmailRequestJSON = {}): UpdateEmailRequest {
    return new UpdateEmailRequest({
      email: m['email']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ITokenResponse {
  token?: string
  
  toJSON?(): object
}

export interface ITokenResponseJSON {
  token?: string
  toJSON?(): object
}

export class TokenResponse implements ITokenResponse {
  private readonly _json: ITokenResponseJSON;

  constructor(m?: ITokenResponse) {
    this._json = {};
    if (m) {
      this._json['token'] = m.token;
    }
  }
  
  // token (token)
  public get token(): string {
    return this._json.token!
  }
  public set token(value: string) {
    this._json.token = value
  }
  
  static fromJSON(m: ITokenResponseJSON = {}): TokenResponse {
    return new TokenResponse({
      token: m['token']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IUpdatePasswordRequest {
  oldPassword?: string
  newPassword?: string
  
  toJSON?(): object
}

export interface IUpdatePasswordRequestJSON {
  old_password?: string
  new_password?: string
  toJSON?(): object
}

export class UpdatePasswordRequest implements IUpdatePasswordRequest {
  private readonly _json: IUpdatePasswordRequestJSON;

  constructor(m?: IUpdatePasswordRequest) {
    this._json = {};
    if (m) {
      this._json['old_password'] = m.oldPassword;
      this._json['new_password'] = m.newPassword;
    }
  }
  
  // oldPassword (old_password)
  public get oldPassword(): string {
    return this._json.old_password!
  }
  public set oldPassword(value: string) {
    this._json.old_password = value
  }
  
  // newPassword (new_password)
  public get newPassword(): string {
    return this._json.new_password!
  }
  public set newPassword(value: string) {
    this._json.new_password = value
  }
  
  static fromJSON(m: IUpdatePasswordRequestJSON = {}): UpdatePasswordRequest {
    return new UpdatePasswordRequest({
      oldPassword: m['old_password']!,
      newPassword: m['new_password']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IUserTokenRequest {
  userId?: string
  token?: string
  
  toJSON?(): object
}

export interface IUserTokenRequestJSON {
  user_id?: string
  token?: string
  toJSON?(): object
}

export class UserTokenRequest implements IUserTokenRequest {
  private readonly _json: IUserTokenRequestJSON;

  constructor(m?: IUserTokenRequest) {
    this._json = {};
    if (m) {
      this._json['user_id'] = m.userId;
      this._json['token'] = m.token;
    }
  }
  
  // userId (user_id)
  public get userId(): string {
    return this._json.user_id!
  }
  public set userId(value: string) {
    this._json.user_id = value
  }
  
  // token (token)
  public get token(): string {
    return this._json.token!
  }
  public set token(value: string) {
    this._json.token = value
  }
  
  static fromJSON(m: IUserTokenRequestJSON = {}): UserTokenRequest {
    return new UserTokenRequest({
      userId: m['user_id']!,
      token: m['token']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ILoginRequest {
  anonymousToken?: string
  
  toJSON?(): object
}

export interface ILoginRequestJSON {
  anonymous_token?: string
  toJSON?(): object
}

export class LoginRequest implements ILoginRequest {
  private readonly _json: ILoginRequestJSON;

  constructor(m?: ILoginRequest) {
    this._json = {};
    if (m) {
      this._json['anonymous_token'] = m.anonymousToken;
    }
  }
  
  // anonymousToken (anonymous_token)
  public get anonymousToken(): string {
    return this._json.anonymous_token!
  }
  public set anonymousToken(value: string) {
    this._json.anonymous_token = value
  }
  
  static fromJSON(m: ILoginRequestJSON = {}): LoginRequest {
    return new LoginRequest({
      anonymousToken: m['anonymous_token']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IConsentStatus {
  consent?: string
  version?: string
  granted?: boolean
  
  toJSON?(): object
}

export interface IConsentStatusJSON {
  consent?: string
  version?: string
  granted?: boolean
  toJSON?(): object
}

export class ConsentStatus implements IConsentStatus {
  private readonly _json: IConsentStatusJSON;

  constructor(m?: IConsentStatus) {
    this._json = {};
    if (m) {
      this._json['consent'] = m.consent;
      this._json['version'] = m.version;
      this._json['granted'] = m.granted;
    }
  }
  
  // consent (consent)
  public get consent(): string {
    return this._json.consent!
  }
  public set consent(value: string) {
    this._json.consent = value
  }
  
  // version (version)
  public get version(): string {
    return this._json.version!
  }
  public set version(value: string) {
    this._json.version = value
  }
  
  // granted (granted)
  public get granted(): boolean {
    return this._json.granted!
  }
  public set granted(value: boolean) {
    this._json.granted = value
  }
  
  static fromJSON(m: IConsentStatusJSON = {}): ConsentStatus {
    return new ConsentStatus({
      consent: m['consent']!,
      version: m['version']!,
      granted: m['granted']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IUpdateConsentsRequest {
  updates?: ConsentStatus[]
  
  toJSON?(): object
}

export interface IUpdateConsentsRequestJSON {
  updates?: ConsentStatus[]
  toJSON?(): object
}

export class UpdateConsentsRequest implements IUpdateConsentsRequest {
  private readonly _json: IUpdateConsentsRequestJSON;

  constructor(m?: IUpdateConsentsRequest) {
    this._json = {};
    if (m) {
      this._json['updates'] = m.updates;
    }
  }
  
  // updates (updates)
  public get updates(): ConsentStatus[] {
    return this._json.updates || []
  }
  public set updates(value: ConsentStatus[]) {
    this._json.updates = value
  }
  
  static fromJSON(m: IUpdateConsentsRequestJSON = {}): UpdateConsentsRequest {
    return new UpdateConsentsRequest({
      updates: (m['updates']! || []).map((v) => { return ConsentStatus.fromJSON(v) })
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IForgotPasswordEmailRequest {
  email?: string
  
  toJSON?(): object
}

export interface IForgotPasswordEmailRequestJSON {
  email?: string
  toJSON?(): object
}

export class ForgotPasswordEmailRequest implements IForgotPasswordEmailRequest {
  private readonly _json: IForgotPasswordEmailRequestJSON;

  constructor(m?: IForgotPasswordEmailRequest) {
    this._json = {};
    if (m) {
      this._json['email'] = m.email;
    }
  }
  
  // email (email)
  public get email(): string {
    return this._json.email!
  }
  public set email(value: string) {
    this._json.email = value
  }
  
  static fromJSON(m: IForgotPasswordEmailRequestJSON = {}): ForgotPasswordEmailRequest {
    return new ForgotPasswordEmailRequest({
      email: m['email']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IForgotPasswordResetRequest {
  email?: string
  code?: string
  password?: string
  
  toJSON?(): object
}

export interface IForgotPasswordResetRequestJSON {
  email?: string
  code?: string
  password?: string
  toJSON?(): object
}

export class ForgotPasswordResetRequest implements IForgotPasswordResetRequest {
  private readonly _json: IForgotPasswordResetRequestJSON;

  constructor(m?: IForgotPasswordResetRequest) {
    this._json = {};
    if (m) {
      this._json['email'] = m.email;
      this._json['code'] = m.code;
      this._json['password'] = m.password;
    }
  }
  
  // email (email)
  public get email(): string {
    return this._json.email!
  }
  public set email(value: string) {
    this._json.email = value
  }
  
  // code (code)
  public get code(): string {
    return this._json.code!
  }
  public set code(value: string) {
    this._json.code = value
  }
  
  // password (password)
  public get password(): string {
    return this._json.password!
  }
  public set password(value: string) {
    this._json.password = value
  }
  
  static fromJSON(m: IForgotPasswordResetRequestJSON = {}): ForgotPasswordResetRequest {
    return new ForgotPasswordResetRequest({
      email: m['email']!,
      code: m['code']!,
      password: m['password']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ISyncFacebookPageRequest {
  accessToken?: string
  
  toJSON?(): object
}

export interface ISyncFacebookPageRequestJSON {
  access_token?: string
  toJSON?(): object
}

export class SyncFacebookPageRequest implements ISyncFacebookPageRequest {
  private readonly _json: ISyncFacebookPageRequestJSON;

  constructor(m?: ISyncFacebookPageRequest) {
    this._json = {};
    if (m) {
      this._json['access_token'] = m.accessToken;
    }
  }
  
  // accessToken (access_token)
  public get accessToken(): string {
    return this._json.access_token!
  }
  public set accessToken(value: string) {
    this._json.access_token = value
  }
  
  static fromJSON(m: ISyncFacebookPageRequestJSON = {}): SyncFacebookPageRequest {
    return new SyncFacebookPageRequest({
      accessToken: m['access_token']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IDisconnectFacebookPageRequest {
  pageId?: string
  
  toJSON?(): object
}

export interface IDisconnectFacebookPageRequestJSON {
  page_id?: string
  toJSON?(): object
}

export class DisconnectFacebookPageRequest implements IDisconnectFacebookPageRequest {
  private readonly _json: IDisconnectFacebookPageRequestJSON;

  constructor(m?: IDisconnectFacebookPageRequest) {
    this._json = {};
    if (m) {
      this._json['page_id'] = m.pageId;
    }
  }
  
  // pageId (page_id)
  public get pageId(): string {
    return this._json.page_id!
  }
  public set pageId(value: string) {
    this._json.page_id = value
  }
  
  static fromJSON(m: IDisconnectFacebookPageRequestJSON = {}): DisconnectFacebookPageRequest {
    return new DisconnectFacebookPageRequest({
      pageId: m['page_id']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IFacebookPage_InstagramAccount {
  id?: string
  username?: string
  pictureUrl?: string
  
  toJSON?(): object
}

export interface IFacebookPage_InstagramAccountJSON {
  id?: string
  username?: string
  picture_url?: string
  toJSON?(): object
}

export class FacebookPage_InstagramAccount implements IFacebookPage_InstagramAccount {
  private readonly _json: IFacebookPage_InstagramAccountJSON;

  constructor(m?: IFacebookPage_InstagramAccount) {
    this._json = {};
    if (m) {
      this._json['id'] = m.id;
      this._json['username'] = m.username;
      this._json['picture_url'] = m.pictureUrl;
    }
  }
  
  // id (id)
  public get id(): string {
    return this._json.id!
  }
  public set id(value: string) {
    this._json.id = value
  }
  
  // username (username)
  public get username(): string {
    return this._json.username!
  }
  public set username(value: string) {
    this._json.username = value
  }
  
  // pictureUrl (picture_url)
  public get pictureUrl(): string {
    return this._json.picture_url!
  }
  public set pictureUrl(value: string) {
    this._json.picture_url = value
  }
  
  static fromJSON(m: IFacebookPage_InstagramAccountJSON = {}): FacebookPage_InstagramAccount {
    return new FacebookPage_InstagramAccount({
      id: m['id']!,
      username: m['username']!,
      pictureUrl: m['picture_url']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IFacebookPage {
  pageId?: string
  name?: string
  link?: string
  pictureUrl?: string
  instagram?: FacebookPage_InstagramAccount
  
  toJSON?(): object
}

export interface IFacebookPageJSON {
  page_id?: string
  name?: string
  link?: string
  picture_url?: string
  instagram?: FacebookPage_InstagramAccount
  toJSON?(): object
}

export class FacebookPage implements IFacebookPage {
  private readonly _json: IFacebookPageJSON;

  constructor(m?: IFacebookPage) {
    this._json = {};
    if (m) {
      this._json['page_id'] = m.pageId;
      this._json['name'] = m.name;
      this._json['link'] = m.link;
      this._json['picture_url'] = m.pictureUrl;
      this._json['instagram'] = m.instagram;
    }
  }
  
  // pageId (page_id)
  public get pageId(): string {
    return this._json.page_id!
  }
  public set pageId(value: string) {
    this._json.page_id = value
  }
  
  // name (name)
  public get name(): string {
    return this._json.name!
  }
  public set name(value: string) {
    this._json.name = value
  }
  
  // link (link)
  public get link(): string {
    return this._json.link!
  }
  public set link(value: string) {
    this._json.link = value
  }
  
  // pictureUrl (picture_url)
  public get pictureUrl(): string {
    return this._json.picture_url!
  }
  public set pictureUrl(value: string) {
    this._json.picture_url = value
  }
  
  // instagram (instagram)
  public get instagram(): FacebookPage_InstagramAccount {
    return this._json.instagram!
  }
  public set instagram(value: FacebookPage_InstagramAccount) {
    this._json.instagram = value
  }
  
  static fromJSON(m: IFacebookPageJSON = {}): FacebookPage {
    return new FacebookPage({
      pageId: m['page_id']!,
      name: m['name']!,
      link: m['link']!,
      pictureUrl: m['picture_url']!,
      instagram: FacebookPage_InstagramAccount.fromJSON(m['instagram']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IListSocialIntegrationsResponse {
  facebookPages?: FacebookPage[]
  
  toJSON?(): object
}

export interface IListSocialIntegrationsResponseJSON {
  facebook_pages?: FacebookPage[]
  toJSON?(): object
}

export class ListSocialIntegrationsResponse implements IListSocialIntegrationsResponse {
  private readonly _json: IListSocialIntegrationsResponseJSON;

  constructor(m?: IListSocialIntegrationsResponse) {
    this._json = {};
    if (m) {
      this._json['facebook_pages'] = m.facebookPages;
    }
  }
  
  // facebookPages (facebook_pages)
  public get facebookPages(): FacebookPage[] {
    return this._json.facebook_pages || []
  }
  public set facebookPages(value: FacebookPage[]) {
    this._json.facebook_pages = value
  }
  
  static fromJSON(m: IListSocialIntegrationsResponseJSON = {}): ListSocialIntegrationsResponse {
    return new ListSocialIntegrationsResponse({
      facebookPages: (m['facebook_pages']! || []).map((v) => { return FacebookPage.fromJSON(v) })
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ICampaignTaskRequest {
  campaignId?: string
  
  toJSON?(): object
}

export interface ICampaignTaskRequestJSON {
  campaign_id?: string
  toJSON?(): object
}

export class CampaignTaskRequest implements ICampaignTaskRequest {
  private readonly _json: ICampaignTaskRequestJSON;

  constructor(m?: ICampaignTaskRequest) {
    this._json = {};
    if (m) {
      this._json['campaign_id'] = m.campaignId;
    }
  }
  
  // campaignId (campaign_id)
  public get campaignId(): string {
    return this._json.campaign_id!
  }
  public set campaignId(value: string) {
    this._json.campaign_id = value
  }
  
  static fromJSON(m: ICampaignTaskRequestJSON = {}): CampaignTaskRequest {
    return new CampaignTaskRequest({
      campaignId: m['campaign_id']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ISubmitToMagnumRequest {
  campaignId?: string
  skipInsertPromotion?: boolean
  skipInsertRemarketingPromotion?: boolean
  
  toJSON?(): object
}

export interface ISubmitToMagnumRequestJSON {
  campaign_id?: string
  skip_insert_promotion?: boolean
  skip_insert_remarketing_promotion?: boolean
  toJSON?(): object
}

export class SubmitToMagnumRequest implements ISubmitToMagnumRequest {
  private readonly _json: ISubmitToMagnumRequestJSON;

  constructor(m?: ISubmitToMagnumRequest) {
    this._json = {};
    if (m) {
      this._json['campaign_id'] = m.campaignId;
      this._json['skip_insert_promotion'] = m.skipInsertPromotion;
      this._json['skip_insert_remarketing_promotion'] = m.skipInsertRemarketingPromotion;
    }
  }
  
  // campaignId (campaign_id)
  public get campaignId(): string {
    return this._json.campaign_id!
  }
  public set campaignId(value: string) {
    this._json.campaign_id = value
  }
  
  // skipInsertPromotion (skip_insert_promotion)
  public get skipInsertPromotion(): boolean {
    return this._json.skip_insert_promotion!
  }
  public set skipInsertPromotion(value: boolean) {
    this._json.skip_insert_promotion = value
  }
  
  // skipInsertRemarketingPromotion (skip_insert_remarketing_promotion)
  public get skipInsertRemarketingPromotion(): boolean {
    return this._json.skip_insert_remarketing_promotion!
  }
  public set skipInsertRemarketingPromotion(value: boolean) {
    this._json.skip_insert_remarketing_promotion = value
  }
  
  static fromJSON(m: ISubmitToMagnumRequestJSON = {}): SubmitToMagnumRequest {
    return new SubmitToMagnumRequest({
      campaignId: m['campaign_id']!,
      skipInsertPromotion: m['skip_insert_promotion']!,
      skipInsertRemarketingPromotion: m['skip_insert_remarketing_promotion']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ICancelCampaignTaskRequest {
  campaignId?: string
  reason?: CancelCampaignTaskRequest_Reason
  
  toJSON?(): object
}

export enum CancelCampaignTaskRequest_Reason {
  REASON_USER = 'REASON_USER',
  REASON_ADMIN = 'REASON_ADMIN',
  REASON_EXPIRED = 'REASON_EXPIRED'
}

export interface ICancelCampaignTaskRequestJSON {
  campaign_id?: string
  reason?: CancelCampaignTaskRequest_Reason
  toJSON?(): object
}

export class CancelCampaignTaskRequest implements ICancelCampaignTaskRequest {
  private readonly _json: ICancelCampaignTaskRequestJSON;

  constructor(m?: ICancelCampaignTaskRequest) {
    this._json = {};
    if (m) {
      this._json['campaign_id'] = m.campaignId;
      this._json['reason'] = m.reason;
    }
  }
  
  // campaignId (campaign_id)
  public get campaignId(): string {
    return this._json.campaign_id!
  }
  public set campaignId(value: string) {
    this._json.campaign_id = value
  }
  
  // reason (reason)
  public get reason(): CancelCampaignTaskRequest_Reason {
    return (<any>CancelCampaignTaskRequest_Reason)[this._json.reason!]
  }
  public set reason(value: CancelCampaignTaskRequest_Reason) {
    this._json.reason = value
  }
  
  static fromJSON(m: ICancelCampaignTaskRequestJSON = {}): CancelCampaignTaskRequest {
    return new CancelCampaignTaskRequest({
      campaignId: m['campaign_id']!,
      reason: (<any>CancelCampaignTaskRequest_Reason)[m['reason']!]!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IUserTaskRequest {
  userId?: string
  
  toJSON?(): object
}

export interface IUserTaskRequestJSON {
  user_id?: string
  toJSON?(): object
}

export class UserTaskRequest implements IUserTaskRequest {
  private readonly _json: IUserTaskRequestJSON;

  constructor(m?: IUserTaskRequest) {
    this._json = {};
    if (m) {
      this._json['user_id'] = m.userId;
    }
  }
  
  // userId (user_id)
  public get userId(): string {
    return this._json.user_id!
  }
  public set userId(value: string) {
    this._json.user_id = value
  }
  
  static fromJSON(m: IUserTaskRequestJSON = {}): UserTaskRequest {
    return new UserTaskRequest({
      userId: m['user_id']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IStorageObject {
  bucket?: string
  objectName?: string
  
  toJSON?(): object
}

export interface IStorageObjectJSON {
  bucket?: string
  object_name?: string
  toJSON?(): object
}

export class StorageObject implements IStorageObject {
  private readonly _json: IStorageObjectJSON;

  constructor(m?: IStorageObject) {
    this._json = {};
    if (m) {
      this._json['bucket'] = m.bucket;
      this._json['object_name'] = m.objectName;
    }
  }
  
  // bucket (bucket)
  public get bucket(): string {
    return this._json.bucket!
  }
  public set bucket(value: string) {
    this._json.bucket = value
  }
  
  // objectName (object_name)
  public get objectName(): string {
    return this._json.object_name!
  }
  public set objectName(value: string) {
    this._json.object_name = value
  }
  
  static fromJSON(m: IStorageObjectJSON = {}): StorageObject {
    return new StorageObject({
      bucket: m['bucket']!,
      objectName: m['object_name']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ICreateUserAudioSourceTaskRequest {
  operationId?: string
  userId?: string
  industryId?: string
  source?: StorageObject
  destination?: StorageObject
  
  toJSON?(): object
}

export interface ICreateUserAudioSourceTaskRequestJSON {
  operation_id?: string
  user_id?: string
  industry_id?: string
  source?: StorageObject
  destination?: StorageObject
  toJSON?(): object
}

export class CreateUserAudioSourceTaskRequest implements ICreateUserAudioSourceTaskRequest {
  private readonly _json: ICreateUserAudioSourceTaskRequestJSON;

  constructor(m?: ICreateUserAudioSourceTaskRequest) {
    this._json = {};
    if (m) {
      this._json['operation_id'] = m.operationId;
      this._json['user_id'] = m.userId;
      this._json['industry_id'] = m.industryId;
      this._json['source'] = m.source;
      this._json['destination'] = m.destination;
    }
  }
  
  // operationId (operation_id)
  public get operationId(): string {
    return this._json.operation_id!
  }
  public set operationId(value: string) {
    this._json.operation_id = value
  }
  
  // userId (user_id)
  public get userId(): string {
    return this._json.user_id!
  }
  public set userId(value: string) {
    this._json.user_id = value
  }
  
  // industryId (industry_id)
  public get industryId(): string {
    return this._json.industry_id!
  }
  public set industryId(value: string) {
    this._json.industry_id = value
  }
  
  // source (source)
  public get source(): StorageObject {
    return this._json.source!
  }
  public set source(value: StorageObject) {
    this._json.source = value
  }
  
  // destination (destination)
  public get destination(): StorageObject {
    return this._json.destination!
  }
  public set destination(value: StorageObject) {
    this._json.destination = value
  }
  
  static fromJSON(m: ICreateUserAudioSourceTaskRequestJSON = {}): CreateUserAudioSourceTaskRequest {
    return new CreateUserAudioSourceTaskRequest({
      operationId: m['operation_id']!,
      userId: m['user_id']!,
      industryId: m['industry_id']!,
      source: StorageObject.fromJSON(m['source']!),
      destination: StorageObject.fromJSON(m['destination']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ICreateUserAudioTaskRequest {
  operationId?: string
  userAudio?: UserAudio
  destination?: StorageObject
  
  toJSON?(): object
}

export interface ICreateUserAudioTaskRequestJSON {
  operation_id?: string
  user_audio?: UserAudio
  destination?: StorageObject
  toJSON?(): object
}

export class CreateUserAudioTaskRequest implements ICreateUserAudioTaskRequest {
  private readonly _json: ICreateUserAudioTaskRequestJSON;

  constructor(m?: ICreateUserAudioTaskRequest) {
    this._json = {};
    if (m) {
      this._json['operation_id'] = m.operationId;
      this._json['user_audio'] = m.userAudio;
      this._json['destination'] = m.destination;
    }
  }
  
  // operationId (operation_id)
  public get operationId(): string {
    return this._json.operation_id!
  }
  public set operationId(value: string) {
    this._json.operation_id = value
  }
  
  // userAudio (user_audio)
  public get userAudio(): UserAudio {
    return this._json.user_audio!
  }
  public set userAudio(value: UserAudio) {
    this._json.user_audio = value
  }
  
  // destination (destination)
  public get destination(): StorageObject {
    return this._json.destination!
  }
  public set destination(value: StorageObject) {
    this._json.destination = value
  }
  
  static fromJSON(m: ICreateUserAudioTaskRequestJSON = {}): CreateUserAudioTaskRequest {
    return new CreateUserAudioTaskRequest({
      operationId: m['operation_id']!,
      userAudio: UserAudio.fromJSON(m['user_audio']!),
      destination: StorageObject.fromJSON(m['destination']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IVerifyEmailTaskRequest {
  userId?: string
  email?: string
  welcome?: boolean
  
  toJSON?(): object
}

export interface IVerifyEmailTaskRequestJSON {
  user_id?: string
  email?: string
  welcome?: boolean
  toJSON?(): object
}

export class VerifyEmailTaskRequest implements IVerifyEmailTaskRequest {
  private readonly _json: IVerifyEmailTaskRequestJSON;

  constructor(m?: IVerifyEmailTaskRequest) {
    this._json = {};
    if (m) {
      this._json['user_id'] = m.userId;
      this._json['email'] = m.email;
      this._json['welcome'] = m.welcome;
    }
  }
  
  // userId (user_id)
  public get userId(): string {
    return this._json.user_id!
  }
  public set userId(value: string) {
    this._json.user_id = value
  }
  
  // email (email)
  public get email(): string {
    return this._json.email!
  }
  public set email(value: string) {
    this._json.email = value
  }
  
  // welcome (welcome)
  public get welcome(): boolean {
    return this._json.welcome!
  }
  public set welcome(value: boolean) {
    this._json.welcome = value
  }
  
  static fromJSON(m: IVerifyEmailTaskRequestJSON = {}): VerifyEmailTaskRequest {
    return new VerifyEmailTaskRequest({
      userId: m['user_id']!,
      email: m['email']!,
      welcome: m['welcome']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IUpdatedEmailTaskRequest {
  userId?: string
  oldEmail?: string
  newEmail?: string
  
  toJSON?(): object
}

export interface IUpdatedEmailTaskRequestJSON {
  user_id?: string
  old_email?: string
  new_email?: string
  toJSON?(): object
}

export class UpdatedEmailTaskRequest implements IUpdatedEmailTaskRequest {
  private readonly _json: IUpdatedEmailTaskRequestJSON;

  constructor(m?: IUpdatedEmailTaskRequest) {
    this._json = {};
    if (m) {
      this._json['user_id'] = m.userId;
      this._json['old_email'] = m.oldEmail;
      this._json['new_email'] = m.newEmail;
    }
  }
  
  // userId (user_id)
  public get userId(): string {
    return this._json.user_id!
  }
  public set userId(value: string) {
    this._json.user_id = value
  }
  
  // oldEmail (old_email)
  public get oldEmail(): string {
    return this._json.old_email!
  }
  public set oldEmail(value: string) {
    this._json.old_email = value
  }
  
  // newEmail (new_email)
  public get newEmail(): string {
    return this._json.new_email!
  }
  public set newEmail(value: string) {
    this._json.new_email = value
  }
  
  static fromJSON(m: IUpdatedEmailTaskRequestJSON = {}): UpdatedEmailTaskRequest {
    return new UpdatedEmailTaskRequest({
      userId: m['user_id']!,
      oldEmail: m['old_email']!,
      newEmail: m['new_email']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IDeliverEmailTaskRequest {
  outgoingEmailId?: string
  
  toJSON?(): object
}

export interface IDeliverEmailTaskRequestJSON {
  outgoing_email_id?: string
  toJSON?(): object
}

export class DeliverEmailTaskRequest implements IDeliverEmailTaskRequest {
  private readonly _json: IDeliverEmailTaskRequestJSON;

  constructor(m?: IDeliverEmailTaskRequest) {
    this._json = {};
    if (m) {
      this._json['outgoing_email_id'] = m.outgoingEmailId;
    }
  }
  
  // outgoingEmailId (outgoing_email_id)
  public get outgoingEmailId(): string {
    return this._json.outgoing_email_id!
  }
  public set outgoingEmailId(value: string) {
    this._json.outgoing_email_id = value
  }
  
  static fromJSON(m: IDeliverEmailTaskRequestJSON = {}): DeliverEmailTaskRequest {
    return new DeliverEmailTaskRequest({
      outgoingEmailId: m['outgoing_email_id']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IAssignSystemUserToFacebookPageTaskRequest {
  facebookPageConnectionId?: string
  
  toJSON?(): object
}

export interface IAssignSystemUserToFacebookPageTaskRequestJSON {
  facebook_page_connection_id?: string
  toJSON?(): object
}

export class AssignSystemUserToFacebookPageTaskRequest implements IAssignSystemUserToFacebookPageTaskRequest {
  private readonly _json: IAssignSystemUserToFacebookPageTaskRequestJSON;

  constructor(m?: IAssignSystemUserToFacebookPageTaskRequest) {
    this._json = {};
    if (m) {
      this._json['facebook_page_connection_id'] = m.facebookPageConnectionId;
    }
  }
  
  // facebookPageConnectionId (facebook_page_connection_id)
  public get facebookPageConnectionId(): string {
    return this._json.facebook_page_connection_id!
  }
  public set facebookPageConnectionId(value: string) {
    this._json.facebook_page_connection_id = value
  }
  
  static fromJSON(m: IAssignSystemUserToFacebookPageTaskRequestJSON = {}): AssignSystemUserToFacebookPageTaskRequest {
    return new AssignSystemUserToFacebookPageTaskRequest({
      facebookPageConnectionId: m['facebook_page_connection_id']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IRemoveAgencyFromFacebookPageTaskRequest {
  memberId?: string
  pageId?: string
  
  toJSON?(): object
}

export interface IRemoveAgencyFromFacebookPageTaskRequestJSON {
  member_id?: string
  page_id?: string
  toJSON?(): object
}

export class RemoveAgencyFromFacebookPageTaskRequest implements IRemoveAgencyFromFacebookPageTaskRequest {
  private readonly _json: IRemoveAgencyFromFacebookPageTaskRequestJSON;

  constructor(m?: IRemoveAgencyFromFacebookPageTaskRequest) {
    this._json = {};
    if (m) {
      this._json['member_id'] = m.memberId;
      this._json['page_id'] = m.pageId;
    }
  }
  
  // memberId (member_id)
  public get memberId(): string {
    return this._json.member_id!
  }
  public set memberId(value: string) {
    this._json.member_id = value
  }
  
  // pageId (page_id)
  public get pageId(): string {
    return this._json.page_id!
  }
  public set pageId(value: string) {
    this._json.page_id = value
  }
  
  static fromJSON(m: IRemoveAgencyFromFacebookPageTaskRequestJSON = {}): RemoveAgencyFromFacebookPageTaskRequest {
    return new RemoveAgencyFromFacebookPageTaskRequest({
      memberId: m['member_id']!,
      pageId: m['page_id']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ICaptureCreativeRequest {
  creativeId?: string
  force?: boolean
  
  toJSON?(): object
}

export interface ICaptureCreativeRequestJSON {
  creative_id?: string
  force?: boolean
  toJSON?(): object
}

export class CaptureCreativeRequest implements ICaptureCreativeRequest {
  private readonly _json: ICaptureCreativeRequestJSON;

  constructor(m?: ICaptureCreativeRequest) {
    this._json = {};
    if (m) {
      this._json['creative_id'] = m.creativeId;
      this._json['force'] = m.force;
    }
  }
  
  // creativeId (creative_id)
  public get creativeId(): string {
    return this._json.creative_id!
  }
  public set creativeId(value: string) {
    this._json.creative_id = value
  }
  
  // force (force)
  public get force(): boolean {
    return this._json.force!
  }
  public set force(value: boolean) {
    this._json.force = value
  }
  
  static fromJSON(m: ICaptureCreativeRequestJSON = {}): CaptureCreativeRequest {
    return new CaptureCreativeRequest({
      creativeId: m['creative_id']!,
      force: m['force']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ISubmitAuditRequest {
  campaignId?: string
  auditorUid?: string
  audit?: AuditResult
  
  toJSON?(): object
}

export interface ISubmitAuditRequestJSON {
  campaign_id?: string
  auditor_uid?: string
  audit?: AuditResult
  toJSON?(): object
}

export class SubmitAuditRequest implements ISubmitAuditRequest {
  private readonly _json: ISubmitAuditRequestJSON;

  constructor(m?: ISubmitAuditRequest) {
    this._json = {};
    if (m) {
      this._json['campaign_id'] = m.campaignId;
      this._json['auditor_uid'] = m.auditorUid;
      this._json['audit'] = m.audit;
    }
  }
  
  // campaignId (campaign_id)
  public get campaignId(): string {
    return this._json.campaign_id!
  }
  public set campaignId(value: string) {
    this._json.campaign_id = value
  }
  
  // auditorUid (auditor_uid)
  public get auditorUid(): string {
    return this._json.auditor_uid!
  }
  public set auditorUid(value: string) {
    this._json.auditor_uid = value
  }
  
  // audit (audit)
  public get audit(): AuditResult {
    return this._json.audit!
  }
  public set audit(value: AuditResult) {
    this._json.audit = value
  }
  
  static fromJSON(m: ISubmitAuditRequestJSON = {}): SubmitAuditRequest {
    return new SubmitAuditRequest({
      campaignId: m['campaign_id']!,
      auditorUid: m['auditor_uid']!,
      audit: AuditResult.fromJSON(m['audit']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IDiscountTier {
  thresholdCents?: string
  discountPercent?: string
  maxDiscountCents?: string
  
  toJSON?(): object
}

export interface IDiscountTierJSON {
  threshold_cents?: string
  discount_percent?: string
  max_discount_cents?: string
  toJSON?(): object
}

export class DiscountTier implements IDiscountTier {
  private readonly _json: IDiscountTierJSON;

  constructor(m?: IDiscountTier) {
    this._json = {};
    if (m) {
      this._json['threshold_cents'] = m.thresholdCents;
      this._json['discount_percent'] = m.discountPercent;
      this._json['max_discount_cents'] = m.maxDiscountCents;
    }
  }
  
  // thresholdCents (threshold_cents)
  public get thresholdCents(): string {
    return this._json.threshold_cents!
  }
  public set thresholdCents(value: string) {
    this._json.threshold_cents = value
  }
  
  // discountPercent (discount_percent)
  public get discountPercent(): string {
    return this._json.discount_percent!
  }
  public set discountPercent(value: string) {
    this._json.discount_percent = value
  }
  
  // maxDiscountCents (max_discount_cents)
  public get maxDiscountCents(): string {
    return this._json.max_discount_cents!
  }
  public set maxDiscountCents(value: string) {
    this._json.max_discount_cents = value
  }
  
  static fromJSON(m: IDiscountTierJSON = {}): DiscountTier {
    return new DiscountTier({
      thresholdCents: m['threshold_cents']!,
      discountPercent: m['discount_percent']!,
      maxDiscountCents: m['max_discount_cents']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ICoupon {
  code?: string
  name?: string
  discountType?: Coupon_DiscountType
  currency?: string
  amount?: number
  appliesToRenewals?: boolean
  redemptionMax?: string
  redemptionScope?: Coupon_RedemptionScope
  redemptionContext?: string
  firstPurchaseOnly?: boolean
  startDate?: string
  endDate?: string
  discountTiers?: DiscountTier[]
  
  toJSON?(): object
}

export enum Coupon_DiscountType {
  DISCOUNT_TYPE_UNUSED_BUT_REQUIRED_BY_PROTO3 = 'DISCOUNT_TYPE_UNUSED_BUT_REQUIRED_BY_PROTO3',
  FIXED = 'FIXED',
  PERCENT = 'PERCENT',
  TIERED = 'TIERED'
}

export enum Coupon_RedemptionScope {
  REDEMPTION_SCOPE_UNUSED_BUT_REQUIRED_BY_PROTO3 = 'REDEMPTION_SCOPE_UNUSED_BUT_REQUIRED_BY_PROTO3',
  GLOBAL = 'GLOBAL',
  PER_USER = 'PER_USER',
  ID_SCOPE = 'ID_SCOPE',
  EMAIL_SCOPE = 'EMAIL_SCOPE'
}

export interface ICouponJSON {
  code?: string
  name?: string
  discount_type?: Coupon_DiscountType
  currency?: string
  amount?: number
  applies_to_renewals?: boolean
  redemption_max?: string
  redemption_scope?: Coupon_RedemptionScope
  redemption_context?: string
  first_purchase_only?: boolean
  start_date?: string
  end_date?: string
  discount_tiers?: DiscountTier[]
  toJSON?(): object
}

export class Coupon implements ICoupon {
  private readonly _json: ICouponJSON;

  constructor(m?: ICoupon) {
    this._json = {};
    if (m) {
      this._json['code'] = m.code;
      this._json['name'] = m.name;
      this._json['discount_type'] = m.discountType;
      this._json['currency'] = m.currency;
      this._json['amount'] = m.amount;
      this._json['applies_to_renewals'] = m.appliesToRenewals;
      this._json['redemption_max'] = m.redemptionMax;
      this._json['redemption_scope'] = m.redemptionScope;
      this._json['redemption_context'] = m.redemptionContext;
      this._json['first_purchase_only'] = m.firstPurchaseOnly;
      this._json['start_date'] = m.startDate;
      this._json['end_date'] = m.endDate;
      this._json['discount_tiers'] = m.discountTiers;
    }
  }
  
  // code (code)
  public get code(): string {
    return this._json.code!
  }
  public set code(value: string) {
    this._json.code = value
  }
  
  // name (name)
  public get name(): string {
    return this._json.name!
  }
  public set name(value: string) {
    this._json.name = value
  }
  
  // discountType (discount_type)
  public get discountType(): Coupon_DiscountType {
    return (<any>Coupon_DiscountType)[this._json.discount_type!]
  }
  public set discountType(value: Coupon_DiscountType) {
    this._json.discount_type = value
  }
  
  // currency (currency)
  public get currency(): string {
    return this._json.currency!
  }
  public set currency(value: string) {
    this._json.currency = value
  }
  
  // amount (amount)
  public get amount(): number {
    return this._json.amount!
  }
  public set amount(value: number) {
    this._json.amount = value
  }
  
  // appliesToRenewals (applies_to_renewals)
  public get appliesToRenewals(): boolean {
    return this._json.applies_to_renewals!
  }
  public set appliesToRenewals(value: boolean) {
    this._json.applies_to_renewals = value
  }
  
  // redemptionMax (redemption_max)
  public get redemptionMax(): string {
    return this._json.redemption_max!
  }
  public set redemptionMax(value: string) {
    this._json.redemption_max = value
  }
  
  // redemptionScope (redemption_scope)
  public get redemptionScope(): Coupon_RedemptionScope {
    return (<any>Coupon_RedemptionScope)[this._json.redemption_scope!]
  }
  public set redemptionScope(value: Coupon_RedemptionScope) {
    this._json.redemption_scope = value
  }
  
  // redemptionContext (redemption_context)
  public get redemptionContext(): string {
    return this._json.redemption_context!
  }
  public set redemptionContext(value: string) {
    this._json.redemption_context = value
  }
  
  // firstPurchaseOnly (first_purchase_only)
  public get firstPurchaseOnly(): boolean {
    return this._json.first_purchase_only!
  }
  public set firstPurchaseOnly(value: boolean) {
    this._json.first_purchase_only = value
  }
  
  // startDate (start_date)
  public get startDate(): string {
    return this._json.start_date!
  }
  public set startDate(value: string) {
    this._json.start_date = value
  }
  
  // endDate (end_date)
  public get endDate(): string {
    return this._json.end_date!
  }
  public set endDate(value: string) {
    this._json.end_date = value
  }
  
  // discountTiers (discount_tiers)
  public get discountTiers(): DiscountTier[] {
    return this._json.discount_tiers || []
  }
  public set discountTiers(value: DiscountTier[]) {
    this._json.discount_tiers = value
  }
  
  static fromJSON(m: ICouponJSON = {}): Coupon {
    return new Coupon({
      code: m['code']!,
      name: m['name']!,
      discountType: (<any>Coupon_DiscountType)[m['discount_type']!]!,
      currency: m['currency']!,
      amount: m['amount']!,
      appliesToRenewals: m['applies_to_renewals']!,
      redemptionMax: m['redemption_max']!,
      redemptionScope: (<any>Coupon_RedemptionScope)[m['redemption_scope']!]!,
      redemptionContext: m['redemption_context']!,
      firstPurchaseOnly: m['first_purchase_only']!,
      startDate: m['start_date']!,
      endDate: m['end_date']!,
      discountTiers: (m['discount_tiers']! || []).map((v) => { return DiscountTier.fromJSON(v) })
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ICreateCouponRequest {
  coupon?: Coupon
  
  toJSON?(): object
}

export interface ICreateCouponRequestJSON {
  coupon?: Coupon
  toJSON?(): object
}

export class CreateCouponRequest implements ICreateCouponRequest {
  private readonly _json: ICreateCouponRequestJSON;

  constructor(m?: ICreateCouponRequest) {
    this._json = {};
    if (m) {
      this._json['coupon'] = m.coupon;
    }
  }
  
  // coupon (coupon)
  public get coupon(): Coupon {
    return this._json.coupon!
  }
  public set coupon(value: Coupon) {
    this._json.coupon = value
  }
  
  static fromJSON(m: ICreateCouponRequestJSON = {}): CreateCouponRequest {
    return new CreateCouponRequest({
      coupon: Coupon.fromJSON(m['coupon']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ICreateCouponResponse {
  id?: string
  
  toJSON?(): object
}

export interface ICreateCouponResponseJSON {
  id?: string
  toJSON?(): object
}

export class CreateCouponResponse implements ICreateCouponResponse {
  private readonly _json: ICreateCouponResponseJSON;

  constructor(m?: ICreateCouponResponse) {
    this._json = {};
    if (m) {
      this._json['id'] = m.id;
    }
  }
  
  // id (id)
  public get id(): string {
    return this._json.id!
  }
  public set id(value: string) {
    this._json.id = value
  }
  
  static fromJSON(m: ICreateCouponResponseJSON = {}): CreateCouponResponse {
    return new CreateCouponResponse({
      id: m['id']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IUpdateCouponRequest {
  id?: string
  coupon?: Coupon
  
  toJSON?(): object
}

export interface IUpdateCouponRequestJSON {
  id?: string
  coupon?: Coupon
  toJSON?(): object
}

export class UpdateCouponRequest implements IUpdateCouponRequest {
  private readonly _json: IUpdateCouponRequestJSON;

  constructor(m?: IUpdateCouponRequest) {
    this._json = {};
    if (m) {
      this._json['id'] = m.id;
      this._json['coupon'] = m.coupon;
    }
  }
  
  // id (id)
  public get id(): string {
    return this._json.id!
  }
  public set id(value: string) {
    this._json.id = value
  }
  
  // coupon (coupon)
  public get coupon(): Coupon {
    return this._json.coupon!
  }
  public set coupon(value: Coupon) {
    this._json.coupon = value
  }
  
  static fromJSON(m: IUpdateCouponRequestJSON = {}): UpdateCouponRequest {
    return new UpdateCouponRequest({
      id: m['id']!,
      coupon: Coupon.fromJSON(m['coupon']!)
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IApplyDiscountTiersRequest {
  couponId?: string
  discountTiers?: DiscountTier[]
  
  toJSON?(): object
}

export interface IApplyDiscountTiersRequestJSON {
  coupon_id?: string
  discount_tiers?: DiscountTier[]
  toJSON?(): object
}

export class ApplyDiscountTiersRequest implements IApplyDiscountTiersRequest {
  private readonly _json: IApplyDiscountTiersRequestJSON;

  constructor(m?: IApplyDiscountTiersRequest) {
    this._json = {};
    if (m) {
      this._json['coupon_id'] = m.couponId;
      this._json['discount_tiers'] = m.discountTiers;
    }
  }
  
  // couponId (coupon_id)
  public get couponId(): string {
    return this._json.coupon_id!
  }
  public set couponId(value: string) {
    this._json.coupon_id = value
  }
  
  // discountTiers (discount_tiers)
  public get discountTiers(): DiscountTier[] {
    return this._json.discount_tiers || []
  }
  public set discountTiers(value: DiscountTier[]) {
    this._json.discount_tiers = value
  }
  
  static fromJSON(m: IApplyDiscountTiersRequestJSON = {}): ApplyDiscountTiersRequest {
    return new ApplyDiscountTiersRequest({
      couponId: m['coupon_id']!,
      discountTiers: (m['discount_tiers']! || []).map((v) => { return DiscountTier.fromJSON(v) })
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface IArchiveCouponRequest {
  couponId?: string
  
  toJSON?(): object
}

export interface IArchiveCouponRequestJSON {
  coupon_id?: string
  toJSON?(): object
}

export class ArchiveCouponRequest implements IArchiveCouponRequest {
  private readonly _json: IArchiveCouponRequestJSON;

  constructor(m?: IArchiveCouponRequest) {
    this._json = {};
    if (m) {
      this._json['coupon_id'] = m.couponId;
    }
  }
  
  // couponId (coupon_id)
  public get couponId(): string {
    return this._json.coupon_id!
  }
  public set couponId(value: string) {
    this._json.coupon_id = value
  }
  
  static fromJSON(m: IArchiveCouponRequestJSON = {}): ArchiveCouponRequest {
    return new ArchiveCouponRequest({
      couponId: m['coupon_id']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ICreateAdminTokenRequest {
  userId?: string
  adminId?: string
  
  toJSON?(): object
}

export interface ICreateAdminTokenRequestJSON {
  user_id?: string
  admin_id?: string
  toJSON?(): object
}

export class CreateAdminTokenRequest implements ICreateAdminTokenRequest {
  private readonly _json: ICreateAdminTokenRequestJSON;

  constructor(m?: ICreateAdminTokenRequest) {
    this._json = {};
    if (m) {
      this._json['user_id'] = m.userId;
      this._json['admin_id'] = m.adminId;
    }
  }
  
  // userId (user_id)
  public get userId(): string {
    return this._json.user_id!
  }
  public set userId(value: string) {
    this._json.user_id = value
  }
  
  // adminId (admin_id)
  public get adminId(): string {
    return this._json.admin_id!
  }
  public set adminId(value: string) {
    this._json.admin_id = value
  }
  
  static fromJSON(m: ICreateAdminTokenRequestJSON = {}): CreateAdminTokenRequest {
    return new CreateAdminTokenRequest({
      userId: m['user_id']!,
      adminId: m['admin_id']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ICreateAdminTokenResponse {
  token?: string
  
  toJSON?(): object
}

export interface ICreateAdminTokenResponseJSON {
  token?: string
  toJSON?(): object
}

export class CreateAdminTokenResponse implements ICreateAdminTokenResponse {
  private readonly _json: ICreateAdminTokenResponseJSON;

  constructor(m?: ICreateAdminTokenResponse) {
    this._json = {};
    if (m) {
      this._json['token'] = m.token;
    }
  }
  
  // token (token)
  public get token(): string {
    return this._json.token!
  }
  public set token(value: string) {
    this._json.token = value
  }
  
  static fromJSON(m: ICreateAdminTokenResponseJSON = {}): CreateAdminTokenResponse {
    return new CreateAdminTokenResponse({
      token: m['token']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}

export interface ICancelCampaignRequest {
  campaignId?: string
  
  toJSON?(): object
}

export interface ICancelCampaignRequestJSON {
  campaign_id?: string
  toJSON?(): object
}

export class CancelCampaignRequest implements ICancelCampaignRequest {
  private readonly _json: ICancelCampaignRequestJSON;

  constructor(m?: ICancelCampaignRequest) {
    this._json = {};
    if (m) {
      this._json['campaign_id'] = m.campaignId;
    }
  }
  
  // campaignId (campaign_id)
  public get campaignId(): string {
    return this._json.campaign_id!
  }
  public set campaignId(value: string) {
    this._json.campaign_id = value
  }
  
  static fromJSON(m: ICancelCampaignRequestJSON = {}): CancelCampaignRequest {
    return new CancelCampaignRequest({
      campaignId: m['campaign_id']!
    })
  }

  public toJSON(): object {
    return this._json
  }
}



// Services
export interface IService {
  checkToken: (data: google_protobuf.Empty, headers?: object) => Promise<google_protobuf.Empty>
  getUser: (data: google_protobuf.Empty, headers?: object) => Promise<User>
  login: (data: LoginRequest, headers?: object) => Promise<google_protobuf.Empty>
  loginWithToken: (data: UserTokenRequest, headers?: object) => Promise<TokenResponse>
  updateEmail: (data: UpdateEmailRequest, headers?: object) => Promise<TokenResponse>
  updatePassword: (data: UpdatePasswordRequest, headers?: object) => Promise<google_protobuf.Empty>
  updateConsents: (data: UpdateConsentsRequest, headers?: object) => Promise<google_protobuf.Empty>
  forgotPasswordEmail: (data: ForgotPasswordEmailRequest, headers?: object) => Promise<google_protobuf.Empty>
  forgotPasswordReset: (data: ForgotPasswordResetRequest, headers?: object) => Promise<google_protobuf.Empty>
  verifyEmail: (data: UserTokenRequest, headers?: object) => Promise<TokenResponse>
  resendVerifyEmail: (data: google_protobuf.Empty, headers?: object) => Promise<google_protobuf.Empty>
  getIndustryConfig: (data: IndustryConfigRequest, headers?: object) => Promise<IndustryConfig>
  listDrafts: (data: google_protobuf.Empty, headers?: object) => Promise<DraftList>
  loadDraft: (data: Draft, headers?: object) => Promise<Draft>
  saveDraft: (data: Draft, headers?: object) => Promise<Draft>
  deleteDraft: (data: Draft, headers?: object) => Promise<google_protobuf.Empty>
  listUserImages: (data: UserImageRequest, headers?: object) => Promise<UserImageResponse>
  createUserImage: (data: Upload, headers?: object) => Promise<UserImage>
  createUserAudioSource: (data: Upload, headers?: object) => Promise<UserAudioSourceOperation>
  pollUserAudioSourceOperation: (data: UserAudioSourceOperation, headers?: object) => Promise<UserAudioSourceOperation>
  listUserAudioSources: (data: UserAudioSourceRequest, headers?: object) => Promise<UserAudioSourceResponse>
  updateUserAudioSource: (data: UserAudioSource, headers?: object) => Promise<UserAudioSource>
  deleteUserAudioSource: (data: UserAudioSource, headers?: object) => Promise<google_protobuf.Empty>
  createUserAudio: (data: UserAudio, headers?: object) => Promise<UserAudioOperation>
  pollUserAudioOperation: (data: UserAudioOperation, headers?: object) => Promise<UserAudioOperation>
  listUserAudio: (data: UserAudioRequest, headers?: object) => Promise<UserAudioResponse>
  listMemberImages: (data: MemberImageRequest, headers?: object) => Promise<MemberImageResponse>
  regenerateRecommendedAds: (data: RecommendedAdsRequest, headers?: object) => Promise<RecommendedAds>
  getRecommendedAds: (data: RecommendedAdsRequest, headers?: object) => Promise<RecommendedAds>
  getCampaignExampleAds: (data: CampaignExampleAdsRequest, headers?: object) => Promise<CampaignExampleAds>
  customizeCreative: (data: CustomizeCreativeRequest, headers?: object) => Promise<CustomizeCreativeResponse>
  imageConfig: (data: ImageConfigRequest, headers?: object) => Promise<ImageConfigResponse>
  getPaymentMethod: (data: google_protobuf.Empty, headers?: object) => Promise<PaymentMethodResponse>
  updatePaymentMethod: (data: UpdatePaymentMethodRequest, headers?: object) => Promise<PaymentMethodResponse>
  clearPaymentMethod: (data: google_protobuf.Empty, headers?: object) => Promise<google_protobuf.Empty>
  applyCoupon: (data: ApplyCouponRequest, headers?: object) => Promise<ApplyCouponResponse>
  submitCampaign: (data: SubmitCampaignRequest, headers?: object) => Promise<SubmitCampaignResponse>
  campaignReceipt: (data: Campaign, headers?: object) => Promise<Charge>
  listCampaigns: (data: CampaignListRequest, headers?: object) => Promise<CampaignList>
  getCampaign: (data: Campaign, headers?: object) => Promise<Campaign>
  getReports: (data: ReportRequest, headers?: object) => Promise<ReportResponse>
  requestExport: (data: ReportRequest, headers?: object) => Promise<google_protobuf.Empty>
  beginCampaignCancellation: (data: BeginCampaignCancellationRequest, headers?: object) => Promise<BeginCampaignCancellationResponse>
  completeCampaignCancellation: (data: CompleteCampaignCancellationRequest, headers?: object) => Promise<google_protobuf.Empty>
  createCancellationFeedback: (data: CreateCancellationFeedbackRequest, headers?: object) => Promise<google_protobuf.Empty>
  getRejectedCampaign: (data: Campaign, headers?: object) => Promise<RejectedCampaign>
  rejectedCampaignCorrection: (data: RejectedCampaignCorrectionRequest, headers?: object) => Promise<google_protobuf.Empty>
  validateURL: (data: ValidateURLRequest, headers?: object) => Promise<ValidateURLResponse>
  searchRetailer: (data: SearchRetailerRequest, headers?: object) => Promise<SearchRetailerResponse>
  listCharges: (data: google_protobuf.Empty, headers?: object) => Promise<ChargeList>
  syncFacebookPage: (data: SyncFacebookPageRequest, headers?: object) => Promise<FacebookPage>
  disconnectFacebookPage: (data: DisconnectFacebookPageRequest, headers?: object) => Promise<google_protobuf.Empty>
  disconnectAllFacebookPages: (data: google_protobuf.Empty, headers?: object) => Promise<google_protobuf.Empty>
  listSocialIntegrations: (data: google_protobuf.Empty, headers?: object) => Promise<ListSocialIntegrationsResponse>
}

export class Service implements IService {
  private hostname: string
  private fetch: Fetch
  private path = '/twirp/messages.pisa.Service/';

  constructor(hostname: string, fetch: Fetch) {
    this.hostname = hostname
    this.fetch = fetch
  }

  private url(name: string): string {
    return this.hostname + this.path + name
  }

  
  public checkToken(params: google_protobuf.Empty, headers: object = {}): Promise<google_protobuf.Empty> {
    return this.fetch(
      this.url('CheckToken'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return google_protobuf.Empty.fromJSON(m)})
    })
  }
  
  public getUser(params: google_protobuf.Empty, headers: object = {}): Promise<User> {
    return this.fetch(
      this.url('GetUser'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return User.fromJSON(m)})
    })
  }
  
  public login(params: LoginRequest, headers: object = {}): Promise<google_protobuf.Empty> {
    return this.fetch(
      this.url('Login'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return google_protobuf.Empty.fromJSON(m)})
    })
  }
  
  public loginWithToken(params: UserTokenRequest, headers: object = {}): Promise<TokenResponse> {
    return this.fetch(
      this.url('LoginWithToken'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return TokenResponse.fromJSON(m)})
    })
  }
  
  public updateEmail(params: UpdateEmailRequest, headers: object = {}): Promise<TokenResponse> {
    return this.fetch(
      this.url('UpdateEmail'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return TokenResponse.fromJSON(m)})
    })
  }
  
  public updatePassword(params: UpdatePasswordRequest, headers: object = {}): Promise<google_protobuf.Empty> {
    return this.fetch(
      this.url('UpdatePassword'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return google_protobuf.Empty.fromJSON(m)})
    })
  }
  
  public updateConsents(params: UpdateConsentsRequest, headers: object = {}): Promise<google_protobuf.Empty> {
    return this.fetch(
      this.url('UpdateConsents'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return google_protobuf.Empty.fromJSON(m)})
    })
  }
  
  public forgotPasswordEmail(params: ForgotPasswordEmailRequest, headers: object = {}): Promise<google_protobuf.Empty> {
    return this.fetch(
      this.url('ForgotPasswordEmail'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return google_protobuf.Empty.fromJSON(m)})
    })
  }
  
  public forgotPasswordReset(params: ForgotPasswordResetRequest, headers: object = {}): Promise<google_protobuf.Empty> {
    return this.fetch(
      this.url('ForgotPasswordReset'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return google_protobuf.Empty.fromJSON(m)})
    })
  }
  
  public verifyEmail(params: UserTokenRequest, headers: object = {}): Promise<TokenResponse> {
    return this.fetch(
      this.url('VerifyEmail'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return TokenResponse.fromJSON(m)})
    })
  }
  
  public resendVerifyEmail(params: google_protobuf.Empty, headers: object = {}): Promise<google_protobuf.Empty> {
    return this.fetch(
      this.url('ResendVerifyEmail'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return google_protobuf.Empty.fromJSON(m)})
    })
  }
  
  public getIndustryConfig(params: IndustryConfigRequest, headers: object = {}): Promise<IndustryConfig> {
    return this.fetch(
      this.url('GetIndustryConfig'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return IndustryConfig.fromJSON(m)})
    })
  }
  
  public listDrafts(params: google_protobuf.Empty, headers: object = {}): Promise<DraftList> {
    return this.fetch(
      this.url('ListDrafts'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return DraftList.fromJSON(m)})
    })
  }
  
  public loadDraft(params: Draft, headers: object = {}): Promise<Draft> {
    return this.fetch(
      this.url('LoadDraft'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return Draft.fromJSON(m)})
    })
  }
  
  public saveDraft(params: Draft, headers: object = {}): Promise<Draft> {
    return this.fetch(
      this.url('SaveDraft'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return Draft.fromJSON(m)})
    })
  }
  
  public deleteDraft(params: Draft, headers: object = {}): Promise<google_protobuf.Empty> {
    return this.fetch(
      this.url('DeleteDraft'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return google_protobuf.Empty.fromJSON(m)})
    })
  }
  
  public listUserImages(params: UserImageRequest, headers: object = {}): Promise<UserImageResponse> {
    return this.fetch(
      this.url('ListUserImages'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return UserImageResponse.fromJSON(m)})
    })
  }
  
  public createUserImage(params: Upload, headers: object = {}): Promise<UserImage> {
    return this.fetch(
      this.url('CreateUserImage'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return UserImage.fromJSON(m)})
    })
  }
  
  public createUserAudioSource(params: Upload, headers: object = {}): Promise<UserAudioSourceOperation> {
    return this.fetch(
      this.url('CreateUserAudioSource'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return UserAudioSourceOperation.fromJSON(m)})
    })
  }
  
  public pollUserAudioSourceOperation(params: UserAudioSourceOperation, headers: object = {}): Promise<UserAudioSourceOperation> {
    return this.fetch(
      this.url('PollUserAudioSourceOperation'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return UserAudioSourceOperation.fromJSON(m)})
    })
  }
  
  public listUserAudioSources(params: UserAudioSourceRequest, headers: object = {}): Promise<UserAudioSourceResponse> {
    return this.fetch(
      this.url('ListUserAudioSources'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return UserAudioSourceResponse.fromJSON(m)})
    })
  }
  
  public updateUserAudioSource(params: UserAudioSource, headers: object = {}): Promise<UserAudioSource> {
    return this.fetch(
      this.url('UpdateUserAudioSource'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return UserAudioSource.fromJSON(m)})
    })
  }
  
  public deleteUserAudioSource(params: UserAudioSource, headers: object = {}): Promise<google_protobuf.Empty> {
    return this.fetch(
      this.url('DeleteUserAudioSource'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return google_protobuf.Empty.fromJSON(m)})
    })
  }
  
  public createUserAudio(params: UserAudio, headers: object = {}): Promise<UserAudioOperation> {
    return this.fetch(
      this.url('CreateUserAudio'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return UserAudioOperation.fromJSON(m)})
    })
  }
  
  public pollUserAudioOperation(params: UserAudioOperation, headers: object = {}): Promise<UserAudioOperation> {
    return this.fetch(
      this.url('PollUserAudioOperation'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return UserAudioOperation.fromJSON(m)})
    })
  }
  
  public listUserAudio(params: UserAudioRequest, headers: object = {}): Promise<UserAudioResponse> {
    return this.fetch(
      this.url('ListUserAudio'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return UserAudioResponse.fromJSON(m)})
    })
  }
  
  public listMemberImages(params: MemberImageRequest, headers: object = {}): Promise<MemberImageResponse> {
    return this.fetch(
      this.url('ListMemberImages'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return MemberImageResponse.fromJSON(m)})
    })
  }
  
  public regenerateRecommendedAds(params: RecommendedAdsRequest, headers: object = {}): Promise<RecommendedAds> {
    return this.fetch(
      this.url('RegenerateRecommendedAds'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return RecommendedAds.fromJSON(m)})
    })
  }
  
  public getRecommendedAds(params: RecommendedAdsRequest, headers: object = {}): Promise<RecommendedAds> {
    return this.fetch(
      this.url('GetRecommendedAds'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return RecommendedAds.fromJSON(m)})
    })
  }
  
  public getCampaignExampleAds(params: CampaignExampleAdsRequest, headers: object = {}): Promise<CampaignExampleAds> {
    return this.fetch(
      this.url('GetCampaignExampleAds'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return CampaignExampleAds.fromJSON(m)})
    })
  }
  
  public customizeCreative(params: CustomizeCreativeRequest, headers: object = {}): Promise<CustomizeCreativeResponse> {
    return this.fetch(
      this.url('CustomizeCreative'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return CustomizeCreativeResponse.fromJSON(m)})
    })
  }
  
  public imageConfig(params: ImageConfigRequest, headers: object = {}): Promise<ImageConfigResponse> {
    return this.fetch(
      this.url('ImageConfig'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return ImageConfigResponse.fromJSON(m)})
    })
  }
  
  public getPaymentMethod(params: google_protobuf.Empty, headers: object = {}): Promise<PaymentMethodResponse> {
    return this.fetch(
      this.url('GetPaymentMethod'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return PaymentMethodResponse.fromJSON(m)})
    })
  }
  
  public updatePaymentMethod(params: UpdatePaymentMethodRequest, headers: object = {}): Promise<PaymentMethodResponse> {
    return this.fetch(
      this.url('UpdatePaymentMethod'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return PaymentMethodResponse.fromJSON(m)})
    })
  }
  
  public clearPaymentMethod(params: google_protobuf.Empty, headers: object = {}): Promise<google_protobuf.Empty> {
    return this.fetch(
      this.url('ClearPaymentMethod'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return google_protobuf.Empty.fromJSON(m)})
    })
  }
  
  public applyCoupon(params: ApplyCouponRequest, headers: object = {}): Promise<ApplyCouponResponse> {
    return this.fetch(
      this.url('ApplyCoupon'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return ApplyCouponResponse.fromJSON(m)})
    })
  }
  
  public submitCampaign(params: SubmitCampaignRequest, headers: object = {}): Promise<SubmitCampaignResponse> {
    return this.fetch(
      this.url('SubmitCampaign'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return SubmitCampaignResponse.fromJSON(m)})
    })
  }
  
  public campaignReceipt(params: Campaign, headers: object = {}): Promise<Charge> {
    return this.fetch(
      this.url('CampaignReceipt'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return Charge.fromJSON(m)})
    })
  }
  
  public listCampaigns(params: CampaignListRequest, headers: object = {}): Promise<CampaignList> {
    return this.fetch(
      this.url('ListCampaigns'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return CampaignList.fromJSON(m)})
    })
  }
  
  public getCampaign(params: Campaign, headers: object = {}): Promise<Campaign> {
    return this.fetch(
      this.url('GetCampaign'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return Campaign.fromJSON(m)})
    })
  }
  
  public getReports(params: ReportRequest, headers: object = {}): Promise<ReportResponse> {
    return this.fetch(
      this.url('GetReports'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return ReportResponse.fromJSON(m)})
    })
  }
  
  public requestExport(params: ReportRequest, headers: object = {}): Promise<google_protobuf.Empty> {
    return this.fetch(
      this.url('RequestExport'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return google_protobuf.Empty.fromJSON(m)})
    })
  }
  
  public beginCampaignCancellation(params: BeginCampaignCancellationRequest, headers: object = {}): Promise<BeginCampaignCancellationResponse> {
    return this.fetch(
      this.url('BeginCampaignCancellation'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return BeginCampaignCancellationResponse.fromJSON(m)})
    })
  }
  
  public completeCampaignCancellation(params: CompleteCampaignCancellationRequest, headers: object = {}): Promise<google_protobuf.Empty> {
    return this.fetch(
      this.url('CompleteCampaignCancellation'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return google_protobuf.Empty.fromJSON(m)})
    })
  }
  
  public createCancellationFeedback(params: CreateCancellationFeedbackRequest, headers: object = {}): Promise<google_protobuf.Empty> {
    return this.fetch(
      this.url('CreateCancellationFeedback'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return google_protobuf.Empty.fromJSON(m)})
    })
  }
  
  public getRejectedCampaign(params: Campaign, headers: object = {}): Promise<RejectedCampaign> {
    return this.fetch(
      this.url('GetRejectedCampaign'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return RejectedCampaign.fromJSON(m)})
    })
  }
  
  public rejectedCampaignCorrection(params: RejectedCampaignCorrectionRequest, headers: object = {}): Promise<google_protobuf.Empty> {
    return this.fetch(
      this.url('RejectedCampaignCorrection'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return google_protobuf.Empty.fromJSON(m)})
    })
  }
  
  public validateURL(params: ValidateURLRequest, headers: object = {}): Promise<ValidateURLResponse> {
    return this.fetch(
      this.url('ValidateURL'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return ValidateURLResponse.fromJSON(m)})
    })
  }
  
  public searchRetailer(params: SearchRetailerRequest, headers: object = {}): Promise<SearchRetailerResponse> {
    return this.fetch(
      this.url('SearchRetailer'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return SearchRetailerResponse.fromJSON(m)})
    })
  }
  
  public listCharges(params: google_protobuf.Empty, headers: object = {}): Promise<ChargeList> {
    return this.fetch(
      this.url('ListCharges'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return ChargeList.fromJSON(m)})
    })
  }
  
  public syncFacebookPage(params: SyncFacebookPageRequest, headers: object = {}): Promise<FacebookPage> {
    return this.fetch(
      this.url('SyncFacebookPage'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return FacebookPage.fromJSON(m)})
    })
  }
  
  public disconnectFacebookPage(params: DisconnectFacebookPageRequest, headers: object = {}): Promise<google_protobuf.Empty> {
    return this.fetch(
      this.url('DisconnectFacebookPage'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return google_protobuf.Empty.fromJSON(m)})
    })
  }
  
  public disconnectAllFacebookPages(params: google_protobuf.Empty, headers: object = {}): Promise<google_protobuf.Empty> {
    return this.fetch(
      this.url('DisconnectAllFacebookPages'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return google_protobuf.Empty.fromJSON(m)})
    })
  }
  
  public listSocialIntegrations(params: google_protobuf.Empty, headers: object = {}): Promise<ListSocialIntegrationsResponse> {
    return this.fetch(
      this.url('ListSocialIntegrations'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return ListSocialIntegrationsResponse.fromJSON(m)})
    })
  }
  
}export interface ITasks {
  createUserAudioSource: (data: CreateUserAudioSourceTaskRequest, headers?: object) => Promise<google_protobuf.Empty>
  createUserAudio: (data: CreateUserAudioTaskRequest, headers?: object) => Promise<google_protobuf.Empty>
  prepareCampaign: (data: CampaignTaskRequest, headers?: object) => Promise<google_protobuf.Empty>
  submitToMagnum: (data: SubmitToMagnumRequest, headers?: object) => Promise<google_protobuf.Empty>
  updateNetworkConfigurations: (data: CampaignTaskRequest, headers?: object) => Promise<google_protobuf.Empty>
  cancelCampaign: (data: CancelCampaignTaskRequest, headers?: object) => Promise<google_protobuf.Empty>
  sendResultsUnlockedEmail: (data: CampaignTaskRequest, headers?: object) => Promise<google_protobuf.Empty>
  sendFeedbackSurveyEmail: (data: CampaignTaskRequest, headers?: object) => Promise<google_protobuf.Empty>
  refreshUserTokensValidAfter: (data: UserTaskRequest, headers?: object) => Promise<google_protobuf.Empty>
  verifyEmail: (data: VerifyEmailTaskRequest, headers?: object) => Promise<google_protobuf.Empty>
  updatedEmail: (data: UpdatedEmailTaskRequest, headers?: object) => Promise<google_protobuf.Empty>
  deliverEmail: (data: DeliverEmailTaskRequest, headers?: object) => Promise<google_protobuf.Empty>
  captureCreative: (data: CaptureCreativeRequest, headers?: object) => Promise<google_protobuf.Empty>
  assignSystemUserToFacebookPage: (data: AssignSystemUserToFacebookPageTaskRequest, headers?: object) => Promise<google_protobuf.Empty>
  removeAgencyFromFacebookPage: (data: RemoveAgencyFromFacebookPageTaskRequest, headers?: object) => Promise<google_protobuf.Empty>
  exportResults: (data: CampaignTaskRequest, headers?: object) => Promise<google_protobuf.Empty>
}

export class Tasks implements ITasks {
  private hostname: string
  private fetch: Fetch
  private path = '/twirp/messages.pisa.Tasks/';

  constructor(hostname: string, fetch: Fetch) {
    this.hostname = hostname
    this.fetch = fetch
  }

  private url(name: string): string {
    return this.hostname + this.path + name
  }

  
  public createUserAudioSource(params: CreateUserAudioSourceTaskRequest, headers: object = {}): Promise<google_protobuf.Empty> {
    return this.fetch(
      this.url('CreateUserAudioSource'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return google_protobuf.Empty.fromJSON(m)})
    })
  }
  
  public createUserAudio(params: CreateUserAudioTaskRequest, headers: object = {}): Promise<google_protobuf.Empty> {
    return this.fetch(
      this.url('CreateUserAudio'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return google_protobuf.Empty.fromJSON(m)})
    })
  }
  
  public prepareCampaign(params: CampaignTaskRequest, headers: object = {}): Promise<google_protobuf.Empty> {
    return this.fetch(
      this.url('PrepareCampaign'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return google_protobuf.Empty.fromJSON(m)})
    })
  }
  
  public submitToMagnum(params: SubmitToMagnumRequest, headers: object = {}): Promise<google_protobuf.Empty> {
    return this.fetch(
      this.url('SubmitToMagnum'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return google_protobuf.Empty.fromJSON(m)})
    })
  }
  
  public updateNetworkConfigurations(params: CampaignTaskRequest, headers: object = {}): Promise<google_protobuf.Empty> {
    return this.fetch(
      this.url('UpdateNetworkConfigurations'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return google_protobuf.Empty.fromJSON(m)})
    })
  }
  
  public cancelCampaign(params: CancelCampaignTaskRequest, headers: object = {}): Promise<google_protobuf.Empty> {
    return this.fetch(
      this.url('CancelCampaign'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return google_protobuf.Empty.fromJSON(m)})
    })
  }
  
  public sendResultsUnlockedEmail(params: CampaignTaskRequest, headers: object = {}): Promise<google_protobuf.Empty> {
    return this.fetch(
      this.url('SendResultsUnlockedEmail'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return google_protobuf.Empty.fromJSON(m)})
    })
  }
  
  public sendFeedbackSurveyEmail(params: CampaignTaskRequest, headers: object = {}): Promise<google_protobuf.Empty> {
    return this.fetch(
      this.url('SendFeedbackSurveyEmail'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return google_protobuf.Empty.fromJSON(m)})
    })
  }
  
  public refreshUserTokensValidAfter(params: UserTaskRequest, headers: object = {}): Promise<google_protobuf.Empty> {
    return this.fetch(
      this.url('RefreshUserTokensValidAfter'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return google_protobuf.Empty.fromJSON(m)})
    })
  }
  
  public verifyEmail(params: VerifyEmailTaskRequest, headers: object = {}): Promise<google_protobuf.Empty> {
    return this.fetch(
      this.url('VerifyEmail'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return google_protobuf.Empty.fromJSON(m)})
    })
  }
  
  public updatedEmail(params: UpdatedEmailTaskRequest, headers: object = {}): Promise<google_protobuf.Empty> {
    return this.fetch(
      this.url('UpdatedEmail'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return google_protobuf.Empty.fromJSON(m)})
    })
  }
  
  public deliverEmail(params: DeliverEmailTaskRequest, headers: object = {}): Promise<google_protobuf.Empty> {
    return this.fetch(
      this.url('DeliverEmail'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return google_protobuf.Empty.fromJSON(m)})
    })
  }
  
  public captureCreative(params: CaptureCreativeRequest, headers: object = {}): Promise<google_protobuf.Empty> {
    return this.fetch(
      this.url('CaptureCreative'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return google_protobuf.Empty.fromJSON(m)})
    })
  }
  
  public assignSystemUserToFacebookPage(params: AssignSystemUserToFacebookPageTaskRequest, headers: object = {}): Promise<google_protobuf.Empty> {
    return this.fetch(
      this.url('AssignSystemUserToFacebookPage'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return google_protobuf.Empty.fromJSON(m)})
    })
  }
  
  public removeAgencyFromFacebookPage(params: RemoveAgencyFromFacebookPageTaskRequest, headers: object = {}): Promise<google_protobuf.Empty> {
    return this.fetch(
      this.url('RemoveAgencyFromFacebookPage'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return google_protobuf.Empty.fromJSON(m)})
    })
  }
  
  public exportResults(params: CampaignTaskRequest, headers: object = {}): Promise<google_protobuf.Empty> {
    return this.fetch(
      this.url('ExportResults'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return google_protobuf.Empty.fromJSON(m)})
    })
  }
  
}export interface IAdmin {
  submitAudit: (data: SubmitAuditRequest, headers?: object) => Promise<google_protobuf.Empty>
  createCoupon: (data: CreateCouponRequest, headers?: object) => Promise<CreateCouponResponse>
  updateCoupon: (data: UpdateCouponRequest, headers?: object) => Promise<google_protobuf.Empty>
  applyDiscountTiers: (data: ApplyDiscountTiersRequest, headers?: object) => Promise<google_protobuf.Empty>
  archiveCoupon: (data: ArchiveCouponRequest, headers?: object) => Promise<google_protobuf.Empty>
  createAdminToken: (data: CreateAdminTokenRequest, headers?: object) => Promise<CreateAdminTokenResponse>
  cancelCampaign: (data: CancelCampaignRequest, headers?: object) => Promise<google_protobuf.Empty>
}

export class Admin implements IAdmin {
  private hostname: string
  private fetch: Fetch
  private path = '/twirp/messages.pisa.Admin/';

  constructor(hostname: string, fetch: Fetch) {
    this.hostname = hostname
    this.fetch = fetch
  }

  private url(name: string): string {
    return this.hostname + this.path + name
  }

  
  public submitAudit(params: SubmitAuditRequest, headers: object = {}): Promise<google_protobuf.Empty> {
    return this.fetch(
      this.url('SubmitAudit'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return google_protobuf.Empty.fromJSON(m)})
    })
  }
  
  public createCoupon(params: CreateCouponRequest, headers: object = {}): Promise<CreateCouponResponse> {
    return this.fetch(
      this.url('CreateCoupon'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return CreateCouponResponse.fromJSON(m)})
    })
  }
  
  public updateCoupon(params: UpdateCouponRequest, headers: object = {}): Promise<google_protobuf.Empty> {
    return this.fetch(
      this.url('UpdateCoupon'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return google_protobuf.Empty.fromJSON(m)})
    })
  }
  
  public applyDiscountTiers(params: ApplyDiscountTiersRequest, headers: object = {}): Promise<google_protobuf.Empty> {
    return this.fetch(
      this.url('ApplyDiscountTiers'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return google_protobuf.Empty.fromJSON(m)})
    })
  }
  
  public archiveCoupon(params: ArchiveCouponRequest, headers: object = {}): Promise<google_protobuf.Empty> {
    return this.fetch(
      this.url('ArchiveCoupon'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return google_protobuf.Empty.fromJSON(m)})
    })
  }
  
  public createAdminToken(params: CreateAdminTokenRequest, headers: object = {}): Promise<CreateAdminTokenResponse> {
    return this.fetch(
      this.url('CreateAdminToken'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return CreateAdminTokenResponse.fromJSON(m)})
    })
  }
  
  public cancelCampaign(params: CancelCampaignRequest, headers: object = {}): Promise<google_protobuf.Empty> {
    return this.fetch(
      this.url('CancelCampaign'),
      createTwirpRequest(params, headers)
    ).then((res) => {
      if (!res.ok) {
        return throwTwirpError(res)
      }
      return res.json().then((m) => { return google_protobuf.Empty.fromJSON(m)})
    })
  }
  
}