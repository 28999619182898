import {
  ActionTree, Module,
} from 'vuex';
import { RootState } from '@/shared/store/types';

interface State {}

export const initialState: State = {};

const actions: ActionTree<State, RootState> = {
  abandonmentSurvey(context, step: string): void {
    this.dispatch('googleTagManager/trackEvent', {
      event: 'qualaroo.fireAbandonmentSurvey',
      data: {
        abandonmentSurvey: {
          step,
        },
      },
    });
  },
  resultsSurvey(context, props: { [key: string]: string }): void {
    this.dispatch('googleTagManager/trackEvent', {
      event: 'qualaroo.fireResultsSurvey',
      data: {
        resultsSurvey: { ...props },
      },
    });
  },
};

export const qualaroo: Module<State, RootState> = {
  namespaced: true,
  state: { ...initialState },
  actions,
};
