const maxImageFileSizeInMb = 25;
const maxImageFileSizeInKb = maxImageFileSizeInMb * 1024;
const maxImageFileSizeInB = maxImageFileSizeInKb * 1024;
const maxAudioFileSizeInMb = 500;
const maxAudioFileSizeInKb = maxAudioFileSizeInMb * 1024;
const maxAudioFileSizeInB = maxAudioFileSizeInKb * 1024;

export {
  maxImageFileSizeInB,
  maxImageFileSizeInKb,
  maxImageFileSizeInMb,
  maxAudioFileSizeInMb,
  maxAudioFileSizeInKb,
  maxAudioFileSizeInB,
};
