/// <reference types="stripe-v3" />

import {
  ActionTree, GetterTree, Module, MutationTree,
} from 'vuex';
import { RootState } from '@/shared/store/types';
import {
  BeginCampaignCancellationRequest,
  BeginCampaignCancellationResponse,
  Campaign as APICampaign,
  Campaign_Status as CampaignStatus,
  CampaignListRequest,
  CampaignList,
  CampaignList_Item as APICampaignListItem,
  CompleteCampaignCancellationRequest,
  CreateCancellationFeedbackRequest,
  CurrencyAmount,
  Draft as APIDraft,
  FacebookPage,
  LandingPage as APILandingPage,
  RejectedCampaignCorrectionRequest,
  RejectedCampaign,
} from '@/shared/gen/messages.pisa';
import { Empty } from '@/shared/gen/google/protobuf/google.protobuf';
import defaultErrorToast from '@/shared/lib/defaultToast';
import { BuilderService } from '@/shared/lib/api';
import {
  Draft, extractHostname, IUGC, normalizeDraft,
} from '@/shared/store/onboarding';
import { isEnded } from '@/shared/lib/campaign_status';
import { parseDate } from '@/shared/lib/protobuf';

export enum CampaignsTab {
  Active,
  Completed,
}

export interface CancellationFeedback {
  campaignId: string;
  reason: string;
  customText: string;
}

export interface CancellationFee {
  currency: string;
  amount: number;
}

export function normalizeCampaign(pisaCampaign: APICampaign): Campaign {
  return {
    id: pisaCampaign.id,
    createdAt: new Date(pisaCampaign.createdAt),
    status: pisaCampaign.status,
    ugc: pisaCampaign.ugc,
    landingPages: pisaCampaign.landingPages,
    budget: pisaCampaign.budget,
    durationDays: pisaCampaign.durationDays,
    submittedAt: new Date(pisaCampaign.submittedAt),
    activatedAt: parseDate(pisaCampaign.activatedAt),
    endedAt: parseDate(pisaCampaign.endedAt),
    adNetworks: pisaCampaign.adNetworks,
    facebookPage: pisaCampaign.facebookPage,
  };
}

export interface CampaignReport {
  campaign?: Campaign;
}

export interface Campaign {
  id?: string;
  createdAt?: Date;
  status?: CampaignStatus;
  ugc?: IUGC;
  imageUrl?: string;
  landingPages: APILandingPage[];
  budget?: CurrencyAmount;
  durationDays?: number;
  submittedAt?: Date;
  activatedAt?: Date;
  endedAt?: Date;
  adNetworks: string[],
  facebookPage?: FacebookPage;
}

export interface CampaignListItem {
  campaign: Campaign;
  impressions: number;
  interactions: number;
}

export interface AuditResult {
  releaseDataApproved: boolean;
  releaseDataRejectedFields: string[];
  releaseDataComments: string;
  landingPagesApproved: boolean;
  landingPagesRejectedRetailers: string[];
  landingPagesComments: string;
  audioApproved: boolean;
  audioRejectedIds: string[];
  audioComments: string;
  imagesApproved: boolean;
  imagesRejectedIds: string[];
  imagesComments: string;
  facebookPageConnectionApproved: boolean;
  facebookPageConnectionComments: string;
  auditorName?: string;
}

export interface AudioStatistics {
  id: number;
  plays: number;
  progress?: {
    durationMs: number;
    percentage: number;
  }[];
  averageProgressMs: number;
  feedback?: {
    rating: number;
    percentage: number;
  }[];
  averageFeedback: number;
}

export interface RetailerStatistics {
  retailer: string;
  clicks: number;
  percentage: number;
}

export interface CampaignState {
  selectedTab: CampaignsTab;
  campaigns: {
    loaded: boolean,
    loading: boolean,
    running: CampaignListItem[],
    ended: CampaignListItem[],
    errors: string[],
  },
  campaign: {
    loaded: boolean,
    loading: boolean,
    details: Campaign,
    report: CampaignReport,
    errors: string[],
  },
  drafts: Draft[],
}

const initialState: CampaignState = {
  selectedTab: CampaignsTab.Active,
  campaign: {
    loaded: false,
    loading: false,
    details: {
      landingPages: [],
      adNetworks: [],
    },
    // TODO move this out of campaign to be a sibling
    report: {},
    errors: [],
  },
  campaigns: {
    loaded: false,
    loading: false,
    running: [],
    ended: [],
    errors: [],
  },
  drafts: [],
};

const actions: ActionTree<CampaignState, RootState> = {
  setSelectedTab({ commit }, tab: CampaignsTab): void {
    commit('setSelectedTab', tab);
  },
  storeCampaign({ commit }, payload: APICampaign): Promise<void> {
    return new Promise((resolve) => {
      commit('setCampaignDetails', normalizeCampaign(payload));
      resolve();
    });
  },
  list({ commit }): Promise<void> {
    commit('setLoadingCampaigns', true);
    return new Promise((resolve, reject) => {
      BuilderService(this.dispatch('profile/loggedIn'), (svc) => {
        svc.listCampaigns(new CampaignListRequest()).then((res: CampaignList) => {
          commit('setLoadingCampaigns', false);
          commit('setCampaigns', res.campaigns);
          resolve();
        }).catch((error: any) => {
          defaultErrorToast();
          commit('setLoadingCampaigns', false);
          commit('setCampaignsErrors', [error.message]);
          reject(error);
        });
      });
    });
  },
  load({ commit }, id: string): Promise<void> {
    commit('setLoadingCampaign', true);
    return new Promise((resolve, reject) => {
      BuilderService(this.dispatch('profile/loggedIn'), (svc) => {
        svc.getCampaign(new APICampaign({ id })).then((res: any) => {
          commit('setLoadingCampaign', false);
          commit('setCampaignDetails', normalizeCampaign(res));
          resolve();
        }).catch((error: any) => {
          if (error.code === 'not_found') {
            defaultErrorToast('This account is not authorized to view this page');
          } else {
            defaultErrorToast();
          }
          commit('setLoadingCampaign', false);
          commit('setCampaignErrors', [error.message]);
          reject(error);
        });
      });
    });
  },
  listDrafts({ commit }): Promise<void> {
    return new Promise((resolve, reject) => {
      BuilderService(this.dispatch('profile/loggedIn'), (svc) => {
        svc.listDrafts(new Empty(), {}).then((res: any) => {
          const localDrafts = res.drafts.map((d: APIDraft) => normalizeDraft(d));
          commit('setDrafts', localDrafts);
        }).catch((error: any) => {
          defaultErrorToast();
          reject(error);
        });
      });
    });
  },
  getRejectedCampaign(context, id: string): Promise<RejectedCampaign> {
    return new Promise((resolve, reject) => {
      BuilderService(this.dispatch('profile/loggedIn'), (svc) => {
        svc.getRejectedCampaign(new APICampaign({ id })).then(resolve, reject);
      });
    });
  },
  beginCancellation(context, payload: string): Promise<CancellationFee> {
    return new Promise((resolve, reject) => {
      BuilderService(this.dispatch('profile/loggedIn'), (svc) => {
        svc.beginCampaignCancellation(new BeginCampaignCancellationRequest({ campaignId: payload }))
          .then((resp: BeginCampaignCancellationResponse) => {
            const { currency, amount } = resp.fee;
            resolve({ currency, amount });
          })
          .catch(reject);
      });
    });
  },
  completeCancellation(context, campaignId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      BuilderService(this.dispatch('profile/loggedIn'), (svc) => {
        svc.completeCampaignCancellation(new CompleteCampaignCancellationRequest({ campaignId }))
          .then(() => { resolve(); })
          .catch(reject);
      });
    });
  },
  submitFeedback({ commit }, payload: CancellationFeedback): Promise<void> {
    return new Promise((resolve, reject) => {
      BuilderService(this.dispatch('profile/loggedIn'), (svc) => {
        svc.createCancellationFeedback(new CreateCancellationFeedbackRequest(payload), {}).then(() => {
          resolve();
        }).catch((error: any) => {
          defaultErrorToast();
          commit('setCampaignErrors', [error.message]);
          reject(error);
        });
      });
    });
  },
  submitRejectedCampaign({ commit }, payload: any): Promise<void> {
    return new Promise((resolve, reject) => {
      BuilderService(this.dispatch('profile/loggedIn'), (svc) => {
        svc.rejectedCampaignCorrection(new RejectedCampaignCorrectionRequest(payload), {}).then(() => {
          resolve();
        }).catch((error: any) => {
          defaultErrorToast();
          commit('setCampaignErrors', [error.message]);
          reject(error);
        });
      });
    });
  },
  loadSampleReport({ commit }, payload: any) {
    commit('setSampleReportDetails', payload);
  },
};

const getters: GetterTree<CampaignState, RootState> = {
  selectedTab(state): CampaignsTab {
    return state.selectedTab;
  },
  campaignLoaded(state): boolean {
    return state.campaign.loaded;
  },
  campaignLoading(state): boolean {
    return state.campaign.loading;
  },
  campaignDetails(state): Campaign {
    return state.campaign.details;
  },
  report(state): CampaignReport {
    return state.campaign.report;
  },
  campaignErrors(state): string[] {
    return state.campaign.errors;
  },
  campaignsLoaded(state): boolean {
    return state.campaigns.loaded;
  },
  campaignsLoading(state): boolean {
    return state.campaigns.loading;
  },
  running(state): CampaignListItem[] {
    return state.campaigns.running;
  },
  ended(state): CampaignListItem[] {
    return state.campaigns.ended;
  },
  campaignsErrors(state): string[] {
    return state.campaigns.errors;
  },
  drafts(state): Draft[] {
    return state.drafts;
  },
  getNumImages(state): number {
    if (state.campaign.details.ugc) {
      return state.campaign.details.ugc.images.length;
    }
    return 0;
  },
  hasAudio(state): boolean {
    let numAudio = 0;
    if (state.campaign.details.ugc) {
      numAudio = state.campaign.details.ugc.audio.length;
    }
    return (numAudio > 0);
  },
  getLandingPages(state): APILandingPage[] {
    return state.campaign.details.landingPages;
  },
  getLandingPageDomains(state): string[] {
    const lps = state.campaign.details.landingPages;
    const lpDomains: string[] = [];
    if (lps) {
      lps.forEach((lp) => {
        const { url } = lp;
        lpDomains.push(extractHostname(url));
      });
    }
    return lpDomains;
  },
  getAdNetworks(state): string[] {
    return state.campaign.details.adNetworks;
  },
  getCampaignFacebookPage(state) {
    if (state.campaign.details.facebookPage) {
      return state.campaign.details.facebookPage;
    }
    return undefined;
  },
};

const mutations: MutationTree<CampaignState> = {
  setSelectedTab(state, tab: CampaignsTab) {
    state.selectedTab = tab;
  },
  setLoadingCampaign(state: CampaignState, payload: boolean) {
    state.campaign.loaded = false;
    state.campaign.loading = payload;
  },
  setCampaignDetails(state: CampaignState, payload: Campaign) {
    state.campaign.details = payload;
    state.campaign.loaded = true;
  },
  setCampaignReport(state: CampaignState, payload: CampaignReport) {
    state.campaign.report = payload;
    state.campaign.loaded = true;
  },
  setCampaignErrors(state: CampaignState, payload: string[]) {
    state.campaign.errors = payload;
    state.campaign.loaded = false;
  },
  setLoadingCampaigns(state: CampaignState, payload: boolean) {
    state.campaigns.loading = payload;
  },
  setCampaigns(state: CampaignState, payload: APICampaignListItem[]) {
    const campaigns = payload.map((i) => ({
      impressions: i.impressions || 0,
      interactions: i.interactions || 0,
      campaign: normalizeCampaign(i.campaign),
    }));
    state.campaigns.running = campaigns.filter((i) => !isEnded(i.campaign.status!));
    state.campaigns.ended = campaigns.filter((i) => isEnded(i.campaign.status!));
    state.campaigns.loaded = true;
  },
  setCampaignsErrors(state: CampaignState, payload: string[]) {
    state.campaign.errors = payload;
    state.campaign.loaded = false;
  },
  setDrafts(state: CampaignState, payload: Draft[]) {
    state.drafts = payload;
  },
  setSampleReportDetails(state: CampaignState, payload: Campaign) {
    state.campaign.details = payload;
    state.campaign.loaded = true;
  },
};

export const campaign: Module<CampaignState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
