import Vue from 'vue';
import {
  ActionTree, GetterTree, Module, MutationTree,
} from 'vuex';
import { RootState } from '@/shared/store/types';
import { API_ROOT } from '@/shared/lib/api';

interface Creative {
  id: string,
  iframe: HTMLIFrameElement,
}

export interface State {
  map: {
    [id: string]: {
      id: string,
      iframe: HTMLIFrameElement;
    };
  };
  focusedCreatives: string[];
}

const initialState: State = {
  map: {},
  focusedCreatives: [],
};

const actions: ActionTree<State, RootState> = {
  add({ commit }, payload: Creative): void {
    commit('add', payload);
  },
  remove({ commit }, id: string): void {
    commit('remove', id);
  },
  postMessage({ getters }, { id, ...payload }: { id: string, command: string }): void {
    const iframe = getters.iframeById(id);
    if (iframe) {
      iframe.contentWindow.postMessage(payload, API_ROOT);
    }
  },
  focused({ commit }, id: string): void {
    commit('focused', id);
  },
  refreshFocused({ commit, dispatch, state }): void {
    state.focusedCreatives.forEach((focusedId) => {
      dispatch('postMessage', { id: focusedId, command: 'zire:reload' });
    });
    commit('clearFocused');
  },
};

const getters: GetterTree<State, RootState> = {
  iframeById(state): (id: string) => void|HTMLIFrameElement {
    return (id: string): void|HTMLIFrameElement => {
      if (state.map[id] && state.map[id].iframe) {
        return state.map[id].iframe;
      }
      return undefined;
    };
  },
};

const mutations: MutationTree<State> = {
  add(state, { id, iframe }: Creative) {
    // Need to wrap iframe in a function to ensure Vue doesn't attempt to add reactivity...
    const value = { id };
    if (state.map[id]) {
      // throw an error here?
    }
    Object.defineProperty(value, 'iframe', { get: () => iframe });
    Vue.set(state.map, id, value);
  },
  remove(state, id: string) {
    Vue.delete(state.map, id);
    state.focusedCreatives = state.focusedCreatives.filter((focusedId) => focusedId !== id);
  },
  focused(state, id: string) {
    state.focusedCreatives.push(id);
  },
  clearFocused(state) {
    state.focusedCreatives = [];
  },
};

export const creatives: Module<State, RootState> = {
  namespaced: true,
  state: initialState,
  actions,
  getters,
  mutations,
};
