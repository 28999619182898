// This file needs to be included at the top of router.ts in order to prevent
// TypeError: guard is not a function
// error when navigating directly to views/components that extend a component with hooks.
// For Zire, this meant navigating directly to the results pages when some routes were in the main
// app.js file, and others were loaded via chunks.  Navigating to another page and then to the
// results worked fine, while navigating directly to the results raised an error.
// It appears to have something to do w/ vue-router setting the correct option merging strategies
// for the router hooks.  https://github.com/vuejs/vue-router/issues/2871#issuecomment-524855285
import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);
