







import { Component, Prop, Vue } from 'vue-property-decorator';
import abbreviate from 'number-abbreviate';

export const DEFAULT_LENGTH = 5;

@Component({
})
export default class AbbreviatedValue extends Vue {
  @Prop({ default: '' })
  title: number | string;

  @Prop({ required: true })
  value: number;

  @Prop({ default: DEFAULT_LENGTH })
  length: number;

  @Prop({ default: '-' })
  zeroValue: string;

  get defaultTitle() {
    const v = this.value;
    if (v) {
      return v.toLocaleString ? v.toLocaleString() : v;
    }
    return undefined;
  }

  get abbreviatedValue() {
    const v = this.value;
    if (v) {
      const vStr = v.toLocaleString ? v.toLocaleString() : String(v);
      if (vStr.length <= this.length) {
        return vStr;
      }
      for (let i = this.length - 2; i > 0; i -= 1) {
        const result = `${abbreviate(v, i)}`;
        if (i === 0 || result.length <= this.length) {
          return result;
        }
      }
      return `${abbreviate(v, 0)}`;
    }
    return this.zeroValue;
  }
}
