















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class HeaderCta extends Vue {
  @Prop({ default: false }) isLarge: boolean;

  @Prop({ default: false }) isFilled: boolean;
}
