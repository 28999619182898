import {
  ActionTree, GetterTree, Module, MutationTree,
} from 'vuex';
import { RootState } from '@/shared/store/types';

interface State {
  trackOnceEvents: string[];
}

export const initialState: State = {
  trackOnceEvents: [],
};

const actions: ActionTree<State, RootState> = {
  trackOnce({ getters, dispatch, commit }, payload: any) {
    const { action } = payload;
    if (getters.trackOnceEvents.indexOf(action) === -1) {
      dispatch('track', payload);
      commit('addTrackOnceEvent', action);
    }
  },
  resetTrackOnceEventList({ commit }) {
    commit('resetTrackOnceEventList');
  },
  track({ rootGetters }, { action, properties }: { action: string, properties: any }) {
    const mergedProperties = {
      userId: rootGetters['profile/userId'],
      loggedIn: rootGetters['profile/isLoggedInWithEmail'],
      syncedSocialAccounts: rootGetters['facebook/hasSyncedPages'],
      ...properties,
    };
    this.dispatch('googleTagManager/trackEvent', {
      event: 'mixpanel.track',
      data: {
        mixpanel: {
          event_name: action,
          properties: mergedProperties,
        },
      },
    });
  },
};

const getters: GetterTree<State, RootState> = {
  trackOnceEvents(state): string[] {
    return state.trackOnceEvents;
  },
};

const mutations: MutationTree<State> = {
  addTrackOnceEvent(state: State, action: string) {
    state.trackOnceEvents.push(action);
  },
  resetTrackOnceEventList(state: State) {
    state.trackOnceEvents = [];
  },
};

export const mixpanel: Module<State, RootState> = {
  namespaced: true,
  state: { ...initialState },
  getters,
  actions,
  mutations,
};
