



















import { Component, Prop, Vue } from 'vue-property-decorator';
import ProgressBar from 'progressbar.js';

// eslint-disable-next-line @typescript-eslint/no-unused-vars,max-len
const SVG_PLAY_PAUSE_CONTROL = '<svg class="control cutout" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg"><g fill="#fff" fill-rule="evenodd"><path d="M20 40C8.954 40 0 31.046 0 20S8.954 0 20 0s20 8.954 20 20-8.954 20-20 20zm9.134-21.437L16.57 11.248a1.58 1.58 0 0 0-1.107-.229 1.76 1.76 0 0 0-1.035.528c-.285.293-.428.674-.428 1.143v14.63c0 .423.137.786.41 1.09.274.306.613.493 1.018.564.404.07.785 0 1.142-.211l12.564-7.316c.381-.21.637-.504.768-.88.13-.374.13-.75 0-1.125-.131-.375-.387-.668-.768-.879z" class="control-play"/><path d="M20 40C8.954 40 0 31.046 0 20S8.954 0 20 0s20 8.954 20 20-8.954 20-20 20zm-3.718-11.103c.948 0 1.718-.859 1.718-1.917V12.918c0-1.06-.77-1.918-1.718-1.918h-2.564C12.77 11 12 11.859 12 12.918V26.98c0 1.058.77 1.917 1.718 1.917h2.564zM28 27.082V13.02c0-1.058-.77-1.917-1.718-1.917h-2.564c-.948 0-1.718.859-1.718 1.917v14.062c0 1.06.77 1.918 1.718 1.918h2.564C27.23 29 28 28.141 28 27.082z" class="control-pause"/></g></svg>';

@Component
export default class AudioPlayButton extends Vue {
  $refs!: {
    audio: HTMLAudioElement,
    progressContainer: HTMLElement,
  };

  @Prop({ default: 'blue' }) color: string;

  progressBar: any;

  playing: boolean = false;

  mounted() {
    const { progressContainer } = this.$refs;
    const strokeWidth = 4;
    this.progressBar = new ProgressBar.Circle(progressContainer, {
      strokeWidth,
      easing: 'linear',
      duration: 400,
      color: this.color,
      svgStyle: null,
      text: {
        value: SVG_PLAY_PAUSE_CONTROL,
        style: {
          position: 'absolute',
          top: `${strokeWidth}px`,
          bottom: `${strokeWidth}px`,
          left: `${strokeWidth}px`,
          right: `${strokeWidth}px`,
        },
      },
    });
    this.$refs.audio.addEventListener('timeupdate', this.handleProgress);
    this.$refs.audio.addEventListener('ended', this.handleEnded);
  }

  handleProgress(evt: any) {
    const audio: HTMLAudioElement = evt.target;
    const { currentTime, duration } = audio;
    if (this.progressBar) {
      this.progressBar.animate(currentTime / duration);
    }
  }

  handleEnded(evt: any) {
    const audio: HTMLAudioElement = evt.target;
    audio.currentTime = 0;
    this.playing = false;
  }

  playPause() {
    if (!this.$refs.audio) {
      return;
    }
    if (this.$refs.audio.paused) {
      const playPromise = this.$refs.audio.play();
      if (playPromise && typeof playPromise.then === 'function') {
        playPromise.then(null, () => {
          this.pause();
        });
      }
    } else {
      this.$refs.audio.pause();
    }
    this.playing = !this.$refs.audio.paused;
  }

  pause() {
    if (this.$refs.audio) {
      this.$refs.audio.pause();
      this.playing = false;
    }
  }
}
