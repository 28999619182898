

















import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component
export default class BlacklistNotice extends Vue {
  @Getter('profile/blacklistedCountry') blacklistedCountry: string;
}
