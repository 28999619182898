




import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component({
  mixins: [],
  components: {
  },
})
export default class DeviceDetector extends Vue {
  static MAXIMUM_MOBILE_PIXELS = 767;

  static MAXIMUM_TABLET_PIXELS = 1250;

  static calculateDevice(width: number) {
    if (width <= DeviceDetector.MAXIMUM_MOBILE_PIXELS) {
      return 'mobile';
    }
    if (width <= DeviceDetector.MAXIMUM_TABLET_PIXELS) {
      return 'tablet';
    }
    return 'desktop';
  }

  static isWebview = () : boolean => {
    const webview = document.cookie.match(/webview=([^;]*);/);

    return !!webview && webview[1] === 'true';
  }

  @Getter('layout/getDevice') device: string;

  mounted() {
    this.$store.dispatch('layout/setDevice', DeviceDetector.calculateDevice(window.innerWidth));
    this.$store.dispatch('layout/setWebviewState', DeviceDetector.isWebview());
    window.addEventListener('resize', this.handleWindowResize);
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  handleWindowResize(event: any) {
    const calculatedDevice = DeviceDetector.calculateDevice(event.currentTarget.innerWidth);
    if (calculatedDevice !== this.device) {
      this.$store.dispatch('layout/setDevice', calculatedDevice);
    }
  }
}
