



















import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component
export default class LogoLink extends Vue {
  @Getter('profile/isLoggedInWithEmail') isLoggedInWithEmail: boolean;

  get homeRoute() {
    if (this.isLoggedInWithEmail) {
      return { name: 'campaigns' };
    }
    return { name: 'home' };
  }

  handleClick(e: MouseEvent, next: (e: Event) => void) {
    let emitClick = true;
    // Do not emit when clicked with control keys...
    if (e.metaKey || e.altKey || e.ctrlKey || e.shiftKey) {
      emitClick = false;
    }
    if (emitClick) {
      this.$emit('click', e);
    }
    next(e);
  }
}
