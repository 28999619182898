






































import { Component, Model, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { Scope } from '@/shared/store/cookieConsent';

interface PreferenceMetadata {
  name: string,
  title: string;
  tooltip: string;
  enabled: boolean;
  readonly: boolean;
}

@Component
export default class CookiePreferences extends Vue {
  @Model('input') value: { [key: string]: boolean };

  @Getter('cookieConsent/scopes') scopes: Scope[];

  options: PreferenceMetadata[] = [
    {
      name: 'required',
      title: 'Allow Required Cookies',
      tooltip: '<strong>Required Cookies</strong> are strictly necessary to provide you with services available through our websites'
        + ' and some of its features. Because these cookies are strictly necessary to deliver the websites,'
        + ' you cannot refuse them without impacting how our website functions.  You can block or delete them by changing'
        + ' your browser settings.',
      enabled: true,
      readonly: true,
    },
  ];

  mounted() {
    this.scopes.forEach((i) => {
      this.options.push({
        name: i.name,
        title: i.display.name,
        tooltip: i.display.description,
        enabled: this.value[i.name] || false,
        readonly: false,
      });
    });
  }

  handlePreferenceChange(name: string, enabled: boolean) {
    const updatedPreferences = { ...this.value };
    updatedPreferences[name] = enabled;
    this.$emit('input', updatedPreferences);
  }

  toggleOption(option: { name: string, readonly: boolean, enabled: boolean }) {
    if (!option.readonly) {
      // eslint-disable-next-line no-param-reassign
      option.enabled = !option.enabled;
      this.handlePreferenceChange(option.name, option.enabled);
    }
  }
}
