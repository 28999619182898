















import { Component, Prop, Vue } from 'vue-property-decorator';

  @Component
export default class MobileNavLink extends Vue {
  @Prop({ default: false }) active: boolean;
}
