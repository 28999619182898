import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faExclamationTriangle, faCheck } from '@fortawesome/pro-regular-svg-icons';
import { faInfo } from '@fortawesome/pro-solid-svg-icons';

library.add(faExclamationTriangle, faCheck, faInfo);
dom.watch(); // This was the line that did the trick
Vue.component('FontAwesomeIcon', FontAwesomeIcon);

export const defaultToastOptions: any = {
  iconPack: 'custom-class',
  theme: 'outline',
  singleton: true,
  position: 'top-center',
  action: {
    text: 'X',
    onClick: (e: any, toastObject: any) => {
      toastObject.goAway(0);
    },
  },
};

export default function (contents?: string | undefined, type?: string | 'error', overrideIconClass?: string | '', duration? : number | 3000) {
  Vue.toasted.clear();
  let iconClass = '';
  if (type === 'info') {
    iconClass = 'fas fa-info mr-2';
  }
  if (type === 'success') {
    iconClass = 'far fa-check mr-2';
  }
  if (overrideIconClass) {
    iconClass = overrideIconClass;
  }
  const toastOptions: any = { ...defaultToastOptions };
  if (iconClass) {
    toastOptions.icon = { name: iconClass, after: false };
  }
  if (type && type !== 'error') {
    toastOptions.duration = duration;
  }
  if (type === 'success') {
    Vue.toasted.success(contents || 'Success!', toastOptions);
  } else if (type === 'info') {
    Vue.toasted.info(contents || 'Info here.', toastOptions);
  } else if (type === 'private') {
    Vue.toasted.error(
      contents || 'Sorry, we don’t support private BandLab accounts at this moment. Please make your account public to continue with your promotion.',
      toastOptions,
    );
  } else {
    Vue.toasted.error(contents || '🙀&nbsp; Something went wrong. Please try again.', toastOptions);
  }
}
