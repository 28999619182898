

























import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  copyForSurvey, ctaForSurvey, Survey, urlForSurvey,
} from '@/shared/lib/survey';

@Component
export default class MobileNudge extends Vue {
  @Prop({ required: true })
  survey!: Survey;

  @Prop({ default: () => {} })
  urlParams: { [key: string]: string };

  get copy() {
    return copyForSurvey(this.survey);
  }

  get cta() {
    return ctaForSurvey(this.survey);
  }

  openSurvey() {
    this.$emit('clicked');
    this.$store.dispatch('survey/dismissSurvey', this.survey);
    window.open(urlForSurvey(this.survey, this.urlParams), '_blank');
  }

  dismiss() {
    this.$emit('dismissed');
    this.$store.dispatch('survey/dismissSurvey', this.survey);
  }
}
