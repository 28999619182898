export function parseIntFromString(i: string, radix?: number): number {
  if (i) {
    return parseInt(i, radix);
  }
  return 0;
}

// eslint-disable-next-line consistent-return
export function parseDate(t: string): Date | undefined {
  if (t) {
    return new Date(t);
  }
}
