import store from '@/shared/store';
import defaultToast from '@/shared/lib/defaultToast';
import { FirebaseAuth } from '@/shared/firebase';

export default function Fetch(input: RequestInfo, init?: RequestInit): Promise<Response> {
  const user = FirebaseAuth.currentUser;
  if (user === null) {
    return Promise.reject(new Error('not logged in'));
  }
  const o: RequestInit = { ...init };
  return user.getIdToken(false).then((token) => {
    o.headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    return window.fetch(input, o).then((response) => {
      if (response.status === 401 && !user.isAnonymous) {
        return user.getIdToken(true).then(() => (
          // Token is still valid, nothing to do...
          response
        )).catch((reason) => {
          // TODO: Not convinced this is the correct place for this check.
          if (reason && reason.code === 'auth/user-token-expired') {
            setTimeout(() => {
              store.dispatch('profile/logOut').then(() => {
                defaultToast('Login credentials have been updated.  Please log in again.');
              });
            }, 0);
          }
          return response;
        });
      }
      return response;
    });
  });
}
