





































































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  mixins: [],
  components: {},
  props: {},
  validations: {},
})

export default class MaterialInputField extends Vue {
  $refs!: {
    [key: string]: any,
  };

  useAltProps: boolean = false;

  userInput: string = '';

  @Prop({ default: '' })
  value: string;

  @Prop({ default: 255 })
  maxlength: number;

  @Prop({ default: '' })
  elementId: string;

  @Prop({ default: '' })
  elementRef: string;

  @Prop({ default: false })
  disabled: boolean;

  @Prop({ default: 'off' })
  autocomplete: string;

  @Prop({ default: 'off' })
  autocorrect: string;

  @Prop({ default: 'text' })
  type: string;

  @Prop({ default: 'text' })
  altType: string;

  @Prop({ default: '' })
  placeholder: string;

  @Prop({ default: 0 })
  tabIndex: number;

  @Prop({ default: false })
  autofocus: boolean;

  @Prop({ default: '' })
  label: string;

  @Prop({ default: false })
  showRightButton: boolean;

  @Prop({ default: 'error-icon' })
  rightButtonClasses: string;

  @Prop({ default: () => [] })
  rightButtonIcon: string[];

  @Prop({ default: () => [] })
  altRightButtonIcon: string[];

  @Prop({ default: false })
  showLeftIcon: boolean;

  @Prop({ default: '' })
  leftIconClasses: string;

  @Prop({ default: () => [] })
  leftIcon: string[];

  @Prop({ default: false })
  showError: boolean;

  @Prop({ default: '' })
  errorText: string;

  @Prop({ default: 'form-field__input' })
  inputClasses: string;

  @Prop({ default: 'form-field__label' })
  labelClasses: string;

  @Prop({ default: 'error-notification form-error' })
  errorNotificationClasses: string;

  @Prop({ default: false })
  focus: boolean;

  mounted() {
    this.userInput = this.value;
    if (this.focus && this.$refs[this.elementRef]) {
      this.$nextTick(() => {
        this.$refs[this.elementRef].focus();
      });
    }
  }

  updated() {
    this.$nextTick(() => {
      if (this.userInput !== this.value) {
        this.userInput = this.value;
      }
    });
  }

  toggleAltProps() {
    if (this.rightButtonIcon.length > 0 && this.altRightButtonIcon.length > 0) {
      this.useAltProps = !this.useAltProps;
    }
    return false;
  }

  emitEvent(eventType: string, evt: any) {
    switch (eventType) {
      case 'input':
        this.$emit('input', evt.target.value);
        this.userInput = evt.target.value;
        break;
      case 'focus':
        this.$emit('focus', evt);
        break;
      case 'blur':
        this.$emit('blur', evt);
        break;
      case 'change':
        this.$emit('change', evt);
        break;
      case 'keypress':
        this.$emit('keypress', evt);
        break;
      default:
        this.$emit(eventType, evt);
    }
  }
}
