import {
  IService, Service,
} from '@/shared/gen/messages.pisa';
import { Fetch } from '@/shared/gen/twirp';
import Deferred from '@/shared/lib/deferred';

const r = new RegExp('^(?:[a-z]+:)?//', 'i');

export const API_ROOT = `${window.location.protocol}//${window.location.host}/_api`;
export const BL_ROOT = `${window.location.protocol}//${window.Zire.env?.bandlab?.domain}`;

export const BL_LOGIN = window.Zire.env?.bandlab?.loginUrl && r.test(window.Zire.env.bandlab.loginUrl)
  ? window.Zire.env.bandlab.loginUrl
  : (window.Zire.env?.bandlab?.loginUrl && `${API_ROOT}${window.Zire.env.bandlab.loginUrl}`) || undefined;

const serviceDeferred = new Deferred<IService>();

export default {
  use: (fetch: Fetch) => {
    serviceDeferred.resolve(new Service(API_ROOT, fetch));
  },
};

export function BuilderService(promise: Promise<any>, callback: (client: IService) => void): void {
  serviceDeferred.promise.then((client) => promise.then(() => { callback(client); }));
}
