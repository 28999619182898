const defaultWidth = 300;
const defaultHeight = 250;

const halfWidth = defaultWidth / 2;
const halfHeight = defaultHeight / 2;

const thumbnailWidth = 300;
const thumbnailHeight = 300;

const memberImageThumbnailWidth = 144;
const memberImageThumbnailHeight = 144;

const listingWidth = 100;
const listingHeight = 235;

const defaultBlur = 500;

interface ColorPalette {
  averageLuminance: any;
  color1?: string;
  color2?: string;
  color3?: string;
  color4?: string;
  color5?: string;
  color6?: string;
  vibrant?: string;
  vibrantDark?: string;
  vibrantLight?: string;
  muted?: string;
  mutedDark?: string;
  mutedLight?: string;
}

function withParameters(url: string, newParams: string) {
  if (url.indexOf('?') > -1) {
    return `${url}&${newParams}`;
  }
  return `${url}?${newParams}`;
}

function removeParameters(url: string, exactParams: string) {
  return url.replace(exactParams, '');
}

function getImgixSizeQuerystring(url: string, width: number, height: number) {
  const newParams = `w=${width}&h=${height}`;
  return withParameters(url, newParams);
}

function getListingThumbnailUrl(url: string, square: boolean = false) {
  return getImgixSizeQuerystring(url, square ? listingHeight : listingWidth, listingHeight);
}

function getImageThumbnailUrl(url: string) {
  return getImgixSizeQuerystring(url, thumbnailWidth, thumbnailHeight);
}

function getMemberImageThumbnailUrl(url: string) {
  return getImgixSizeQuerystring(url, memberImageThumbnailWidth, memberImageThumbnailHeight);
}

function getImageUrl(url: string) {
  return getImgixSizeQuerystring(url, defaultWidth, defaultHeight);
}

function getHalfSizeImageUrl(url: string) {
  return getImgixSizeQuerystring(url, halfWidth, halfHeight);
}

function getGrayscaleImage(url: string) {
  return withParameters(url, 'sat=-100');
}

function getFocalPointDebug(url: string) {
  return withParameters(url, 'fp-debug=true');
}

function getBlurred(url: string) {
  return withParameters(url, `blur=${defaultBlur}`);
}

function setBrightness(url: string, brightness: number) {
  return withParameters(url, `bri=${brightness}`);
}

function getRawImage(url: string) {
  let newUrl = removeParameters(url, 'crop=focalpoint');
  newUrl = removeParameters(newUrl, 'fit=crop');
  newUrl = withParameters(newUrl, 'fit=fill');
  return withParameters(newUrl, 'fill=blur');
  // newUrl = withParameters(newUrl, 'crop=left');
  // return withParameters(newUrl, 'crop=entropy');
  // return newUrl;
}

function extractColorPalette(colorPalette: any) {
  const extractedPalette: ColorPalette = {
    averageLuminance: colorPalette.averageLuminance,
  };
  [
    'color1',
    'color2',
    'color3',
    'color4',
    'color5',
    'color6',
    'vibrant',
    'vibrantDark',
    'vibrantLight',
    'muted',
    'mutedDark',
    'mutedLight',
  ].forEach((i) => {
    if (colorPalette[i]) {
      (extractedPalette as any)[i] = `#${colorPalette[i]}`;
    }
  });
  return extractedPalette;
}

export {
  getListingThumbnailUrl,
  getImageThumbnailUrl,
  getMemberImageThumbnailUrl,
  getImageUrl,
  getHalfSizeImageUrl,
  getGrayscaleImage,
  extractColorPalette,
  ColorPalette,
  getFocalPointDebug,
  getRawImage,
  getImgixSizeQuerystring,
  getBlurred,
  setBrightness,
};
