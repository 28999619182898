import {
  ActionTree, GetterTree, Module, MutationTree,
} from 'vuex';
import { RootState } from '@/shared/store/types';

export const BLANK_IMAGE_SRC = 'data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';

interface State {
  urls: string[]
}

const initialState: State = {
  urls: [],
};

const actions: ActionTree<State, RootState> = {
  dropPixel({ commit }, url: string): void {
    commit('add', url);
  },
  dropAdvertisingPixel({ dispatch, rootGetters }, url: string): void {
    if (rootGetters['cookieConsent/granted'].indexOf('advertising') === -1) {
      return;
    }
    dispatch('dropPixel', url);
  },
};

const getters: GetterTree<State, RootState> = {
  urls(state: State): string[] {
    return state.urls;
  },
};

const mutations: MutationTree<State> = {
  add(state: State, url: string) {
    state.urls = [...state.urls, url];
  },
};

export const pixels: Module<State, RootState> = {
  namespaced: true,
  state: { ...initialState },
  actions,
  getters,
  mutations,
};
