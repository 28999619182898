import {
  ActionTree, GetterTree, Module, MutationTree,
} from 'vuex';
import { RootState } from '@/shared/store/types';

export interface LayoutState {
  device: string,
  campaignsTab: number,
  webview: boolean,
}

export const initialState: LayoutState = {
  device: 'desktop',
  campaignsTab: 0,
  webview: false,
};

const actions: ActionTree<LayoutState, RootState> = {
  setDevice(context, device) {
    context.commit('setDevice', device);
  },
  setWebviewState(context, webview) {
    context.commit('setWebviewState', webview);
  },
};

const getters: GetterTree<LayoutState, RootState> = {
  isMobile(state) {
    return (state.device === 'mobile');
  },
  isTablet(state) {
    return (state.device === 'tablet');
  },
  isTouch(state) {
    return (state.device === 'tablet') || (state.device === 'mobile');
  },
  isWebview(state) {
    return state.webview;
  },
  getDevice(state) {
    return state.device;
  },
};

const mutations: MutationTree<LayoutState> = {
  setDevice(state, payload) {
    state.device = payload;
  },
  setWebviewState(state, payload) {
    state.webview = payload;
  },
};

export const layout: Module<LayoutState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
