import {
  ActionTree, GetterTree, Module, MutationTree,
} from 'vuex';
import { RootState } from '@/shared/store/types';
import { API_ROOT } from '@/shared/lib/api';

export interface Scope {
  name: string
  version: string
  display: {
    name: string
    description: string
  }
}

export interface Consent {
  name: string
  version: string
  granted: boolean
  timestamp?: number
}

interface State {
  scopes: Scope[]
  require: boolean
  consent: Consent[]
  gdpr: boolean
  bannerShown: boolean
}

const SCOPES: Scope[] = [
  {
    name: 'analytics',
    version: '2020-07-24',
    display: {
      name: 'Allow Functional Cookies',
      description: '<strong>Functional Cookies</strong> collect information that is used to help us understand how our website is being used'
        + ' or how effective our marketing campaigns are.',
    },
  },
  {
    name: 'advertising',
    version: '2020-07-24',
    display: {
      name: 'Allow Advertising Cookies',
      description: '<strong>Advertising Cookies</strong> are used to make advertising messages more relevant to you and your interests.  They'
        + ' also perform functions like preventing the same ad from continuously reappearing, ensuring that ads are'
        + ' properly displayed, and in some cases selecting advertisements that are based on your interests.',
    },
  },
];

const initialState: State = {
  scopes: SCOPES,
  require: false,
  consent: [],
  gdpr: false,
  bannerShown: false,
};

const actions: ActionTree<State, RootState> = {
  set({ commit, getters }, payload: { consent: Consent[], require?: boolean, gdpr?: boolean }): void {
    commit('set', payload);
    const cookieConsent: {[key: string]: boolean } = {};
    getters.scopes.forEach((i: Scope) => {
      cookieConsent[i.name] = getters.granted.indexOf(i.name) !== -1;
    });
    this.dispatch('googleTagManager/push', {
      event: 'setCookieConsent',
      cookieConsent,
    });
  },
  update({ dispatch }, payload: { token?: string, consent: Consent[] }): Promise<void> {
    const headers: HeadersInit = {
      'Content-Type': 'application/json',
    };
    if (payload.token) {
      headers.Authorization = `Bearer ${payload.token}`;
    }
    const request = new Request(`${API_ROOT}/consent/update`, {
      method: 'POST',
      mode: 'same-origin',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers,
      body: JSON.stringify(payload.consent),
    });
    return window.fetch(request)
      .then((response) => response.json())
      .then((consent: Consent[]) => dispatch('set', { consent }));
  },
  bannerShown({ commit }): void {
    commit('bannerShown', true);
  },
};

const getters: GetterTree<State, RootState> = {
  scopes(state: State): Scope[] {
    return state.scopes;
  },
  requireUserConsent(state: State): boolean {
    if (!state.require) {
      return false;
    }
    return state.scopes.length !== state.consent.length
      || !state.scopes.every((i) => state.consent.some((j) => i.name === j.name && i.version === j.version));
  },
  hasSavedConsent(state: State): boolean {
    return state.consent.length > 0;
  },
  gdpr(state: State): boolean {
    return state.gdpr;
  },
  granted(state: State, _getters): string[] {
    if (_getters.requireUserConsent) {
      if (_getters.gdpr) {
        return state.consent.filter((i) => i.granted && state.scopes.some((j) => i.name === j.name && i.version === j.version)).map((i) => i.name);
      }
      return state.scopes.map((i) => i.name).filter((i) => _getters.refused.indexOf(i) === -1);
    }
    return state.consent.filter((i) => i.granted).map((i) => i.name);
  },
  refused(state: State): string[] {
    return state.consent.filter((i) => !i.granted).map((i) => i.name);
  },
  bannerShown(state: State): boolean {
    return state.bannerShown;
  },
};

const mutations: MutationTree<State> = {
  set(state, payload: { consent: Consent[], require?: boolean, gdpr?: boolean }) {
    if (Object.prototype.hasOwnProperty.call(payload, 'require')) {
      state.require = payload.require!;
    }
    if (Object.prototype.hasOwnProperty.call(payload, 'gdpr')) {
      state.gdpr = payload.gdpr!;
    }
    state.consent = payload.consent;
  },
  bannerShown(state, payload: boolean) {
    state.bannerShown = payload;
  },
};

export const cookieConsent: Module<State, RootState> = {
  namespaced: true,
  state: initialState,
  actions,
  getters,
  mutations,
};
