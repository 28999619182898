import { addDays, differenceInDays } from 'date-fns';
import { Campaign } from '@/shared/store/campaign';
import { getListingThumbnailUrl } from '@/shared/lib/imgixFunctions';
import { UserAudio } from '@/shared/gen/messages.pisa';
import { IUGC } from '@/shared/store/onboarding';
import { IStatus, Status } from '@/shared/lib/campaign_status';

export enum Action {
  Cancel = 'cancel',
}

export function firstImageThumbnail(ugc: IUGC) : string | undefined {
  const firstImage = ugc.images[0];
  if (firstImage && firstImage.url) {
    return getListingThumbnailUrl(firstImage.url, true);
  }
  return undefined;
}

export function campaignImage(campaign: Campaign) : string | undefined {
  const lpImageUrls = ['spotify', 'apple', 'deezer', 'bandlab', 'other'].map((retailer) => {
    const i = campaign.landingPages.findIndex((lp) => lp.retailer === retailer);
    if (i !== -1 && campaign.landingPages[i].ogImageUrl) {
      return campaign.landingPages[i].ogImageUrl;
    }
    return '';
  }).filter(Boolean);
  const fallback = firstImageThumbnail(campaign.ugc!);
  if (lpImageUrls.length) {
    let url = lpImageUrls[0];
    if (fallback) {
      url = `${url}${url.indexOf('?') === -1 ? '?' : '&'}fallback=${encodeURIComponent(fallback)}`;
    }
    return url;
  }
  return fallback;
}

export function campaignAudio(campaign: Campaign) : UserAudio | undefined {
  return campaign.ugc!.audio[0];
}

export function daysLeft(campaign: Campaign) : number {
  if (!campaign.activatedAt || !campaign.durationDays) {
    return NaN;
  }
  const endDate = addDays(campaign.activatedAt, campaign.durationDays);
  return 1 + differenceInDays(endDate, new Date());
}

export function daysLeftText(campaign: Campaign) : string {
  const days = daysLeft(campaign);
  if (days <= 0) {
    return '< 1 day left';
  }
  if (days === 1) {
    return '1 day left';
  }
  return `${days} days left`;
}

export function stateText(state: IStatus) : string {
  switch (state) {
    case Status.Reviewing:
      return 'In Review';
    case Status.Rejected:
      return 'Needs Attention';
    case Status.Running:
      return 'Running';
    case Status.Completed:
      return 'Completed';
    case Status.Aborted:
      return 'Campaign Never Ran';
    default:
      return 'Unknown';
  }
}

export function hasFacebook(campaign: Campaign) {
  if (campaign.adNetworks.indexOf('facebook') !== -1 && campaign.facebookPage) {
    return campaign.facebookPage.pageId;
  }
  return false;
}

export function hasInstagramAccount(campaign: Campaign) {
  if (campaign.adNetworks.indexOf('instagram') !== -1 && campaign.facebookPage) {
    return campaign.facebookPage.instagram && campaign.facebookPage.instagram.id;
  }
  return false;
}

export function hasInstagram(campaign: Campaign) {
  if (campaign.adNetworks.indexOf('instagram') !== -1 && campaign.facebookPage) {
    return (campaign.facebookPage.instagram && campaign.facebookPage.instagram.id) || campaign.facebookPage.pageId;
  }
  return false;
}

export function hasSocial(campaign: Campaign) {
  return hasFacebook(campaign) || hasInstagram(campaign);
}
