



















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CallToAction extends Vue {
  @Prop({ required: true }) text!: string;

  @Prop({ required: true }) action!: () => Promise<void>;

  @Prop({ default: false, type: Boolean }) pill: boolean;

  performingAction: boolean = false;

  performAction() {
    this.performingAction = true;
    this.action().then(() => {
      this.performingAction = false;
    });
  }
}
