























































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  mixins: [],
  components: {},
  props: {},
  validations: {},
})

export default class LoadingIndicator extends Vue {
  @Prop({ default: 200 })
  size: number;
}
