
























































import { Component, Vue } from 'vue-property-decorator';
import { BModal } from 'bootstrap-vue';
import { Getter } from 'vuex-class';
import LoginModal from '@/shared/components/common/LoginModal.vue';
import VerifyEmailNotice from '@/shared/components/common/VerifyEmailNotice.vue';
import ForgotPasswordModal from '@/shared/components/common/ForgotPasswordModal.vue';
import BlacklistNotice from '@/shared/components/common/BlacklistNotice.vue';

@Component({
  components: {
    BlacklistNotice,
    VerifyEmailNotice,
    LoginModal,
    ForgotPasswordModal,
  },
})
export default class StandardLayout extends Vue {
  $refs!: {
    loginModal: BModal,
  };

  @Getter('profile/requiresEmailVerification') requiresEmailVerification: boolean;

  @Getter('profile/blacklistedCountry') blacklistedCountry: string;

  @Getter('layout/isWebview') isWebview: boolean;

  get verificationInterstitial() {
    return this.requiresEmailVerification && !this.$router.currentRoute.meta.supportsEmailVerification;
  }

  created() {
    if (window.scrollTo) {
      window.scrollTo(0, 0);
    }
  }
}
