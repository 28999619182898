import {
  ActionTree, GetterTree, Module, MutationTree,
} from 'vuex';
import { RootState } from '@/shared/store/types';
import {
  Upload as APIUpload,
  UserAudioSource,
  UserAudioSourceOperation,
  UserAudio,
  UserAudioOperation,
  Operation_Status as OperationStatus,
  UserAudioSourceRequest, UserAudioSourceResponse, UserAudioRequest, UserAudioResponse,
} from '@/shared/gen/messages.pisa';
import { BuilderService } from '@/shared/lib/api';
import { maxAudioFileSizeInB } from '@/shared/lib/defaultFileConstraints';
import { FileExceedsMaximumAllowedSizeError, Upload } from '@/shared/store/upload';

export const ErrorDetailInvalid = 'invalid';
export const ErrorDetailTooShort = 'too_short';
export const ErrorDetailTooLong = 'too_long';

export interface AudioLibrary {
  currentOperationStatus: string | null;
}

const initialState: AudioLibrary = {
  currentOperationStatus: null,
};

const actions: ActionTree<AudioLibrary, RootState> = {
  upload({
    dispatch,
    rootGetters,
  }, file: File): Promise<[string, Promise<UserAudioSource>]> {
    if (file.size > maxAudioFileSizeInB) {
      return Promise.reject(FileExceedsMaximumAllowedSizeError);
    }
    return this.dispatch('upload/uploadFile', { location: 'audio', file }).then((audioRef) => {
      const uploadObj = rootGetters['upload/uploadById'](audioRef);
      if (uploadObj.error) {
        return Promise.reject(uploadObj.error);
      }
      const uploadPromise = (rootGetters['upload/uploadPromiseById'](audioRef) as Promise<Upload>);
      return [
        audioRef,
        uploadPromise.then(({ path }) => dispatch('tagAsAudio', path)),
      ];
    });
  },
  tagAsAudio(context, payload: string): Promise<UserAudioSource> {
    return new Promise((resolve, reject) => {
      BuilderService(this.dispatch('profile/loggedIn'), (svc) => {
        // TODO: FIXME: DON'T HARDCODE INDUSTRY ID!!!
        const userAudio = new APIUpload({ file: payload, industryId: '2' });
        svc.createUserAudioSource(userAudio).then((operation: UserAudioSourceOperation) => {
          const poll = () => {
            svc.pollUserAudioSourceOperation(operation).then((op: UserAudioSourceOperation) => {
              if (op.operation.status === OperationStatus.COMPLETED) {
                resolve(op.userAudioSource);
              } else if (op.operation.status === OperationStatus.ERROR) {
                reject(op);
              } else {
                setTimeout(poll, 1000);
              }
            });
          };
          setTimeout(poll, 2000);
        }).catch(reject);
      });
    });
  },
  createPreview({ commit }, payload: UserAudio): Promise<UserAudio> {
    return new Promise<UserAudio>((resolve, reject) => {
      BuilderService(this.dispatch('profile/loggedIn'), (svc) => {
        svc.createUserAudio(payload).then((operation: UserAudioOperation) => {
          const poll = () => {
            svc.pollUserAudioOperation(operation).then((op: UserAudioOperation) => {
              if (op.operation.status === OperationStatus.COMPLETED) {
                commit('setCurrentOperationStatus', null);
                resolve(op.userAudio);
              } else if (op.operation.status === OperationStatus.ERROR) {
                commit('setCurrentOperationStatus', null);
                reject(op);
              } else {
                commit('setCurrentOperationStatus', op.operation.status);
                setTimeout(poll, 1000);
              }
            });
          };
          setTimeout(poll, 2000);
        }).catch(reject);
      });
    });
  },
  deleteAudio(context, payload: UserAudioSource): Promise<void> {
    return new Promise((resolve, reject) => {
      BuilderService(this.dispatch('profile/loggedIn'), (svc) => {
        svc.deleteUserAudioSource(payload).then(() => {
          resolve();
        }).catch(reject);
      });
    });
  },
  updateAudio(context, payload: UserAudioSource): Promise<UserAudioSource> {
    return new Promise((resolve, reject) => {
      BuilderService(this.dispatch('profile/loggedIn'), (svc) => {
        svc.updateUserAudioSource(payload).then(() => {
          resolve(payload);
        }).catch(reject);
      });
    });
  },
  getUploadedAudioSource(context, payload: UserAudioSourceRequest): Promise<UserAudioSourceResponse> {
    return new Promise((resolve, reject) => {
      BuilderService(this.dispatch('profile/loggedIn'), (svc) => {
        svc.listUserAudioSources(payload).then((audios) => {
          resolve(audios);
        }).catch(reject);
      });
    });
  },
  getUploadedAudio(context, payload: UserAudioRequest): Promise<UserAudioResponse> {
    return new Promise((resolve, reject) => {
      BuilderService(this.dispatch('profile/loggedIn'), (svc) => {
        svc.listUserAudio(payload).then((audio) => {
          resolve(audio);
        }).catch(reject);
      });
    });
  },
};

const getters: GetterTree<AudioLibrary, RootState> = {
  getCurrentStatus(state): string | null {
    if (state.currentOperationStatus) {
      return state.currentOperationStatus;
    }
    return null;
  },
};

const mutations: MutationTree<AudioLibrary> = {
  setCurrentOperationStatus(state: AudioLibrary, payload: string | null) {
    state.currentOperationStatus = payload;
  },
};

export const audioLibrary: Module<AudioLibrary, RootState> = {
  namespaced: true,
  state: initialState,
  actions,
  getters,
  mutations,
};
