




































import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { BTooltip } from 'bootstrap-vue';
import defaultErrorToast from '@/shared/lib/defaultToast';
import SimpleModal from '@/shared/components/modals/SimpleModal.vue';
import CookiePreferences from '@/shared/components/site/CookiePreferences.vue';
import { Consent, Scope } from '@/shared/store/cookieConsent';

@Component({
  components: { CookiePreferences, SimpleModal },
})
export default class CookieConsentModal extends Vue {
  $refs!: {
    [key: string]: BTooltip,
  };

  @Getter('cookieConsent/scopes') scopes: Scope[];

  @Getter('cookieConsent/granted') granted: string[];

  preferences: { [key: string]: boolean } = {};

  resetPreferences() {
    this.preferences = {};
    this.scopes.forEach((i) => {
      this.preferences[i.name] = this.granted.indexOf(i.name) !== -1;
    });
  }

  savePreferences() {
    const consent: Consent[] = this.scopes.map((i) => ({ name: i.name, version: i.version, granted: this.preferences[i.name] }));
    this.$store.dispatch('cookieConsent/update', { consent })
      .then(() => {
        this.$root.$emit('bv::hide::modal', 'cookie-consent-modal');
      })
      .catch(() => {
        defaultErrorToast('Unexpected error saving cookie preferences');
        this.resetPreferences();
      });
  }
}
