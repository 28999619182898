import {
  ActionTree, GetterTree, Module, MutationTree,
} from 'vuex';
import { RootState } from '@/shared/store/types';
import defaultErrorToast from '@/shared/lib/defaultToast';
import { BuilderService } from '@/shared/lib/api';
import {
  IIndustryConfig,
  IndustryConfigRequest,
  NetworkOption,
  // eslint-disable-next-line camelcase
  NetworkOption_BudgetRestriction,
} from '@/shared/gen/messages.pisa';
import * as budgetOptions from '@/shared/lib/budgetOptions';
import SingleFlight from '@/shared/lib/singleFlight';

export interface IndustryState {
  genres: string[];
  errors: string[];
  networkOptions: NetworkOption[],
}

export interface FullNetworkOption {
  key: string
  required: boolean
  // eslint-disable-next-line camelcase
  budgetRestrictions: NetworkOption_BudgetRestriction[]
  name: string
  copy: string
  icon: string[]
  disabled: boolean
}

export const initialState: IndustryState = {
  genres: [],
  errors: [],
  networkOptions: [],
};

const singleFlight = new SingleFlight();

const actions: ActionTree<IndustryState, RootState> = {
  loadConfigIfNecessary({ dispatch, getters }, payload: { industryId: string } = { industryId: '2' }): Promise<void> {
    if (getters.$_isLoaded) {
      return Promise.resolve();
    }
    return dispatch('loadConfig', payload);
  },
  loadConfig({ commit }, payload: { industryId: string } = { industryId: '2' }): Promise<void> {
    return singleFlight.do(`loadConfig:${payload.industryId}`, () => new Promise<void>((resolve, reject) => {
      BuilderService(this.dispatch('profile/loggedIn'), (svc) => {
        const req = new IndustryConfigRequest({
          industryId: payload.industryId,
        });
        svc.getIndustryConfig(req, {}).then((resp: IIndustryConfig) => {
          commit('setGenres', resp.genres);
          commit('setNetworkOptions', resp.networkOptions);
          resolve();
        }).catch((error: any) => {
          reject(error);
          defaultErrorToast();
          commit('setErrors', [error.message]);
        });
      });
    }));
  },
};

const getters: GetterTree<IndustryState, RootState> = {
  // TODO: This is industry specific.
  $_isLoaded(state): boolean {
    return state.genres && state.genres.length > 0 && state.networkOptions && state.networkOptions.length > 0;
  },
  genreOptions(state): string[] {
    return state.genres;
  },
  networkOptions(state) {
    return state.networkOptions;
  },
  fullNetworkOptions(state): FullNetworkOption[] {
    if (state.networkOptions) {
      return state.networkOptions.map((option) => {
        const feNetworkIndex = budgetOptions.networks.findIndex((feNetwork) => option.key === feNetwork.key);
        return {
          key: option.key,
          required: option.required,
          budgetRestrictions: option.BudgetRestrictions,
          name: budgetOptions.networks[feNetworkIndex].name,
          copy: budgetOptions.networks[feNetworkIndex].copy,
          icon: budgetOptions.networks[feNetworkIndex].icon,
          disabled: budgetOptions.networks[feNetworkIndex].disabled,
        };
      });
    }
    return [];
  },
};

const mutations: MutationTree<IndustryState> = {
  setGenres(state: IndustryState, payload: any[]) {
    state.genres = payload
      .map((genre) => genre.name)
      .sort((a: string, b: string) => a.localeCompare(b));
  },
  setNetworkOptions(state: IndustryState, payload: any[]) {
    state.networkOptions = payload.slice(0);
  },
  setErrors(state: IndustryState, payload: Array<string>) {
    state.errors = payload.slice(0);
  },
};

export const industry: Module<IndustryState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
