import {
  ActionTree, GetterTree, Module, MutationTree,
} from 'vuex';
import { RootState } from '@/shared/store/types';
import Env from '@/shared/lib/env';
import { deviceUUID, sessionUUID } from '@/shared/lib/analytics';

declare global {
  interface Window {
    dataLayer: {
      push(update: any): any;
    }
  }
}

export interface GoogleTagManagerState {
  initialized: boolean;
}

const initialState: GoogleTagManagerState = {
  initialized: false,
};

const actions: ActionTree<GoogleTagManagerState, RootState> = {
  push({ commit, getters, rootGetters }, payloads: any | any[]): void {
    const toPush = Array.isArray(payloads) ? payloads : [payloads];
    if (!getters.initialized) {
      commit('initialized');
      toPush.unshift({ deviceUUID, sessionUUID });
    }
    toPush.forEach((i: any) => {
      if (!i) {
        return;
      }
      if (!window.dataLayer && Env.debug) {
        // eslint-disable-next-line no-console
        console.log('Ignored GTM Event', i);
      }
      if (window.dataLayer) {
        window.dataLayer.push({
          ...i,
          user: {
            ...i.user,
            loggedIn: rootGetters['profile/isLoggedInWithEmail'],
          },
        });
      }
    });
  },
  trackEvent({ dispatch }, { event, data }: { event: string, data?: any }): void {
    dispatch('push', [{
      event,
      data,
    }, {
      data: undefined,
    }]);
  },
  trackView({ dispatch }, { name, path, fullPath }: { name: string, path: string, fullPath: string }): void {
    dispatch('push', {
      event: 'Virtual Page View',
      route: {
        name,
        path,
        fullPath,
      },
    });
  },
};

const getters: GetterTree<GoogleTagManagerState, RootState> = {
  initialized(state): boolean {
    return state.initialized;
  },
};

const mutations: MutationTree<GoogleTagManagerState> = {
  initialized(state) {
    state.initialized = true;
  },
};

export const googleTagManager: Module<GoogleTagManagerState, RootState> = {
  namespaced: true,
  state: initialState,
  actions,
  getters,
  mutations,
};
