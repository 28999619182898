const options = [
  {
    title: 'Short',
    duration: '1 Week',
    durationSingular: '1 Week',
    minimumBudgetCents: 1000,
    defaultOption: false,
    dayDuration: 7,
  },
  {
    title: 'Extended',
    duration: '1 Month',
    durationSingular: '1 Month',
    minimumBudgetCents: 4000,
    defaultOption: true,
    dayDuration: 30,
  },
  {
    title: 'Maximum',
    duration: '3 Months',
    durationSingular: '3 Month',
    minimumBudgetCents: 7500,
    defaultOption: false,
    dayDuration: 90,
  },
];

const networks = [
  {
    name: 'Interactive Ads',
    copy: '* Always included',
    key: 'display',
    icon: ['far', 'desktop'],
    disabled: false,
  },
  {
    name: 'Facebook Ads',
    copy: '',
    key: 'facebook',
    icon: ['fab', 'facebook'],
    disabled: false,
  },
  {
    name: 'Instagram Ads',
    copy: '',
    key: 'instagram',
    icon: ['fab', 'instagram'],
    disabled: false,
  },
];

// Impressions multiplier represents 1000 impressions per dollar.
// Calculated by taking <budget> * ((1 - <profit margin>) * <network percentage> / <estimated CPM>)
const displayImpressionsMultiplier = 0.1; // ((1 - 0.5) * 0.1 / 0.50)
const socialImpressionsMultiplier = 0.1; // ((1 - 0.5) * 0.9 / 4.50)

function estimateImpressions(budget: number) {
  const displayImpressions = budget * displayImpressionsMultiplier;
  const socialImpressions = budget * socialImpressionsMultiplier;
  return Math.floor(displayImpressions + socialImpressions) * 1000;
}

function defaultOption() {
  const optionList = options.filter((option) => option.defaultOption === true);
  if (optionList.length > 0) {
    return optionList[0];
  }
  return options[0];
}

function optionForDuration(duration: number) {
  const optionList = options.filter((option) => option.dayDuration === duration);
  if (optionList.length === 1) {
    return optionList[0];
  }
  return null;
}

function convertFromCentsToWholeDollar(budgetCents: number) {
  return Math.floor(budgetCents / 100);
}

function convertToCents(budget: number) {
  return Math.floor(budget) * 100;
}

function adNetworksCopy(networksForCopy: string[]) {
  if (networksForCopy.length === 0) {
    return '';
  }
  let copyString = '';
  networksForCopy.forEach((network, i) => {
    if (i > 0) {
      if (i >= networksForCopy.length - 1) {
        copyString += ' & ';
      } else {
        copyString += ', ';
      }
    }
    switch (network) {
      case 'display':
        copyString += 'Interactive';
        break;
      default:
        copyString += network[0].toUpperCase() + network.slice(1);
    }
  });
  copyString += ' Ads';
  return copyString;
}

const defaultBudgetCents = 30000;

const minimumBudgetCents = 4000;
const maximumBudgetCents = 100000;

const defaultDurationDays = 7;

const currency = 'USD';

export {
  options,
  networks,
  defaultOption,
  defaultBudgetCents,
  minimumBudgetCents,
  maximumBudgetCents,
  adNetworksCopy,
  optionForDuration,
  currency,
  convertFromCentsToWholeDollar,
  convertToCents,
  defaultDurationDays,
  estimateImpressions,
};
