import '@/zire/class-component-hooks';
import Vue from 'vue';

import BootstrapVue from 'bootstrap-vue';
import VueMq from 'vue-mq';
import Toasted from 'vue-toasted';
import format from 'date-fns/format';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import icons from '@/zire/icons';

import { ICurrencyAmount } from '@/shared/gen/messages.pisa';

import App from '@/zire/App.vue';
import router from '@/zire/router';
import store from '@/shared/store';
import '@/zire/registerServiceWorker';
import { FirebaseAuth } from '@/shared/firebase';
import Fetch from '@/zire/fetch';
import API from '@/shared/lib/api';
import CookieConsent from '@/shared/lib/cookieConsent';
import Debounce from '@/shared/lib/debounce';
import Facebook from '@/shared/lib/facebook';
import '@/zire/honeybadger';

Vue.use(BootstrapVue);

Vue.use(VueMq, {
  breakpoints: {
    xs: 576,
    sm: 768,
    md: 992,
    lg: 1200,
  },
});

Vue.use(Toasted, {
  duration: 5000,
  containerClass: 'toasted--zire',
});

icons.forEach((i) => library.add(i));

Vue.component('FaIcon', FontAwesomeIcon);

Vue.config.productionTip = false;

Vue.filter('formatDate', (value: string) => {
  if (value) {
    return format(new Date(value), 'MM/dd/yyyy');
  }
  return value;
});

Vue.filter('currency', (value: ICurrencyAmount) => {
  const amount = value;
  if (!amount.amount) {
    amount.amount = 0;
  }
  if (!amount.currency) {
    amount.currency = 'usd';
  }
  const s = (amount.amount / 100).toLocaleString(undefined, {
    style: 'currency',
    currency: amount.currency,
  });
  if (s.endsWith('.00')) {
    return s.slice(0, s.length - 3);
  }
  return s;
});

API.use(Fetch);

Facebook.init();

FirebaseAuth.onAuthStateChanged((u) => {
  store.dispatch('profile/onAuthStateChanged', u);
});

['profile/isLoggedInWithEmail', 'profile/userId', 'cookieConsent/granted', 'cookieConsent/refused'].forEach((i) => {
  const debouncedFn = Debounce(() => {
    if (store.getters['cookieConsent/granted'].indexOf('advertising') !== -1) {
      const userId = store.getters['profile/userId'];
      if (userId && store.getters['profile/isLoggedInWithEmail']) {
        store.dispatch(
          'pixels/dropPixel',
          `https://secure.adnxs.com/seg?member_id=10525&add_code=retarget_to_user:${userId}&remove_code=retargeting_opt_out&t=2`,
        );
      } else {
        store.dispatch(
          'pixels/dropPixel',
          'https://secure.adnxs.com/seg?member_id=10525&remove_code=retargeting_opt_out&t=2',
        );
      }
    }
    if (store.getters['cookieConsent/refused'].indexOf('advertising') !== -1) {
      store.dispatch(
        'pixels/dropPixel',
        'https://secure.adnxs.com/seg?member_id=10525&add_code=retargeting_opt_out&remove_code=retarget_to_user&t=2',
      );
    }
  }, 25);
  store.watch(() => store.getters[i], debouncedFn);
});

// Initialize cookie consent store...
if (CookieConsent) {
  store.dispatch('cookieConsent/set', CookieConsent);
}

// Only initialize the app once after we have logged in...
store.dispatch('profile/loggedIn').then(() => {
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app');
}).catch((reason) => {
  const loadingMessage = document.getElementById('app-loading__messages__error-message');
  if (loadingMessage) {
    loadingMessage.style.display = 'block';
  }
  return Promise.reject(reason);
});
