



























































import { Component, Prop, Vue } from 'vue-property-decorator';
import Honeybadger from 'honeybadger-js';

@Component({
})
export default class BuilderNavigation extends Vue {
  @Prop({ default: false })
  isProcessing: boolean;

  @Prop({ default: false })
  submitDisabled: boolean;

  @Prop({ required: true, default: () => { Honeybadger.notify('no onSubmit function defined', 'BuilderNavigationError'); } })
  onSubmit: Function;

  @Prop({ default: null })
  onBack: Function;

  @Prop({ default: 'primary' })
  buttonVariant: string;

  @Prop({ default: '' })
  extraInfo: string;

  mounted() {}

  updated() {}

  // beforeCreate() {}
}
