



















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import LoadingIndicator from '@/shared/components/campaign/LoadingIndicator.vue';

@Component({
  mixins: [],
  components: { LoadingIndicator },
  props: {},
})

export default class InputField extends Vue {
  $refs!: {
    userInputField: any,
    [key: string]: any,
  };

  @Prop({ default: 'search' })
  type: string;

  @Prop({ default: 'userInputField' })
  fieldRef: string;

  @Prop({ default: true })
  displayLeftIcon: boolean;

  @Prop({ default: () => [] })
  leftIcon: Array<String>;

  @Prop({ default: () => [] })
  rightIcon: Array<String>;

  @Prop({ default: false })
  rightIconButton: boolean;

  @Prop({ default: false })
  disabled: boolean;

  @Prop({ default: '' })
  rightIconClass: string;

  @Prop({ default: '' })
  placeholder: string;

  @Prop({ default: false })
  searching: boolean;

  @Prop({ default: false })
  searched: boolean;

  @Prop({ default: false, type: Boolean })
  valid: boolean;

  @Prop({ default: false })
  error: boolean;

  @Prop({ default: false })
  corrected: boolean;

  @Prop({ default: '' })
  value: string;

  @Prop({ default: 1 })
  tabindex: number;

  @Prop({ default: '' })
  colorClass: string;

  @Prop({ default: '' })
  containerClass: string;

  @Prop({ default: '' })
  label: string;

  @Prop({ default: false })
  autoFocus: boolean;

  @Prop({ default: false })
  keepFocusOnSubmit: boolean;

  userInput: string = '';

  pasteInput: boolean = false;

  get rightIconContainerClass() {
    const retVal: any = {
      'border-circle': !this.searching,
    };
    if (this.colorClass) {
      retVal[this.colorClass] = true;
    }
    return retVal;
  }

  mounted() {
    if (this.userInput !== this.value) {
      this.userInput = this.value;
    }
    if (this.autoFocus) {
      this.$refs[this.fieldRef].focus();
      if (this.fieldRef && this.$refs[this.fieldRef] && this.$refs[this.fieldRef].id !== document.activeElement!.id) {
        window.setTimeout(() => {
          if (this.fieldRef && this.$refs[this.fieldRef] && this.$refs[this.fieldRef].id !== document.activeElement!.id) {
            this.$refs[this.fieldRef].focus();
          }
        }, 100);
      }
    }
  }

  updated() {
    if (this.userInput !== this.value) {
      this.userInput = this.value;
    }
  }

  emitEvent(eventType: string, evt: any) {
    switch (eventType) {
      case 'input':
        this.$emit('input', evt.target.value);
        if (this.pasteInput) {
          this.pasteInput = false;
          this.$emit('paste', evt);
        }
        break;
      case 'blur':
        this.$emit('blur', evt);
        break;
      case 'submit':
        this.$emit('submit', evt);
        if (!this.keepFocusOnSubmit) {
          evt.target.blur();
        }
        break;
      case 'paste':
        // this.$emit('paste', evt);
        this.pasteInput = true;
        break;
      default:
        this.$emit(eventType, evt);
    }
  }
  // created() {}

  // beforeCreate() {}

  handleClick(evt: any) {
    if (this.searched) {
      this.userInput = '';
      this.$emit('clear', evt);
      this.$refs[this.fieldRef].focus();
    } else {
      this.$emit('submit', evt);
      evt.target.blur();
      if (this.keepFocusOnSubmit) {
        this.$refs[this.fieldRef].focus();
      }
    }
  }
}
