import {
  ActionTree, GetterTree, Module, MutationTree,
} from 'vuex';
import Honeybadger from 'honeybadger-js';
import { RootState } from '@/shared/store/types';
import {
  Draft as APIDraft,
  LandingPage as APILandingPage,
  UGC as APIUGC,
  UserAudio,
  UserImage,
  MemberImage,
  FacebookPage,
} from '@/shared/gen/messages.pisa';
import {
  defaultBudgetCents,
  defaultDurationDays,
  currency,
} from '@/shared/lib/budgetOptions';
import defaultErrorToast from '@/shared/lib/defaultToast';
import { BuilderService } from '@/shared/lib/api';
import Deferred from '@/shared/lib/deferred';

export const BUILDER_SOURCE_SEARCH = 'search';
export const BUILDER_SOURCE_MANUAL = 'manual';

export interface IUGC {
  fields: { [key: string]: any; }
  images: UserImage[]
  memberImages: MemberImage[]
  audio: UserAudio[]
}

export interface AudioSource {
  url: string
  startMs: number
}

export interface Draft {
  id: string;
  ugc?: IUGC;
  imageURL?: string;
  landingPages: APILandingPage[];
  budgetCents: number;
  currency: string;
  durationDays: number;
  builderSource?: string;
  isDraft: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  facebookPage?: FacebookPage;
  adNetworks: string[];
  source?: string;
}

export interface DraftState {
  draft: Draft;
  processing: boolean;
  errors: string[];
  couponCode: string;
  processedImages?: string[];
  createPreviewID: number;
  audioPreviewGenerating: boolean;
  audioSource: AudioSource | null;
  accessedThrough?: string;
}

export const initialState: DraftState = {
  draft: {
    id: '0',
    ugc: new APIUGC({
      fields: {},
      images: [],
      audio: [],
      memberImages: [],
    }),
    imageURL: '',
    landingPages: [],
    budgetCents: defaultBudgetCents,
    currency,
    durationDays: defaultDurationDays,
    builderSource: undefined,
    isDraft: false,
    facebookPage: undefined,
    adNetworks: ['display'],
    source: '',
  },
  processedImages: [],
  processing: false,
  errors: [],
  couponCode: '',
  createPreviewID: 0,
  audioPreviewGenerating: false,
  audioSource: null,
  accessedThrough: undefined,
};

let createPreviewDeferred: Deferred<void> | null = null;

export function extractHostname(url: string) {
  const regEx = new RegExp('^(?:[a-zA-Z]+://)?([^?/:]+)');

  const host = url.match(regEx);
  if (host) {
    return host[1];
  }
  return '';
}

export function extractDomainFromHostname(url: string) {
  const domainParts = url.split('.');
  return domainParts.splice(-2).join('.');
}

export function normalizeDraft(draft: APIDraft) : Draft {
  return {
    id: draft.id || initialState.draft.id,
    ugc: draft.ugc,
    // imageURL: draft.imageUrl,
    landingPages: draft.landingPages || initialState.draft.landingPages,
    budgetCents: draft.budgetCents || initialState.draft.budgetCents,
    currency: draft.currency || initialState.draft.currency,
    durationDays: draft.durationDays || initialState.draft.durationDays,
    createdAt: new Date(draft.createdAt),
    updatedAt: new Date(draft.updatedAt),
    isDraft: draft.isDraft || initialState.draft.isDraft,
    builderSource: draft.builderSource || initialState.draft.builderSource,
    facebookPage: draft.facebookPage || initialState.draft.facebookPage,
    adNetworks: draft.adNetworks || initialState.draft.adNetworks,
    source: draft.source || initialState.draft.source,
  };
}

const AbortReason = 'zire:aborted';

const actions: ActionTree<DraftState, RootState> = {
  load({ getters, commit, rootGetters }, payload?: string): Promise<void> {
    return new Promise((resolve, reject) => {
      // TODO: we probably want to reset the draft to make sure we've got the same state between
      // the API and client, it's not urgently necessary, but would probably make things cleaner
      // theoretically someone could start a draft and then load an existing draft and they might
      // have some weirdness with the state in the stores - I don't think this will happen, but
      // better to be sure
      // commit('resetDraft');
      BuilderService(this.dispatch('profile/loggedIn'), (svc) => {
        let draftId = payload || '0';
        if ((!payload || payload === '0') && getters.getDraftId) {
          draftId = getters.getDraftId;
        }
        // TODO: FIXME: Should this just reject if the ID is 0?
        const getDraft = new APIDraft({ id: draftId });
        svc.loadDraft(getDraft, {})
          .then((draft) => {
            const localDraft = normalizeDraft(draft);
            commit('draftLoaded', localDraft);
            if (rootGetters['imageLibrary/getLibraryCount'] === 0) {
              // Pre-load the library...
              this.dispatch('imageLibrary/loadLibrary');
            }
            resolve();
          }).catch((error: any) => {
            reject(error);
            defaultErrorToast();
            // Vue.toasted.error(error.message);
            commit('setErrors', [error.message]);
          });
      });
    });
  },
  save({ commit, getters }): Promise<void> {
    const payload: Draft = getters.getDraft;
    return new Promise((resolve, reject) => {
      BuilderService(this.dispatch('profile/loggedIn'), (svc) => {
        const apiDraft = new APIDraft({
          id: payload.id,
          imageUrl: payload.imageURL,
          landingPages: payload.landingPages,
          ugc: new APIUGC({
            fields: payload.ugc!.fields,
            images: payload.ugc!.images,
            memberImages: payload.ugc!.memberImages,
            audio: payload.ugc!.audio,
          }),
          budgetCents: payload.budgetCents,
          currency: payload.currency,
          durationDays: payload.durationDays,
          builderSource: payload.builderSource,
          isDraft: payload.isDraft,
          facebookPage: new FacebookPage({
            pageId: payload.facebookPage && payload.facebookPage.pageId ? payload.facebookPage!.pageId : '',
          }),
          adNetworks: payload.adNetworks,
          source: payload.source,
        });
        svc.saveDraft(apiDraft).then((responsePayload) => {
          const localDraft: Draft = normalizeDraft(responsePayload);
          commit('draftLoaded', localDraft);
          commit('setErrors', []);
          resolve();
        }).catch((error) => {
          defaultErrorToast();
          // Vue.toasted.error(error.message);
          commit('setErrors', [error.message]);
          reject(error);
        });
      });
    });
  },
  saveUserAudioToUGC({ state, commit }, payload: UserAudio[]): void {
    if (state.draft.ugc) {
      commit('updateUGCAudio', payload);
    }
  },
  saveImagesToUGC({ state, commit }, payload: UserImage[]): void {
    if (state.draft.ugc) {
      commit('updateUGCImages', payload);
    }
  },
  updateUGCImages({ commit }, payload: object[]): void {
    commit('updateUGCImages', payload);
  },
  saveMemberImagesToUGC({ state, commit }, payload: MemberImage[]): void {
    if (state.draft.ugc) {
      commit('updateUGCMemberImages', payload);
    }
  },
  updateUGCMemberImages({ commit }, payload: object[]): void {
    commit('updateUGCMemberImages', payload);
  },
  updateUGC({ commit }, payload: object): void {
    commit('updateUGC', payload);
  },
  updateSource({ commit }, source: string): void {
    commit('updateSource', source);
  },
  resetDraft({ commit }): Promise<void> {
    return new Promise((resolve) => {
      commit('resetDraft');
      resolve();
    });
  },
  delete({ commit }, payload?: string): Promise<void> {
    return new Promise((resolve, reject) => {
      BuilderService(this.dispatch('profile/loggedIn'), (svc) => {
        if (!payload) {
          reject(new Error('no draft ID provided'));
          defaultErrorToast();
          return;
        }
        const toDelete = new APIDraft({ id: payload });
        svc.deleteDraft(toDelete, {})
          .then(() => {
            commit('resetDraft');
            resolve();
          }).catch((error: any) => {
            reject(error);
            defaultErrorToast();
            // Vue.toasted.error(error.message);
            commit('setErrors', [error.message]);
          });
      });
    });
  },
  addCampaignLandingPage({ commit }, payload: APILandingPage): void {
    commit('addCampaignLandingPage', payload);
  },
  removeCampaignLandingPage({ commit }, payload: APILandingPage): void {
    commit('removeCampaignLandingPage', payload);
  },
  clearCampaignLandingPages({ commit }): void {
    commit('clearCampaignLandingPages');
  },
  clearAudio({ commit, dispatch }) {
    commit('updateUGCAudio', []);
    return dispatch('cancelCreatePreview');
  },
  setImageUrl({ commit }, payload: string): void {
    commit('setImageUrl', payload);
  },
  setBudget({ commit }, payload: { budgetCents: number, currency: string, durationDays: number, adNetworks: string[] }): void {
    commit('setBudget', payload);
  },
  markImageProcessed({ commit }, payload: string): void {
    commit('markImageProcessed', payload);
  },
  unMarkImageProcessed({ commit }, payload: string): void {
    commit('unMarkImageProcessed', payload);
  },
  setBuilderSourceType({ commit }, payload: string | undefined): void {
    commit('setBuilderSourceType', payload);
  },
  setAccessedThrough({ commit }, payload: string): void {
    commit('setAccessedThrough', payload);
  },
  clear({ commit }): Promise<void> {
    commit('clearDraft');
    commit('setBuilderSourceType', undefined);
    commit('clearImageProcessedList');
    commit('setCouponCode', '');
    return Promise.resolve();
  },
  completeOnboardingAndSave({ commit, dispatch }) {
    commit('completeOnboarding');
    return dispatch('save');
  },
  createPreview({ commit, getters }, payload: UserAudio): Promise<void> {
    const localCreatePreviewID = getters.createPreviewID + 1;
    commit('setCreatePreviewID', localCreatePreviewID);
    commit('setAudioPreviewGenerating', true);
    commit('setAudioSource', {
      url: payload.source.url,
      startMs: payload.startMs,
    });
    if (createPreviewDeferred === null) {
      createPreviewDeferred = new Deferred<void>();
    }
    this.dispatch('audioLibrary/createPreview', payload).then((returnedAudio: UserAudio) => {
      if (localCreatePreviewID !== getters.createPreviewID) {
        return Promise.reject(AbortReason);
      }
      commit('updateUGCAudio', [returnedAudio]);
      commit('setAudioPreviewGenerating', false);
      commit('setAudioSource', null);
      return this.dispatch('onboarding/save');
    }, (error) => {
      if (localCreatePreviewID === getters.createPreviewID) {
        commit('setAudioPreviewGenerating', false);
        commit('setAudioSource', null);
      }
      return Promise.reject(error);
    }).then(() => {
      if (localCreatePreviewID === getters.createPreviewID) {
        createPreviewDeferred!.resolve();
        createPreviewDeferred = null;
      }
    }).catch((error) => {
      if (localCreatePreviewID !== getters.createPreviewID) {
        return;
      }
      if (error === AbortReason) {
        if (createPreviewDeferred) {
          createPreviewDeferred.reject();
          createPreviewDeferred = null;
        }
        // Aborted...
        return;
      }
      if (createPreviewDeferred) {
        createPreviewDeferred.reject(error);
        createPreviewDeferred = null;
      }
      const context: any = { user_audio_source_id: payload.source.id };
      if (error && error.operation && error.operation.status) {
        // Rejected error is a UserAudioOperation...
        context.operation = error.operation;
        Honeybadger.notify('Failed to generate preview', 'CreatePreviewError', { context });
      } else {
        Honeybadger.notify(error, 'CreatePreviewError', { context });
      }
      defaultErrorToast('Failed to generate preview');
      commit('setErrors', ['Failed to generate preview']);
    });
    return createPreviewDeferred.promise;
  },
  createPreviewPromise(): Promise<void> {
    return (createPreviewDeferred && createPreviewDeferred.promise) || Promise.resolve();
  },
  cancelCreatePreview({ commit, getters }) {
    commit('setCreatePreviewID', getters.createPreviewID + 1);
    commit('setAudioPreviewGenerating', false);
    commit('setAudioSource', null);
    if (createPreviewDeferred) {
      createPreviewDeferred.reject();
      createPreviewDeferred = null;
    }
  },
  setCouponCode({ commit }, payload) {
    commit('setCouponCode', payload);
  },
  setFacebookPage({ commit }, payload) {
    commit('setFacebookPage', payload);
  },
};

const getters: GetterTree<DraftState, RootState> = {
  serializedDraft(state): string {
    return JSON.stringify(state.draft);
  },
  getDraft(state): Draft {
    return { ...state.draft };
  },
  getDraftId(state): string {
    return state.draft.id;
  },
  getDraftImages(state): UserImage[] {
    if (state.draft.ugc) {
      return state.draft.ugc.images;
    }
    return [];
  },
  getNumDraftImages(state): number {
    if (state.draft.ugc) {
      return state.draft.ugc.images.length;
    }
    return 0;
  },
  getDraftMemberImages(state): MemberImage[] {
    if (state.draft.ugc) {
      return state.draft.ugc.memberImages;
    }
    return [];
  },
  getNumDraftMemberImages(state): number {
    if (state.draft.ugc) {
      return state.draft.ugc.memberImages.length;
    }
    return 0;
  },
  getDraftAudio(state): UserAudio[] {
    if (state.draft.ugc) {
      return state.draft.ugc.audio;
    }
    return [];
  },
  draftHasAudio(state): boolean {
    let numDraftAudio = 0;
    if (state.draft.ugc) {
      numDraftAudio = state.draft.ugc.audio.length;
    }
    return (numDraftAudio > 0 || state.audioPreviewGenerating);
  },
  getNumDraftAudio(state): Number {
    if (state.draft.ugc) {
      return state.draft.ugc.audio.length;
    }
    return 0;
  },
  getDraftAudioUrl(state): string {
    if (state.draft.ugc && state.draft.ugc.audio[0]) {
      return state.draft.ugc.audio[0].url;
    }
    return '';
  },
  getDraftAudioSourceUrl(state): string {
    if (state.audioSource && state.audioSource.url) {
      return state.audioSource.url;
    }
    return '';
  },
  getDraftAudioStartTimeMs(state): number {
    if (state.audioSource && state.audioSource.startMs) {
      return state.audioSource.startMs;
    }
    return 0;
  },
  getErrors(state): Array<string> {
    return state.errors.slice(0);
  },
  isProcessing(state): boolean {
    return state.processing;
  },
  getUGC(state) {
    return (key: string): string => {
      if (state.draft.ugc && state.draft.ugc.fields[key]) {
        return state.draft.ugc.fields[key];
      }
      return '';
    };
  },
  getIsImageProcessed(state) {
    return (key: string): boolean => {
      if (state && state.processedImages) {
        return (state.processedImages.indexOf(key) > -1);
      }
      return false;
    };
  },
  numProcessedImages(state) {
    if (state.processedImages) {
      return state.processedImages.length;
    }
    return 0;
  },
  getBuilderSource(state): string | undefined {
    return state.draft.builderSource;
  },
  getAccessedThrough(state): string | undefined {
    return state.accessedThrough;
  },
  getLandingPageDomains(state): string[] {
    const lps = state.draft.landingPages;
    const lpDomains: string[] = [];
    lps.forEach((lp) => {
      const { url } = lp;
      lpDomains.push(extractHostname(url));
    });
    return lpDomains;
  },
  getCouponCode(state): string {
    return state.couponCode;
  },
  createPreviewID(state: DraftState): number {
    return state.createPreviewID;
  },
  getFacebookPage(state): FacebookPage | undefined {
    return state.draft.facebookPage;
  },
  getAdNetworks(state): string[] {
    return state.draft.adNetworks;
  },
  getValidAdNetworks(state, _getters, _rootState, rootGetters): string[] {
    const fullNetworkList = rootGetters['industry/fullNetworkOptions'];
    if (fullNetworkList.length > 0) {
      return state.draft.adNetworks.filter((networkKey) => {
        const networkIndex = fullNetworkList.findIndex((network: any) => network.key === networkKey);
        if (fullNetworkList[networkIndex].budgetRestrictions && fullNetworkList[networkIndex].budgetRestrictions.length > 0) {
          const budgetRestrictionIndex = fullNetworkList[networkIndex].budgetRestrictions.findIndex(
            (restriction: any) => restriction.durationDays === state.draft.durationDays,
          );

          const requiredMinBudget = fullNetworkList[networkIndex].budgetRestrictions[budgetRestrictionIndex].minimum.amount;

          return state.draft.budgetCents >= requiredMinBudget;
        }
        return false;
      });
    }
    return [];
  },
};

const mutations: MutationTree<DraftState> = {
  draftLoaded(state: DraftState, payload: Draft) {
    state.draft = { ...initialState.draft, ...state.draft, ...payload };
    if (payload.ugc!.audio && payload.ugc!.audio.length > 0) {
      state.audioSource = {
        url: payload.ugc!.audio[0].source.url,
        startMs: payload.ugc!.audio[0].startMs,
      };
    }
  },
  setErrors(state: DraftState, payload: Array<string>) {
    state.errors = payload.slice(0);
  },
  setProcessing(state: DraftState, payload: boolean) {
    state.processing = payload;
  },
  updateUGC(state: DraftState, payload: any) {
    if (state.draft.ugc) {
      state.draft.ugc.fields[payload.Key] = payload.Value;
    }
  },
  updateUGCImages(state: DraftState, payload: UserImage[]) {
    if (state.draft.ugc) {
      state.draft.ugc.images = payload;
    }
  },
  updateUGCMemberImages(state: DraftState, payload: MemberImage[]) {
    if (state.draft.ugc) {
      state.draft.ugc.memberImages = payload;
    }
  },
  updateUGCAudio(state: DraftState, payload: UserAudio[]) {
    if (state.draft.ugc) {
      state.draft.ugc.audio = payload;
    }
  },
  updateSource(state: DraftState, source: string) {
    state.draft.source = source;
  },
  revertChangeToDraft(state: DraftState, payload: any) {
    if (state.draft.ugc) {
      state.draft = payload;
    }
  },
  // TODO: FIXME: THIS DOESN'T FULLY RESET!  WHEN CREATING A NEW DRAFT SOME UGC DATA FROM THE MOST
  //   RECENTLY LOADED DRAFT IS VISIBLE IN THE UI!
  resetDraft(state: DraftState) {
    state.draft = { ...initialState.draft };
  },
  addCampaignLandingPage(state: DraftState, payload: APILandingPage) {
    state.draft.landingPages = [...state.draft.landingPages, payload];
  },
  removeCampaignLandingPage(state: DraftState, payload: APILandingPage) {
    if (state.draft.landingPages.length !== 0) {
      state.draft.landingPages = state.draft.landingPages.filter((i) => i !== payload);
    }
  },
  clearCampaignLandingPages(state: DraftState) {
    state.draft.landingPages = [];
  },
  setImageUrl(state: DraftState, payload: string) {
    state.draft.imageURL = payload;
  },
  setBudget(state: DraftState, payload: { budgetCents: number, currency: string, durationDays: number, adNetworks: string[] }) {
    state.draft.budgetCents = payload.budgetCents;
    state.draft.currency = payload.currency;
    state.draft.durationDays = payload.durationDays;
    state.draft.adNetworks = payload.adNetworks;
  },
  clearDraft(state: DraftState) {
    const blankSlate = {
      id: '0',
      ugc: new APIUGC({ fields: {}, images: [], audio: [] }),
      imageURL: '',
      landingPages: [],
      budgetCents: defaultBudgetCents,
      currency,
      durationDays: defaultDurationDays,
      couponCode: '',
      builderSource: undefined,
      isDraft: false,
      facebookPage: undefined,
      adNetworks: ['display'],
    };
    state.draft = { ...blankSlate };
    state.errors = [];
    state.processing = false;
    state.audioPreviewGenerating = false;
    state.audioSource = null;
    state.accessedThrough = undefined;
  },
  markImageProcessed(state: DraftState, payload: string) {
    if (state.draft && state.processedImages && state.processedImages.filter((imageID) => imageID === payload)) {
      state.processedImages = [...state.processedImages, payload];
    }
  },
  unMarkImageProcessed(state: DraftState, payload: string) {
    if (state && state.processedImages) {
      state.processedImages = state.processedImages.filter((i) => i !== payload);
    }
  },
  clearImageProcessedList(state: DraftState) {
    state.processedImages = [];
  },
  setBuilderSourceType(state: DraftState, payload: string | undefined) {
    state.draft.builderSource = payload;
  },
  setAccessedThrough(state: DraftState, payload: string) {
    state.accessedThrough = payload;
  },
  completeOnboarding(state: DraftState) {
    state.draft.isDraft = true;
  },
  setAudioPreviewGenerating(state: DraftState, payload: boolean) {
    state.audioPreviewGenerating = payload;
  },
  setCreatePreviewID(state: DraftState, payload: number) {
    state.createPreviewID = payload;
  },
  setAudioSource(state: DraftState, payload: AudioSource | null) {
    if (payload) {
      state.audioSource = { ...payload };
    } else {
      state.audioSource = null;
    }
  },
  setCouponCode(state: DraftState, payload: string) {
    state.couponCode = payload;
  },
  setFacebookPage(state: DraftState, payload: FacebookPage) {
    state.draft.facebookPage = payload;
  },
};

export const onboarding: Module<DraftState, RootState> = {
  namespaced: true,
  state: { ...initialState },
  getters,
  actions,
  mutations,
};
