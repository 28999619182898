var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"input-field",class:_vm.containerClass},[(_vm.leftIcon.length > 0 && _vm.displayLeftIcon)?_c('div',{staticClass:"icon-container icon--left",attrs:{"alt":"exit"},on:{"click":function($event){return _vm.emitEvent('left-icon-click', $event)}}},[_c('div',{staticClass:"no-border"},[_c('fa-icon',{staticClass:"fa-1x icon",attrs:{"icon":_vm.leftIcon}})],1)]):_vm._e(),_c('form',{attrs:{"action":"javascript:","autocomplete":"off"}},[((_vm.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userInput),expression:"userInput"}],ref:_vm.fieldRef,staticClass:"user-input",class:{
        'is-invalid is-invalid--user-input-mask': (!_vm.valid && _vm.searched && !_vm.searching),
        glow: !_vm.autoFocus && !_vm.error && _vm.valid,
        corrected: _vm.valid && _vm.searched,
      },style:(_vm.displayLeftIcon ? 'padding-left: 50px;' : ''),attrs:{"id":_vm.fieldRef,"name":"release-search","placeholder":_vm.placeholder,"autofocus":_vm.autoFocus,"disabled":_vm.disabled,"tabindex":_vm.tabindex,"autocomplete":"off","autocorrect":"off","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.userInput)?_vm._i(_vm.userInput,null)>-1:(_vm.userInput)},on:{"input":function($event){return _vm.emitEvent('input', $event)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();return _vm.emitEvent('submit', $event)},"blur":function($event){return _vm.emitEvent('blur', $event)},"paste":function($event){return _vm.emitEvent('paste', $event)},"focus":function($event){return _vm.emitEvent('focus', $event)},"click":function($event){return _vm.emitEvent('click', $event)},"touchend":function($event){return _vm.emitEvent('touchend', $event)},"change":function($event){var $$a=_vm.userInput,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.userInput=$$a.concat([$$v]))}else{$$i>-1&&(_vm.userInput=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.userInput=$$c}}}}):((_vm.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userInput),expression:"userInput"}],ref:_vm.fieldRef,staticClass:"user-input",class:{
        'is-invalid is-invalid--user-input-mask': (!_vm.valid && _vm.searched && !_vm.searching),
        glow: !_vm.autoFocus && !_vm.error && _vm.valid,
        corrected: _vm.valid && _vm.searched,
      },style:(_vm.displayLeftIcon ? 'padding-left: 50px;' : ''),attrs:{"id":_vm.fieldRef,"name":"release-search","placeholder":_vm.placeholder,"autofocus":_vm.autoFocus,"disabled":_vm.disabled,"tabindex":_vm.tabindex,"autocomplete":"off","autocorrect":"off","type":"radio"},domProps:{"checked":_vm._q(_vm.userInput,null)},on:{"input":function($event){return _vm.emitEvent('input', $event)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();return _vm.emitEvent('submit', $event)},"blur":function($event){return _vm.emitEvent('blur', $event)},"paste":function($event){return _vm.emitEvent('paste', $event)},"focus":function($event){return _vm.emitEvent('focus', $event)},"click":function($event){return _vm.emitEvent('click', $event)},"touchend":function($event){return _vm.emitEvent('touchend', $event)},"change":function($event){_vm.userInput=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userInput),expression:"userInput"}],ref:_vm.fieldRef,staticClass:"user-input",class:{
        'is-invalid is-invalid--user-input-mask': (!_vm.valid && _vm.searched && !_vm.searching),
        glow: !_vm.autoFocus && !_vm.error && _vm.valid,
        corrected: _vm.valid && _vm.searched,
      },style:(_vm.displayLeftIcon ? 'padding-left: 50px;' : ''),attrs:{"id":_vm.fieldRef,"name":"release-search","placeholder":_vm.placeholder,"autofocus":_vm.autoFocus,"disabled":_vm.disabled,"tabindex":_vm.tabindex,"autocomplete":"off","autocorrect":"off","type":_vm.type},domProps:{"value":(_vm.userInput)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.userInput=$event.target.value},function($event){return _vm.emitEvent('input', $event)}],"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();return _vm.emitEvent('submit', $event)},"blur":function($event){return _vm.emitEvent('blur', $event)},"paste":function($event){return _vm.emitEvent('paste', $event)},"focus":function($event){return _vm.emitEvent('focus', $event)},"click":function($event){return _vm.emitEvent('click', $event)},"touchend":function($event){return _vm.emitEvent('touchend', $event)}}}),(_vm.label)?_c('label',{staticClass:"form-field__label",attrs:{"for":_vm.fieldRef}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e()]),(_vm.rightIcon.length > 0)?_c('div',{staticClass:"icon-container icon--right",class:{
      valid: _vm.valid,
      searched: _vm.searched,
    },attrs:{"tabindex":"2","alt":"search"},on:{"click":_vm.handleClick,"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();return _vm.handleClick($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }$event.stopPropagation();return _vm.handleClick($event)}]}},[_c('div',{class:_vm.rightIconContainerClass},[_c('fa-icon',{staticClass:"fa-1x icon",class:_vm.rightIconClass,attrs:{"icon":_vm.rightIcon}})],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }