



























































import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import GridLayout from '@/shared/components/builder/GridLayout.vue';
import LoadingIndicator from '@/shared/components/common/LoadingIndicator.vue';
import defaultToast from '@/shared/lib/defaultToast';

@Component({
  components: {
    GridLayout,
    LoadingIndicator,
  },
})
export default class UserActions extends Vue {
  @Getter('profile/isLoggedInWithEmail') isLoggedInWithEmail: boolean;

  showLoading: boolean = true;

  message = '';

  showResend = false;

  resendEmail() {
    this.$store.dispatch('profile/resendVerifyEmail');
  }

  mounted() {
    switch (this.$route.query.mode) {
      case 'verifyEmail':
        this.message = 'Verifying your email address...';
        this.$store.dispatch('profile/verifyEmail', {
          userId: this.$route.query.user_id,
          token: this.$route.query.code,
        }).then(() => {
          defaultToast('🤩 Your email has been verified!', 'success');
          this.$router.push('/');
        }).catch((reason) => {
          this.message = 'Failed to verify your email address.';
          this.showResend = this.isLoggedInWithEmail;
          if (reason && reason.code) {
            if (reason.code === 'invalid_argument' && reason.meta.argument === 'token') {
              this.message = 'The verification link is invalid.  This can happen if the link is malformed, expired, or has already been used.';
            }
          }
        }).finally(() => {
          this.showLoading = false;
        });
        break;
      default:
        this.$router.push('/');
    }
  }
}
