import {
  ActionTree, GetterTree, Module, MutationTree,
} from 'vuex';
import { RootState } from '@/shared/store/types';
import {
  PaginationRequest, UserImageRequest, UserImageResponse, Upload as APIUpload, UserImage,
} from '@/shared/gen/messages.pisa';
import defaultErrorToast from '@/shared/lib/defaultToast';
import { BuilderService } from '@/shared/lib/api';
import { maxImageFileSizeInB } from '@/shared/lib/defaultFileConstraints';
import { FileExceedsMaximumAllowedSizeError, Upload } from '@/shared/store/upload';

export const InvalidImageDimensionsError = 'Image has invalid dimensions.';
export const MaxImagesToSelect = 50;

export function newImageLimitReachedError(limit: number) {
  return {
    code: 'image_limit_reached',
    message: `You may choose up to ${limit} images.`,
    meta: {
      limit,
    },
  };
}

export interface ImageLibrary {
  images: UserImage[];
  imageUploadsInProgress: number;
  totalImages: number;
  uploadErrors: UploadError[];
}

export interface UploadError {
  name: string;
  error: string;
}

export interface LoadLibraryParams {
  page?: number;
  perPage?: number;
}

const initialState: ImageLibrary = {
  images: [],
  imageUploadsInProgress: 0,
  totalImages: 0,
  uploadErrors: [],
};

const imagesPerPage: number = 65;
let loadingPromise: Promise<any> | null = null;

const actions: ActionTree<ImageLibrary, RootState> = {
  uploadToLibrary({
    commit,
    rootGetters,
    dispatch,
  }, file: File): Promise<UserImage> {
    if (file.size > maxImageFileSizeInB) {
      commit('addUploadError', { name: file.name, error: FileExceedsMaximumAllowedSizeError });
      return Promise.reject(FileExceedsMaximumAllowedSizeError);
    }
    return this.dispatch('upload/uploadFile', { location: 'images', file }).then((imageRef) => (
      (rootGetters['upload/uploadPromiseById'](imageRef) as Promise<Upload>).then(({ path }) => (
        dispatch('tagAsImage', path)
      )).catch((error: any) => {
        commit('addUploadError', { name: file.name, error });
        return Promise.reject(error);
      })
    ));
  },
  loadLibrary({ commit }, payload?: LoadLibraryParams): Promise<null> {
    const page = (payload || {}).page || 1;
    const perPage = (payload || {}).perPage || imagesPerPage;
    if (!loadingPromise) {
      loadingPromise = Promise.resolve();
    }
    loadingPromise.finally(() => {
      loadingPromise = new Promise<void>((resolve, reject) => {
        BuilderService(this.dispatch('profile/loggedIn'), (svc) => {
          const pagination = new PaginationRequest({ page, perPage });
          const request = new UserImageRequest({ pagination });
          svc.listUserImages(request).then((response) => {
            commit('libraryLoaded', response);
            resolve();
          }).catch((error: any) => {
            reject(error);
            defaultErrorToast();
          });
        });
      }).finally(() => {
        loadingPromise = null;
      });
    });
    return loadingPromise;
  },
  loadNextPage({ getters, dispatch }): Promise<null> {
    if (!loadingPromise) {
      loadingPromise = Promise.resolve();
    }
    loadingPromise.finally(() => {
      const nextPage = 1 + Math.floor(getters.getLibraryCount / imagesPerPage);
      loadingPromise = dispatch('loadLibrary', { page: nextPage });
    });
    return loadingPromise;
  },
  tagAsImage({ commit, getters }, payload: string): Promise<null> {
    return new Promise((resolve, reject) => {
      BuilderService(this.dispatch('profile/loggedIn'), (svc) => {
        // TODO: FIXME: DON'T HARDCODE INDUSTRY ID!!!
        const userImage = new APIUpload({ file: payload, industryId: '2' });
        commit('incrementImageUploadsInProgress');
        svc.createUserImage(userImage).then((image) => {
          commit('newImageLoaded', image);
          resolve(getters.getImageByID(image.id));
        }).catch(reject).finally(() => {
          commit('decrementImageUploadsInProgress');
        });
      });
    });
  },
  clearUploadErrors({ commit }): void {
    commit('clearUploadErrors');
  },
};

const getters: GetterTree<ImageLibrary, RootState> = {
  getLibrary(state): UserImage[] {
    return state.images.slice(0);
  },
  getLibraryCount(state): number {
    return state.images.length;
  },
  getImageByID(state): (_: string) => UserImage|undefined {
    return (id: string): UserImage|undefined => (state.images.find((i) => i.id === id));
  },
  getTotalImages(state): number {
    return state.totalImages;
  },
  numberPerPage(): number {
    return imagesPerPage;
  },
  imageUploadsInProgress(state): number {
    return state.imageUploadsInProgress;
  },
  getUploadErrors(state): UploadError[] {
    return state.uploadErrors.slice(0);
  },
  isUploading(state): boolean {
    return state.imageUploadsInProgress > 0;
  },
};

const mutations: MutationTree<ImageLibrary> = {
  libraryLoaded(state: ImageLibrary, payload: UserImageResponse) {
    const { images, pagination } = payload;
    state.totalImages = pagination.totalEntries;
    state.images = state.images.slice(0, pagination.page * pagination.perPage);
    images.forEach((image) => {
      const existingIndex = state.images.findIndex((i) => i.id === image.id);
      if (existingIndex === -1) {
        state.images.push(image);
      }
    });
  },
  newImageLoaded(state: ImageLibrary, payload: UserImage) {
    state.totalImages += 1;
    state.images.unshift(payload);
  },
  incrementImageUploadsInProgress(state: ImageLibrary) {
    state.imageUploadsInProgress += 1;
  },
  decrementImageUploadsInProgress(state: ImageLibrary) {
    if (state.imageUploadsInProgress > 0) {
      state.imageUploadsInProgress -= 1;
    }
  },
  addUploadError(state: ImageLibrary, payload: UploadError) {
    state.uploadErrors.push(payload);
  },
  clearUploadErrors(state: ImageLibrary) {
    state.uploadErrors = [];
  },
};

export const imageLibrary: Module<ImageLibrary, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
