






















































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { Affix } from 'vue-affix';
import VueSlickCarousel from 'vue-slick-carousel';
import ReleaseSearch from '@/shared/components/onboarding/ReleaseSearch.vue';
import SiteLayout from '@/shared/components/site/SiteLayout.vue';
import { convertFromCentsToWholeDollar, minimumBudgetCents } from '@/shared/lib/budgetOptions';
import store from '@/shared/store';
import ModalDspSearch from '@/shared/components/onboarding/ModalDspSearch.vue';
import AudioPlayButton from '@/shared/components/homepage/AudioPlayButton.vue';
import FlipReveal from '@/shared/components/homepage/FlipReveal.vue';
import MobileNudge from '@/shared/components/common/MobileNudge.vue';
import { Survey } from '@/shared/lib/survey';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

@Component({
  components: {
    MobileNudge,
    AudioPlayButton,
    FlipReveal,
    ModalDspSearch,
    SiteLayout,
    ReleaseSearch,
    Affix,
    VueSlickCarousel,
  },
})
export default class Home extends Vue {
  $refs!: {
    topSearch: HTMLElement,
    topSearchComponent: ReleaseSearch,
    bottomSearch: HTMLElement,
    bottomSearchComponent: ReleaseSearch,
    audioPlayer: AudioPlayButton,
  };

  sliderSettings: any = {
    arrows: true,
    slidesToShow: 7,
    slidesToScroll: 7,
    infinite: false,
    dots: true,
    draggable: false,
    variableWidth: false,
    centerMode: false,
    verticalSwiping: false,
    responsive: [
      {
        breakpoint: 1414,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
          // draggable: true,
          infinite: true,
        },
      },
      {
        breakpoint: 1235,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          // draggable: true,
          infinite: true,
        },
      },
      {
        breakpoint: 1063,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          // draggable: true,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          // draggable: true,
          infinite: true,
          arrows: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          // draggable: true,
          infinite: true,
          arrows: false,
        },
      },
      {
        breakpoint: 677,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          // draggable: true,
          centerPadding: '0px',
          variableWidth: true,
        },
      },
    ],
  };

  showTopGetStarted: boolean = false;

  loaded: boolean = false;

  searched: boolean = false;

  searchCriteria: string = '';

  libraryExampleSeen: boolean = false;

  copywritingExampleSeen: boolean = false;

  mosaicSeen: boolean = false;

  mosaicRandomizationScale = 500;

  private readonly exampleUrlRotationDelay = 4000;

  private readonly exampleRotationInitialDelay = 2000;

  private readonly exampleRotationBaseDelay = 3750;

  private readonly exampleRotationRandomOffsetMax = 750;

  exampleUrlInterval: number = 0;

  exampleUrls: string[] = [
    'rollingstone.com',
    'pitchfork.com',
    'billboard.com',
    'worldstarhiphop.com',
    'reddit.com',
  ];

  exampleUrlIdx: number = 0;

  @Getter('layout/isMobile') isMobile: boolean;

  @Getter('layout/isTouch') isTouch: boolean;

  get exampleUrl() {
    return this.exampleUrls[this.exampleUrlIdx];
  }

  libraryExampleInterval: number = 0;

  libraryExamples: string[] = [
    'example1.jpg',
    'example4.jpg',
    'example3.jpg',
  ];

  libraryExampleIdx: number = 0;

  get libraryExampleSrc() {
    // eslint-disable-next-line global-require,import/no-dynamic-require
    return require(`@/assets/zire/homepage/${this.libraryExamples[this.libraryExampleIdx]}`);
  }

  copywritingExampleInterval: number = 0;

  copywritingExamples: string[] = [
    'copywriting1.jpg',
    'copywriting2.jpg',
    'copywriting3.jpg',
  ];

  copywritingExampleIdx: number = 0;

  get copywritingExampleSrc() {
    // eslint-disable-next-line global-require,import/no-dynamic-require
    return require(`@/assets/zire/homepage/${this.copywritingExamples[this.copywritingExampleIdx]}`);
  }

  get minimumBudgetDollars() {
    return convertFromCentsToWholeDollar(minimumBudgetCents);
  }

  get abandonmentSurvey() {
    return Survey.Abandonment;
  }

  handleScroll() {
    const dist = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
    if (dist > 466) {
      this.showTopGetStarted = true;
    } else {
      this.showTopGetStarted = false;
    }
  }

  abandonmentUrlParams: { [key: string]: string } = {};

  beforeRouteEnter(to: any, from: any, next: any) {
    if (store.getters['profile/isLoggedInWithEmail']) {
      next('/campaigns');
    } else {
      let params = {};
      if (from.meta && from.meta.qualarooMapping) {
        params = { exit_point: from.meta.qualarooMapping };
      }
      next((vm: Home) => {
        // eslint-disable-next-line no-param-reassign
        vm.abandonmentUrlParams = params;
      });
    }
  }

  @Getter('profile/isLoggedInWithEmail') isLoggedInWithEmail: boolean;

  @Watch('isLoggedInWithEmail')
  watchLoggedIn(val: boolean) {
    if (val) {
      this.$nextTick(() => {
        if (this.$route.params.redirectAfterLogIn) {
          this.$router.push(this.$route.params.redirectAfterLogIn);
          return;
        }
        this.$router.push('/campaigns');
      });
    }
  }

  @Getter('survey/showAbandonmentSurvey') showAbandonmentSurvey: boolean;

  @Getter('cookieConsent/requireUserConsent') showCookieConsent: boolean;

  abandonmentSurveyVisible: boolean = false;

  private readonly abandonmentSurveyDelay = 2000;

  @Watch('showAbandonmentSurvey') abandonmentSurveyWatcher() {
    this.showAbandonmentSurveyIfEnabled();
  }

  @Watch('showCookieConsent') cookieConsentWatcher() {
    this.showAbandonmentSurveyIfEnabled();
  }

  showAbandonmentSurveyIfEnabled() {
    if (this.showAbandonmentSurvey && !this.showCookieConsent) {
      setTimeout(() => {
        this.abandonmentSurveyVisible = true;
      }, this.abandonmentSurveyDelay);
    } else {
      this.abandonmentSurveyVisible = false;
    }
  }

  created() {
    this.exampleUrlInterval = setInterval(() => {
      this.updateExampleUrl();
    }, this.exampleUrlRotationDelay);
  }

  mounted() {
    if (this.$route.query?.token) {
      this.$store.dispatch('profile/logInWithBLToken', { token: this.$route.query?.token });
    }

    if (this.$store.getters['profile/isLoggedInWithEmail']) {
      this.$router.push({
        name: 'campaigns',
      });
    } else {
      this.$store.dispatch('onboarding/clear').then(() => {
        if (this.$route.query.coupon) {
          this.$store.dispatch('onboarding/setCouponCode', this.$route.query.coupon);
        }
      });
      this.$store.dispatch('mixpanel/resetTrackOnceEventList');
      this.loaded = true;
      this.$store.dispatch('mixpanel/track', {
        properties: { referringDiscountCode: this.$route.query.coupon },
        action: 'Zire.HomePageViewed',
      });
      this.showAbandonmentSurveyIfEnabled();
      if (this.$route.params.redirectAfterLogIn) {
        this.$root.$emit('bv::show::modal', 'login-modal');
      }
    }
    window.addEventListener('click', this.clearSearch);
    window.addEventListener('scroll', this.handleScroll, true);
  }

  beforeDestroy() {
    clearInterval(this.exampleUrlInterval);
    clearInterval(this.libraryExampleInterval);
    clearInterval(this.copywritingExampleInterval);
  }

  destroyed() {
    window.removeEventListener('click', this.clearSearch);
    window.removeEventListener('scroll', this.handleScroll, true);
  }

  clearSearch(evt: any) {
    if (((this.$refs.topSearch && this.$refs.topSearchComponent.searched) || (this.$refs.bottomSearch && this.$refs.bottomSearchComponent.searched))
      && !this.$refs.topSearch.contains(evt.target) && !this.$refs.bottomSearch.contains(evt.target)) {
      this.$refs.topSearchComponent.handleClear();
      this.$refs.bottomSearchComponent.handleClear();
    }
  }

  handlePlayableAdClick() {
    this.$refs.audioPlayer.playPause();
    this.itemClicked('PlayableAdExample');
  }

  handlePlayableAdVisibility(visible: boolean) {
    if (!visible) {
      this.$refs.audioPlayer.pause();
    }
  }

  libraryExampleVisibilsityHandler(visible: boolean) {
    if (!this.libraryExampleSeen && visible) {
      setTimeout(() => {
        this.libraryExampleSeen = visible;
        this.updateLibraryExample(0);
        this.libraryExampleInterval = setInterval(() => {
          this.updateLibraryExample(Math.random() * this.exampleRotationRandomOffsetMax);
        }, this.exampleRotationBaseDelay);
      }, this.exampleRotationInitialDelay);
    }
  }

  copywritingExampleVisibilsityHandler(visible: boolean) {
    if (!this.copywritingExampleSeen && visible) {
      setTimeout(() => {
        this.copywritingExampleSeen = visible;
        this.updateCopywritingExample(0);
        this.copywritingExampleInterval = setInterval(() => {
          this.updateCopywritingExample(Math.random() * this.exampleRotationRandomOffsetMax);
        }, this.exampleRotationBaseDelay);
      }, this.exampleRotationInitialDelay);
    }
  }

  mosaicVisibilityHandler(visible: boolean) {
    if (!this.mosaicSeen && visible) {
      this.mosaicSeen = visible;
    }
  }

  itemClicked(clickedItem?: string) {
    this.$store.dispatch('mixpanel/track', {
      properties: {
        clickedItem,
        referringDiscountCode: this.$route.query.coupon,
      },
      action: 'Zire.HomePageItemClicked',
    });
  }

  openModalForMobile(evt: any) {
    if (this.isMobile) {
      evt.target.blur();
      this.$root.$emit('bv::show::modal', 'modal-dsp-search');
      window.scrollTo(0, 0);
    }
  }

  updateExampleUrl() {
    this.exampleUrlIdx += 1;
    if (this.exampleUrlIdx >= this.exampleUrls.length) {
      this.exampleUrlIdx = 0;
    }
  }

  updateLibraryExample(offset: number) {
    setTimeout(() => {
      this.libraryExampleIdx += 1;
      if (this.libraryExampleIdx >= this.libraryExamples.length) {
        this.libraryExampleIdx = 0;
      }
    }, offset);
  }

  updateCopywritingExample(offset: number) {
    setTimeout(() => {
      this.copywritingExampleIdx += 1;
      if (this.copywritingExampleIdx >= this.copywritingExamples.length) {
        this.copywritingExampleIdx = 0;
      }
    }, offset);
  }

  navToFuture() {
    this.$router.push({ name: 'future' });
  }
}
