import '@/shared/routerSetup';

import Vue from 'vue';
import Router, { RawLocation, Route } from 'vue-router';
import store from '@/shared/store';
import { Survey } from '@/shared/lib/survey';

import { Consent, Scope } from '@/shared/store/cookieConsent';
import Home from './views/Home.vue';
import Campaigns from './views/campaign/Campaigns.vue';
import UserActions from './views/UserActions.vue';

const Settings = () => import(/* webpackChunkName: "user" */ './views/Settings.vue');

const Results = () => import(/* webpackChunkName: "campaignResults" */ './views/campaign/Results.vue');

const NewCampaign = () => import(/* webpackChunkName: "onboarding" */ './views/onboarding/NewCampaign.vue');
const ReviewRelease = () => import(/* webpackChunkName: "onboarding" */ './views/onboarding/ReviewRelease.vue');
const AboutYourRelease = () => import(/* webpackChunkName: "onboarding" */ './views/onboarding/AboutYourRelease.vue');
const ReleaseLink = () => import(/* webpackChunkName: "onboarding" */ './views/onboarding/ReleaseLink.vue');
const FeatureImages = () => import(/* webpackChunkName: "onboarding" */ './views/onboarding/FeatureImages.vue');
const SelectAudio = () => import(/* webpackChunkName: "onboarding" */ './views/onboarding/SelectAudio.vue');
const Intermission = () => import(/* webpackChunkName: "onboarding" */ './views/onboarding/Intermission.vue');

const RecommendedAds = () => import(/* webpackChunkName: "builder" */ './views/builder/RecommendedAds.vue');
const BudgetStep = () => import(/* webpackChunkName: "builder" */ './views/builder/BudgetStep.vue');
const CheckoutStep = () => import(/* webpackChunkName: "builder" */ './views/builder/CheckoutStep.vue');

const Receipt = () => import(/* webpackChunkName: "receipt" */ './views/builder/Receipt.vue');

const RejectedCampaign = () => import(/* webpackChunkName: "rejectedCampaign" */ './views/campaign/RejectedCampaign.vue');

const Legal = () => import(/* webpackChunkName: "termsEtc" */ './views/Legal.vue');

const Future = () => import(/* webpackChunkName: "marketing" */ './views/Future.vue');
const HowItWorks = () => import(/* webpackChunkName: "marketing" */ './views/HowItWorks.vue');

const SampleReport = () => import(/* webpackChunkName: "sampleReport" */ './views/SampleReport.vue');

const NotFound = () => import(/* webpackChunkName: "notFound" */ './views/NotFound.vue');

const Export = () => import(/* webpackChunkName: "campaignExport" */ './views/campaign/Export.vue');

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        transitionName: 'none',
        footerTheme: 'dark',
        // inspectletTag: 'Homepage',
      },
    },
    {
      path: '/future',
      name: 'future',
      component: Future,
      meta: {
        theme: 'manifesto',
        bodyClass: 'manifesto-background',
      },
    },
    {
      path: '/how-it-works',
      name: 'how-it-works',
      component: HowItWorks,
    },
    {
      path: '/sample-report',
      name: 'sample-report',
      component: SampleReport,
      meta: {
        // inspectletTag: 'SampleReport',
        id: '-1',
      },
    },
    {
      path: '/terms',
      name: 'terms',
      component: Legal,
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: Legal,
    },
    {
      path: '/copyright',
      name: 'copyright',
      component: Legal,
    },
    {
      path: '/spin-to-win',
      name: 'spin-to-win',
      component: Legal,
    },
    {
      path: '/settings',
      name: 'settings',
      component: Settings,
      meta: {
        transitionName: 'none',
        // inspectletTag: 'AccountSettings',
      },
    },
    {
      path: '/campaigns',
      name: 'campaigns',
      component: Campaigns,
      meta: {
        transitionName: 'none',
        // inspectletTag: 'CampaignListings',
      },
    },
    {
      path: '/campaigns/:id',
      name: 'campaign-results',
      component: Results,
      meta: {
        // inspectletTag: 'CampaignResults',
      },
    },
    {
      path: '/campaigns/export/:id',
      name: 'campaign-export',
      component: Export,
      meta: {
        // inspectletTag: 'CampaignResults',
      },
    },
    {
      path: '/campaigns/rejected/:id',
      name: 'rejected-campaign',
      component: RejectedCampaign,
      meta: {
        // inspectletTag: 'RejectedCampaign',
      },
    },
    {
      path: '/onboarding/new-campaign/:search?',
      props: true,
      name: 'new-campaign',
      component: NewCampaign,
      meta: {
        transitionName: 'animate--onboarding',
        bodyClass: 'gradient-background',
        qualarooMapping: 'OnboardingFlow_SearchForRelease',
        inspectletTag: 'CampaignBuilder',
      },
    },
    {
      path: '/onboarding/review-release/:id?',
      props: true,
      name: 'review-release',
      component: ReviewRelease,
      meta: {
        transitionName: 'animate--onboarding',
        bodyClass: 'gradient-background',
        qualarooMapping: 'OnboardingFlow_ReviewReleaseDetailsStep',
        inspectletTag: 'CampaignBuilder',
      },
    },
    {
      path: '/onboarding/about-your-release/:id?',
      props: true,
      name: 'about-your-release',
      component: AboutYourRelease,
      meta: {
        transitionName: 'animate--onboarding',
        bodyClass: 'gradient-background',
        qualarooMapping: 'OnboardingFlow_ManualReleaseDetails',
        inspectletTag: 'CampaignBuilder',
      },
    },
    {
      path: '/onboarding/release-link/:id?',
      props: true,
      name: 'release-link',
      component: ReleaseLink,
      meta: {
        transitionName: 'animate--onboarding',
        bodyClass: 'gradient-background',
        qualarooMapping: 'OnboardingFlow_ManualLinkDetails',
        inspectletTag: 'CampaignBuilder',
      },
    },
    {
      path: '/onboarding/feature-images/:id?',
      props: true,
      name: 'feature-images',
      component: FeatureImages,
      meta: {
        transitionName: 'animate--onboarding',
        bodyClass: 'gradient-background',
        qualarooMapping: 'OnboardingFlow_ImageStep',
        inspectletTag: 'CampaignBuilder',
      },
    },
    {
      path: '/onboarding/select-audio/:id?',
      props: true,
      name: 'select-audio',
      component: SelectAudio,
      meta: {
        transitionName: 'animate--onboarding',
        bodyClass: 'gradient-background',
        qualarooMapping: 'OnboardingFlow_SongStep',
        inspectletTag: 'CampaignBuilder',
      },
    },
    {
      path: '/onboarding/processing/:id?',
      props: true,
      name: 'intermission',
      component: Intermission,
      meta: {
        transitionName: 'animate--onboarding',
        bodyClass: 'gradient-background',
        qualarooMapping: 'OnboardingFlow_BuildCampaignStep',
        inspectletTag: 'CampaignBuilder',
      },
    },
    {
      path: '/builder/recommended-ads/:id?',
      name: 'recommended-ads',
      props: true,
      component: RecommendedAds,
      meta: {
        transitionName: 'load-recommended-ads',
        bodyClass: 'gradient-background',
        qualarooMapping: 'RecommendedAdStep',
        inspectletTag: 'CampaignBuilder',
      },
    },
    {
      path: '/builder/budget/:id?',
      name: 'budget-step',
      props: true,
      component: BudgetStep,
      meta: {
        transitionName: 'component-fade',
        qualarooMapping: 'BudgetStep',
        inspectletTag: 'CampaignBuilder',
      },
    },
    {
      path: '/builder/checkout/:id?',
      name: 'checkout-step',
      props: true,
      component: CheckoutStep,
      meta: {
        transitionName: 'component-fade',
        supportsEmailVerification: true,
        qualarooMapping: 'CheckoutStep',
        inspectletTag: 'CampaignBuilder',
      },
    },
    {
      path: '/builder/receipt/:id',
      name: 'receipt',
      props: true,
      component: Receipt,
      meta: {
        transitionName: 'component-fade',
        supportsEmailVerification: true,
        inspectletTag: 'Receipt',
      },
    },
    {
      path: '/user-actions',
      name: 'user-actions',
      props: true,
      beforeEnter: (to: Route, from: Route, next: Function) => {
        if (to.query.mode as string === 'verifyEmail') {
          next();
        } else {
          next('/');
        }
      },
      component: UserActions,
      meta: {
        bodyClass: 'gradient-background',
        supportsEmailVerification: true,
        robots: 'noindex',
      },
    },
    {
      path: '/*',
      name: 'not-found',
      component: NotFound,
      meta: {
        transitionName: 'component-fade',
        footerTheme: 'dark',
        gtmIgnore: true,
        robots: 'noindex',
      },
    },
  ],
});

// Check for login tokens
router.beforeEach((to, from, next) => {
  const query = { ...to.query };
  const { user_id: userId, token } = query;
  if (token) {
    delete query.token;
    delete query.user_id;
    store.dispatch('profile/logInWithToken', { userId, token })
      .then(() => next({ query }))
      .catch(() => next('/'));
  } else {
    next();
  }
});

// Process robots meta...
router.afterEach((to) => {
  document.querySelectorAll('head meta[name=robots][data-router-controlled]').forEach((el) => {
    if (el && el.parentNode) {
      el.parentNode.removeChild(el);
    }
  });
  const { robots } = to.meta;
  if (robots) {
    const tag = document.createElement('meta');
    tag.setAttribute('name', 'robots');
    tag.setAttribute('content', robots);
    tag.setAttribute('data-router-controlled', '');
    document.head.appendChild(tag);
  }
});

// Automatically accept cookies outside gdpr
const cookieConsentAfterHook = router.afterEach((to, from) => {
  if (to.name === 'privacy' || from.name === 'privacy') {
    return;
  }
  if (store.getters['cookieConsent/requireUserConsent']) {
    if (store.getters['cookieConsent/bannerShown'] && !store.getters['cookieConsent/gdpr']) {
      const consent: Consent[] = store.getters['cookieConsent/scopes'].map(({ name, version }: Scope) => ({
        name,
        version,
        granted: store.getters['cookieConsent/refused'].indexOf(name) === -1,
      }));
      store.dispatch('cookieConsent/update', { consent })
        .then(() => { cookieConsentAfterHook(); })
        .catch(() => {});
    }
  } else {
    cookieConsentAfterHook();
  }
});

// GTM
router.afterEach((to) => {
  const name = to.meta.gtm || to.name;
  // Ignore routes without a name or flagged as ignored.
  if (!name || to.meta.gtmIgnore) {
    return;
  }
  Vue.nextTick(() => {
    store.dispatch('googleTagManager/trackView', { name, path: to.path, fullPath: to.fullPath });
  });
});

// Qualaroo
router.afterEach((to, from) => {
  const isLoggedIn = store.getters['profile/isLoggedInWithEmail'];
  if (!isLoggedIn && from.meta && from.meta.qualarooMapping && to.name && to.name === 'home') {
    Vue.nextTick(() => {
      store.dispatch('qualaroo/abandonmentSurvey', from.meta.qualarooMapping);
      store.dispatch('survey/enableSurvey', Survey.Abandonment);
    });
  }
});

// Inspectlet
router.afterEach((to) => {
  const sessionTag = to.meta.inspectletTag;
  if (sessionTag) {
    Vue.nextTick(() => {
      store.dispatch('googleTagManager/trackEvent', {
        event: 'inspectlet.setSessionTag',
        data: {
          inspectlet: {
            sessionTag,
          },
        },
      });
    });
  }
});

// Patches...
// https://github.com/vuejs/vue-router/issues/2932#issuecomment-533453711
const originalPush: any = router.push;
router.push = function push(location: RawLocation, onResolve?: Function, onReject?: (err: Error) => void) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }
  return originalPush.call(this, location).catch((err: any) => {
    if (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(false);
  });
};
// End patches.

export default router;
