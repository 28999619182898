























































































































import { Getter } from 'vuex-class';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { email, required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import ModalButton from '@/shared/components/modals/ModalButton.vue';
import MaterialInputField from '@/shared/components/form/MaterialInputField.vue';
import SimpleModal from '@/shared/components/modals/SimpleModal.vue';
import Submittable from '@/shared/components/common/Submittable.vue';
import { BL_LOGIN } from '@/shared/lib/api';

@Component({
  mixins: [
    validationMixin,
  ],
  components: {
    MaterialInputField,
    ModalButton,
    SimpleModal,
    Submittable,
  },
  validations: {
    emailAddr: { email, required },
    pwd: { required },
  },
})
export default class LoginModal extends Vue {
  $refs!: {
    emailInput: HTMLFormElement,
    password: any,
    modal: SimpleModal,
  };

  bandlabOnly: boolean = false;

  email: string = '';

  password: string = '';

  pwdVisible: boolean = false;

  loggingIn: boolean = false;

  errors = {
    global: '',
    email: '',
    password: '',
  };

  touchMap = new WeakMap();

  @Getter('layout/isMobile') isMobile: boolean;

  @Getter('profile/isLoggedInWithEmail') isLoggedInWithEmail: boolean;

  reset() {
    this.email = '';
    this.password = '';
    this.pwdVisible = false;
    this.loggingIn = false;
    this.errors = {
      global: '',
      email: '',
      password: '',
    };
    this.$v.$reset();
  }

  modalShown() {
    this.$store.dispatch('mixpanel/track', {
      properties: {
        locationSurfaced: this.$route.name,
      },
      action: 'Zire.LoginFlowStarted',
    });
  }

  closeModal(trigger?: string) {
    this.$refs.modal.hide(trigger);
  }

  @Watch('isLoggedInWithEmail')
  watchLoggedIn(val: boolean, oldVal: boolean) {
    if (val && val !== oldVal) {
      this.$store.dispatch('mixpanel/track', {
        properties: {
          locationSurfaced: this.$route.name,
        },
        action: 'Zire.LoginCompleted',
      });
      this.closeModal('login');
    }
  }

  delayTouch($v: any) {
    $v.$reset();
    if (this.touchMap.has($v)) {
      clearTimeout(this.touchMap.get($v));
    }
    this.touchMap.set($v, setTimeout($v.$touch, 1000));
  }

  delayTouchUntilBlur($v: any) {
    $v.$reset();
  }

  touchElement($v: any) {
    $v.$touch();
  }

  get emailAddr() {
    return this.email;
  }

  set emailAddr(value: string) {
    this.email = value;
  }

  get pwd() {
    return this.password;
  }

  set pwd(value: string) {
    this.password = value;
  }

  togglePwdVisibility() {
    this.pwdVisible = !this.pwdVisible;
    return false;
  }

  invalid($v: any) {
    if ($v) {
      return this.email === '' || this.password.length === 0 || $v.emailAddr.$invalid
        || (this.errors.password && this.errors.password.length > 0) || (this.errors.email && this.errors.email.length > 0) || $v.pwd.$invalid;
    }
    return true;
  }

  forgotPassword() {
    this.$root.$emit('bv::show::modal', 'forgot-password-modal');
    this.closeModal('forgot-password');
  }

  handleLoginBL() {
    if (BL_LOGIN) window.location.href = `${BL_LOGIN}?redirect=${window.location.pathname}`;
  }

  handleSubmit(event: Event) {
    event.preventDefault();
    this.loggingIn = true;
    this.$store.dispatch('profile/logIn', { email: this.email, password: this.password })
      .catch((error) => {
        this.loggingIn = false;
        window.scrollTo(0, 0);
        if (error && error.code && error.message) {
          let errMsg = '';
          switch (error.code) {
            case 'auth/user-not-found':
            case 'auth/wrong-password':
              errMsg = 'Invalid email or password.';
              break;
            case 'auth/timeout':
              errMsg = 'Issues completing login.  Please try again.';
              break;
            default:
              errMsg = error.message;
          }
          this.errors.global = errMsg;
        } else {
          this.errors.global = 'An unknown error has occurred.';
        }
      });
  }
}
