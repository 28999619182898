import {
  ActionTree, GetterTree, Module, MutationTree,
} from 'vuex';
import { RootState } from '@/shared/store/types';
import { Empty } from '@/shared/gen/google/protobuf/google.protobuf';
import {
  DisconnectFacebookPageRequest,
  FacebookPage,
  SyncFacebookPageRequest,
} from '@/shared/gen/messages.pisa';

import { BuilderService } from '@/shared/lib/api';
import Facebook, { PAGE_REQUIRED_TASKS } from '@/shared/lib/facebook';

export interface StoreInstagramPage {
  id: string,
  username: string,
  pictureUrl: string,
}

export interface StoreFacebookPage {
  pageId: string,
  name: string,
  link?: string,
  pictureUrl?: string,
  instagram?: StoreInstagramPage[],
  accessToken?: string,
  loadedFullPageDetails: boolean,
}

export interface FacebookState {
  pages: StoreFacebookPage[];
  syncedPages: StoreFacebookPage[];
}

export const initialState: FacebookState = {
  pages: [],
  syncedPages: [],
};

const MissingPermissionsErrorMessage = 'missing permissions';
const NotConnectedErrorMessage = 'not connected';

export function isMissingPermissionsError(err: any): boolean {
  return err && err.message === MissingPermissionsErrorMessage;
}

export function isNotConnectedError(err: any): boolean {
  return err && err.message === NotConnectedErrorMessage;
}

export function isInUseError(err: any): boolean {
  return err && err.code && err.code === 'failed_precondition' && err.meta && err.meta.reason_code === 'in_use';
}

export function isPermissionDeniedError(err: any): boolean {
  return err && err.code && err.code === 'failed_precondition' && err.meta && err.meta.reason_code === 'permission_denied';
}

export function isPageNotPublishedError(err: any): boolean {
  return err && err.code && err.code === 'failed_precondition' && err.meta && err.meta.reason_code === 'not_published';
}

export function isPageNotPromotionEligibleError(err: any): boolean {
  return err && err.code && err.code === 'failed_precondition' && err.meta && err.meta.reason_code === 'not_promotion_eligible';
}

export function isTemporarilyBlockedError(err: any): boolean {
  return err && err.code && err.code === 'failed_precondition' && err.meta && err.meta.reason_code === 'temporarily_blocked';
}

export function isTemporarilyBlockedPolicyViolationError(err: any): boolean {
  return isTemporarilyBlockedError(err) && err.meta.reason_subcode === 'policy_violation';
}

const actions: ActionTree<FacebookState, RootState> = {
  facebookAuth(context, options?: facebook.LoginOptions): Promise<facebook.StatusResponse> {
    let scope: string | undefined;
    if (options) {
      ({ scope } = options);
    }
    return Facebook.login({
      ...options,
      scope,
      return_scopes: true,
    }).then((statusResponse) => {
      if (statusResponse.status === 'connected') {
        if (scope && !Facebook.authResponseHasPermissions(statusResponse.authResponse, scope)) {
          // Missing permission...
          throw new Error(MissingPermissionsErrorMessage);
        }
      } else {
        // Not connected...
        throw new Error(NotConnectedErrorMessage);
      }
      return statusResponse;
    });
  },
  loadPages({ commit, dispatch }): Promise<string[]> {
    return Facebook.api('me/accounts').then((response: any) => {
      commit('setPages', response.data);
      return dispatch('retrieveDetailsForPages');
    });
  },
  syncPage({ commit }, payload: any): Promise<FacebookPage> {
    return new Promise((resolve, reject) => {
      BuilderService(this.dispatch('profile/loggedIn'), (svc) => {
        const req = new SyncFacebookPageRequest();
        req.accessToken = payload.accessToken;
        svc.syncFacebookPage(req).then((page) => {
          const syncedPage: any = {
            pageId: page.pageId,
            name: page.name,
            link: page.link,
            pictureUrl: page.pictureUrl,
            instagram: [],
            loadedFullPageDetails: true,
          };
          if (page.instagram && page.instagram.id) {
            syncedPage.instagram.push({
              id: page.instagram.id,
              username: page.instagram.username,
              pictureUrl: page.instagram.pictureUrl,
            });
          }
          commit('addUpdateSyncedPage', syncedPage);
          resolve(page);
        }, reject);
      });
    });
  },
  retrieveDetailsForPage({ commit }, payload: StoreFacebookPage): Promise<string> {
    if (payload.loadedFullPageDetails) {
      return Promise.resolve(payload.pageId);
    }
    return Facebook.api(payload.pageId, 'get', {
      fields: 'id,is_published,name,link,picture.type(large),instagram_accounts{id,username,profile_pic,has_profile_picture}',
      access_token: payload.accessToken,
    }).then((response: any) => {
      commit('setPageDetails', {
        ...response,
        access_token: payload.accessToken,
        loadedFullPageDetails: true,
      });
      return payload.pageId;
    });
  },
  retrieveDetailsForPages({ dispatch, state }): Promise<string[]> {
    return Promise.all(state.pages.map((i) => dispatch('retrieveDetailsForPage', i)));
  },
  loadSyncedPages({ commit }) {
    return new Promise<void>((resolve, reject) => {
      BuilderService(this.dispatch('profile/loggedIn'), (svc) => {
        svc.listSocialIntegrations(new Empty()).then((res) => {
          commit('setSyncedPages', res.facebookPages.map((page) => {
            const syncedPage: any = {
              pageId: page.pageId,
              name: page.name,
              link: page.link,
              pictureUrl: page.pictureUrl,
              instagram: [],
              loadedFullPageDetails: true,
            };
            if (page.instagram && page.instagram.id) {
              syncedPage.instagram.push({
                id: page.instagram.id,
                username: page.instagram.username,
                pictureUrl: page.instagram.pictureUrl,
              });
            }
            return syncedPage;
          }));
          resolve();
        }, reject);
      });
    });
  },
  disconnectPage({ commit }, pageId: string) {
    return new Promise((resolve, reject) => {
      BuilderService(this.dispatch('profile/loggedIn'), (svc) => {
        svc.disconnectFacebookPage(new DisconnectFacebookPageRequest({ pageId })).then(() => {
          commit('disconnectPage', pageId);
        }).then(resolve, reject);
      });
    });
  },
  disconnectAllPages({ commit }) {
    return new Promise((resolve, reject) => {
      BuilderService(this.dispatch('profile/loggedIn'), (svc) => {
        svc.disconnectAllFacebookPages(new Empty()).then(() => {
          commit('disconnectAllPages');
        }).then(resolve, reject);
      });
    });
  },
};

const getters: GetterTree<FacebookState, RootState> = {
  hasSyncedPages(state): boolean {
    if (state.syncedPages && state.syncedPages.length > 0) {
      return true;
    }
    return false;
  },
  getPages(state): StoreFacebookPage[] {
    return [...state.pages.filter((page) => page.loadedFullPageDetails)];
  },
  getSyncedPages(state): StoreFacebookPage[] {
    return state.syncedPages.slice(0);
  },
};

const mutations: MutationTree<FacebookState> = {
  setPages(state, payload: any) {
    state.pages = payload
      .filter((page: any) => PAGE_REQUIRED_TASKS.every((t) => page.tasks.includes(t)))
      .map((page: any) => ({
        pageId: page.id,
        name: page.name,
        accessToken: page.access_token,
        loadedFullPageDetails: false,
      }));
  },
  setPageDetails(state, payload: any) {
    const index = state.pages.findIndex((element) => (element.pageId === payload.id));
    if (index !== -1) {
      const igPages: StoreInstagramPage[] = [];
      if (payload.instagram_accounts && payload.instagram_accounts.data.length > 0) {
        payload.instagram_accounts.data.forEach((igPayload: any) => {
          const igPage: StoreInstagramPage = {
            id: igPayload.id,
            username: igPayload.username,
            pictureUrl: igPayload.profile_pic,
          };
          igPages.push(igPage);
        });
      }
      const fbPage: StoreFacebookPage = {
        pageId: payload.id,
        name: payload.name,
        link: payload.link,
        pictureUrl: payload.picture.data.url,
        instagram: igPages,
        loadedFullPageDetails: true,
        accessToken: payload.access_token,
      };
      if (payload.is_published) {
        state.pages.splice(index, 1, fbPage);
      } else {
        state.pages.splice(index, 1);
      }
    }
  },
  setSyncedPages(state, payload: StoreFacebookPage[]) {
    state.syncedPages = payload;
  },
  addUpdateSyncedPage(state, payload: StoreFacebookPage) {
    const index = state.syncedPages.findIndex((page) => page.pageId === payload.pageId);
    if (index === -1) {
      state.syncedPages.push(payload);
    } else {
      state.syncedPages.splice(index, 1, payload);
    }
  },
  disconnectPage(state, pageId: string) {
    const index = state.syncedPages.findIndex((i) => i.pageId === pageId);
    if (index !== -1) {
      state.syncedPages.splice(index, 1);
    }
  },
  disconnectAllPages(state) {
    state.syncedPages = [];
  },
};

export const facebook: Module<FacebookState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
