





























import {
  Component, Model, Prop, Vue,
} from 'vue-property-decorator';

@Component
export default class Pagination extends Vue {
  @Prop({ default: 10 })
  perPage: number;

  @Prop()
  totalRows!: number;

  @Model('input', { type: Number })
  readonly value: number;

  currentPage: number;

  created() {
    this.currentPage = this.value;
  }
}
