import Env from '@/shared/lib/env';
import Deferred from '@/shared/lib/deferred';

const VERSION = 'v20.0';

export const AGENCY_SCOPE = 'business_management,pages_manage_ads,pages_read_engagement,pages_show_list';

export const PAGE_REQUIRED_TASKS = ['ADVERTISE', 'MANAGE'];

class FacebookError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'FacebookError';
  }
}

declare global {
  interface Window {
    fbAsyncInit?: Function
    FB: facebook.FacebookStatic
  }
}

function loadSDK() {
  return new Promise<facebook.FacebookStatic>((resolve, reject) => {
    if (window.FB) {
      resolve(window.FB);
      return;
    }
    const src = '//connect.facebook.net/en_US/sdk.js';
    const script = document.createElement('script');
    script.id = 'facebook-js-sdk';
    script.src = src;
    script.async = true;
    script.addEventListener('load', () => resolve(window.FB), false);
    script.addEventListener('error', () => reject(new Error(`Error loading Facebook JS SDK from ${src}`)), false);

    const sibling = document.getElementsByTagName('script')[0];
    sibling!.parentNode!.insertBefore(script, sibling);
  });
}

const initDeferred = new Deferred<facebook.FacebookStatic>();

let initOnce = (params: facebook.InitParams) => {
  window.fbAsyncInit = () => {
    window.FB.init(params);
    initDeferred.resolve(window.FB);
  };
  loadSDK().then(null, initDeferred.reject);
  initOnce = () => {
    throw new Error('Facebook has already been initialized.');
  };
};

function init(params?: Partial<facebook.InitParams>) {
  initOnce({
    appId: (Env.facebook && Env.facebook.appId)!,
    version: VERSION,
    xfbml: false,
    ...params,
  });
}

const ready = initDeferred.promise.then.bind(initDeferred.promise);

function login(options?: facebook.LoginOptions): Promise<facebook.StatusResponse> {
  return ready((FB) => new Promise((resolve) => {
    FB.login(resolve, options);
  }));
}

function logout(): Promise<facebook.StatusResponse> {
  return ready((FB) => new Promise((resolve) => {
    FB.logout(resolve);
  }));
}

function getAuthResponse() {
  if (window.FB) {
    return FB.getAuthResponse();
  }
  return null;
}

function getLoginStatus(force = false) {
  return ready((FB) => new Promise((resolve) => {
    FB.getLoginStatus(resolve, force);
  }));
}

type TMethod = 'get' | 'post' | 'delete';

function api<TParams extends object>(path: string, method?: TMethod | TParams, params?: TParams) {
  /* eslint-disable no-param-reassign */
  if (typeof method !== 'string') {
    params = method;
    method = 'get';
  }
  if (!params) {
    params = <TParams>{};
  }
  /* eslint-enable no-param-reassign */
  return ready((FB) => new Promise((resolve, reject) => {
    FB.api(path, <TMethod>method, <TParams>params, (response: any) => {
      if (!response) {
        reject(new FacebookError('No response from Facebook'));
      } else if (response.error) {
        reject(response.error);
      } else {
        resolve(response);
      }
    });
  }));
}

type UIParams =
  facebook.ShareDialogParams |
  facebook.ShareOpenGraphDialogParams |
  facebook.AddPageTabDialogParams |
  facebook.GameRequestDialogParams |
  facebook.PayDialogParams |
  facebook.PaymentsLiteDialogParams |
  facebook.LiveDialogParams |
  facebook.SendDialogParams |
  facebook.CreateOfferDialogParams |
  facebook.LeadgenDialogParams |
  facebook.InstantExperiencesAdsDialogParams |
  facebook.InstantExperiencesPreviewDialogParams |
  facebook.CollectionAdsDialogParams;

function ui(params: UIParams) {
  return ready((FB) => new Promise((resolve, reject) => {
    FB.ui(<any>params, (response: any) => {
      if (!response) {
        reject(new FacebookError('No response from Facebook'));
      } else if (response.error) {
        reject(response.error);
      } else {
        resolve(response);
      }
    });
  }));
}

function authResponseHasPermissions(response: facebook.AuthResponse, scope: string): boolean {
  if (!scope || !response.grantedScopes) {
    return false;
  }
  const grantedScopes = response.grantedScopes.split(',');
  return scope.split(',').every((i) => grantedScopes.indexOf(i) !== -1);
}

const Facebook = {
  // FB SDK API
  init,
  ready,
  login,
  logout,
  getAuthResponse,
  getLoginStatus,
  api,
  ui,
  // Zire FB Helpers
  authResponseHasPermissions,
};

export default Facebook;
