// Loosely based on https://godoc.org/golang.org/x/sync/singleflight
export default class SingleFlight {
  private $byID: {
    [key: string]: Promise<any>
  } = {};

  $getByID(id: string): undefined | Promise<any> {
    return this.$byID[id];
  }

  $setByID(id: string, promise: Promise<any>): Promise<any> {
    this.$byID[id] = promise;
    promise.finally(() => {
      if (promise === this.$byID[id]) {
        delete this.$byID[id];
      }
    });
    return promise;
  }

  do(id: string, fn: () => Promise<any>): Promise<any> {
    return this.$getByID(id) || this.$setByID(id, fn());
  }
}
