








































































































































import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import InputField from '@/shared/components/onboarding/InputField.vue';
import defaultErrorToast from '@/shared/lib/defaultToast';
import { AlbumResult } from '@/shared/store/retailerSearch';

@Component({
  mixins: [],
  components: {
    InputField,
  },
  props: {},
})
export default class ReleaseSearch extends Vue {
  $refs!: {
    releaseSearchAnchor: any;
    scrollingSearchResults: any;
  };

  @Prop({ default: '' })
  value: string;

  @Prop({ default: 'userInputField' })
  fieldRef: string;

  @Prop({ default: false })
  lite: boolean;

  @Prop({ default: '' })
  placeholder: string;

  @Prop({ default: '' })
  inputFieldContainerClass: string;

  @Prop({ default: true })
  autoFocus: boolean;

  @Prop({ default: true })
  displayLeftIcon: boolean;

  @Prop({ default: false })
  hideManualLinks: boolean;

  @Prop({ default: '' })
  scrollableElementId: string;

  @Prop({ default: true })
  scrollOnSearchResults: boolean;

  @Prop({ default: false })
  keepFocusOnSubmit: boolean;

  @Prop({ default: false })
  wide: boolean;

  searching: boolean = false;

  searched: boolean = false;

  selectedIndex: number = -1;

  @Getter('retailerSearch/getSearchResults') searchResults: AlbumResult[];

  @Getter('layout/isMobile') isMobile: boolean;

  get hasSearchResults() {
    return this.searchResults && this.searchResults.length > 0;
  }

  localValue: any = '';

  get rightIcon() {
    if (this.searching) {
      return ['fas', 'spinner'];
    }
    if (this.searched) {
      return ['far', 'times'];
    }
    return ['far', 'search'];
  }

  get leftIcon() {
    if (this.isMobile) {
      return ['far', 'arrow-left'];
    }
    return '';
  }

  created() {
    this.localValue = this.value;
  }

  beforeCreate() {
    this.$store.dispatch('retailerSearch/clearSearch');
  }

  updated() {
    this.localValue = this.value;
  }

  selectResult(index: number) {
    this.$emit('release-selected');

    this.$store.dispatch('retailerSearch/setSelectedIndex', index).then(() => {
      this.$store.dispatch('mixpanel/trackOnce', {
        properties: {
          releaseType: this.$store.getters['retailerSearch/getSelectedReleaseType'],
        },
        action: 'Zire.SearchResultSelected',
      });
      this.$router.push({
        name: 'review-release',
      });
    });
  }

  leftIconClick() {
    this.$emit('left-icon-click');
  }

  handleClick(evt: Event) {
    this.$emit('click', evt);
  }

  emitEvent(eventType: string, evt: any) {
    this.$emit(eventType, evt);
  }

  scrollReleaseSearchIntoView() {
    if (this.$refs.releaseSearchAnchor && this.$refs.releaseSearchAnchor.scrollIntoView) {
      this.$refs.releaseSearchAnchor.scrollIntoView(true);
    }
  }

  handleSubmit() {
    if (this.searched) {
      this.searching = false;
      // this.handleClear();
    } else if (this.localValue) {
      this.searching = true;
      this.$store.dispatch('retailerSearch/clearSearch').then(() => {
        this.$store.dispatch('retailerSearch/search', this.localValue).then(() => {
          this.searching = false;
          this.searched = true;
          this.$emit('searched', true);
          if (this.scrollOnSearchResults) {
            this.scrollReleaseSearchIntoView();
          }
        }, () => {
          defaultErrorToast();
        });
      });
    }
  }

  handleClear() {
    this.searched = false;
    this.$emit('searched', false);
    this.setValue('');
    this.$store.dispatch('retailerSearch/clearSearch');
  }

  setValue(value: any) {
    this.localValue = value;
    this.$emit('input', this.localValue);
    if (this.searched) {
      this.searched = false;
      this.$store.dispatch('retailerSearch/clearSearch');
    }
  }

  calcReleaseType(result: any) {
    const releaseType = result.albumType.toLowerCase() || '';
    if (releaseType === 'ep') {
      return 'EP';
    }
    return releaseType.slice(0, 1).toUpperCase() + releaseType.slice(1);
  }

  manualFlow() {
    this.$store.dispatch('mixpanel/track', {
      properties: {
        clickedItem: 'ManualReleaseDetails',
      },
      action: 'Zire.ManualReleaseLinkClicked',
    });
    this.$nextTick(() => {
      this.$router.push({
        name: 'about-your-release',
      });
    });
  }
}
