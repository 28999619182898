import { Campaign_Status as CampaignStatus } from '@/shared/gen/messages.pisa';

export type IStatus = CampaignStatus

export const Status = {
  Reviewing: CampaignStatus.REVIEWING,
  Rejected: CampaignStatus.REJECTED,
  Running: CampaignStatus.RUNNING,
  Completed: CampaignStatus.COMPLETED,
  Aborted: CampaignStatus.ABORTED,
};

export function isEnded(status: IStatus) : boolean {
  return [Status.Completed, Status.Aborted].includes(status);
}

export function isInReview(status: IStatus) : boolean {
  return [Status.Reviewing, Status.Rejected].includes(status);
}
