export interface Interface {
  name: string;
  bandlab: {
    domain: string,
    loginUrl: string,
    logoutUrl: string,
  };
  country: {
    code: string,
    gdpr: boolean,
  };
  debug: boolean;
  facebook?: {
    appId: string,
  };
  firebase: any;
  googleTagManager?: {
    containerId?: string;
    debug?: boolean;
  };
  honeybadger?: {
    apiKey: string;
    environment: string;
  };
  stripe: {
    publishableKey: string;
  };
  [key: string]: any;
}

export default window.Zire.env!;
