


























import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import DeviceDetector from '@/shared/components/common/DeviceDetector.vue';
import Pixels from '@/shared/components/pixels/Pixels.vue';

const DEFAULT_TRANSITION = 'animate--page';
const DEFAULT_BODY_CLASS = '';

@Component({
  components: {
    DeviceDetector,
    Pixels,
  },
})
export default class App extends Vue {
  transitionName = DEFAULT_TRANSITION;

  bodyClass= DEFAULT_BODY_CLASS;

  @Getter('profile/adminImpersonator') adminImpersonator: string;

  @Getter('layout/isWebview') isWebview: boolean;

  created() {
    this.$router.beforeEach((to, from, next) => {
      this.transitionName = to.meta.transitionName || DEFAULT_TRANSITION;
      next();
    });
  }

  logOut() {
    this.$store.dispatch('profile/logOut');
  }

  setBodyClass(bodyClass: string) {
    const bodyElement = document.body;
    bodyElement.className = '';

    if (bodyClass) {
      bodyElement.className = this.bodyClass;
    }
  }

  beforeUpdate() {
    if (this.$router.currentRoute) {
      this.bodyClass = this.$router.currentRoute.meta.bodyClass || DEFAULT_BODY_CLASS;
      this.setBodyClass(this.bodyClass);
      if (this.isWebview) {
        document.body.classList.add('webview');
      }
    }
  }
}
