import {
  Component, Watch, Vue,
} from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { Route } from 'vue-router';
import store from '@/shared/store';

@Component
export default class AuthRequired extends Vue {
  @Getter('profile/isLoggedInWithEmail') isLoggedInWithEmail: boolean;

  @Watch('isLoggedInWithEmail')
  watchLoggedIn(val: boolean) {
    if (!val) {
      this.$router.push('/');
    }
  }

  beforeRouteEnter(to: Route, from: Route, next: any) {
    // this === undefined, so reference store directly
    if (store.getters['profile/isLoggedInWithEmail']) {
      next();
    } else {
      next({
        name: 'home',
        params: {
          redirectAfterLogIn: to.fullPath,
        },
      });
    }
  }
}
