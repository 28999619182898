




























import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import ReleaseSearch from '@/shared/components/onboarding/ReleaseSearch.vue';

@Component({
  components: {
    ReleaseSearch,
  },
})
export default class ModalDspSearch extends Vue {
  @Getter('layout/isMobile') isMobile: boolean;

  search: string = '';

  beforeDestroy() {
    this.exitModal();
  }

  leftIconClick() {
    this.$store.dispatch('retailerSearch/clearSearch');
    this.$nextTick(() => {
      this.exitModal();
    });
  }

  handleReleaseSelected() {
    this.exitModal();
  }

  exitModal() {
    this.search = '';
    document.body.focus();
    this.$root.$emit('bv::hide::modal', 'modal-dsp-search');
  }
}
