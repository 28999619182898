import {
  ActionTree, GetterTree, Module, MutationTree,
} from 'vuex';
import { RootState } from '@/shared/store/types';
import { Survey } from '@/shared/lib/survey';

export interface SurveyState {
  [key: string]: {
    enabled: boolean;
    dismissed: boolean;
  }
}

const initialState: SurveyState = {
  [Survey.Abandonment]: {
    enabled: false,
    dismissed: false,
  },
  [Survey.Results]: {
    enabled: false,
    dismissed: false,
  },
};

const actions: ActionTree<SurveyState, RootState> = {
  enableSurvey({ commit }, survey: Survey) : Promise<void> {
    commit('setSurveyEnabled', { survey, enabled: true });
    return Promise.resolve();
  },
  dismissSurvey({ commit }, survey: Survey): Promise<void> {
    commit('setSurveyDismissed', { survey });
    return Promise.resolve();
  },
};

const getters: GetterTree<SurveyState, RootState> = {
  showAbandonmentSurvey(state): boolean {
    return state[Survey.Abandonment].enabled
      && !state[Survey.Abandonment].dismissed;
  },
  showResultsSurvey(state): boolean {
    return state[Survey.Results].enabled
      && !state[Survey.Results].dismissed;
  },
};

const mutations: MutationTree<SurveyState> = {
  setSurveyEnabled(state, payload: { survey: Survey, enabled: boolean }) {
    state[payload.survey].enabled = payload.enabled;
    state[payload.survey].dismissed = false;
  },
  setSurveyDismissed(state, payload: { survey: Survey }) {
    state[payload.survey].dismissed = true;
  },
};

export const survey: Module<SurveyState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
