import {
  ActionTree, GetterTree, Module, MutationTree,
} from 'vuex';
import { Charge } from '@/shared/gen/messages.pisa';
import { RootState } from '@/shared/store/types';
import { BuilderService } from '@/shared/lib/api';
import { Empty } from '@/shared/gen/google/protobuf/google.protobuf';
import defaultToast from '@/shared/lib/defaultToast';

export interface BillingHistoryState {
  loading: boolean;
  loaded: boolean;
  history: Charge[];
}

const initialState: BillingHistoryState = {
  loading: false,
  loaded: false,
  history: [],
};

const getters: GetterTree<BillingHistoryState, RootState> = {
  getCharges(state): Charge[] {
    return state.history.slice(0);
  },
};

const actions: ActionTree<BillingHistoryState, RootState> = {
  load({ commit }): Promise<void> {
    commit('setLoading', true);
    return new Promise((resolve, reject) => {
      BuilderService(this.dispatch('profile/loggedIn'), (svc) => {
        svc.listCharges(new Empty(), {}).then((res) => {
          commit('setCharges', res.charges);
          resolve();
        }).catch((error: any) => {
          reject(error);
          defaultToast();
        }).finally(() => {
          commit('setLoading', false);
        });
      });
    });
  },
};

const mutations: MutationTree<BillingHistoryState> = {
  setLoading(state, payload: boolean) {
    state.loading = payload;
  },
  setCharges(state, payload: Charge[]) {
    state.history = payload;
    state.loaded = true;
  },
};

export const billingHistory: Module<BillingHistoryState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
