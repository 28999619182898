import { addMonths } from 'date-fns';
import docCookies from '@/shared/lib/docCookies';

export const enum Survey {
  Abandonment = 'abandonment',
  Results = 'results',
}

const surveyMap: { [key: string]: { copy: string, cta: string, url: string }} = {
  [Survey.Abandonment]: {
    copy: 'We noticed you didn\'t finish your campaign. May we ask why?',
    cta: 'Take Survey',
    url: 'https://zire1.typeform.com/to/moQx2Q',
  },
  [Survey.Results]: {
    copy: 'How are we doing? Share your thoughts on Zire.',
    cta: 'Give Feedback',
    url: 'https://zire1.typeform.com/to/YK1SHe',
  },
};

export function copyForSurvey(survey: Survey): string {
  return surveyMap[survey].copy;
}

export function ctaForSurvey(survey: Survey): string {
  return surveyMap[survey].cta;
}

export function urlForSurvey(survey: Survey, params: { [key: string]: string }): string {
  const qs = Object.keys(params).map((key) => `${key}=${params[key]}`).join('&');
  return `${surveyMap[survey].url}?${qs}`;
}

function cookieKey(survey: Survey, contextId: string) {
  return `zss-${survey}${contextId ? `-${contextId}` : ''}`;
}

export function saveSurveySeen(survey: Survey, contextId: string = '', expiryMonths: number = 2) {
  const expires = addMonths(new Date(), expiryMonths);
  const key = cookieKey(survey, contextId);
  docCookies.setItem(key, '1', expires);
}

export function surveySeen(survey: Survey, contextId: string = ''): boolean {
  return docCookies.getItem(cookieKey(survey, contextId)) !== null;
}
