































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class BackToBandlab extends Vue {
  @Prop({ default: '', type: String })
  link: string;

  navigationText = 'Back to BandLab';

  handleClick(e: MouseEvent, next: (e: Event) => void) {
    let emitClick = true;
    // Do not emit when clicked with control keys...
    if (e.metaKey || e.altKey || e.ctrlKey || e.shiftKey) {
      emitClick = false;
    }
    if (emitClick) {
      this.$emit('click', e);
    }
    next(e);
  }
}
