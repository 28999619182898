import {
  ActionTree, GetterTree, Module, MutationTree,
} from 'vuex';
import Vue from 'vue';
import * as firebase from 'firebase/app';
import Honeybadger from 'honeybadger-js';
import { RootState } from '@/shared/store/types';
import { FirebaseStorage, isCanceled, isRetryLimitExceeded } from '@/shared/firebase';
import { UserInterface } from '@/shared/store/profile';
import { maxImageFileSizeInB } from '@/shared/lib/defaultFileConstraints';
import Deferred from '@/shared/lib/deferred';
import uuid from '@/shared/lib/uuid';

import UploadTask = firebase.storage.UploadTask;
import UploadTaskSnapshot = firebase.storage.UploadTaskSnapshot;
import UploadMetadata = firebase.storage.UploadMetadata;

type UploadState = firebase.storage.TaskState;

export const STATE_CANCELED: UploadState = firebase.storage.TaskState.CANCELED;
export const STATE_ERROR: UploadState = firebase.storage.TaskState.ERROR;
export const STATE_PAUSED: UploadState = firebase.storage.TaskState.PAUSED;
export const STATE_RUNNING: UploadState = firebase.storage.TaskState.RUNNING;
export const STATE_SUCCESS: UploadState = firebase.storage.TaskState.SUCCESS;

export const InconsistentStateError = 'Internal state mismatch.';
export const FileExceedsMaximumAllowedSizeError = 'File exceeds maximum allowed size.';

const MAX_PARALLEL_UPLOADS = 2;

export interface Upload {
  id: string;
  path: string,
  name: string;
  type: string;
  bytesTransferred: number;
  totalBytes: number;
  state: UploadState | null;
  error?: any;
}

interface UploadInternal {
  readonly file: File;
  task?: UploadTask;
  readonly taskDeferred: Deferred<UploadInternal>;
  readonly finishedDeferred: Deferred<Upload>;
  readonly finished: Promise<Upload>;
}

const internalState: { [index:string]: UploadInternal } = {};

// For testing only!!!
export const TestingFunctions = {
  getInternalStateById: (id: string): UploadInternal => internalState[id],
  setInternalState: (value: typeof internalState) => {
    Object.keys(internalState).forEach((id) => {
      delete internalState[id];
    });
    Object.keys(value).forEach((id) => {
      internalState[id] = value[id];
    });
  },
};

export interface State {
  map: {
    [index: string]: Upload;
  };
  queue: string[];
  maxParallelUploads: number;
  activeUploads: string[];
}

const initialState: State = {
  map: {},
  queue: [],
  maxParallelUploads: MAX_PARALLEL_UPLOADS,
  activeUploads: [],
};

const toBasename = (name: string): string => (
  name.substring(name.lastIndexOf('/') + 1)
);

export interface PerformUploadPayload{
  id: string;
  path: string;
  metadata: UploadMetadata;
  data: Blob | Uint8Array | ArrayBuffer;
}

const actions: ActionTree<State, RootState> = {
  $_performUpload({ commit }, {
    id, path, metadata, data,
  }: PerformUploadPayload) : Promise<UploadTaskSnapshot> {
    const uploadTask: UploadTask = FirebaseStorage.ref(path).put(data, metadata);
    commit('setTask', { id, uploadTask });
    commit('recordProgress', { id, snapshot: uploadTask.snapshot });
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot: any) => {
      commit('recordProgress', { id, snapshot });
    });
    return uploadTask.then((snapshot: UploadTaskSnapshot) => {
      commit('recordProgress', { id, snapshot });
      return snapshot;
    });
  },
  // $_performUploadWithRetry is here because when using Google Chrome on Android with files from
  // Google Drive, it may fail with net::ERR_UPLOAD_FILE_CHANGED due to a bug in Chrome.  In order
  // to work around this issue, we will retry the upload with an ArrayBuffer instead of a File.
  // Retry is limited to files under +maxImageFileSizeInB+.
  // https://bugs.chromium.org/p/chromium/issues/detail?id=1063576
  $_performUploadWithRetry({ dispatch, getters }, payload: PerformUploadPayload) : Promise<UploadTaskSnapshot> {
    return dispatch('$_performUpload', { ...payload }).catch((reason) => {
      if (isRetryLimitExceeded(reason)) {
        const upload: Upload = getters.uploadById(payload.id);
        if (upload.bytesTransferred === 0) {
          const uploadInternal = internalState[upload.id];
          if (uploadInternal) {
            const { file }: { file: File } = uploadInternal;
            if (file.size <= maxImageFileSizeInB && typeof (file as any).arrayBuffer === 'function') {
              return (file as any).arrayBuffer()
                .then((buffer: ArrayBuffer) => dispatch('$_performUpload', { ...payload, data: buffer }))
                .catch(() => Promise.reject(reason));
            }
          }
        }
      }
      return Promise.reject(reason);
    });
  },
  $_processUploadQueue({ getters, dispatch, commit }) {
    if (!getters.$_canProcessUploadQueue) {
      return;
    }
    const upload: Upload = getters.$_nextUpload;
    if (!upload) {
      return;
    }
    const {
      id, path, name, type,
    } = upload;
    const uploadInternal: UploadInternal = internalState[id];
    if (!uploadInternal) {
      commit('recordError', { id, error: InconsistentStateError });
      Honeybadger.notify(InconsistentStateError, 'InconsistentStateError');
      return;
    }
    const { file }: { file: File } = uploadInternal;
    commit('$_startUpload', { id });
    dispatch('profile/loggedIn', undefined, { root: true }).then((user: UserInterface) => {
      const uploadPromise = dispatch('$_performUploadWithRetry', {
        id,
        path: `/uploads/user/${user.uid}/${path}`,
        metadata: {
          customMetadata: {
            originalFilename: name,
          },
        },
        data: file,
      });
      uploadPromise.then(() => {
        uploadInternal.finishedDeferred.resolve(upload);
      }, uploadInternal.finishedDeferred.reject);
      return uploadPromise;
    }).catch((error: any) => {
      if (isCanceled(error)) {
        commit('clear', { id });
        return;
      }
      let code;
      let serverResponse;
      const errorName: any = { name: 'UploadError' };
      if (error && error.code) {
        ({ code } = error);
        errorName.fingerprint = `${errorName.name} - ${code}`;
        if (error.serverResponse) {
          ({ serverResponse } = error);
        }
      }
      Honeybadger.notify(error, errorName, {
        context: {
          code,
          serverResponse,
          file: {
            name,
            type,
            size: file.size,
          },
        },
      });
      commit('recordError', { id, error });
    }).finally(() => {
      commit('$_finishUpload', { id });
      dispatch('$_processUploadQueue');
    });
  },
  uploadFiles({ dispatch }, { location, files }: { location: string, files: File[] }): Promise<string[]> {
    return Promise.all(files.map((file) => dispatch('uploadFile', { location, file })));
  },
  uploadFile({ dispatch, commit }, { location, file }: { location: string, file: File }): Promise<string> {
    const id = uuid();
    commit('enqueue', { id, location, file });
    dispatch('$_processUploadQueue');
    return Promise.resolve(id);
  },
  cancelUpload({ getters, commit }) {
    const id = getters.activeUploads[0];
    if (id) {
      commit('cancel', { id });
      commit('clear', { id });
    }
  },
};

const getters: GetterTree<State, RootState> = {
  $_canProcessUploadQueue(state): boolean {
    return state.activeUploads.length < state.maxParallelUploads;
  },
  $_nextUpload(state): Upload | null {
    for (let i = 0; i < state.queue.length; i += 1) {
      const id = state.queue[i];
      if (state.activeUploads.indexOf(id) === -1) {
        const upload = state.map[id];
        if (!upload.state && !upload.error) {
          return upload;
        }
      }
    }
    return null;
  },
  uploads(state): string[] {
    return state.queue.slice(0);
  },
  activeUploads(state): string[] {
    return state.activeUploads.slice(0);
  },
  uploadById(state): (id: string) => Upload {
    return (id: string): Upload => state.map[id];
  },
  isUploading(state): boolean {
    return state.activeUploads.length > 0;
  },
  uploadFileName(state): (id: string) => string {
    return (id: string): string => {
      const upload = state.map[id];
      if (upload) {
        return upload.name;
      }
      return '';
    };
  },
  uploadPercentage(state): (id: string) => number {
    return (id: string): number => {
      const upload = state.map[id];
      if (upload) {
        const { totalBytes, bytesTransferred } = upload;
        // Prevent returning NaN
        if (totalBytes > 0 && bytesTransferred > 0) {
          if (bytesTransferred > totalBytes) {
            return 100;
          }
          return parseInt(((bytesTransferred / totalBytes) * 100).toFixed(0), 10);
        }
      }
      return 0;
    };
  },
  uploadPromiseById(): (id: string) => Promise<Upload> {
    return (id) => internalState[id].finished;
  },
};

const mutations: MutationTree<State> = {
  $_startUpload(state, { id }: { id: string }) {
    state.activeUploads.push(id);
  },
  $_finishUpload(state, { id }: { id: string }) {
    const index = state.activeUploads.indexOf(id);
    if (index !== -1) {
      state.activeUploads.splice(index, 1);
    }
  },
  enqueue(state, { id, location, file }: { id: string, location: string, file: File }) {
    let path = id;
    if (location) {
      path = `${location}/${id}`.replace(/\/+/, '/').replace(/^\/+/, '');
    }
    Vue.set(state.map, id, {
      id,
      path,
      name: toBasename(file.name),
      type: file.type,
      bytesTransferred: 0,
      totalBytes: 0,
      state: null,
      error: null,
    });
    const taskDeferred: Deferred<UploadInternal> = new Deferred();
    const finishedDeferred: Deferred<Upload> = new Deferred();
    internalState[id] = {
      file,
      taskDeferred,
      finishedDeferred,
      finished: finishedDeferred.promise,
    };
    state.queue.push(id);
  },
  setTask(state, { id, uploadTask }: { id: string, uploadTask: UploadTask }) {
    const uploadInternal = internalState[id];
    if (uploadInternal) {
      uploadInternal.task = uploadTask;
      uploadInternal.taskDeferred.resolve(uploadInternal);
    } else {
      uploadTask.cancel();
    }
  },
  recordProgress(state, { id, snapshot }: { id: string, snapshot: UploadTaskSnapshot }) {
    const upload = state.map[id];
    if (upload) {
      upload.state = snapshot.state;
      upload.bytesTransferred = snapshot.bytesTransferred;
      upload.totalBytes = snapshot.totalBytes;
    }
  },
  recordError(state, { id, error }: { id: string, error: any }) {
    const upload = state.map[id];
    if (upload) {
      upload.error = error;
    }
  },
  cancel(state, { id }: { id: string }) {
    const uploadInternal = internalState[id];
    if (uploadInternal) {
      uploadInternal.taskDeferred.promise.then((u) => u.task!.cancel());
    }
  },
  pause(state, { id }: { id: string }) {
    const uploadInternal = internalState[id];
    if (uploadInternal) {
      uploadInternal.taskDeferred.promise.then((u) => u.task!.pause());
    }
  },
  resume(state, { id }: { id: string }) {
    const uploadInternal = internalState[id];
    if (uploadInternal) {
      uploadInternal.taskDeferred.promise.then((u) => u.task!.resume());
    }
  },
  clear(state, { id }: { id: string }) {
    const index = state.queue.indexOf(id);
    if (index !== -1) {
      state.queue.splice(index, 1);
    }
    delete internalState[id];
    delete state.map[id];
  },
};

export const upload: Module<State, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
