import {
  ActionTree, GetterTree, Module, MutationTree,
} from 'vuex';
import Vue from 'vue';
import { RootState } from '@/shared/store/types';
import {
  Creative as APICreative,
  ReportRequest,
  ReportRequest_Breakdown as ReportRequestBreakdown,
  ReportRequest_RegionBreakdownOptions as RegionBreakdownOptions,
  ReportResponse,
  ReportResponse_Report as ReportResponseReport,
  ReportResponse_Report_Audio as ReportResponseReportAudio,
  ReportResponse_Report_SocialActivity as ReportResponseSocialActivity,
  ReportResponse_Report_PromoPage as ReportResponsePromoPage,
} from '@/shared/gen/messages.pisa';
import { BuilderService } from '@/shared/lib/api';
import { parseDate, parseIntFromString } from '@/shared/lib/protobuf';
import SingleFlight from '@/shared/lib/singleFlight';

export interface ReportAudio {
  id: number;
  durationMs: number;
  plays: number;
  progress?: {
    durationMs: number;
    percentage: number;
    count: number;
  }[];
  feedback?: {
    rating: number;
    percentage: number;
  }[];
  averageFeedback: number;
  feedbackEnabled: boolean;
}

export interface ReportSocialActivity {
  reactions: number;
  shares: number;
  pageLikes: number;
  linkClicks: number;
}

export interface ReportPromoPage {
  url: string;
  impressions: number;
}

export interface ReportSocialDemographics {
  ageRange: string;
  female: {
    impressions: number;
    interactions: number;
  };
  male: {
    impressions: number;
    interactions: number;
  };
}

export interface MinimalReport {
  impressions: number;
  interactions: number;
}

export interface CampaignReport {
  impressions: number;
  interactions: number;
  clicks: number;
  retailers: {
    retailer: string;
    clicks: number;
  }[];
  audio: ReportAudio[];
  socialActivity: ReportSocialActivity;
  promoPage: ReportPromoPage;
}

function processReportAudio(audio: ReportResponseReportAudio[]) : ReportAudio[] {
  return audio.map((i) => ({
    id: parseIntFromString(i.id, 10),
    durationMs: i.durationMs || 0,
    plays: i.plays || 0,
    progress: i.progress.map((p) => ({
      durationMs: p.durationMs || 0,
      percentage: p.percentage || 0,
      count: p.count || 0,
    })),
    feedback: i.feedback.map((f) => ({
      rating: f.rating,
      percentage: f.percentage || 0,
    })),
    averageFeedback: i.averageFeedback || 0,
    feedbackEnabled: !!i.feedbackEnabled,
  }));
}

function processReportSocialActivity(socialActivity: ReportResponseSocialActivity) : ReportSocialActivity {
  return {
    reactions: socialActivity.reactions || 0,
    shares: socialActivity.shares || 0,
    pageLikes: socialActivity.pageLikes || 0,
    linkClicks: socialActivity.linkClicks || 0,
  };
}

function processReportPromoPage(page: ReportResponsePromoPage) : ReportPromoPage {
  return {
    url: page.url || '',
    impressions: page.impressions || 0,
  };
}

function processCampaignReport(report: ReportResponseReport) : CampaignReport {
  return {
    impressions: report.impressions || 0,
    interactions: report.interactions || 0,
    clicks: report.clicks || 0,
    retailers: report.retailers.map((i) => ({
      retailer: i.retailer,
      clicks: i.clicks || 0,
    })),
    audio: processReportAudio(report.audio),
    socialActivity: processReportSocialActivity(report.socialActivity),
    promoPage: processReportPromoPage(report.promoPage),
  };
}

function processMinimalReport(report: ReportResponseReport) : MinimalReport {
  return {
    impressions: report.impressions || 0,
    interactions: report.interactions || 0,
  };
}

export const enum ReportType {
  Campaign = 'campaign',
  Daily = 'daily',
  Country = 'country',
  Region = 'region',
  Domain = 'domain',
  Creative = 'creative',
  Image = 'image',
  Copy = 'copy',
  Demo = 'demo',
}

type ReportTypeValues = typeof ReportType[keyof typeof ReportType];

const reportBreakdownMap = {
  [ReportType.Campaign]: ReportRequestBreakdown.BREAKDOWN_NONE,
  [ReportType.Daily]: ReportRequestBreakdown.BREAKDOWN_DATE,
  [ReportType.Domain]: ReportRequestBreakdown.BREAKDOWN_DOMAIN,
  [ReportType.Country]: ReportRequestBreakdown.BREAKDOWN_COUNTRY,
  [ReportType.Region]: ReportRequestBreakdown.BREAKDOWN_REGION,
  [ReportType.Creative]: ReportRequestBreakdown.BREAKDOWN_CREATIVE,
  [ReportType.Image]: ReportRequestBreakdown.BREAKDOWN_IMAGE,
  [ReportType.Copy]: ReportRequestBreakdown.BREAKDOWN_COPY,
  [ReportType.Demo]: ReportRequestBreakdown.BREAKDOWN_DEMO,
};

function breakdownForReport(report: ReportTypeValues) : ReportRequestBreakdown {
  const breakdown = reportBreakdownMap[report];
  if (breakdown) {
    return breakdown;
  }
  throw new Error(`unknown report type '${report}'`);
}

function reportForBreakdown(breakdown: ReportRequestBreakdown) : ReportTypeValues {
  const entry = Object.entries(reportBreakdownMap).find((i) => breakdown === i[1]);
  if (entry) {
    return <ReportTypeValues>entry[0];
  }
  throw new Error(`unknown breakdown '${breakdown}'`);
}

export interface DailyReport {
  date: Date,
  report: MinimalReport,
}

export interface DomainReport {
  domain: string,
  retargeted: boolean,
  report: MinimalReport,
}

export interface CountryReport {
  country: string,
  countryCode: string,
  hasRegions: boolean,
  report: MinimalReport,
}

export interface RegionReport {
  region: string,
  report: MinimalReport,
}

export interface CreativeReport {
  report: MinimalReport,
  creative: APICreative,
}

export interface ImageReport {
  id: number,
  type: 'member_image' | 'user_image',
  url: string,
  theme: string,
  report: MinimalReport,
}

export interface CopyReport {
  header: string,
  subheader: string,
  report: MinimalReport,
}

export interface DemoReport {
  gender: string,
  ageRange: string,
  report: MinimalReport,
}

export interface CampaignReportsState {
  reports: {
    [campaignId: string]: {
      $meta: {
        loadedReports: ReportTypeValues[],
        loadedRegionCountryCodes: string[],
        unlocked: boolean,
        unlocksAt?: Date,
      }
      [ReportType.Campaign]?: CampaignReport,
      [ReportType.Daily]?: DailyReport[],
      [ReportType.Domain]?: DomainReport[],
      [ReportType.Country]?: CountryReport[],
      [ReportType.Region]?: {
        [countryCode: string]: RegionReport[],
      },
      [ReportType.Creative]?: CreativeReport[],
      [ReportType.Image]?: ImageReport[],
      [ReportType.Copy]?: CopyReport[],
      [ReportType.Demo]?: DemoReport[],
    },
  },
  loadingReportKeys: string[],
}

const initialState: CampaignReportsState = {
  reports: {},
  loadingReportKeys: [],
};

function $reportKey(campaignId: string, t: ReportTypeValues) {
  return `${campaignId}:${breakdownForReport(t)}`;
}

export function reportKey(campaignId: string, t: Exclude<ReportTypeValues, ReportType.Region>) {
  return $reportKey(campaignId, t);
}

export function regionReportKey(campaignId: string, countryCode: string) {
  if (!countryCode) {
    throw new Error('region report requires country code');
  }
  return `${$reportKey(campaignId, ReportType.Region)}:${countryCode}`;
}

const singleFlight = new SingleFlight();

type ReportsPayload = { campaignId: string, reports: ReportType[], countryCodes?: string[] };

function payloadToReportKeys(payload: ReportsPayload): string[] {
  const reportKeys: string[] = [];
  payload.reports.forEach((i) => {
    if (i === ReportType.Region) {
      (payload.countryCodes || []).forEach((j) => {
        reportKeys.push(regionReportKey(payload.campaignId, j));
      });
    } else {
      reportKeys.push(reportKey(payload.campaignId, i));
    }
  });
  return reportKeys;
}

const actions: ActionTree<CampaignReportsState, RootState> = {
  getReports({ dispatch, getters }, payload: ReportsPayload): Promise<void> {
    const toLoad: ReportsPayload = {
      campaignId: payload.campaignId,
      reports: [],
      countryCodes: [],
    };
    payload.reports.forEach((i) => {
      if (i === ReportType.Region) {
        (payload.countryCodes || []).forEach((countryCode) => {
          if (!getters.regionReportsLoaded(payload.campaignId, countryCode)) {
            toLoad.countryCodes!.push(countryCode);
          }
        });
        if (toLoad.countryCodes!.length > 0) {
          toLoad.reports.push(i);
        }
      } else if (!getters.$reportLoaded(payload.campaignId, i)) {
        toLoad.reports.push(i);
      }
    });
    if (toLoad.reports.length > 0) {
      return dispatch('loadReports', toLoad);
    }
    return Promise.resolve();
  },
  requestExport(_, payload: ReportsPayload): Promise<void> {
    return new Promise((resolve, reject) => {
      BuilderService(this.dispatch('profile/loggedIn'), (svc) => {
        const req = new ReportRequest({
          campaignId: payload.campaignId,
          breakdowns: [],
        });
        svc.requestExport(req).then(() => resolve()).catch((err) => reject(err));
      });
    });
  },
  loadReports({ commit }, payload: ReportsPayload): Promise<void> {
    const { campaignId, reports, countryCodes } = payload;

    let execPromise: Promise<any>;
    const execFunction = () => {
      if (!execPromise) {
        execPromise = new Promise((resolve, reject) => {
          const breakdowns = reports.map(breakdownForReport);
          BuilderService(this.dispatch('profile/loggedIn'), (svc) => {
            const req = new ReportRequest({ campaignId, breakdowns });
            if (countryCodes && countryCodes.length > 0) {
              req.regionBreakdownOptions = new RegionBreakdownOptions({ countryCodes });
            }
            svc.getReports(req).then((resp: ReportResponse) => {
              commit('setReports', { request: req, response: resp });
            }).then(resolve, reject);
          });
        });
      }
      return execPromise;
    };

    const reportKeys = payloadToReportKeys(payload);
    commit('setLoading', reportKeys);
    return Promise.all(reportKeys.map((i) => singleFlight.do(i, execFunction).finally(() => {
      commit('clearLoading', [i]);
    }))).then(() => {});
  },
  clearReports({ commit }, campaignId: string) {
    commit('clearReports', campaignId);
  },
  loadSampleReport({ commit }, payload: any) {
    // commit('setReports', { sampleReq, sampleResp });
    commit('setSampleReport', payload);
  },
};

const mutations: MutationTree<CampaignReportsState> = {
  setReports(state, { request, response }: { request: ReportRequest, response: ReportResponse }) {
    const { campaignId } = response;
    if (!state.reports[campaignId]) {
      Vue.set(state.reports, campaignId, {
        $meta: {
          loadedReports: [],
          loadedRegionCountryCodes: [],
          unlocked: response.unlocked,
          unlocksAt: parseDate(response.unlocksAt),
        },
      });
    }
    const reports = state.reports[campaignId];
    reports.$meta.loadedReports = [
      ...reports.$meta.loadedReports,
      ...request.breakdowns.map(reportForBreakdown),
    ];
    if (request.regionBreakdownOptions && request.regionBreakdownOptions.countryCodes.length) {
      reports.$meta.loadedRegionCountryCodes = [
        ...reports.$meta.loadedRegionCountryCodes,
        ...request.regionBreakdownOptions.countryCodes,
      ];
    }
    if (request.breakdowns.indexOf(ReportRequestBreakdown.BREAKDOWN_NONE) !== -1) {
      Vue.set(reports, ReportType.Campaign, processCampaignReport(response.report));
    }
    if (!reports[ReportType.Daily] || response.dateReports.length > 0) {
      const dailyReports = response.dateReports.map((i) => ({
        date: new Date(i.date),
        report: processMinimalReport(i.report),
      }));
      Vue.set(reports, ReportType.Daily, dailyReports);
    }
    if (!reports[ReportType.Domain] || response.domainReports.length > 0) {
      const domainReports = response.domainReports.map((i) => ({
        domain: i.domain,
        retargeted: i.retargeted,
        report: processMinimalReport(i.report),
      }));
      Vue.set(reports, ReportType.Domain, domainReports);
    }
    if (!reports[ReportType.Country] || response.countryReports.length > 0) {
      const countryReports = response.countryReports.map((i) => ({
        country: i.country,
        countryCode: i.countryCode,
        hasRegions: i.hasRegions,
        report: processMinimalReport(i.report),
      }));
      Vue.set(reports, ReportType.Country, countryReports);
    }
    if (!reports[ReportType.Region] || response.regionReports.length > 0) {
      if (!reports.region) {
        Vue.set(reports, ReportType.Region, {});
      }
      response.regionReports.forEach((i) => {
        const regionsReport = i.regions.map((j) => ({
          region: j.region,
          report: processMinimalReport(j.report),
        }));
        Vue.set(reports.region!, i.countryCode, regionsReport);
      });
    }
    if (!reports[ReportType.Creative] || response.creativeReports.length > 0) {
      const creativeReports = response.creativeReports.map((i) => ({
        report: processMinimalReport(i.report),
        creative: i.creative,
      }));
      Vue.set(reports, ReportType.Creative, creativeReports);
    }
    if (!reports[ReportType.Image] || response.imageReports.length > 0) {
      Vue.set(reports, ReportType.Image, response.imageReports.map((imageReport) => ({
        id: imageReport.id,
        type: imageReport.type,
        url: imageReport.url,
        theme: imageReport.theme,
        report: processMinimalReport(imageReport.report),
      })));
    }
    if (!reports[ReportType.Copy] || response.copyReports.length > 0) {
      Vue.set(reports, ReportType.Copy, response.copyReports.map((copyReport) => ({
        header: copyReport.header,
        subheader: copyReport.subheader,
        report: processMinimalReport(copyReport.report),
      })));
    }
    if (!reports[ReportType.Demo] || response.demoReports.length > 0) {
      Vue.set(reports, ReportType.Demo, response.demoReports.map((demoReport) => ({
        gender: demoReport.gender,
        ageRange: demoReport.ageRange,
        report: processMinimalReport(demoReport.report),
      })));
    }
  },
  clearReports(state, campaignId: string) {
    Vue.delete(state.reports, campaignId);
  },
  setSampleReport(state, payload: any) {
    const { sampleReports, loadedRegions } = payload;
    const campaignId = '-1';
    Vue.set(state.reports, campaignId, {
      $meta: {
        loadedReports: [],
        loadedRegionCountryCodes: [],
        unlocked: true,
        unlocksAt: parseDate('2020-07-04T00:00:00'),
      },
    });

    const reports = state.reports[campaignId];
    const samples = Object.keys(sampleReports);
    samples.forEach((sample: string) => {
      if (sampleReports[sample]) {
        Vue.set(reports, sample, sampleReports[sample]);
      }
    });
    state.reports[campaignId].$meta.loadedReports = state.reports[campaignId].$meta.loadedReports.concat([
      ReportType.Campaign,
      ReportType.Daily,
      ReportType.Country,
      ReportType.Region,
      ReportType.Domain,
      ReportType.Creative,
      ReportType.Image,
      ReportType.Copy,
      ReportType.Demo,
    ]);
    state.reports[campaignId].$meta.loadedRegionCountryCodes = state.reports[campaignId].$meta.loadedRegionCountryCodes.concat(loadedRegions);
  },
  setLoading(state, keys: string[]) {
    state.loadingReportKeys.push(...keys);
  },
  clearLoading(state, keys: string[]) {
    keys.forEach((i) => {
      const index = state.loadingReportKeys.indexOf(i);
      if (index !== -1) {
        state.loadingReportKeys.splice(index, 1);
      }
    });
  },
};

const getters: GetterTree<CampaignReportsState, RootState> = {
  reports(state) {
    return (campaignId: string) => state.reports[campaignId];
  },
  unlocked(state, g) {
    return (campaignId: string) => {
      const reports = g.reports(campaignId);
      if (reports) {
        return !!reports.$meta.unlocked;
      }
      return false;
    };
  },
  campaignReport(state, g) {
    return (campaignId: string): CampaignReport | undefined => {
      if (g.campaignReportLoaded(campaignId)) {
        return state.reports[campaignId].campaign;
      }
      return undefined;
    };
  },
  dailyReports(state, g) {
    return (campaignId: string): DailyReport[] | undefined => {
      if (g.dailyReportsLoaded(campaignId)) {
        return state.reports[campaignId].daily;
      }
      return undefined;
    };
  },
  domainReports(state, g) {
    return (campaignId: string): DomainReport[] | undefined => {
      if (g.domainReportsLoaded(campaignId)) {
        return state.reports[campaignId].domain;
      }
      return undefined;
    };
  },
  countryReports(state, g) {
    return (campaignId: string): CountryReport[] | undefined => {
      if (g.countryReportsLoaded(campaignId)) {
        return state.reports[campaignId].country;
      }
      return undefined;
    };
  },
  regionReports(state, g) {
    return (campaignId: string, countryCode: string): RegionReport[] | undefined => {
      if (g.regionReportsLoaded(campaignId, countryCode)) {
        return state.reports[campaignId].region![countryCode];
      }
      return undefined;
    };
  },
  allRegionReports(state) {
    return (campaignId: string): { [countryCode: string]: RegionReport[] } => {
      if (state.reports[campaignId]) {
        return state.reports[campaignId].region || {};
      }
      return {};
    };
  },
  creativeReports(state, g) {
    return (campaignId: string): CreativeReport[] | undefined => {
      if (g.creativeReportsLoaded(campaignId)) {
        return state.reports[campaignId].creative;
      }
      return undefined;
    };
  },
  imageReports(state, g) {
    return (campaignId: string): ImageReport[] | undefined => {
      if (g.imageReportsLoaded(campaignId)) {
        return state.reports[campaignId].image;
      }
      return undefined;
    };
  },
  copyReports(state, g) {
    return (campaignId: string): CopyReport[] | undefined => {
      if (g.copyReportsLoaded(campaignId)) {
        return state.reports[campaignId].copy;
      }
      return undefined;
    };
  },
  demoReports(state, g) {
    return (campaignId: string): DemoReport[] | undefined => {
      if (g.demoReportsLoaded(campaignId)) {
        return state.reports[campaignId].demo;
      }
      return undefined;
    };
  },
  allReports(state) {
    return (campaignId: string): any | undefined => state.reports[campaignId];
  },
  $reportKeyLoading(state) {
    return (key: string): boolean => (state.loadingReportKeys.indexOf(key) !== -1);
  },
  campaignReportLoading(state, g) {
    return (campaignId: string): boolean => g.$reportKeyLoading(reportKey(campaignId, ReportType.Campaign));
  },
  dailyReportsLoading(state, g) {
    return (campaignId: string): boolean => g.$reportKeyLoading(reportKey(campaignId, ReportType.Daily));
  },
  domainReportsLoading(state, g) {
    return (campaignId: string): boolean => g.$reportKeyLoading(reportKey(campaignId, ReportType.Domain));
  },
  countryReportsLoading(state, g) {
    return (campaignId: string): boolean => g.$reportKeyLoading(reportKey(campaignId, ReportType.Country));
  },
  regionReportsLoading(state, g) {
    return (campaignId: string, countryCode: string): boolean => g.$reportKeyLoading(regionReportKey(campaignId, countryCode));
  },
  creativeReportsLoading(state, g) {
    return (campaignId: string): boolean => g.$reportKeyLoading(reportKey(campaignId, ReportType.Creative));
  },
  imageReportsLoading(state, g) {
    return (campaignId: string): boolean => g.$reportKeyLoading(reportKey(campaignId, ReportType.Image));
  },
  copyReportsLoading(state, g) {
    return (campaignId: string): boolean => g.$reportKeyLoading(reportKey(campaignId, ReportType.Copy));
  },
  demoReportsLoading(state, g) {
    return (campaignId: string): boolean => g.$reportKeyLoading(reportKey(campaignId, ReportType.Demo));
  },
  $reportLoaded(state) {
    return (campaignId: string, reportType: ReportTypeValues): boolean => (
      state.reports[campaignId] && state.reports[campaignId].$meta.loadedReports.indexOf(reportType) !== -1
    );
  },
  campaignReportLoaded(state, g) {
    return (campaignId: string): boolean => g.$reportLoaded(campaignId, ReportType.Campaign);
  },
  dailyReportsLoaded(state, g) {
    return (campaignId: string): boolean => g.$reportLoaded(campaignId, ReportType.Daily);
  },
  domainReportsLoaded(state, g) {
    return (campaignId: string): boolean => g.$reportLoaded(campaignId, ReportType.Domain);
  },
  countryReportsLoaded(state, g) {
    return (campaignId: string): boolean => g.$reportLoaded(campaignId, ReportType.Country);
  },
  regionReportsLoaded(state) {
    return (campaignId: string, countryCode: string): boolean => (
      state.reports[campaignId] && state.reports[campaignId].$meta.loadedRegionCountryCodes.indexOf(countryCode) !== -1
    );
  },
  creativeReportsLoaded(state, g) {
    return (campaignId: string): boolean => g.$reportLoaded(campaignId, ReportType.Creative);
  },
  imageReportsLoaded(state, g) {
    return (campaignId: string): boolean => g.$reportLoaded(campaignId, ReportType.Image);
  },
  copyReportsLoaded(state, g) {
    return (campaignId: string): boolean => g.$reportLoaded(campaignId, ReportType.Copy);
  },
  demoReportsLoaded(state, g) {
    return (campaignId: string): boolean => g.$reportLoaded(campaignId, ReportType.Demo);
  },
};

export const campaignReports: Module<CampaignReportsState, RootState> = {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
  getters,
};
