
















































































































































import {
  Component, Provide, Watch,
} from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { validationMixin } from 'vuelidate';
import { isBefore } from 'date-fns';

import SiteLayout from '@/shared/components/site/SiteLayout.vue';
import LoadingIndicator from '@/shared/components/campaign/LoadingIndicator.vue';
import CampaignCard from '@/shared/components/campaign/CampaignCard.vue';

import { Draft } from '@/shared/store/onboarding';
import AbortedCampaignCard from '@/shared/components/campaign/AbortedCampaignCard.vue';
import CompletedCampaignCard from '@/shared/components/campaign/CompletedCampaignCard.vue';
import DraftCard from '@/shared/components/campaign/DraftCard.vue';
import { Campaign, CampaignListItem, CampaignsTab } from '@/shared/store/campaign';
import { Status } from '@/shared/lib/campaign_status';
import Pagination from '@/shared/components/common/Pagination.vue';
import CallToAction from '@/shared/components/common/CallToAction.vue';
import defaultToast from '@/shared/lib/defaultToast';
import AuthRequired from '@/shared/components/AuthRequired';

@Component({
  mixins: [validationMixin],
  components: {
    CallToAction,
    Pagination,
    LoadingIndicator,
    DraftCard,
    CompletedCampaignCard,
    AbortedCampaignCard,
    SiteLayout,
    CampaignCard,
  },
  validations: {
  },
})
export default class Campaigns extends AuthRequired {
    @Getter('campaign/campaignsLoaded') campaignsLoaded: boolean;

    @Getter('campaign/campaignsLoading') campaignsLoading: boolean;

    @Getter('campaign/running') activeCampaigns: CampaignListItem[];

    @Getter('campaign/ended') endedCampaigns: CampaignListItem[];

    @Getter('campaign/drafts') drafts: Draft[];

    endedPagination = {
      perPage: 20,
      currentPage: 1,
    };

    // TODO once we have a mechanism for determining this, use it, right now always false.
    hasNewlyEndedCampaigns: boolean = false;

    get currentTab() {
      return this.$store.getters['campaign/selectedTab'];
    }

    set currentTab(val) {
      this.$store.dispatch('campaign/setSelectedTab', val);
    }

    @Watch('currentTab')
    currentTabWatch(newVal: number) {
      if (newVal === CampaignsTab.Completed) {
        this.hasNewlyEndedCampaigns = false;
      }
    }

    get hasActiveCampaigns() {
      return this.activeCampaigns.length > 0;
    }

    get hasDrafts() {
      return this.draftCampaigns.length > 0;
    }

    get hasEndedCampaigns() {
      return this.endedCampaigns.length > 0;
    }

    get sortedEndedCampaigns() {
      const sortedEndedCampaigns = this.endedCampaigns.slice(0)
        .sort((a, b) => (isBefore(b.campaign.endedAt!, a.campaign.endedAt!) ? -1 : 1));
      const featuredIdx = sortedEndedCampaigns.findIndex((c) => c.campaign.status === Status.Completed);
      if (featuredIdx > 0) { // If it is already 0, no need to do anything...
        sortedEndedCampaigns.unshift(...sortedEndedCampaigns.splice(featuredIdx, 1));
      }
      return sortedEndedCampaigns;
    }

    get endedCampaignItemsPage() {
      const begin = ((this.endedPagination.currentPage - 1) * this.endedPagination.perPage);
      const end = begin + this.endedPagination.perPage;
      return this.sortedEndedCampaigns.slice(begin, end);
    }

    get rejectedCampaignItems() {
      return this.activeCampaigns
        .filter((c) => c.campaign.status === Status.Rejected)
        .sort((a, b) => (isBefore(b.campaign.submittedAt!, a.campaign.submittedAt!) ? -1 : 1));
    }

    get reviewingCampaignItems() {
      return this.activeCampaigns
        .filter((c) => c.campaign.status === Status.Reviewing)
        .sort((a, b) => (isBefore(b.campaign.submittedAt!, a.campaign.submittedAt!) ? -1 : 1));
    }

    get runningCampaignItems() {
      return this.activeCampaigns
        .filter((c) => c.campaign.status === Status.Running)
        .sort((a, b) => (isBefore(b.campaign.activatedAt!, a.campaign.activatedAt!) ? -1 : 1));
    }

    get draftCampaigns() {
      return [...this.drafts];
    }

    beforeCreate() {
      Promise.all([
        this.$store.dispatch('campaign/list'),
        this.$store.dispatch('campaign/listDrafts'),
      ]).catch((reason) => {
        if (reason.code !== 'unauthenticated') {
          defaultToast('Failed to load campaigns.  Please try again.');
        }
      });
    }

    mounted() {
      this.$store.dispatch('mixpanel/resetTrackOnceEventList');
    }

    newCampaign() {
      return this.$router.push({
        name: 'new-campaign',
      });
    }

    deleteDraft(id: number) {
      return this.$store.dispatch('onboarding/delete', id).then(() => {
        this.$store.dispatch('campaign/listDrafts');
      });
    }

    isCompleted(c: Campaign) {
      return c.status === Status.Completed;
    }

    @Provide('deleteDraft') deleteDraftInjection: Function = this.deleteDraft;
}
