



















import { Component, Prop, Vue } from 'vue-property-decorator';

enum Themes {
  Transparent = 'transparent',
  Dark = 'dark',
}

@Component
export default class LoadingIndicator extends Vue {
  @Prop({ default: false })
  active: boolean;

  @Prop({ default: 'Loading...' })
  message: string;

  @Prop({
    default: 'transparent',
    validator: (theme: any) => Object.values(Themes).includes(theme),
  })
  theme: string;

  @Prop({ default: '70px' })
  iconSize: string;
}
