import docCookies from '@/shared/lib/docCookies'; // TODO: Include in package.json?
import uuid from '@/shared/lib/uuid';

const cookieDeviceUUID = docCookies.getItem('di') || '';
const deviceUUID = cookieDeviceUUID || uuid();
if (cookieDeviceUUID !== deviceUUID) {
  const expires = new Date();
  expires.setDate(expires.getDate() + 30);
  docCookies.setItem('di', deviceUUID, expires);
}

const cookieSessionUUID = docCookies.getItem('si') || '';
const sessionUUID = cookieSessionUUID || uuid();
if (cookieSessionUUID !== sessionUUID) {
  docCookies.setItem('si', sessionUUID);
}

export {
  deviceUUID,
  sessionUUID,
};
