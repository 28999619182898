









































































import {
  Component, Inject, Prop, Vue,
} from 'vue-property-decorator';
import format from 'date-fns/format';
import { Draft } from '@/shared/store/onboarding';
import LoadingIndicator from '@/shared/components/campaign/LoadingIndicator.vue';
import { campaignAudio } from '@/shared/lib/campaign';

@Component({
  components: { LoadingIndicator },
})
export default class DraftCard extends Vue {
  @Prop({ required: true }) private draft!: Draft;

  loading: boolean = false;

  deleting: boolean = false;

  get campaignAudio() {
    return campaignAudio(this.draft);
  }

  get songTitle() {
    if (this.campaignAudio) {
      return this.campaignAudio.source.name;
    }
    return '';
  }

  get updateDate() {
    return this.draft.updatedAt ? format(this.draft.updatedAt, 'MMM d, yyyy') : '';
  }

  handleClick() {
    this.loading = true;
    this.$router.push({
      name: 'recommended-ads',
      params: { id: this.draft.id! },
      query: { source: 'campaign-list' },
    }).then(() => {
      this.loading = false;
    });
  }

  confirmDeletion() {
    this.loading = true;
    this.deleteDraft(this.draft.id).then(() => {
      this.loading = false;
    });
  }

  @Inject() readonly deleteDraft: Function;
}
