import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faCreditCard,
  faExclamationSquare,
  faExclamationTriangle as fasExclamationTriangle,
  faLightbulb,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faImage,
  faLink as fasLink,
  faMusic,
  faStar as fasStar,
  faStarHalfAlt,
  faSpinner,
  faPlay,
  faUser,
  faUserCircle,
  faCircle as fasCircle,
  faCaretUp,
  faCaretDown,
  faFemale,
  faMale,
  faUserPlus,
  faList,
  faTh,
  faQuestionCircle as fasQuestionCircle,
  faPlus as fasPlus,
  faPause as fasPause,
  faPencil as fasPencil,
  faCheck as fasCheck,
  faCheckCircle as fasCheckCircle,
  faTrashAlt,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faSignOutAlt,
  faCircleNotch,
  faArrowLeft,
  faArrowRight,
  faEllipsisH,
  faExternalLinkAlt,
  faPlus,
  faPlusCircle,
  faMinus,
  faMinusCircle,
  faCheckCircle,
  faAngleUp,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faTimes,
  faCheck,
  faPencil,
  faUndoAlt,
  faEye,
  faEyeSlash,
  faExclamationTriangle,
  faTrash,
  faQuestionCircle,
  faImages,
  faStar,
  faLink,
  faSearch,
  faHandPointUp,
  faLongArrowAltRight,
  faMusic as farMusic,
  faPause,
  faSatelliteDish,
  faCarBump,
  faSignIn,
  faSignOut,
  faGlobe,
  faThumbsUp,
  faComment,
  faShare,
  faBookmark,
  faHeart,
  faPaperPlane,
  faUserSecret,
  faUserAstronaut,
  faDesktop,
  faRedo,
  faPowerOff,
  faChartLine,
  faUsers,
  faBolt,
  faStopwatch,
  faMapMarkerAlt,
  faText,
  faRetweet,
} from '@fortawesome/pro-regular-svg-icons';

import {
  faHandPointUp as falHandPointUp,
  faEye as falEye,
  faTimes as falTimes,
} from '@fortawesome/pro-light-svg-icons';

import {
  faFacebook,
  faTwitter,
  faInstagram,
  faCcAmex,
  faCcDinersClub,
  faCcDiscover,
  faCcJcb,
  faCcMastercard,
  faCcVisa,
  faSpotify,
  faApple,
  faSellsy,
} from '@fortawesome/free-brands-svg-icons';

const icons: IconDefinition[] = [
  faAngleDown,
  faAngleUp,
  faCircleNotch,
  faArrowLeft,
  faArrowRight,
  faBookmark,
  faCheck,
  fasCheck,
  faCircleNotch,
  faCreditCard,
  faMusic,
  faImage,
  faPlus,
  fasPlus,
  faPlusCircle,
  faMinus,
  faMinusCircle,
  faCheckCircle,
  faAngleLeft,
  faAngleRight,
  faHandPointUp,
  falHandPointUp,
  faEye,
  faTimes,
  faExclamationSquare,
  faExternalLinkAlt,
  faLightbulb,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faPencil,
  fasPencil,
  faUndoAlt,
  faFacebook,
  faTwitter,
  faInstagram,
  faUser,
  faUserCircle,
  faEllipsisH,
  falEye,
  falTimes,
  faGlobe,
  faHeart,
  faThumbsUp,
  faComment,
  faShare,
  faEyeSlash,
  faCcAmex,
  faCcDinersClub,
  faCcDiscover,
  faCcJcb,
  faCcMastercard,
  faCcVisa,
  faExclamationTriangle,
  fasExclamationTriangle,
  faImage,
  faTrash,
  faQuestionCircle,
  faImages,
  faStar,
  fasStar,
  faStarHalfAlt,
  faSpinner,
  faLink,
  fasLink,
  faSearch,
  faSpotify,
  faApple,
  faSellsy,
  faSignOutAlt,
  faLongArrowAltRight,
  farMusic,
  faPlay,
  faPause,
  fasPause,
  faSatelliteDish,
  faCarBump,
  faSignIn,
  faSignOut,
  faPaperPlane,
  faUserSecret,
  faUserAstronaut,
  faDesktop,
  faRedo,
  faPowerOff,
  faChartLine,
  faUsers,
  faBolt,
  fasCircle,
  faCaretUp,
  faCaretDown,
  faFemale,
  faMale,
  faUserPlus,
  faList,
  faTh,
  faStopwatch,
  fasQuestionCircle,
  faMapMarkerAlt,
  faText,
  faRetweet,
  faTrashAlt,
  fasCheckCircle,
];

export default icons;
