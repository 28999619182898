





































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import CookieConsentModal from '@/shared/components/site/CookieConsentModal.vue';
import defaultErrorToast from '@/shared/lib/defaultToast';
import { Consent, Scope } from '@/shared/store/cookieConsent';

@Component({
  components: { CookieConsentModal },
})
export default class CookieConsentBanner extends Vue {
  @Prop({ default: false, type: Boolean }) dark: boolean;

  @Prop({ default: false, type: Boolean }) spacer: boolean;

  @Getter('cookieConsent/scopes') scopes: Scope[];

  @Getter('cookieConsent/gdpr') gdpr: boolean;

  @Getter('cookieConsent/requireUserConsent') required: boolean;

  get isTabletOrAbove() {
    return !['xs', 'sm'].includes(this.$mq);
  }

  handlePrivacyClick(event: Event) {
    if (this.$router) {
      event.preventDefault();
      this.$router.push({ name: 'privacy' });
    }
  }

  openConsentModal() {
    this.$root.$emit('bv::show::modal', 'cookie-consent-modal');
  }

  consent() {
    const consent: Consent[] = this.scopes.map((i) => ({ name: i.name, version: i.version, granted: true }));
    this.$store.dispatch('cookieConsent/update', { consent })
      .catch(() => {
        defaultErrorToast('Unexpected error saving cookie preferences');
      });
  }

  handleModalHidden() {
    if (!this.gdpr && this.required) {
      this.consent();
    }
  }
}
