import Vue from 'vue';
import Honeybadger from 'honeybadger-js';
import HoneybadgerVue from '@honeybadger-io/vue';

import Env from '@/shared/lib/env';

declare module 'vue/types/vue' {
  interface VueConstructor {
    $honeybadger: typeof Honeybadger;
  }
  interface Vue {
    $honeybadger: typeof Honeybadger;
  }
}

const options: any = Env.honeybadger || {};

Vue.use(HoneybadgerVue, {
  apiKey: options.apiKey,
  environment: options.environment || Env.name || 'development',
  revision: process.env.COMMIT_HASH,
});

if (!options.apiKey) {
  Vue.$honeybadger.beforeNotify((n) => {
    if (n) {
      // eslint-disable-next-line no-console
      console.error('[Honeybadger]', n.name, n.message, n);
    }
  });
}

export default Honeybadger;
