










import { Component, Prop, Vue } from 'vue-property-decorator';
import { BLANK_IMAGE_SRC } from '@/shared/store/pixels';

@Component({})
export default class Pixel extends Vue {
  @Prop({ required: true })
  src: string;

  hasLoadError: boolean = false;

  get srcOrFallback() {
    if (this.hasLoadError) {
      return BLANK_IMAGE_SRC;
    }
    return this.src;
  }

  handlePixelLoadError() {
    this.hasLoadError = true;
  }
}
