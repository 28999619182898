






















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { BModal } from 'bootstrap-vue';
import {
  disableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';

@Component
export default class SimpleModal extends Vue {
  $refs!: {
    modal: BModal,
  };

  @Prop({ required: true }) modalId: string;

  @Prop({ default: '' }) size: string;

  @Prop({ default: '' }) title: string;

  @Prop({ default: false, type: Boolean }) noCloseOnBackdrop: boolean;

  @Prop({ default: false, type: Boolean }) centered: boolean;

  @Prop({ default: false, type: Boolean }) restrictScrolling: boolean;

  @Prop({ default: false, type: Boolean }) allowScrolling: boolean;

  @Prop({ default: false, type: Boolean }) noCloseOnEsc: boolean;

  @Prop({ default: false, type: Boolean }) white: boolean;

  @Getter('layout/isMobile') isMobile: boolean;

  show() {
    this.$refs.modal.show();
  }

  hide(trigger?: string) {
    this.$refs.modal.hide(trigger);
  }

  lockBodyScroll() {
    if (this.$slots.default && this.$slots.default[0].elm) {
      disableBodyScroll(this.$slots.default[0].elm, {
        allowTouchMove: () => true,
      });
      if (this.$slots.default[0].elm instanceof HTMLElement && this.isMobile) {
        this.$slots.default[0].elm.style.minHeight = 'calc(100% + 10px)';
      }
    }
  }

  clearBodyScroll() {
    clearAllBodyScrollLocks();
  }
}
