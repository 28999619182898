





















































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { email } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { Getter } from 'vuex-class';

import MaterialInputField from '@/shared/components/form/MaterialInputField.vue';

@Component({
  mixins: [validationMixin],
  components: {
    MaterialInputField,
  },
  validations: {
    newEmailAddr: { email },
  },
})
export default class UpdateEmailFields extends Vue {
  $refs!: any;

  @Prop({ default: false }) disabled: boolean;

  @Prop({ default: '' }) labelColor: string;

  @Prop({ default: false }) darkBg: boolean;

  @Prop({ default: false }) narrowField: boolean;

  @Getter('profile/userEmail') userEmail: string;

  updating: boolean = false;

  newEmail: string = '';

  pwd: string = '';

  isMobile = window.innerWidth < 1096;

  errors = {
    email: '',
    password: '',
  };

  pwdVisible: boolean = false;

  touchMap = new WeakMap();

  delayTouch($v: any) {
    $v.$reset();
    if (this.touchMap.has($v)) {
      clearTimeout(this.touchMap.get($v));
    }
    this.touchMap.set($v, setTimeout($v.$touch, 1000));
  }

  delayTouchUntilBlur($v: any) {
    $v.$reset();
  }

  touchElement($v: any) {
    $v.$touch();
  }

  resetElement($v: any) {
    $v.$reset();
  }

  get invalid() {
    const $v = this.$v!;
    const result = this.newEmail === '' || this.newEmail === this.userEmail || this.pwd.length === 0 || $v.newEmailAddr!.$invalid
      || (this.errors.password && this.errors.password.length > 0) || (this.errors.email && this.errors.email.length > 0);
    return !!result;
  }

  @Watch('invalid')
  onChildChanged(val: boolean) {
    this.$emit('invalid', val);
  }

  get newEmailAddr() {
    return this.newEmail;
  }

  set newEmailAddr(value: string) {
    this.newEmail = value;
  }

  mounted() {
    this.clearFields();
  }

  clearFields() {
    this.newEmail = '';
    this.pwd = '';
  }

  togglePwdVisibility() {
    this.pwdVisible = !this.pwdVisible;
    return false;
  }

  updateEmail(): Promise<string | boolean> {
    this.updating = true;
    return this.$store.dispatch('profile/updateEmail', {
      newEmail: this.newEmail,
      currentEmail: this.userEmail,
      password: this.pwd,
    }).then(() => {
      const emailAddress = this.newEmail;
      this.clearFields();
      return emailAddress;
    }).catch((error) => {
      this.errors.email = 'Sorry, we were unable to update your email address';
      this.errors.password = '';
      if (error && error.code) {
        if (error.code === 'auth/wrong-password') {
          this.pwd = '';
          this.errors.email = '';
          this.errors.password = 'Invalid password';
        }
      }
      return false;
    }).finally(() => {
      this.updating = false;
    });
  }

  scrollIntoView(id: string) {
    if (this.isMobile) {
      const elem = document.getElementById(id);
      if (elem) {
        const yCoordinate = elem.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -150;
        window.scrollTo({
          top: yCoordinate + yOffset,
          behavior: 'smooth',
        });
      }
    }
  }
}
