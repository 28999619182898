import Vue from 'vue';
import Vuex, { StoreOptions, Plugin, createLogger } from 'vuex';
import Env from '@/shared/lib/env';

import { audioLibrary } from '@/shared/store/audioLibrary';
import { layout } from '@/shared/store/layout';
import { profile } from '@/shared/store/profile';
import { industry } from '@/shared/store/industry';
import { onboarding } from '@/shared/store/onboarding';
import { campaign } from '@/shared/store/campaign';
import { campaignReports } from '@/shared/store/campaignReports';
import { creatives } from '@/shared/store/creatives';
import { payment } from '@/shared/store/payment';
import { pixels } from '@/shared/store/pixels';
import { upload } from '@/shared/store/upload';
import { imageLibrary } from '@/shared/store/imageLibrary';
import { recommendedAds } from '@/shared/store/recommended_ads';
import { linkValidation } from '@/shared/store/linkValidation';
import { retailerSearch } from '@/shared/store/retailerSearch';
import { billingHistory } from '@/shared/store/billingHistory';
import { googleTagManager } from '@/shared/store/googleTagManager';
import { mixpanel } from '@/shared/store/mixpanel';
import { qualaroo } from '@/shared/store/qualaroo';
import { survey } from '@/shared/store/survey';
import { facebook } from '@/shared/store/facebook';
import { cookieConsent } from '@/shared/store/cookieConsent';
import { RootState } from './types';

const plugins: Plugin<RootState>[] = [];
if (Env.debug && Env.name !== 'test') {
  plugins.push(createLogger({}));
}

Vue.use(Vuex);

const modules = {
  audioLibrary,
  layout,
  profile,
  industry,
  onboarding,
  campaign,
  campaignReports,
  creatives,
  upload,
  payment,
  pixels,
  imageLibrary,
  recommendedAds,
  linkValidation,
  retailerSearch,
  billingHistory,
  googleTagManager,
  mixpanel,
  qualaroo,
  survey,
  facebook,
  cookieConsent,
};

// modules that cannot be bulk-reset such as on logout
const resetExempt: { [key: string]: boolean } = {
  layout: true,
  profile: true,
  cookieConsent: true,
};

const initialState: { [key: string]: any } = {};

for (const [moduleName, module] of Object.entries(modules)) {
  if (resetExempt[moduleName]) {
    continue;
  }
  initialState[moduleName] = { ...module.state };
}

export const storeConfig: StoreOptions<RootState> = {
  state: {
    version: '1.0.0',
  },
  mutations: {
    reset(state: { [key: string]: any }) {
      for (const [moduleName, resetState] of Object.entries(initialState)) {
        Object.assign(state[moduleName], resetState);
      }
    },
  },
  strict: Env.debug,
  modules,
  plugins,
};

export default new Vuex.Store<RootState>(storeConfig);
