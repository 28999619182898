














































































































































import { Getter } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import { validationMixin } from 'vuelidate';
import {
  email, required, minLength, maxLength,
} from 'vuelidate/lib/validators';
import { passwordRule } from '@/shared/lib/validationRules';
import defaultToast from '@/shared/lib/defaultToast';
import MaterialInputField from '@/shared/components/form/MaterialInputField.vue';
import ModalButton from '@/shared/components/modals/ModalButton.vue';
import SimpleModal from '@/shared/components/modals/SimpleModal.vue';
import Submittable from '@/shared/components/common/Submittable.vue';

@Component({
  mixins: [validationMixin],
  components: {
    MaterialInputField,
    ModalButton,
    SimpleModal,
    Submittable,
  },
  validations: {
    email: { email, required },
    password: { passwordRule, required },
    code: { required, minLength: minLength(7), maxLength: maxLength(7) },
  },
})
export default class ForgotPasswordModal extends Vue {
  $refs!: {
    form: HTMLFormElement,
    emailInput: HTMLFormElement,
    code: HTMLFormElement,
    password: HTMLFormElement,
    modal: SimpleModal,
  };

  processing: boolean = false;

  get emailInvalid() {
    return (this.$v.email!.$dirty && this.$v.email!.$invalid) || this.errors.email;
  }

  get passwordInvalid() {
    return (this.$v.password!.$dirty && this.$v.password!.$invalid) || this.errors.password;
  }

  get codeInvalid() {
    return (this.$v.code!.$dirty && this.$v.code!.$invalid) || this.errors.code;
  }

  invalid() {
    if (this.step === 1) {
      return !!(this.$v.email!.$invalid || this.errors.email);
    }
    if (this.step === 2) {
      return !!(this.$v.code!.$invalid || this.errors.code || this.$v.password!.$invalid || this.errors.password);
    }
    return true;
  }

  step: number = 1;

  email: string = '';

  password: string = '';

  rawCode: string = '';

  get code() {
    return this.rawCode;
  }

  set code(value: string) {
    this.rawCode = value.toUpperCase().replace(/[^A-Z0-9]*$/, '');
  }

  pwdVisible: boolean = false;

  errors = {
    email: '',
    password: '',
    code: '',
  };

  @Getter('layout/isMobile') isMobile: boolean;

  modalShown() {
    this.$store.dispatch('mixpanel/track', {
      properties: {
        locationSurfaced: this.$route.name,
      },
      action: 'Zire.ForgotPasswordStarted',
    });
  }

  touchMap = new WeakMap();

  delayTouch($v: any) {
    $v.$reset();
    if (this.touchMap.has($v)) {
      clearTimeout(this.touchMap.get($v));
    }
    this.touchMap.set($v, setTimeout($v.$touch, 1000));
  }

  delayTouchUntilBlur($v: any) {
    $v.$reset();
  }

  touchElement($v: any) {
    $v.$touch();
  }

  reset() {
    this.processing = false;
    this.step = 1;
    this.email = '';
    this.password = '';
    this.code = '';
    this.errors = {
      email: '',
      password: '',
      code: '',
    };
    this.$v.$reset();
  }

  togglePwdVisibility() {
    this.pwdVisible = !this.pwdVisible;
    return false;
  }

  closeModal(trigger?: string) {
    // this.$root.$emit('bv::hide::modal', 'forgot-password-modal');
    this.$refs.modal.hide(trigger);
  }

  sendEmail() {
    return this.$store.dispatch('profile/forgotPasswordEmail', { email: this.email });
  }

  resendEmail() {
    return this.$store.dispatch('profile/forgotPasswordEmail', { email: this.email }).then(() => {
      defaultToast('Email Sent', 'success');
    }).catch(() => {
      defaultToast("We weren't able to send the email. Please try again.");
    });
  }

  handleSubmit() {
    if (this.invalid()) {
      return;
    }
    switch (this.step) {
      case 1:
        this.processing = true;
        this.sendEmail()
          .then(() => {
            this.step = 2;
          })
          .catch((reason) => {
            if (reason.message === 'email not found') {
              this.errors.email = "We couldn't find an account with that email. Please try a different email.";
            } else {
              defaultToast("We weren't able to send the email. Please try again.");
            }
          })
          .finally(() => {
            this.processing = false;
          });
        break;
      case 2:
        this.processing = true;
        this.$store.dispatch('profile/forgotPasswordReset',
          { email: this.email, code: this.code, password: this.password })
          .then(() => {
            defaultToast('Password Reset', 'success');
            return this.$store.dispatch('profile/logIn', { email: this.email, password: this.password })
              .then(() => {
                this.$store.dispatch('mixpanel/track', {
                  properties: {
                    locationSurfaced: this.$route.name,
                  },
                  action: 'Zire.ForgotPasswordCompleted',
                });
                this.closeModal('forgot-password');
              });
          })
          .catch((reason) => {
            this.processing = false;
            if (reason.message === 'code expired') {
              this.errors.code = 'This code is invalid.';
            } else {
              defaultToast(reason.message);
            }
          });
        break;
      default:
          // noop
    }
  }
}
