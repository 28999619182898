import {
  ActionTree, GetterTree, Module, MutationTree,
} from 'vuex';
import { User as FirebaseUser } from 'firebase/app';
import {
  UpdateConsentsRequest,
  LoginRequest,
  UpdateEmailRequest,
  UpdatePasswordRequest,
  User,
  UserTokenRequest,
  ForgotPasswordEmailRequest,
  ForgotPasswordResetRequest,
  ConsentStatus,
} from '@/shared/gen/messages.pisa';
import { RootState } from '@/shared/store/types';
import { Firebase, FirebaseAuth } from '@/shared/firebase';
import { BuilderService } from '@/shared/lib/api';
import Deferred from '@/shared/lib/deferred';
import { Empty } from '@/shared/gen/google/protobuf/google.protobuf';
import defaultToast from '@/shared/lib/defaultToast';
import Env from '@/shared/lib/env';
import Facebook from '@/shared/lib/facebook';
import { countryNameForCode, isBlacklistedCountryCode } from '@/shared/lib/countries';

export interface UserInterface {
  id: string,
  uid: string,
  email: string,
  emailVerified: boolean,
  consents: ConsentStatus[],
}

interface claims {
  'zire:admin'?: string;
  'bandlab_account'?: string;
}

export interface ProfileState {
  user: UserInterface;
  firebaseReady: Promise<void>;
  loaded: boolean;
  anonymousToken: string;
  version: number; // optimistic locking for the polling operation
  newAccount: boolean;
  loggingIn: boolean; // HACK: bit of state to delay changing isLoggedInWithEmail before the LogIn request completes
  claims: claims;
}

const emptyUser = {
  id: '',
  uid: '',
  email: '',
  emailVerified: false,
  consents: [],
  claims: {},
};

const firebaseReadyDeferred = new Deferred<void>();

const initialState: ProfileState = {
  user: { ...emptyUser },
  firebaseReady: firebaseReadyDeferred.promise,
  loaded: false,
  anonymousToken: '',
  version: 0,
  newAccount: false,
  loggingIn: false,
  claims: {},
};

const afterAuthStateChanged: {(user: UserInterface): void}[] = [];

interface ConsentVersions {
  [key: string]: string;
}
interface ConsentMap {
  [key: string]: boolean;
}
const consentVersions: ConsentVersions = {
  marketing: '2019-12-09',
  terms: '2019-12-09',
  privacy: '2019-12-09',
};

const actions: ActionTree<ProfileState, RootState> = {

  $_pollCheckToken({ dispatch, state }, { uid, version }: { uid: string, version: number }): void {
    if (state.version !== version || !FirebaseAuth.currentUser) {
      return;
    }
    BuilderService(Promise.resolve(), (svc) => {
      svc.checkToken(new Empty()).then(() => {
        if (state.version !== version || !FirebaseAuth.currentUser || FirebaseAuth.currentUser.uid !== uid) {
          return;
        }
        setTimeout(() => {
          dispatch('$_pollCheckToken', { uid, version });
        }, 3000);
      }).catch((reason) => {
        if (reason && reason.code === 'unauthenticated') {
          if (FirebaseAuth.currentUser && FirebaseAuth.currentUser.uid === uid) {
            dispatch('logOut');
          }
        }
      });
    });
  },

  $_pollUntilEmailVerified({ dispatch, state }, { uid, version }: { uid: string, version: number }): void {
    if (state.version !== version || !FirebaseAuth.currentUser) {
      return;
    }
    FirebaseAuth.currentUser.reload().then(() => {
      if (state.version !== version || !FirebaseAuth.currentUser || FirebaseAuth.currentUser.uid !== uid) {
        return;
      }
      if (FirebaseAuth.currentUser.emailVerified) {
        FirebaseAuth.currentUser.getIdToken(true).then(() => {
          dispatch('onAuthStateChanged', FirebaseAuth.currentUser);
        });
      } else {
        setTimeout(() => {
          dispatch('$_pollUntilEmailVerified', { uid, version });
        }, 3000);
      }
    }).catch(() => {
      // User might have logged out or something... Stop polling.
    });
  },

  loadUser({ commit, dispatch, state }): Promise<UserInterface> {
    if (!FirebaseAuth.currentUser) {
      return Promise.reject();
    }
    return new Promise<UserInterface>((resolveGetUser) => {
      BuilderService(Promise.resolve(), (svc) => {
        resolveGetUser(svc.getUser(new Empty()).then((user) => {
          commit('profileLoaded', user);
          if (user.email && !user.emailVerified) {
            const { version } = state;
            setTimeout(() => {
              dispatch('$_pollUntilEmailVerified', { uid: user.uid, version });
            }, 5000);
          }
          return new Promise<UserInterface>((resolveAnonToken) => {
            if (user.email) {
              resolveAnonToken(user);
            } else {
              FirebaseAuth.currentUser!.getIdToken(false).then((idToken) => {
                commit('lastAnonymousToken', idToken);
                resolveAnonToken(user);
              });
            }
          });
        }).catch(() => {
          commit('profileLoaded');
          return Promise.resolve(state.user);
        }).finally(() => {
          this.dispatch('facebook/loadSyncedPages');
        }));
      });
    });
  },

  onAuthStateChanged({ commit, state }, payload: FirebaseUser): Promise<UserInterface> {
    if (payload) {
      payload.getIdTokenResult().then((idToken) => {
        commit('tokenClaims', idToken.claims);
      });
      return this.dispatch('profile/loadUser').finally(() => {
        afterAuthStateChanged.forEach((fn) => {
          fn(state.user);
        });
        afterAuthStateChanged.length = 0;
      });
    }

    // no payload, clear state
    commit('profileLoaded');
    afterAuthStateChanged.forEach((fn) => {
      fn(state.user);
    });
    afterAuthStateChanged.length = 0;
    return Promise.resolve(emptyUser);
  },

  loggedIn({ commit, dispatch, state }): Promise<UserInterface> {
    /*
      Ensure we are authenticated in some way by taking the following steps:
      1. Wait for Firebase to initialize and restore any previous sessions.
      2. Check to see if we're logged in and resolve with that user.
      3. Sign in anonymously and resolve with the new anonymous user.
    */
    return new Promise<UserInterface>((resolve) => {
      state.firebaseReady.then(() => {
        if (FirebaseAuth.currentUser) {
          if (FirebaseAuth.currentUser.uid === state.user.uid) {
            resolve(state.user);
          } else {
            resolve(dispatch('onAuthStateChanged', FirebaseAuth.currentUser));
          }
          return;
        }
        FirebaseAuth.signInAnonymously()
          .then(() => {
            afterAuthStateChanged.push(resolve);
          })
          .catch((reason) => {
            commit('profileLoaded');
            resolve(Promise.reject(reason));
          });
      });
    });
  },

  signUp({ commit }, { email, password, consents }): Promise<UserInterface> {
    const credential = Firebase.auth.EmailAuthProvider.credential(email, password);
    const updates: ConsentStatus[] = [];
    for (const [k, granted] of Object.entries(consents)) {
      updates.push(new ConsentStatus({
        consent: k,
        version: consentVersions[k],
        granted: (granted as boolean),
      }));
    }

    return new Promise<UserInterface>((resolve, reject) => {
      BuilderService(Promise.resolve(), (svc) => {
        svc.updateConsents(new UpdateConsentsRequest({ updates })).then(() => (
          FirebaseAuth.currentUser!.linkWithCredential(credential)
            .then((userCred) => {
              commit('newAccount', true);
              return this.dispatch('profile/onAuthStateChanged', userCred.user);
            }).then(resolve)
        )).catch(reject);
      });
    });
  },

  logIn({ state, commit }, { email, password }): Promise<UserInterface> {
    commit('loggingIn', true);
    return new Promise<UserInterface>((resolve, reject) => FirebaseAuth.signInWithEmailAndPassword(email, password)
      .then(() => {
        afterAuthStateChanged.push((user) => {
          BuilderService(Promise.resolve(), (svc) => {
            const tok = state.anonymousToken;
            svc.login(new LoginRequest({ anonymousToken: tok }))
              .then(() => {
                commit('lastAnonymousToken', '');
                resolve(user);
              })
              .catch(((reason) => {
                // Unable to complete login, abandon current session.  Not ideal but at least the
                // client wont' be broken.
                this.dispatch('profile/logOut').then(() => {
                  reject(reason);
                });
              }));
          });
        });
      })
      .catch(reject))
      .finally(() => {
        commit('loggingIn', false);
      });
  },

  logInWithBLToken({ state, commit }, { token }): Promise<UserInterface> {
    commit('loggingIn', true);
    return new Promise<UserInterface>((resolve, reject) => FirebaseAuth.signInWithCustomToken(token)
      .then(() => {
        afterAuthStateChanged.push((user) => {
          BuilderService(Promise.resolve(), (svc) => {
            const tok = state.anonymousToken;
            svc.login(new LoginRequest({ anonymousToken: tok }))
              .then(() => {
                commit('lastAnonymousToken', '');
                resolve(user);
              })
              .catch(((reason) => {
                // Unable to complete login, abandon current session.  Not ideal but at least the
                // client wont' be broken.
                this.dispatch('profile/logOut').then(() => {
                  reject(reason);
                });
              }));
          });
        });
      })
      .catch(reject))
      .finally(() => {
        commit('loggingIn', false);
      });
  },

  logInWithToken({ dispatch }, { userId, token }): Promise<void> {
    return new Promise((resolve, reject) => {
      BuilderService(Promise.resolve(), (svc) => {
        svc.loginWithToken(new UserTokenRequest({ userId, token }))
          .then((response) => {
            if (response.token) {
              return FirebaseAuth.signInWithCustomToken(response.token)
                .then((credential) => dispatch('onAuthStateChanged', credential.user));
            }
            return Promise.resolve();
          }).then(resolve).catch(reject);
      });
    });
  },

  logOut({ commit }): Promise<UserInterface> {
    if (Facebook.getAuthResponse()) {
      Facebook.logout().catch(() => {}); // Ignore logout errors...
    }
    // and by log out, we mean log in as a new anonymous user so that nothing is broken
    if (!FirebaseAuth.currentUser) {
      return this.dispatch('profile/loggedIn');
    }
    return FirebaseAuth.signOut().then(() => {
      commit('reset', null, { root: true });
      return this.dispatch('profile/loggedIn');
    });
  },

  reauthenticateWithCredential(_, { email, password }): Promise<FirebaseUser> {
    if (!FirebaseAuth.currentUser || FirebaseAuth.currentUser.isAnonymous) {
      return Promise.reject();
    }
    const credential = Firebase.auth.EmailAuthProvider.credential(email, password);
    // We don't want to return the user credential from this method, we just need to know whether or not it was valid.
    return FirebaseAuth.currentUser.reauthenticateWithCredential(credential).then((cred) => cred.user!);
  },

  updateEmail({ dispatch, state }, { newEmail, currentEmail, password }): Promise<void> {
    return dispatch('reauthenticateWithCredential', { email: currentEmail, password }).then((user: FirebaseUser) => (
      new Promise((resolve, reject) => {
        BuilderService(Promise.resolve(), (svc) => {
          svc.updateEmail(new UpdateEmailRequest({ email: newEmail })).then((response) => {
            if (response.token) {
              return FirebaseAuth.signInWithCustomToken(response.token)
                .then((credential) => dispatch('onAuthStateChanged', credential.user));
            }
            dispatch('$_pollCheckToken', { uid: user.uid, version: state.version });
            return Promise.resolve();
          }).then(resolve).catch(reject);
        });
      })
    ));
  },

  verifyEmail({ dispatch }, { userId, token }): Promise<void> {
    return new Promise((resolve, reject) => {
      BuilderService(Promise.resolve(), (svc) => {
        svc.verifyEmail(new UserTokenRequest({ userId, token })).then((response) => {
          if (response.token) {
            return FirebaseAuth.signInWithCustomToken(response.token)
              .then((credential) => dispatch('onAuthStateChanged', credential.user));
          }
          return Promise.resolve();
        }).then(resolve).catch(reject);
      });
    });
  },

  updatePassword({ commit }, { oldPassword, newPassword }): Promise<UserInterface> {
    if (!FirebaseAuth.currentUser || !FirebaseAuth.currentUser!.email) {
      return Promise.reject();
    }
    const email = FirebaseAuth.currentUser!.email!;
    return new Promise<UserInterface>((resolve) => {
      BuilderService(Promise.resolve(), (svc) => {
        // This invalidates existing credentials, so bail out of any existing polling.
        commit('incrementProfileVersion');
        resolve(svc.updatePassword(new UpdatePasswordRequest({ oldPassword, newPassword }))
          .then(() => FirebaseAuth.signInWithEmailAndPassword(email, newPassword))
          .then((userCred) => this.dispatch('profile/onAuthStateChanged', userCred.user)));
      });
    });
  },

  resendVerifyEmail() {
    if (!FirebaseAuth.currentUser || !FirebaseAuth.currentUser!.email) {
      return;
    }
    BuilderService(Promise.resolve(), (svc) => {
      svc.resendVerifyEmail(new Empty()).then(() => {
        defaultToast('Email sent!', 'success');
      }).catch(() => {
        defaultToast('Failed to send email.  Please try again.', 'error');
      });
    });
  },

  resetNewAccount({ commit }) {
    commit('newAccount', false);
  },

  updateConsent(_, { consent, granted }): Promise<Empty> {
    return new Promise<Empty>((resolve, reject) => {
      BuilderService(Promise.resolve(), (svc) => {
        const update = new ConsentStatus({
          consent,
          version: consentVersions[consent],
          granted,
        });
        svc.updateConsents(new UpdateConsentsRequest({ updates: [update] }))
          .then(resolve)
          .catch(reject);
      });
    });
  },

  forgotPasswordEmail(_, { email }): Promise<Empty> {
    return new Promise<Empty>((resolve, reject) => {
      BuilderService(Promise.resolve(), (svc) => {
        svc.forgotPasswordEmail(new ForgotPasswordEmailRequest({ email }))
          .then(resolve)
          .catch(reject);
      });
    });
  },

  forgotPasswordReset(_, { email, code, password }): Promise<Empty> {
    return new Promise<Empty>((resolve, reject) => {
      BuilderService(Promise.resolve(), (svc) => {
        svc.forgotPasswordReset(new ForgotPasswordResetRequest({ email, code, password }))
          .then(resolve)
          .catch(reject);
      });
    });
  },
};

const getters: GetterTree<ProfileState, RootState> = {
  userId(state): string {
    return state.user.id;
  },
  userEmail(state): string {
    return state.user.email;
  },
  bandlabAccount(state): string {
    return state.claims.bandlab_account || '';
  },
  isLoggedInWithEmail(state): boolean {
    return state.user.id !== '' && state.user.email !== '' && !state.loggingIn;
  },
  isNewAccount(state): boolean {
    return state.newAccount;
  },
  requiresEmailVerification(state): boolean {
    return state.user.id !== '' && state.user.email !== '' && !state.user.emailVerified;
  },
  blacklistedCountry(): string {
    if (isBlacklistedCountryCode(Env.country.code)) {
      return countryNameForCode(Env.country.code);
    }
    return '';
  },
  loaded(state): boolean {
    return state.loaded;
  },
  consentMap(state): object {
    const m: ConsentMap = {};
    for (const c of Object.keys(consentVersions)) {
      m[c] = false;
    }
    for (const c of state.user.consents) {
      m[c.consent] = c.granted;
    }
    return m;
  },
  adminImpersonator(state): string {
    return state.claims['zire:admin'] || '';
  },
};

const mutations: MutationTree<ProfileState> = {
  loggingIn(state, payload: boolean) {
    state.loggingIn = payload;
  },
  incrementProfileVersion(state) {
    state.version += 1;
  },
  profileLoaded(state, payload: User) {
    state.version += 1;
    if (payload) {
      state.user = {
        id: payload.id || emptyUser.id,
        uid: payload.uid || emptyUser.uid,
        email: payload.email || emptyUser.email,
        emailVerified: payload.emailVerified || emptyUser.emailVerified,
        consents: payload.consents || emptyUser.consents,
      };
    } else {
      state.user = { ...emptyUser };
      state.anonymousToken = '';
      state.claims = {};
    }
    if (!state.loaded) {
      state.loaded = true;
      firebaseReadyDeferred.resolve();
    }
  },
  lastAnonymousToken(state, payload: string) {
    state.anonymousToken = payload;
  },
  newAccount(state, payload: boolean) {
    state.newAccount = payload;
  },
  tokenClaims(state, payload: object) {
    if (payload) {
      state.claims = payload;
    } else {
      state.claims = {};
    }
  },
};

export const profile: Module<ProfileState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
