/// <reference types="stripe-v3" />

import Vue from 'vue';
import {
  ActionTree, GetterTree, Module, MutationTree,
} from 'vuex';
import Honeybadger from 'honeybadger-js';
import { RootState } from '@/shared/store/types';
import {
  Charge,
  IPaymentMethodResponse,
  SubmitCampaignRequest,
  UpdatePaymentMethodRequest,
  Campaign as APICampaign,
  ApplyCouponRequest,
  IApplyCouponResponse,
} from '@/shared/gen/messages.pisa';
import { Empty } from '@/shared/gen/google/protobuf/google.protobuf';
import { Draft } from '@/shared/store/onboarding';
import defaultErrorToast from '@/shared/lib/defaultToast';
import { BuilderService } from '@/shared/lib/api';
import Env from '@/shared/lib/env';

export interface ProcessingErrorMessage {
  code: string;
  status: number;
  message: string;
  param: string;
  requestId: string;
  type: string;
}

export interface PaymentState {
  coupon: IApplyCouponResponse;
  method: IPaymentMethodResponse;
  processing: boolean;
  errors: ProcessingErrorMessage[];
  receiptsByCampaign: {
    [index: number]: Charge;
  };
}

const initialState: PaymentState = {
  coupon: {},
  method: {},
  processing: false,
  errors: [],
  receiptsByCampaign: {},
};

export const StripeStatic = Stripe(Env.stripe.publishableKey);

const parseErrMessage = (error: any) => {
  const errBlob = { ...error };
  if (errBlob.code === 'processing_error') {
    errBlob.message = 'An error occurred while processing your card. Please review your payment details and try again.';
  }
  return errBlob;
};

const actions: ActionTree<PaymentState, RootState> = {
  load({ commit }): Promise<boolean> {
    commit('setProcessing', true);
    return new Promise((resolve) => {
      BuilderService(this.dispatch('profile/loggedIn'), (svc) => {
        svc.getPaymentMethod(new Empty(), {})
          .then((pm) => { commit('paymentMethodLoaded', pm); resolve(true); })
          .catch((reason) => {
            Honeybadger.notify(reason, 'PaymentError');
            commit('paymentMethodLoaded');
            resolve(false);
          })
          .finally(() => { commit('setProcessing', false); });
      });
    });
  },
  save({ commit }, payload): Promise<void> {
    commit('setProcessing', true);
    return new Promise((resolve, reject) => {
      BuilderService(this.dispatch('profile/loggedIn'), (svc) => {
        let stripePromise: Promise<any> = Promise.resolve();

        if (payload.savePayment) {
          stripePromise = StripeStatic.createToken(payload.elements);
        } else {
          stripePromise = StripeStatic.createSource(payload.elements);
        }
        stripePromise.then((result) => {
          const req = new UpdatePaymentMethodRequest({
            token: result,
          });
          svc.updatePaymentMethod(req).then((pm) => {
            commit('paymentMethodLoaded', pm);
            commit('setProcessing', false);
            resolve();
          }).catch((error: any) => {
            // no longer showing toast message for this, we're using the FE error display
            // Vue.toasted.error(error.message);
            commit('setProcessing', false);
            const errMsg = parseErrMessage(error);
            commit('setErrors', [errMsg]);
            reject(error);
          });
        }, (error) => {
          defaultErrorToast();
          // Vue.toasted.error(error.message);
          commit('setProcessing', false);
          const errMsg = parseErrMessage(error);
          commit('setErrors', [errMsg]);
          reject(error);
        });
      });
    });
  },
  delete({ commit }): Promise<void> {
    commit('setProcessing', true);
    return new Promise((resolve, reject) => {
      BuilderService(this.dispatch('profile/loggedIn'), (svc) => {
        svc.clearPaymentMethod(new Empty()).then(() => {
          commit('setProcessing', false);
          resolve();
        }).catch((error: any) => {
          // no longer showing toast message for this, we're using the FE error display
          // Vue.toasted.error(error.message);
          commit('setProcessing', false);
          const errMsg = parseErrMessage(error);
          commit('setErrors', [errMsg]);
          reject(error);
        });
      });
    });
  },
  checkout({ rootGetters, commit }, couponCode: string): Promise<void> {
    commit('setProcessing', true);
    return new Promise((resolve, reject) => {
      BuilderService(this.dispatch('profile/loggedIn'), (svc) => {
        const draft: Draft = rootGetters['onboarding/getDraft'];
        svc.submitCampaign(new SubmitCampaignRequest({
          campaignId: draft.id,
          budgetCents: draft.budgetCents,
          currency: draft.currency,
          durationDays: draft.durationDays,
          adNetworks: rootGetters['onboarding/getValidAdNetworks'],
          couponCode,
          source: draft.source,
        })).then((res) => {
          commit('storeReceiptForCampaign', { id: res.campaign.id, charge: res.charge });
          this.dispatch('profile/resetNewAccount');
          this.dispatch('googleTagManager/trackEvent', {
            event: 'Purchase',
            data: {
              receiptId: `receipt_${res.charge.id}`,
              budget: res.campaign.budget.toJSON(),
              durationDays: res.campaign.durationDays,
            },
          });
          this.dispatch('campaign/storeCampaign', res.campaign).then(() => {
            commit('setProcessing', false);
            resolve();
          });
        }).catch((error) => {
          // no longer showing toast message for this, we're using the FE error display
          // Vue.toasted.error(error.message);
          commit('setProcessing', false);
          const errMsg = parseErrMessage(error);
          commit('setErrors', [errMsg]);
          reject(error);
        });
      });
    });
  },
  applyCoupon({ commit }, { campaignId, code }: { [key: string]: string }): Promise<IApplyCouponResponse> {
    return new Promise((resolve, reject) => {
      BuilderService(Promise.resolve(), (svc) => {
        const applyCouponRequest = new ApplyCouponRequest({ campaignId, code });
        svc.applyCoupon(applyCouponRequest).then((resp) => {
          commit('setAppliedCoupon', resp);
          commit('onboarding/setCouponCode', resp.code, { root: true });
          resolve(resp);
        }).catch((e) => {
          reject(e);
        });
      });
    });
  },
  clearCoupon({ commit }): Promise<void> {
    commit('setAppliedCoupon');
    commit('onboarding/setCouponCode', '', { root: true });
    return Promise.resolve();
  },
  loadReceiptForCampaign({ commit }, id): Promise<Charge> {
    return new Promise((resolve, reject) => {
      BuilderService(this.dispatch('profile/loggedIn'), (svc) => {
        svc.campaignReceipt(new APICampaign({ id })).then((charge) => {
          commit('storeReceiptForCampaign', { id, charge });
          resolve(charge);
        }).catch((e) => {
          reject(e);
        });
      });
    });
  },
};

const getters: GetterTree<PaymentState, RootState> = {
  getPayment(state): IPaymentMethodResponse {
    return { ...state.method };
  },
  isProcessing(state): boolean {
    return state.processing;
  },
  getErrors(state): Array<ProcessingErrorMessage> {
    return state.errors.slice(0);
  },
  receiptForCampaign(state) {
    return (id: number): Charge | undefined => state.receiptsByCampaign[id];
  },
  appliedCoupon(state) {
    return state.coupon;
  },
};

const mutations: MutationTree<PaymentState> = {
  paymentMethodLoaded(state: PaymentState, payload?: IPaymentMethodResponse) {
    if (payload && payload.last4) {
      state.method = {
        brand: payload.brand,
        last4: payload.last4,
        expMonth: payload.expMonth,
        expYear: payload.expYear,
        valid: payload.valid,
      };
    } else {
      state.method = {};
    }
  },
  setErrors(state: PaymentState, payload: Array<string>) {
    state.errors = payload.map((element) => {
      try {
        return JSON.parse(element);
      } catch {
        return element;
      }
    });
  },
  setProcessing(state: PaymentState, payload: boolean) {
    state.processing = payload;
  },
  resetErrors(state: PaymentState) {
    state.errors = [];
  },
  storeReceiptForCampaign(state: PaymentState, { id, charge }) {
    Vue.set(state.receiptsByCampaign, id, charge);
  },
  setAppliedCoupon(state: PaymentState, payload?: IApplyCouponResponse) {
    if (payload) {
      state.coupon = payload;
    } else {
      state.coupon = {};
    }
  },
};

export const payment: Module<PaymentState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
