





































































import { Component, Vue } from 'vue-property-decorator';
import { email } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { Getter } from 'vuex-class';
import UpdateEmailFields from '@/shared/components/form/UpdateEmailFields.vue';
import defaultToast from '@/shared/lib/defaultToast';

const emailDomains: {[index: string]: any} = {
  'gmail.com': 'https://mail.google.com',
  'hotmail.com': 'https://www.hotmail.com',
  'aol.com': 'https://mail.aol.com',
  'yahoo.com': 'https://mail.yahoo.com',
  'earthlink.net': 'https://webmail.earthlink.net',
  'outlook.com': 'https://www.outlook.com',
  'zire.com': 'https://mail.google.com',
};

@Component({
  mixins: [validationMixin],
  components: { UpdateEmailFields },
  validations: {
    newEmailAddr: { email },
  },
})
export default class VerifyEmailNotice extends Vue {
  $refs!: {
    updateEmailFields: UpdateEmailFields,
  };

  @Getter('profile/userEmail') userEmail: string;

  @Getter('layout/isMobile') isMobile: boolean;

  invalid: boolean = true;

  errors: string = '';

  updatingEmail: boolean = false;

  updateInProgress: boolean = false;

  get emailDomainAddress() {
    if (this.userEmail) {
      const domainKey = this.userEmail.split('@')[1].toString();
      return emailDomains[domainKey] || null;
    }
    return null;
  }

  openTab(link: string) {
    window.open(link, '_blank');
  }

  resendEmail() {
    this.$store.dispatch('profile/resendVerifyEmail');
  }

  changeEmail() {
    this.updatingEmail = true;
  }

  updateEmail() {
    if (this.invalid) {
      return;
    }
    this.updateInProgress = true;
    this.$refs.updateEmailFields!.updateEmail().then((emailOrFalse: string | boolean) => {
      if (emailOrFalse !== false) {
        defaultToast('Email updated and verification link resent!', 'success');
        this.updatingEmail = false;
      }
    }).finally(() => {
      this.updateInProgress = false;
    });
  }
}
