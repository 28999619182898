



























































































































import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import format from 'date-fns/format';
import { differenceInCalendarDays, isAfter } from 'date-fns';
import { campaignAudio, campaignImage } from '@/shared/lib/campaign';
import { Campaign } from '@/shared/store/campaign';
import LoadingIndicator from '@/shared/components/campaign/LoadingIndicator.vue';
import AbbreviatedValue from '@/shared/components/common/AbbreviatedValue.vue';
import { adNetworksCopy } from '@/shared/lib/budgetOptions';

@Component({
  components: {
    AbbreviatedValue,
    LoadingIndicator,
  },
})
export default class CompletedCampaignCard extends Vue {
  @Prop({ required: true })
  private campaign!: Campaign;

  @Prop({ default: 0 })
  private impressions: number;

  @Prop({ default: 0 })
  private interactions: number;

  @Prop({ default: false })
  private featured: boolean;

  loading: boolean = false;

  get campaignAudio() {
    return campaignAudio(this.campaign);
  }

  get adNetworksText() {
    return adNetworksCopy(this.campaign.adNetworks);
  }

  get songTitle() {
    if (this.campaignAudio) {
      return this.campaignAudio.source.name;
    }
    return '';
  }

  get daysRun() {
    if (this.endDate && this.startDate && isAfter(this.endDate, this.startDate)) {
      return Math.max(1, differenceInCalendarDays(this.endDate, this.startDate));
    }
    return -1;
  }

  get formattedStart() {
    if (!this.startDate) {
      return '';
    }
    if (this.endDate && format(this.startDate, 'yyyy') === format(this.endDate, 'yyyy')) {
      return format(this.startDate, 'MMM d');
    }
    return format(this.startDate, 'MMM d, yyyy');
  }

  get startDate() {
    return this.campaign.activatedAt;
  }

  get formattedEnd() {
    if (!this.endDate) {
      return '';
    }
    return format(this.endDate, 'MMM d, yyyy');
  }

  get endDate() {
    return this.campaign.endedAt;
  }

  get imageUrl() {
    return campaignImage(this.campaign);
  }

  handleClick() {
    this.loading = true;
    this.$router.push({
      name: 'campaign-results',
      params: { id: this.campaign.id! },
    }).then(() => {
      this.loading = false;
    });
  }
}
