















































































































































































































































































































































import {
  Component, Prop, Provide, Vue, Watch,
} from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import StandardLayout from '@/shared/components/common/StandardLayout.vue';
import HeaderCta from '@/shared/components/site/HeaderCta.vue';
import HeaderLink from '@/shared/components/site/HeaderLink.vue';
import LogoLink from '@/shared/components/site/LogoLink.vue';
import MobileNavLink from '@/shared/components/site/MobileNavLink.vue';
import BackButton from '@/shared/components/site/BackButton.vue';
import CookieConsentBanner from '@/shared/components/site/CookieConsentBanner.vue';

@Component({
  components: {
    CookieConsentBanner,
    BackButton,
    HeaderCta,
    HeaderLink,
    LogoLink,
    MobileNavLink,
    StandardLayout,
  },
})
export default class SiteLayout extends Vue {
  @Getter('profile/isLoggedInWithEmail') isLoggedInWithEmail: boolean;

  @Getter('layout/isMobile') isMobile: boolean;

  @Getter('layout/isTouch') isTouch: boolean;

  @Getter('cookieConsent/requireUserConsent') showCookieConsent: boolean;

  @Prop({ default: '' })
  layoutClass: string;

  @Prop({ default: false })
  hasBackNav: boolean;

  @Prop({ default: () => {} })
  onBack: Function;

  @Prop({ default: true })
  hideNavOnScroll: boolean;

  @Prop({ default: false })
  hideFooter: boolean;

  @Prop({ default: '' })
  isProcessing: boolean;

  @Prop({ default: true })
  isStandalone: boolean;

  @Prop({ default: false })
  hasBottomControl: boolean;

  @Prop({ default: '' })
  additionalClass: string;

  @Prop({ default: true })
  showTopGetStarted: boolean;

  expanded: boolean = false;

  scrollDist: number = 0;

  maxScrollDist: number = 0;

  hideNav: boolean = false;

  @Watch('isTouch') isTouchWatcher(newVal: boolean) {
    if (!newVal) {
      this.expanded = false;
    }
  }

  get siteLayoutClass() {
    const classes = [];
    if (this.isStandalone) {
      classes.push('site-layout');
    } else {
      classes.push('partner-layout');
    }
    classes.push(this.additionalClass);
    return classes;
  }

  get getStartedCtaFilled() {
    return (this.isHomepage || this.isHowItWorks) && !this.transparentNavbar;
  }

  get transparentNavbar() {
    return !this.expanded && (((this.isHomepage || this.isHowItWorks) && this.scrollDist === 0)
      || (this.isManifesto && (!this.isTouch && 150 - this.scrollDist > 0)));
  }

  get isHomepage() {
    return this.$route.name === 'home';
  }

  get isHowItWorks() {
    return this.$route.name === 'how-it-works';
  }

  get isManifesto() {
    return this.$route.name === 'future';
  }

  get isCampaigns() {
    return this.$route.name === 'campaigns';
  }

  handleLogoLinkClick() {
    this.expanded = false;
  }

  mounted() {
    window.addEventListener('scroll', this.handleScroll, true);
    if (this.showCookieConsent) {
      this.$store.dispatch('cookieConsent/bannerShown');
    }
  }

  destroyed() {
    window.removeEventListener('scroll', this.handleScroll, true);
  }

  handleScroll() {
    const dist = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
    if (this.hideNavOnScroll) {
      if (dist > this.scrollDist && dist >= 350) {
        this.hideNav = true;
      } else if (dist < this.maxScrollDist - 500 || dist < 350) {
        this.hideNav = false;
        this.maxScrollDist = 0;
      }
    }
    this.scrollDist = dist;
    if (this.hideNav && this.maxScrollDist <= this.scrollDist) {
      this.maxScrollDist = this.scrollDist;
    }
  }

  get currentYear() {
    const d = new Date();
    return d.getFullYear();
  }

  navTo(name: string) {
    if (this.$router.currentRoute.name !== name) {
      this.$router.push({
        name,
      });
    }
  }

  linkActive(name: string) {
    return this.$route.path.endsWith(name);
  }

  login() {
    this.expanded = false;
    this.$root.$emit('bv::show::modal', 'login-modal');
  }

  logOut(): void {
    this.expanded = false;
    this.$store.dispatch('profile/logOut');
  }

  newCampaign() {
    return this.$router.push({
      name: 'new-campaign',
      query: this.$route.query,
    });
  }

  // created() {}

  // beforeCreate() {}

  forceHiddenNavBar(hide: boolean) {
    this.hideNav = hide;
    if (hide) {
      window.removeEventListener('scroll', this.handleScroll, true);
    } else {
      window.addEventListener('scroll', this.handleScroll, true);
    }
  }

  get buildString() {
    return `Version: ${process.env.COMMIT_HASH} Build Time: ${process.env.BUILD_TIMESTAMP}`;
  }

  @Provide('forceHiddenNavBar') hideNavBarInjection: Function = this.forceHiddenNavBar;

  itemClicked(clickedItem?: string) {
    this.$store.dispatch('mixpanel/track', {
      properties: {
        clickedItem,
      },
      action: 'Zire.SiteLayoutItemClicked',
    });
  }
}
