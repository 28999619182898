
















import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';

@Component
export default class FlipReveal extends Vue {
  @Prop({ default: false }) flip: boolean;

  @Prop({ default: 0 }) randomizationScale: number;

  flipped: boolean = false;

  @Watch('flip')
  flipWatcher() {
    if (this.flip) {
      setTimeout(() => {
        this.flipped = this.flip;
      }, Math.random() * this.randomizationScale);
    }
  }
}
