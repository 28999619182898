import {
  ActionTree, GetterTree, Module, MutationTree,
} from 'vuex';
import { RootState } from '@/shared/store/types';
import { ValidateURLRequest } from '@/shared/gen/messages.pisa';
import defaultToast from '@/shared/lib/defaultToast';
import { BuilderService } from '@/shared/lib/api';

const alternateRetailerRegex = [
  {
    retailer: 'reverbnation',
    regex: /^https?:\/\/(?:[^/]\.)*reverbnation\.com\//i,
  },
  {
    retailer: 'bandcamp',
    regex: /^https?:\/\/(?:[^/]\.)*bandcamp\.com\//i,
  },
  {
    retailer: 'soundcloud',
    regex: /^https?:\/\/(?:[^/]\.)*soundcloud\.com\//i,
  },
  {
    retailer: 'apple',
    regex: /^https?:\/\/(?:[^/]\.)*music\.apple\.com\//i,
  },
];

export interface VendorLink {
  name: string;
  artistName: string;
  url: string;
}

export interface ProviderLinks {
  processing: boolean;
  errors: string[];
  [key:string]: any; // stupid typescript index signatures...
}

export const initialState: ProviderLinks = {
  processing: false,
  errors: [],
};

const actions: ActionTree<ProviderLinks, RootState> = {
  validate({ commit }, payload: string): Promise<any> {
    commit('setProcessing', true);
    return new Promise((resolve, reject) => {
      BuilderService(this.dispatch('profile/loggedIn'), (svc) => {
        const validateURL = new ValidateURLRequest({ url: payload });
        svc.validateURL(validateURL, {}).then((validateResults) => {
          commit('setProcessing', false);
          if (validateResults.valid) {
            // this.dispatch('linkValidation/search', payload);
            resolve(validateResults.valid);
          } else if (validateResults.responseCode < 400) {
            // this shouldn't really happen
            resolve(true);
            // this.dispatch('linkValidation/search', payload);
          } else {
            // this.dispatch('linkValidation/clearRetailerLinks');
            resolve(false);
          }
        }).catch((error: any) => {
          // this.dispatch('linkValidation/clearRetailerLinks');
          reject(error);
          defaultToast();
          // Vue.toasted.error(error.message);
          commit('setErrors', [error.message]);
        });
      });
    });
  },
};

const getters: GetterTree<ProviderLinks, RootState> = {
  getVendorForLink() {
    return (payload: string): string => {
      let result = '';
      alternateRetailerRegex.forEach((altRetailer) => {
        if (altRetailer.regex.test(payload)) {
          result = altRetailer.retailer;
        }
      });
      return result;
    };
  },
  getLinkValidation(state): ProviderLinks {
    return { ...state };
  },
  getErrors(state): Array<string> {
    return state.errors.slice(0);
  },
  isProcessing(state): boolean {
    return state.processing;
  },
};

const mutations: MutationTree<ProviderLinks> = {
  setErrors(state: ProviderLinks, payload: Array<string>) {
    state.errors = payload.slice(0);
  },
  setProcessing(state: ProviderLinks, payload: boolean) {
    state.processing = payload;
  },
};

export const linkValidation: Module<ProviderLinks, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
